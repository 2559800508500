<template>
  <div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <b-tabs
              pills
              card
              no-key-nav
              content-class="custom-card"
              nav-wrapper-class="custom-nav-card"
            >
              <b-tab no-body :title="$t('all')" active>
                <template v-slot:title>
                  <i class="fa fa-list ml-2 mr-2"></i> {{ $t("all") }}
                </template>
                <b-card-text>
                  <b-card no-body class="card">
                    <b-card-header>
                      <div class="card-tools">
                        <div class="input-group input-group-sm">
                          <input
                            type="text"
                            class="form-control float-right"
                            :placeholder="$t('search')"
                            v-model="search_key"
                          />
                          <div class="input-group-append">
                            <button
                              type="submit"
                              :disabled="search_disabled"
                              @click.prevent="searchAll"
                              class="btn btn-default"
                            >
                              <i
                                class="fa fa-spinner fa-spin"
                                v-if="search_disabled"
                              ></i>
                              <i class="fas fa-search" v-else></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-card-header>
                    <!-- /.card-header -->
                    <b-card-body class="table-responsive p-0">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th>
                              #
                            </th>
                            <th>
                              {{ $t("title") }}
                            </th>
                            <th>
                              {{ $t("to") }}
                            </th>
                            <th>
                              {{ $t("created_at") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td v-if="!load" colspan="4">
                              <i class="fa fa-sync  fa-spin load-con"></i>
                            </td>
                            <td
                              class="no_data"
                              colspan="4"
                              v-if="load && notifications.length == 0"
                            >
                              {{ $t("no_data") }}
                            </td>
                          </tr>
                          <tr
                            v-for="(item, i) in notifications"
                            :key="i"
                            v-show="load"
                          >
                            <td>
                              {{ i + 1 }}
                            </td>
                            <td>{{ item.title }}</td>
                            <td>{{ item.to_type }}</td>
                            <td v-html="dateTimeFormat(item.created_at)"></td>
                          </tr>
                        </tbody>
                      </table>
                    </b-card-body>
                    <!-- /.card-body -->
                    <b-card-footer class="card-footer clearfix">
                      <b-pagination
                        class="m-2"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        :prev-text="$t('previous')"
                        :next-text="$t('next')"
                        align="right"
                        :first-number="true"
                        :last-number="true"
                      ></b-pagination>
                    </b-card-footer>
                  </b-card> </b-card-text
              ></b-tab>
              <b-tab no-body :title="$t('send')">
                <template v-slot:title>
                  <i class="fa fa-paper-plane ml-2 mr-2"></i> {{ $t("send") }}
                </template>
                <b-card-text>
                  <b-card no-body class="card p-3">
                    <form class="col-md-8">
                      <div class="row">
                        <div class="form-group col-12">
                          <label class="form-label">{{ $t("to") }}</label>
                          <select
                            class="custom-select"
                            v-model="add_data.to_type"
                            :class="{
                              'is-invalid': add_errors.to_type
                            }"
                          >
                            <option value="" disabled selected>{{
                              $t("select")
                            }}</option>
                            <option value="all">{{ $t("all") }}</option>
                            <option value="users">{{ $t("users") }}</option>
                            <option value="vendors">{{ $t("vendors") }}</option>
                            <option value="custom">{{ $t("custom") }}</option>
                          </select>
                          <span class="error invalid-feedback">{{
                            (add_errors.to_type || []).join("-")
                          }}</span>
                        </div>

                        <div
                          class="form-group col-12"
                          v-if="add_data.to_type === 'custom'"
                        >
                          <label class="form-label">{{ $t("users") }}</label>
                          <multiselect
                            v-model="add_data.to_ids"
                            :options="users"
                            :multiple="true"
                            :close-on-select="false"
                            :placeholder="$t('select')"
                            label="mobile"
                            track-by="mobile"
                            :taggable="false"
                          >
                            <template slot="singleLabel" slot-scope="props"
                              ><span class="option__desc"
                                ><span class="option__title">{{
                                  props.option.name
                                }}</span></span
                              ></template
                            >
                            <template slot="option" slot-scope="props">
                              <div class="option__desc">
                                <p class="option__title mb-1">
                                  {{ props.option.name }}
                                </p>
                                <p class="option__small mb-0">
                                  {{ props.option.mobile }}
                                </p>
                              </div>
                            </template>
                          </multiselect>
                          <span class="error invalid-feedback">{{
                            (add_errors.to_ids || []).join("-")
                          }}</span>
                        </div>
                        <div class="form-group col-12">
                          <label class="form-label">{{ $t("subject") }}</label>
                          <input
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid': add_errors.title
                            }"
                            v-model="add_data.title"
                          />
                          <span class="error invalid-feedback">{{
                            (add_errors.title || []).join("-")
                          }}</span>
                        </div>
                        <div class="form-group col-12">
                          <label class="form-label">{{ $t("desc") }}</label>
                          <textarea
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid': add_errors.body
                            }"
                            v-model="add_data.body"
                          ></textarea>
                          <span class="error invalid-feedback">{{
                            (add_errors.body || []).join("-")
                          }}</span>
                        </div>
                        <div class="col-12">
                          <button
                            class="btn btn-sm btn-primary"
                            :disabled="add_disabled"
                            @click.prevent="sendNotification"
                          >
                            <b-spinner small v-if="add_disabled"></b-spinner>
                            {{ $t("send") }}
                          </button>
                        </div>
                      </div>
                    </form>
                  </b-card>
                </b-card-text>
              </b-tab>
            </b-tabs>

            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
export default {
  name: "AppNotifications",
  components: { Multiselect },
  data() {
    return {
      load: false,
      notifications: [],
      currentPage: 1,
      rows: 0,
      perPage: 0,
      search_key: "",
      search_disabled: false,
      add_data: {
        to_ids: [],
        to_type: ""
      },
      add_errors: {},
      add_disabled: false,
      users: []
    };
  },
  watch: {
    currentPage() {
      this.getNotifications();
    }
  },
  created() {
    this.getNotifications();
    this.getUsers();
  },
  methods: {
    getUsers() {
      return this.axios.get("users").then(({ data }) => {
        if (data.error_flag === 0) {
          this.users = data.result.users;
        }
      });
    },
    getNotifications() {
      return this.axios
        .get("app_notifications", {
          params: {
            page: this.currentPage,
            search_key: this.search_key
          }
        })
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.notifications = data.result.app_notifications.data;
            this.rows = data.result.app_notifications.pagination.total;
            this.perPage = data.result.app_notifications.pagination.per_page;
          }
        })
        .finally(() => {
          this.load = true;
        });
    },
    sendNotification() {
      this.add_disabled = true;
      this.add_errors = {};
      let fd = new FormData();

      Object.keys(this.add_data).forEach(key => {
        if (key === "to_ids") {
          if (this.add_data[key].length !== 0) {
            this.add_data[key].forEach((user, index) => {
              fd.append(`to_ids[${index}]`, user.id);
            });
          }
        } else if (key === "to_type") {
          if (/custom/gi.test(this.add_data[key])) {
            fd.delete("to_ids");
          }
          fd.append(key, this.add_data[key]);
        } else {
          fd.append(key, this.add_data[key]);
        }
      });

      return this.axios
        .post("app_notifications/send", fd)
        .then(({ data }) => {
          if (data.error_flag === 0) {
            this.add_data = {
              to_ids: [],
              to_type: ""
            };
            this.$noty.success(data.message);
            this.getNotifications();
          }
        })
        .catch(({ response }) => {
          let { error_flag, message } = response.data;
          if (error_flag === 1) {
            if (typeof message == "object") {
              this.add_errors = message;
            } else {
              this.$noty.error(message);
            }
          }
        })
        .finally(() => {
          this.add_disabled = false;
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        });
    },
    searchAll() {
      this.search_disabled = true;
      this.getNotifications().then(() => {
        this.search_disabled = false;
      });
    }
  }
};
</script>
