import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
    en: {
        dashboard: "Dashboard",
        en: "English",
        ar: "Arabic",
        my_info: "My info",
        logout: "Log Out",
        home: "Home",
        add: "Add",
        edit: "Edit",
        save: "Save",
        delete: "Delete",
        change_status: "Change status",
        all: "All",
        website: "Go to website",
        products: "Products",
        products_2: "Products",
        product: "Product",
        product_2: "Product",
        name: "Name",
        image: "Image",
        desc: "Description",
        created_at: "Created at",
        created_at_r: "Reservation date",
        status: "Status",
        login: "Sign in",
        login_desc: "Sign in to the control panel",
        search: "Search",
        phone: "Phone",
        mobile: "Mobile",
        birth_date: "Birth date",
        experience: "Experience",
        education: "Education",
        email: "E-mail",
        actions: "Actions",
        on: "On",
        soon: "Soon",
        off: "Off",
        previous: "Previous",
        next: "Next",
        no_data: "No data found",
        select: "Select",
        ok: "OK",
        cancel: "Cancel",
        yes: "Yes",
        no: "No",
        error: "Error",
        error_msg: "An Unexpected Error Occurred",
        choose_file: "Choose a file",
        choose_image: "Choose an image",
        brand: "Brand",
        brand_2: "Brand",
        brands: "Brands",
        brands_2: "Brands",
        category: "Category",
        category_2: "Category",
        categories: "Categories",
        categories_2: "Categories",
        sub_category: "Sub category",
        sub_category_2: "Sub category",
        sub_categories: "Sub categories",
        sub_categories_2: "Sub categories",
        are_you_sure: "Are you sure?",
        password: "Password",
        c_password: "Current password",
        n_password: "New password",
        v_password: "Verify new password",
        show: "Show",
        info: "Information",
        price: "Price",
        image_must_image: "The Image must be an image format",
        reservations: "Reservations",
        egp: "EGP",
        settings: "Settings",
        images: "Images",
        home_page: "Home page",
        url: "Url",
        website_info: "Website info",
        logo: "Logo",
        site_keywords: "Keywords",
        contact_info: "Contacts info",
        address: "Address",
        pages: "Pages",
        page: "Page",
        page_2: "Page",
        title: "Title",
        content: "Content",
        payment_gate: "Current Payment gate",
        payment_gates: "Payment gates",
        new_method: "New method",
        m_apps: "Apps",
        m_app: "App",
        m_app_2: "App",
        amount: "Amount",
        admins: "Employees",
        admin: "Employee",
        admin_2: "Employee",
        password_v: "Verify password",
        groups: "Groups",
        group: "Group",
        group_2: "Group",
        permissions: "Permissions",
        g_section: "Section",
        view: "View",
        otp: "OTP",
        error_mobile: "Cannot verify the mobile number",
        error_otp: "Wrong verification code",
        app_info: "App info",
        copy: "Copy",
        copied: "Copied",
        two_factors: "Two authentication",
        menu: "Menu",
        texts: "Texts",
        footet_text: "Footer text",
        timezone: "TimeZone",
        cairo: "Cairo",
        riyadh: "Riyadh",
        my_zone: "My timezone",
        countries: "Countries",
        balance: "Balance",
        current_balance: "Current balance",
        extra_balance: "Extra balance",
        payment_method: "Payment method",
        pay: "Pay",
        send: "Send",
        last_updated: "Last updated",
        succeeded: "Succeeded",
        failed: "Failed",
        notes: "Notes",
        pending: "Pending",
        balance_info: "Balance info",
        av_balance: "Available balance",
        posts: "Posts",
        posts_2: "Posts",
        post: "Post",
        post_2: "Post",
        type: "Type",
        text: "Text",
        textarea: "Textarea",
        number: "Number",
        options: "Options",
        options_2: "Options",
        option: "Option",
        option_2: "Option",
        boolean: "Yes / No",
        attributes: "Attributes",
        attribute: "Attribute",
        attribute_2: "Attribute",
        attribute_sets: "Attribute sets",
        attribute_set: "Attribute set",
        attribute_set_2: "Attribute set",
        city: "City",
        city_2: "City",
        hour_price: "Hour price",
        day_price: "Day price",
        sar: "SAR",
        firstly_select_category: "Firstly select category",
        users: "Clients",
        users_2: "Clients",
        user: "Client",
        user_2: "Client",
        slider: "Slider",
        slides: "Slides",
        slides_2: "Slides",
        slide: "Slide",
        slide_2: "Slide",
        banners: "Banners",
        banners_2: "Banners",
        banner: "Banner",
        banner_2: "Banner",
        sizes: "Sizes",
        sizes_2: "Sizes",
        size: "Size",
        size_2: "Size",
        coupons: "Coupons",
        coupons_2: "Coupons",
        coupon: "Coupon",
        coupon_2: "Coupon",
        discounts: "Discounts",
        discounts_2: "Discounts",
        discount_2: "Discount",
        icon: "Icon",
        slide_url: "Link",
        r_type: "Reservation type",
        day: "Day",
        hour: "Hour",
        quantity: "Quantity",
        qty: "Quantity",
        sku: "SKU",
        discount: "Discount ",
        discount_type: "Discount type",
        fixed_value: "Fixed",
        percentage: "Percentage",
        discount_start: "Start at",
        discount_end: "End at",
        discount_value: "Discount value",
        in_stock: "In stock",
        orders: "Orders",
        orders_2: "Orders",
        order: "Order",
        order_2: "Order",
        NEW: "New",
        CONFIRMED: "Confirmed",
        PREPAIRING: "Prepairing",
        DELIVERING: "Delivering",
        DELIVERED: "Delivered",
        COMPLETED: "Completed",
        CANCELLED: "Cancelled",
        PENDING: "Pending",
        REFUNDED: "Refunded",
        order_created: "Order created",
        order_confirmed: "Order Confirmed",
        order_prepared: "Prepairing order",
        order_delivered: "Delivering",
        order_completed: "Completed",
        cm: "Cm",
        prepared_time: "Prepared time",
        go_to_next_status: "Go to next status",
        total: "Total",
        change_order_msg: "Are you sure to change order status ?",
        search_select: "Search & select",
        min_spend: "Min spend",
        max_spend: "Max spend",
        coupon_limit: "Limit",
        user_limit: "Limit to each user",
        contacts: "Messages",
        contacts_2: "Messages",
        contact: "Message",
        contact_2: "Message",
        reviews: "Reviews",
        reviews_2: "Reviews",
        review: "Review",
        review_2: "Review",
        subject: "Subject",
        is_read: "Read",
        is_new: "New",
        msg: "Message",
        date: "Date",
        rating: "Rating",
        comment: "Comment",
        vendors: "Vendors",
        vendors_2: "Vendors",
        vendor: "Vendor",
        vendor_2: "Vendor",
        company_name: "Company name",
        rep_name: "Name for representative",
        top_5_products: "Top products",
        top_5_vendors: "Top vendors",
        top_5_shops: "Top shops",
        high_request_cities: "High request cities",
        contact_mobile: "Contact mobile",
        add_a_store: "Add a store",
        store: "Store",
        store_2: "Store",
        stores: "Stores",
        store_name: "Store name",
        store_url: "Store url",
        customize_table: "Customize table",
        "#": "#",
        vendor_products: "Vendor products",
        go_to_products: "Go to products",
        filtering: "Filtering",
        apply: "Apply",
        commission_per: "Commission per",
        region: "Region",
        my_location: "My location",
        accept: "Accept",
        reject: "Reject",
        add_new: "Add new",
        welcome_pages: "Welcome pages",
        welcome_pages_2: "Welcome pages_2",
        welcome_page: "Welcome page",
        welcome_page_2: "Welcome page",
        values: "Values",
        ads: "Ads",
        ads_2: "Ads",
        ad: "Ad",
        ad_2: "Ad",
        delivery_fees: "Delivery fees",
        ACCEPTED: "Accepted",
        REJECTED: "Rejected",
        rejected: "Rejected",
        CANCELED: "Cancelled",
        HOLD: "Hold",
        IS_READY: "Is ready",
        RATING: "Rating",
        c_reasons: "Cancellation reasons",
        c_reason: "Cancellation reason",
        c_reason_2: "Cancellation reason",
        start_at: "Start at",
        end_at: "End at",
        completed_orders: "Completed orders",
        cancelled_orders: "Cancelled orders",
        live_orders: "Live orders",
        past_orders: "Past orders",
        help: "Help",
        help_2: "Help",
        questions: "Questions",
        questions_2: "Questions_2",
        question: "Question",
        question_2: "Question",
        answer: "Answer",
        answer_2: "Answer",
        source: "Source",
        code: "Code",
        admin_per: "Admin percentage",
        vendor_per: "Vendor percentage",
        delivery: "Delivery",
        coupon_type: "Type",
        products_price: "Products price",
        min_amount: "Min order amount",
        max_discount: "Max discount",
        add_with_new: "Add & new",
        is_main: "Is main",
        rejection_reason: "Rejection reason",
        wallet: "Wallet",
        WALLET: "Wallet",
        transaction_id: "Transaction id",
        transaction_type: "Type",
        transaction_alias: "Alias",
        order_id: "Order id",
        setting_2: "Setting",
        value: "Value",
        general: "General",
        incomes: "Incomes",
        admin_credit: "Admin credit",
        admin_debit: "Admin debit",
        vendor_credit: "Vendor credit",
        vendor_debit: "Vendor debit",
        delivery_credit: "Delivery credit",
        delivery_debit: "Delivery debit",
        packs: "Packs",
        packs_2: "Packs",
        pack: "Pack",
        pack_2: "Pack",
        width: "Width",
        height: "Height",
        length: "Length",
        weight: "Weight",
        p_groups: "Product groups",
        p_groups_2: "Product groups",
        p_group: "Product group",
        p_group_2: "Product group",
        collections: "Collections",
        collections_2: "Collections",
        collection: "Collection",
        collection_2: "Collection",
        w_requests: "Withdrawal requests",
        invoices: "Invoices",
        invoices_2: "Invoices",
        invoice: "Invoice",
        invoice_2: "Invoice",
        from: "From",
        to: "To",
        regions: "Regions",
        region_2: "Region",
        cities: "Cities",
        notifications: "Notifications",
        notification_action: "Notification Action",
        notification_type: "Notification Type",
        notification_user_type: "User Type",
        delivery_companies: "Delivery companies",
        delivery_companies_2: "Delivery companies",
        delivery_company: "Delivery company",
        delivery_company_2: "Delivery company",
        companies_count: "Companies count",
        add_to_wallet: "Add to wallet",
        accept_cash: "Accpet cash",
        accept_card: "Accpet card",
        cash: "Cash",
        CASH: "Cash",
        card: "Card",
        CARD: "Card",
        last_orders: "Orders last 7 Days",
        today_orders_status: "Today orders status",
        recent_added_vendors: "Recent added vendors",
        recent_added_products: "Recent added products",
        recent_orders: "Recent orders",
        delivery_options: "Delivery options",
        refund: "Refund",
        cancel_order: "Cancel order",
        vat: "VAT",
        bill_of_lading: "Bill of lading",
        batch: "Batch",
        export_excel: "Export excel",
        bank_account: "Bank account",
        holder_name: "Holder name",
        bank_name: "Bank name",
        iban: "IBAN",
        amounts_due: "Amounts due",
        vendors_count: "Vendors count",
        is_paid: "Paid",
        not_paid: "Not paid",
        paid_is_done: "Paid is done",
        week_days: {
            Sat: "Sat",
            Sun: "Sun",
            Mon: "Mon",
            Tue: "Tue",
            Wed: "Wed",
            Thu: "Thu",
            Fri: "Fri"
        },
        add_new_option: "Add new option",
        delete_option: "Delete option",
        no_options_found: "No options found",
        national_id: "National Id",
        email_templates: "Email templates",
        notification_templates: "Notification templates",
        national_id_image: "National id image",
        download_contract: "Download contract",
        vendor_payrolls: "Vendor payrolls",
        delivery_company_payrolls: "Delivery company payrolls",
        app_notifications: "App notifications",
        custom: "Custom",
        top_cities: "Top cities",
        top_users: "Top users",
        special_order: "Special order",
        is_unlimited: "Unlimited",
        special: "Special",
        normal: "Normal",
        order_type: "Order type",
        pending_price: "Waiting for a price quote from the store",
        status_pending: "Waiting for payment",
        view_order_details: "View order details",
        select_all: "Select all",
        device_type: "Device type",
        pickup: "Pickup",
        dispatch_number: "Dispatch confirmation number",
        stc: "STC",
        extra_fees: "Cash extra fees",
        fixed_fees: "Fixed fees",
        is_due: "Due",
        not_due: "Not due",
        offers: "Offers",
        due_date: "Due date",
        important_stores: "Important Stores",
        home_sections: "Home Settings",
        company_name: "Company Name",
        deserved_amount: "Deserved Amount",
        vendor_name: "Vendor Name",
        association_name: "Association name",
        about_us: "About Us",
        payroll: "Payroll",
        details: "Details",
        statistics: "Statistics",
        statistic: "Statistic",
        name_ar: "Name Arabic",
        name_en: "Name English",
        photo: "Photo",
        about_us_image: "About Us Image",
        download_bill: "Download Bill",
        associations: "Associations",
        manager: "Manager",
        association: "Association",
        select_association: "Select Association",
        select_delivery_company: "Select Delivery Company",
        Choose: "Choose",
        shippment_created_successfully: "Shippment Created Successfully",
        created_shippment_failed: "Created Shippment Failed",
        track_shippment: "Track Shippment",
        order_details: "Order Details",
        "order_status":"Order Status",
        delivery_company: "Shipping Company",
        delivery_details: "Delivery Details"

    },
    ar: {
        "order_status":"حالة الطلب",
        order_details: "تفاصيل الطلب",
        track_shippment: "تتبع الشحنة",
        created_shippment_failed: "حدث خطأ أثناء إنشاء",
        shippment_created_successfully: "تم إنشاء الشحنة بنجاح",
        Choose: "اختر",
        select_delivery_company: "اختر شركة شحن",
        select_association: "اختر جمعية",
        association: "جمعية",
        manager: "مسؤول الجمعية",
        associations: "الجمعيات",
        download_bill: "تحميل الفاتورة",
        about_us_image: "صورة من نحن",
        photo: "صورة",
        name_ar: "الإسم عربي",
        name_en: "الإسم إنجليزي",
        statistic: "الإحصاء",
        statistics: "الإحصائيات",
        details: "تفاصيل",
        payroll: "الفاتورة",
        about_us: "من نحن",
        association_name: "اسم الجمعية",
        vendor_name: "اسم الحرفي",
        company_name: "اسم الشركة",
        deserved_amount: "المبلغ المستحق",
        dashboard: "لوحة التحكم",
        ar: "عربي",
        en: "إنجليزي",
        my_info: "بياناتي",
        logout: "تسجيل خروج",
        home: "الرئيسية",
        add: "إضافة",
        edit: "تعديل",
        save: "حفـظ",
        delete: "حـذف",
        change_status: "تغيير الحالة",
        all: "الكــل",
        website: "الانتقال للموقع",
        products: "المنتجات",
        products_2: "منتجات",
        product: "منتج",
        product_2: "المنتج",
        name: "الاسم",
        image: "الصورة",
        desc: "الوصف",
        created_at: "تاريخ الإضافة",
        created_at_r: "تاريخ الحجز",
        status: "الحالة",
        login: "دخول",
        login_desc: "سجل دخولك للوحة التحكم",
        search: "بحث",
        phone: "رقم الهاتف",
        birth_date: "تاريخ الميلاد",
        experience: "الخبرة",
        education: "التعليم",
        email: "البريد الإلكتروني",
        actions: "الإجراءات",
        on: "نشـط",
        soon: "قريباً",
        off: "غير نشـط",
        previous: "السابق",
        next: "التالي",
        no_data: "لا توجـد بيانات",
        select: "اختر",
        ok: "موافق",
        cancel: "إلغاء",
        yes: "نعم",
        no: "لا",
        error: "خطأ",
        error_msg: "حدث خطأ ما، حاول مرة أخرى",
        choose_file: "اختر الملف",
        choose_image: "اختر الصورة",
        brand: "الماركة",
        brand_2: "ماركة",
        brands: "الماركات",
        brands_2: "ماركات",
        category: "القسم",
        category_2: "قسم",
        categories: "الأقسام",
        categories_2: "أقسام",
        sub_category: "القسم الفرعي",
        sub_category_2: "قسم فرعي",
        sub_categories: "الأقسام الفرعية",
        sub_categories_2: "أقسام قرعية",
        section: "قسم المقالات",
        section_2: "قسم مقالات",
        sections: "أقسام المقالات",
        sections_2: "أقسام مقالات",
        are_you_sure: "هل أنت متأكد؟",
        password: "كلمة المرور",
        c_password: "كلمة المرور الحالية",
        n_password: "كلمة المرور الجديدة",
        v_password: "تأكيد كلمة المرور الجديدة",
        show: "عرض",
        info: "البيانات",
        price: "السعر",
        image_must_image: "الصورة يجب أن تكون بصيغة صورة",
        reservations: "الحجوزات",
        egp: "جنية",
        settings: "الإعدادات",
        images: "الصور",
        home_page: "الصفحة الرئيسية",
        url: "الرابط",
        website_info: "بيانات الموقع",
        logo: "الشعار",
        site_keywords: "الكلمات المفتاحية",
        contact_info: "بيانات التواصل",
        address: "العنوان",
        pages: "الصفحات الفرعية",
        page: "الصفحة",
        page_2: "صفحة",
        title: "العنوان",
        content: "المحتوى",
        payment_gate: "بوابة الدفع الحالية",
        payment_gates: "بوابات الدفع",
        payment_methods: "وسائل الدفع",
        amount: "المبلغ",
        admins: "الموظفين",
        admin: "موظف",
        admin_2: "الموظف",
        password_v: "تأكيد كلمة المرور",
        groups: "المجموعات",
        group: "مجموعة",
        group_2: "المجموعة",
        permissions: "الصلاحيات",
        g_section: "القسم",
        view: "عرض",
        otp: "رمز التحقق",
        error_mobile: "لا يمكننا التحقق من رقم الجوال",
        error_otp: "خطأ في رمز التحقق",
        app_info: "بيانات التطبيق",
        copy: "نسخ",
        copied: "تم النسخ",
        two_factors: "مصادقة ثنائية",
        menu: "القائمة",
        texts: "النصوص",
        footet_text: "نص الفوتر",
        timezone: "المنطقة الزمنية",
        cairo: "القاهرة",
        riyadh: "الرياض",
        my_zone: "منطقتي الزمنية",
        countries: "الدول",
        balance: "الرصيد",
        current_balance: "الرصيد الحالي",
        extra_balance: "الرصيد الإضافي",
        payment_method: "طريقة الدفع",
        pay: "دفع",
        send: "ارسال",
        last_updated: "أخر تحديث",
        succeeded: "تم التنفيذ",
        failed: "فشلت",
        notes: "ملاحظات",
        pending: "في الانتظار",
        balance_info: "بيانات الرصيد",
        av_balance: "الرصيد المتاح",
        posts: "المقالات",
        posts_2: "مقالات",
        post: "المقالة",
        post_2: "مقالة",
        type: "النوع",
        text: "نص",
        textarea: "نص كبير",
        number: "رقم",
        options: "الخيارات",
        options_2: "خيارات",
        option: "الخيار",
        option_2: "خيار",
        boolean: "نعم / لا",
        attributes: "السمات",
        attribute: "السمة",
        attribute_2: "سمة",
        attribute_sets: "مجموعات السمات",
        attribute_set: "مجموعة سمات",
        attribute_set_2: "مجموعة السمات",
        city: "المدينة",
        hour_price: "سعر الساعة",
        day_price: "سعر اليوم",
        sar: "ريال",
        firstly_select_category: "أختر القسم أولاً",
        users: "العملاء",
        users_2: "عملاء",
        user: "العميل",
        user_2: "عميل",
        slider: "السلايدر",
        slides: "الصور",
        slides_2: "صور",
        slide: "الصورة",
        slide_2: "صورة",
        banners: "البنرات",
        banners_2: "بنرات",
        banner: "البانر",
        banner_2: "بانر",
        sizes: "الأحجام",
        sizes_2: "أحجام",
        size: "الحجم",
        size_2: "حجم",
        coupons: "الكوبونات",
        coupons_2: "كوبون",
        coupon: "الكوبون",
        coupon_2: "كوبون",
        discounts: "الخصومات",
        discounts_2: "خصومات",
        discount_2: "خصم",
        icon: "الايقونة",
        slide_url: "الرابط",
        r_type: "نوع الحجز",
        day: "يوم",
        hour: "ساعة",
        quantity: "الكمية",
        store_name: "اسم المتجر",
        qty: "الكمية",
        sku: "SKU",
        discount: "الخصم",
        discount_type: "نوع الخصم",
        fixed_value: "قيمة",
        percentage: "نسبة",
        discount_start: "يبدأ من",
        discount_end: "ينتهي في",
        discount_value: "قيمة الخصم",
        in_stock: "متوفر",
        orders: "الطلبات",
        orders_2: "طلبات",
        order: "الطلب",
        order_2: "طلب",
        NEW: "جديد",
        CONFIRMED: "مؤكد",
        PREPAIRING: "يتم تجهيزه",
        DELIVERING: "يتم توصيله",
        DELIVERED: "تم توصيله",
        COMPLETED: "مُكتمـل",
        CANCELLED: "ملغي",
        PENDING: "انتظار الدفع",
        REFUNDED: "مُسترجعـه",
        order_created: "إنشاء الطلب",
        order_confirmed: "تأكيد الطلب",
        order_prepared: "تجهييز الطلب",
        order_delivered: "بدأ التوصيل",
        order_completed: "تم التسليم",
        cm: "سـم",
        prepared_time: "وقت الإعداد",
        go_to_next_status: "الانتقال للحالة التالية",
        vendor_products: "منتجات الحرفي",
        total: "الإجمالي",
        change_order_msg: "متأكد من تغيير حالة الطلب ؟",
        search_select: "ابحث واختر",
        min_spend: "أقل قيمة",
        max_spend: "أقصى قيمة",
        coupon_limit: "مرات الإستخدام",
        user_limit: "مرات استخدام كل عضو",
        contacts: "الرسائل",
        contacts_2: "رسائل",
        contact: "الرسالة",
        contact_2: "رسالة",
        reviews: "التقييمات",
        reviews_2: "تقييمات",
        review: "التقييم",
        review_2: "تقييم",
        subject: "الموضوع",
        is_read: "مقرؤة",
        is_new: "جديدة",
        msg: "الرسالة",
        date: "التاريخ",
        rating: "التقييم",
        comment: "التعليق",
        vendors: "الحرفيين",
        vendors_2: "حرفيين",
        vendor: "الحرفي",
        vendor_2: "حرفي",
        company_name: "اسم الشركة",
        rep_name: "اسم ممثل الشركة",
        accept: "قبول",
        reject: "رفض",
        add_new: "إضافة جديد",
        welcome_pages: "الصفحات الترحيبية",
        welcome_pages_2: "صفحات ترحيبية",
        welcome_page: "الصفحة الترحيبية",
        welcome_page_2: "صفحة ترحيبية",
        values: "القيم",
        ads: "الإعلانات",
        ads_2: "إعلانات",
        ad: "الإعلان",
        ad_2: "إعلان",
        delivery_fees: "رسوم التوصيل",
        ACCEPTED: "مقبول",
        REJECTED: "مرفوض",
        rejected: "مرفوض",
        CANCELED: "ملغي",
        HOLD: "معلق",
        IS_READY: "جاهز",
        RATING: "تقييم",
        c_reasons: "أسباب الإلغاء",
        c_reason: "سبب الإلغاء",
        c_reason_2: "سبب إلغاء",
        start_at: "يبدأ في",
        end_at: "ينتهي عند",
        filtering: "التصفية",
        apply: "تطبيق",
        completed_orders: "الطلبات المكتملة",
        cancelled_orders: "الطلبات الملغية",
        live_orders: "الطلبات الحالية",
        past_orders: "الطلبات السابقة",
        help: "المساعدة",
        help_2: "مساعدة",
        questions: "الأسئلة",
        questions_2: "أسئلة",
        question: "السؤال",
        question_2: "سؤال",
        answer: "الإجابة",
        answer_2: "إجابة",
        source: "المصدر",
        code: "الكود",
        admin_per: "نسبة تحمل الأدمن",
        vendor_per: "نسبة تحمل التاجر",
        delivery: "التوصيل",
        coupon_type: "النوع",
        products_price: "سعر المنتجات",
        min_amount: "أقل قيمة للطلب",
        max_discount: "أقصى خصم",
        add_with_new: "إضافة وجديد",
        is_main: "رئيسي",
        rejection_reason: "سبب الرفض",
        wallet: "المحفظة",
        WALLET: "المحفظة",
        transaction_id: "رقم العملية",
        transaction_type: "النوع",
        transaction_alias: "الوصف",
        order_id: "رقم الطلب",
        setting_2: "قيمة",
        stores: "المتاجر",
        store: "المتجر",
        store_2: "متجر",
        value: "القيمة",
        general: "العامة",
        incomes: "الدخل",
        admin_credit: "نصيب الأدمن",
        admin_debit: "المستحق على الأدمن",
        vendor_credit: "نصيب التاجر",
        vendor_debit: "المستحق على التاجر",
        delivery_credit: "نصيب التوصيل",
        delivery_debit: "المستحق على التوصيل",
        customize_table: "تخصيص الجدول",
        "#": "#",
        income_2: "دخل",
        packs: "العبوات",
        packs_2: "عبوات",
        pack: "العبوة",
        pack_2: "عبوة",
        width: "العرض",
        height: "الارتفاع",
        length: "الطول",
        weight: "الوزن",
        p_groups: "منتجات الرئيسية",
        p_groups_2: "منتجات رئيسية",
        p_group: "منتجات الرئيسية",
        p_group_2: "منتجات رئيسية",
        collections: "المنتجات المرتبطة",
        collections_2: "منتجات مرتبطة",
        collection: "المنتجات المرتبطة",
        collection_2: "منتجات مرتبطة",
        w_requests: "طلبات السحب",
        invoices: "الفواتير",
        invoices_2: "فواتير",
        invoice: "الفاتورة",
        invoice_2: "فاتورة",
        from: "من",
        to: "إلى",
        regions: "المناطق",
        region: "المنطقة",
        region_2: "منطقة",
        cities: "المدن",
        city_2: "مدينة",
        notifications: "الاشعارات",
        notification_action: "نوع الاجراء",
        notification_type: "نوع الاشعار",
        notification_user_type: "نوع المستخدم",
        delivery_companies: "شركات التوصيل",
        delivery_companies_2: "شركات توصيل",
        delivery_company: "شركة التوصيل",
        delivery_company_2: "شركة توصيل",
        companies_count: "عدد الشركات",
        commission_per: "نسبة العمولة",
        add_a_store: "إضافة متجر",
        mobile: "رقم الجوال",
        contact_mobile: "رقم التواصل",
        store_url: "Store url",
        my_location: "موقعي",
        top_5_shops: "المتاجر الأعلى طلباً",
        top_5_products: "المنتجات الأكثر طلباً",
        top_5_vendors: "التجار الأعلى طلباً",
        add_to_wallet: "إضافة للمحفظة",
        accept_cash: "يقبل كاش",
        accept_card: "يقبل بطاقة",
        cash: "كاش",
        CASH: "كاش",
        card: "بطاقة",
        CARD: "بطاقة",
        last_orders: "طلبات أخر 7 أيام",
        today_orders_status: "طلبات اليوم",
        recent_added_vendors: "أحدث التجار",
        recent_added_products: "أحدث المنتجات",
        recent_orders: "أحدث الطلبات",
        go_to_products: "المنتجات",
        delivery_options: "خيارات التوصيل",
        refund: "ارجاع المبلغ",
        cancel_order: "إلغاء الطلب",
        vat: "الضريبة",
        bill_of_lading: "بوليصة الشحن",
        batch: "الدفعة",
        export_excel: "تحميل EXCEL",
        download_contract: "تحميل العقد",
        bank_account: "الحساب البنكي",
        holder_name: "اسم صاحب الحساب",
        bank_name: "اسم البنك",
        iban: "IBAN",
        amounts_due: "المبالغ المستحقة",
        vendors_count: "عدد الحرفيين",
        is_paid: "تم الدفع",
        not_paid: "مستحق",
        paid_is_done: "تم دفع المبالغ",
        week_days: {
            Sat: "سبت",
            Sun: "أحد",
            Mon: "أثنين",
            Tue: "ثلاثاء",
            Wed: "أربعاء",
            Thu: "خميس",
            Fri: "جمعة"
        },
        add_new_option: "إضافة خيار جديد",
        delete_option: "حذف الخيار",
        no_options_found: "لا يوجد خيارات",
        national_id: "رقم الهوية",
        email_templates: "الايميلات",
        notification_templates: "إعدادات الإشعارات",
        contract: "عقد",
        national_id_image: "صورة الهوية",
        vendor_payrolls: "فواتير الحرفيين",
        delivery_company_payrolls: "فواتير شركات التوصيل",
        app_notifications: "اشعارات التطبيق",
        custom: "مخصص",
        top_cities: "اعلى المدن",
        top_users: "اعلى العملاء",
        special_order: "طلب خاص",
        is_unlimited: "غير محدود",
        special: "خاص",
        normal: "عادي",
        order_type: "نوع الطلب",
        pending_price: "في انتظار تحديد السعر من المتجر",
        view_order_details: "عرض تفاصيل الطلب",
        status_pending: "انتظار الدفع",
        select_all: "اختار الكل",
        device_type: "نوع الجهاز",
        pickup: "طلب التوصيل",
        dispatch_number: "رقم تاكيد الشحنة",
        stc: "STC",
        extra_fees: "رسوم الدفع عند الاستلام",
        fixed_fees: "رسوم ثابتة",
        is_due: "مستحق",
        not_due: "غير مستحق",
        offers: "العروض",
        due_date: "تاريخ الاستحقاق",
        important_stores: "المتاجر المهمة",
        home_sections: "اعدادات الصفحة الرئيسية",
        delivery_company: "شركة الشحن",
        delivery_details: "تفاصيل الشحن"
    }
};

export let i18n = new VueI18n({
    locale: "ar", // set locale
    fallbackLocale: "ar", // set fallback locale
    messages // set locale messages
});
