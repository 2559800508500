<template>
  <div>
    <br />
    <!-- Main content -->
    <section class="content mt-2">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <b-card no-body>
              <b-card-body>
                <i class="fa fa-sync  fa-spin load-con" v-if="!load"></i>
                <form class="col-md-8" v-show="load">
                  <b-alert
                    :show="error || success ? true : false"
                    :variant="error ? 'danger' : 'success'"
                    dismissible
                    @dismissed="error = success = ''"
                  >
                    {{ error || success }}
                  </b-alert>
                  <div class="form-row" v-if="isAdmin">
                    <div class="form-group col-md-12">
                      <label>{{ $t("image") }}</label>

                      <div class="image_div" v-if="profile_image">
                        <img :src="profile_image" />
                        <i
                          class="fa fa-times"
                          @click.prevent="profile_image = profile_icon = null"
                        ></i>
                      </div>
                      <div
                        class="image_div"
                        v-if="profile.photo && !profile_image"
                      >
                        <img
                          :src="image_url + '/' + profile.photo"
                          @error="$event.target.src = '/images/placeholder.jpg'"
                        />
                      </div>
                      <b-form-file
                        v-if="!profile_image"
                        :placeholder="$t('choose_image')"
                        accept="image/*"
                        @change.prevent="onImageChange($event, 'profile')"
                        ref="profile_icon"
                      ></b-form-file>
                      <span class="error invalid-feedback d-block">{{
                        (errors.photo_input || []).join("-")
                      }}</span>
                    </div>
                    <div class="form-group col-md-12">
                      <label>{{ $t("name") }}</label>
                      <input
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.name }"
                        v-model="profile.name"
                      />
                      <span class="error invalid-feedback">{{
                        (errors.name || []).join("-")
                      }}</span>
                    </div>

                    <div class="form-group col-md-6">
                      <label>{{ $t("email") }}</label>
                      <input
                        type="email"
                        class="form-control ltr"
                        :class="{ 'is-invalid': errors.email }"
                        v-model="profile.email"
                      />
                      <span class="error invalid-feedback">{{
                        (errors.email || []).join("-")
                      }}</span>
                    </div>
                    <div class="form-group col-md-6">
                      <label>{{ $t("phone") }}</label>
                      <input
                        type="number"
                        class="form-control ltr"
                        :class="{ 'is-invalid': errors.phone }"
                        v-model="profile.phone"
                      />
                      <span class="error invalid-feedback">{{
                        (errors.phone || []).join("-")
                      }}</span>
                    </div>
                    <div class="form-group col-md-12">
                      <label>{{ $t("c_password") }}</label>
                      <input
                        type="password"
                        class="form-control ltr"
                        :class="{ 'is-invalid': errors.c_password }"
                        v-model="profile.c_password"
                      />
                      <span class="error invalid-feedback">{{
                        (errors.c_password || []).join("-")
                      }}</span>
                    </div>
                    <div class="form-group col-md-6">
                      <label>{{ $t("n_password") }}</label>
                      <input
                        type="password"
                        class="form-control ltr"
                        :class="{ 'is-invalid': errors.password }"
                        v-model="profile.password"
                      />
                      <span class="error invalid-feedback">{{
                        (errors.password || []).join("-")
                      }}</span>
                    </div>
                    <div class="form-group col-md-6">
                      <label>{{ $t("v_password") }}</label>
                      <input
                        type="password"
                        class="form-control ltr"
                        :class="{ 'is-invalid': errors.password_confirmation }"
                        v-model="profile.password_confirmation"
                      />
                      <span class="error invalid-feedback">{{
                        (errors.password_confirmation || []).join("-")
                      }}</span>
                    </div>

                    <div
                      class="form-group col-md-6"
                      style="display:flex"
                      v-if="profile.phone && false"
                    >
                      <label style="margin-top:4px">
                        {{ $t("two_factors") }}</label
                      >

                      <label class="switch mr-2 ml-2">
                        <input
                          type="checkbox"
                          v-model="profile.need_otp"
                          :true-value="1"
                          :false-value="0"
                        />
                        <div class="slider round">
                          <span class="on">{{ $t("yes") }}</span>
                          <span class="off">{{ $t("no") }}</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <!----------- end admin profile ------------>
                  <button
                    type="submit"
                    class="btn btn-info mt-2"
                    :disabled="disabled"
                    @click="updateProfile"
                  >
                    {{ $t("save") }}
                    <b-spinner small v-if="disabled"></b-spinner>
                  </button>
                </form>
              </b-card-body>
              <!-- /.card-body -->
            </b-card>
            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: "Profile",
  data() {
    return {
      load: false,
      disabled: false,
      success: "",
      error: "",
      errors: {},
      add_progress: 0,
      profile_icon: null,
      profile_image: null,
      profile: {
        lat: 1,
        lng: 1
      },
      image_url: "",
      categories: [],
      sub_categories: [],
      cities: [],
      attributes: [],
      images_input: [],
      images_arr: []
    };
  },
  created() {
    this.getProfile();
    if (this.isAdmin) {
      //this.getCategories();
    }
  },
  watch: {
    "profile.category_id"() {
      this.sub_categories = [];
      this.sub_categories = [
        ...(this.categories.find(e => e.id == this.profile.category_id) || {})
          .sub_categories
      ];
      this.getCategoryAttributes();
    }
  },
  methods: {
    getCategories() {
      this.axios
        .get("categories")
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.categories = res.result.categories;
          }
        })
        .catch(error => {
          this.handleError("getCategories", error);
        });
    },
    getProfile() {
      return this.axios
        .get("profile")
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.profile = res.result.profile;
            if (this.isAdvertiser || this.isUser) {
              this.$set(this.profile, "lat", parseFloat(this.profile.lat));
              this.$set(this.profile, "lng", parseFloat(this.profile.lng));
              this.cities = res.result.cities;
            }
            if (this.isAdvertiser) {
              this.categories = res.result.categories;
              this.profile.attributes.forEach(attr => {
                this.$set(
                  this.profile,
                  "attr_" + attr.id,
                  typeof attr.value === "object" ? attr.value.id : attr.value
                );
              });
            }
            this.image_url = res.result.image_url;
          }
        })
        .catch(error => {
          this.handleError("getProfile", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    updateProfile() {
      this.disabled = true;
      this.error = this.success = "";
      let fd = new FormData();

      if (this.profile_icon) {
        fd.append("photo_input", this.profile_icon, this.profile_icon.name);
      }
      Object.keys(this.profile).forEach(key => {
        if (this.profile[key]) {
          fd.append(key, this.profile[key]);
        }
      });
      this.axios
        .post("profile/update", fd)
        .then(response => {
          let res = response.data;
          this.errors = {};
          if (res.error_flag == 0) {
            this.success = res.message;
            this.profile = res.result.profile;
            if (this.isUser) {
              this.$set(this.profile, "lat", parseFloat(this.profile.lat));
              this.$set(this.profile, "lng", parseFloat(this.profile.lng));
            }
            this.profile.password = "";
            this.profile.c_password = "";
            this.profile.c_password_confirmation = "";
            this.profile_icon = null;
            this.profile_image = null;
            this.$store.commit("setUser", {
              ...this.profile,
              image_url: this.image_url
            });
          } else if (res.error_flag == 1) {
            typeof res.message === "object"
              ? (this.errors = res.message)
              : (this.error = res.message);
          } else {
            this.handleError("updateProfile", res.message);
          }
        })
        .catch(error => {
          this.handleError("updateProfile", error);
        })
        .finally(() => {
          this.disabled = false;
          this.add_progress = 0;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    }
  }
};
</script>

<style scoped>
.img_div {
  margin: 15px;
  display: inline-block;
  position: relative;
}

.img_div img {
  height: 120px;
  width: 120px;
}

.img_div div {
  background: #ff0018;
  position: absolute;
  top: -10px;
  color: white;
  left: -10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
</style>
