<template>
    <div>
        <b-modal
            ref="cancel_modal"
            centered
            :title="$t('cancel_order')"
            header-class="new_modal_header"
            hide-footer
        >
            <div class="d-block">
                <form class="col-md-12">
                    <b-alert
                        :show="cancel_error || cancel_success ? true : false"
                        :variant="cancel_error ? 'danger' : 'success'"
                        dismissible
                        @dismissed="cancel_error = cancel_success = ''"
                    >
                        {{ cancel_error || cancel_success }}
                    </b-alert>

                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <label>{{ $t("c_reason") }}</label>
                            <select
                                class="form-control custom-select"
                                :class="{
                                    'is-invalid': cancel_errors.reason_id
                                }"
                                v-model="cancel_data.reason_id"
                            >
                                <option value="" disabled>{{
                                    $t("select")
                                }}</option>
                                <option
                                    v-for="(reason, i) in c_reasons"
                                    :key="i"
                                    :value="reason.id"
                                >
                                    {{ reason["name_" + $i18n.locale] }}
                                </option>
                            </select>
                            <span class="error invalid-feedback">{{
                                (cancel_errors.reason_id || []).join("-")
                            }}</span>
                        </div>
                    </div>

                    <button
                        type="submit"
                        class="btn btn-info mt-2"
                        :disabled="cancel_disabled"
                        @click.prevent="cancelOrder"
                    >
                        {{ $t("save") }}
                        <b-spinner small v-if="cancel_disabled"></b-spinner>
                    </button>
                </form>
            </div>
        </b-modal>

        <br /><br />
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-5">
                        <b-card-text>
                            <b-card no-body class="card">
                                <b-card-header v-if="!order.otoId">
                                    {{ $t("delivery_options") }}
                                </b-card-header>
                                <b-card-header v-if="order.otoId">
                                    {{ $t("delivery_details") }}
                                </b-card-header>
                                <!-- <b-card-header
                                    v-if="
                                        ['NEW', 'PREPAIRING', 'IS_READY', 'DELIVERING'].includes(
                                            order.status
                                        ) && order.otoId
                                    "
                                >
                                    {{ $t("delivery_company") }} : {{ order.otoId ? "Aramix" : "" }} <br>
                                </b-card-header> -->
                                <b-card-body class="table-responsive p-0">
                                    <div
                                        class="row col-md-12"
                                        style="margin-top:15px;"
                                    >
                                        <div
                                            class="col-md-8"
                                            v-if="!order.otoId"
                                        >
                                            <label>{{
                                                $t("select_delivery_company")
                                            }}</label>
                                            <select
                                                v-model="oto_delivery_company"
                                                class="form-control"
                                                :disabled="order.otoId"
                                            >
                                                <!-- <option value="" selected>{{
                                                    $t(
                                                        "select_delivery_company"
                                                    )
                                                }}</option> -->
                                                <option
                                                    v-for="(otoCompany,
                                                    i) in otoCompanies"
                                                    :key="i"
                                                    :selected="
                                                        otoCompany.deliveryOptionId ==
                                                            3392 ||
                                                            otoCompany.deliveryOptionId ==
                                                                order.delivery_company_id
                                                    "
                                                    :value="
                                                        otoCompany.deliveryOptionId ==
                                                        3392
                                                            ? 332
                                                            : otoCompany.deliveryOptionId
                                                    "
                                                >
                                                    {{ otoCompany.dcName }}
                                                </option>
                                            </select>
                                        </div>
                                        <div
                                            class="col-md-3"
                                            v-if="order.otoId"
                                        >
                                            {{ $t("delivery_company") }}: Aramex
                                        </div>

                                        <div
                                            class="col-md-1"
                                            v-if="!order.otoId"
                                        >
                                            <label>&nbsp; &nbsp;</label>
                                            <button
                                                class="btn btn-info btn-md d-block mx-auto"
                                                @click="
                                                    chooseOtoShippingCompany(id)
                                                "
                                                :disabled="
                                                    order.otoId &&
                                                        order.otoId !== 0
                                                "
                                            >
                                                {{ $t("Choose") }}
                                            </button>
                                        </div>

                                        <div class="col-md-12"></div>
                                        <div
                                            class="col-md-6"
                                            v-if="
                                                order.otoId && order.otoId !== 0
                                            "
                                        >
                                            <button
                                                class="btn btn-primary btn-md d-block mx-auto"
                                                @click="createOtoLadinBill(id)"
                                            >
                                                {{ $t("bill_of_lading") }}
                                                <i class="fas fa-truck"></i>
                                            </button>
                                        </div>

                                        <div
                                            class="col-md-6"
                                            v-if="
                                                order.otoId && order.otoId !== 0
                                            "
                                        >
                                            <button
                                                class="btn btn-success btn-md d-block mx-auto"
                                                @click="trackOtoOrder(id)"
                                            >
                                                {{ $t("track_shippment") }}
                                                <i
                                                    class="fas fa-shipping-fast"
                                                ></i>
                                            </button>
                                        </div>
                                        <hr style="width:100%; " />
                                    </div>

                                    <!-- <button
                                        class="btn btn-primary btn-sm d-block mt-3 mx-auto"
                                    >
                                        {{ $t("order_status") }} :
                                        {{ $t(order.status) }}
                                    </button>

                                    <br /> -->

                                    <ul class="timeline">
                                        <li
                                            v-for="(order_status,
                                            i) in order.tracking"
                                            :key="i"
                                        >
                                            <p
                                                class="timeline-date"
                                                v-tooltip="
                                                    $moment(
                                                        order_status.created_at
                                                    )
                                                        .tz(
                                                            $store.state
                                                                .timezone
                                                        )
                                                        .format(
                                                            'Y-MM-DD hh:mm A'
                                                        )
                                                "
                                            >
                                                {{
                                                    $moment(
                                                        order_status.created_at
                                                    )
                                                        .tz(
                                                            $store.state
                                                                .timezone
                                                        )
                                                        .format("hh:mm")
                                                }}
                                                {{
                                                    $moment(
                                                        order_status.created_at
                                                    )
                                                        .tz(
                                                            $store.state
                                                                .timezone
                                                        )
                                                        .locale($i18n.locale)
                                                        .format("A")
                                                }}
                                            </p>
                                            <div
                                                :class="
                                                    'timeline-content active ' +
                                                        (i == 0
                                                            ? 'process_done'
                                                            : '')
                                                "
                                            >
                                                {{ $t(order_status.status) }}
                                            </div>
                                        </li>
                                    </ul>
                                    <button
                                        v-if="
                                            !['DELIVERED'].includes(
                                                order.status
                                            )
                                        "
                                        class="btn btn-info btn-sm d-block mx-auto"
                                        @click="updateStatus(id)"
                                    >
                                        <i
                                            :class="
                                                'm-1 fas fa-arrow-' +
                                                    ($i18n.locale == 'ar'
                                                        ? 'left'
                                                        : 'right')
                                            "
                                        ></i>
                                        {{ $t("go_to_next_status") }} &nbsp;:
                                        "{{ $t(order.next_status) }}"
                                    </button>

                                    <button
                                        v-if="
                                            ![
                                                'CANCELLED',
                                                'DELIVERED'
                                            ].includes(order.status)
                                        "
                                        class="btn btn-danger btn-sm d-block mt-3 mx-auto"
                                        @click="showCancel(id)"
                                    >
                                        <i class="fa fa-times"></i>
                                        {{ $t("cancel_order") }}
                                    </button>
                                </b-card-body>
                            </b-card>
                        </b-card-text>
                    </div>

                    <div class="col-md-7 order_det" v-if="user">
                        <b-card-text>
                            <b-card no-body class="card">
                                <b-card-header>
                                    {{ $t("order_details") }}
                                </b-card-header>

                                <b-card-body>
                                    <div class="form-row">
                                        <div class="form-group col-md-12">
                                            <label>{{ $t("order_id") }}</label>
                                            <a
                                                href="#"
                                                class="text-muted text-sm"
                                                >{{ order.id }}</a
                                            >
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label>{{ $t("user") }}</label>
                                            {{ (user || {})["name"] }}
                                            <br v-if="(user || {})['name']" />
                                            <a
                                                :href="
                                                    'tel:' +
                                                        (user || {})['mobile']
                                                "
                                                >{{ (user || {})["mobile"] }}</a
                                            >
                                        </div>

                                        <div class="form-group col-md-6">
                                            <label>{{ $t("vendor") }}</label>
                                            {{ (vendor || {})["name"] }}
                                            <br />
                                            <a
                                                :href="
                                                    'tel:' +
                                                        (vendor || {})[
                                                            'contact_mobile'
                                                        ]
                                                "
                                                >{{
                                                    (vendor || {})[
                                                        "contact_mobile"
                                                    ]
                                                }}</a
                                            >
                                        </div>

                                        <div class="form-group col-md-6">
                                            <label>{{ $t("store") }}</label>
                                            {{ (store || {})["store_name"] }}
                                            <br />
                                            <a
                                                :href="
                                                    'tel:' +
                                                        (store || {})[
                                                            'store_contact_mobile'
                                                        ]
                                                "
                                                >{{
                                                    (store || {})[
                                                        "store_contact_mobile"
                                                    ]
                                                }}</a
                                            >
                                        </div>

                                        <div class="form-group col-md-6">
                                            <label>{{ $t("address") }}</label>
                                            {{
                                                order.shipping_address
                                                    ? shippingCity.name +
                                                      ", " +
                                                      order.shipping_address
                                                    : "0"
                                            }}
                                        </div>
                                        <div class="form-group col-md-12">
                                            <label>{{ $t("products") }}:</label>
                                        </div>
                                    </div>

                                    <div class="table-responsive">
                                        <table
                                            class="table"
                                            v-if="order.products"
                                        >
                                            <thead>
                                                <tr>
                                                    <th
                                                        style="vertical-align: middle; width: 10px"
                                                    >
                                                        #
                                                    </th>
                                                    <th
                                                        style="vertical-align: middle"
                                                    >
                                                        {{ $t("name") }}
                                                    </th>
                                                    <th
                                                        style="vertical-align: middle"
                                                    >
                                                        {{ $t("qty") }}
                                                    </th>
                                                    <th>
                                                        {{ $t("price") }} <br />
                                                        ( {{ $t("sar") }} )
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="(prod,
                                                    i) in order.products"
                                                    :key="i"
                                                >
                                                    <td>{{ i + 1 }}.</td>
                                                    <td>
                                                        <div>
                                                            <img
                                                                :src="
                                                                    image_url +
                                                                        '/' +
                                                                        prod.image
                                                                "
                                                                style="border-radius: 50%; margin: 5px"
                                                                @error="
                                                                    $event.target.src =
                                                                        '/images/placeholder.jpg'
                                                                "
                                                            />
                                                        </div>
                                                        <div>
                                                            <b>{{
                                                                prod[
                                                                    "name_" +
                                                                        $i18n.locale
                                                                ]
                                                            }}</b>
                                                            <br />

                                                            <span
                                                                v-for="(option,
                                                                i) in prod.options"
                                                                :key="i"
                                                                style="color: red; fontsize: 14px"
                                                            >
                                                                {{
                                                                    option[
                                                                        `name_${$i18n.locale}`
                                                                    ]
                                                                }}:
                                                                {{
                                                                    option[
                                                                        `option_value_name_${$i18n.locale}`
                                                                    ]
                                                                }}
                                                                <i
                                                                    v-if="
                                                                        i <
                                                                            prod
                                                                                .options
                                                                                .length -
                                                                                1
                                                                    "
                                                                    ><br
                                                                /></i>
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>X {{ prod.qty }}</td>
                                                    <td>
                                                        <b
                                                            v-if="
                                                                (
                                                                    prod.pivot ||
                                                                    {}
                                                                ).new_price
                                                            "
                                                            >{{
                                                                (
                                                                    prod.pivot ||
                                                                    {}
                                                                ).new_price *
                                                                    (
                                                                        prod.pivot ||
                                                                        {}
                                                                    ).qty
                                                            }}</b
                                                        >
                                                        <b v-else>{{
                                                            prod.price *
                                                                prod.qty
                                                        }}</b>
                                                        &nbsp; &nbsp;
                                                        <del
                                                            v-if="
                                                                (
                                                                    prod.pivot ||
                                                                    {}
                                                                ).new_price
                                                            "
                                                            >{{
                                                                prod.price *
                                                                    prod.qty
                                                            }}</del
                                                        >
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label>{{
                                                $t("products_price")
                                            }}</label>
                                            {{
                                                order.products_price === 0
                                                    ? $t("pending_price")
                                                    : order.products_price
                                            }}
                                            {{
                                                order.products_price !== 0
                                                    ? $t("sar")
                                                    : ""
                                            }}
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label>{{
                                                $t("delivery_fees")
                                            }}</label>
                                            {{ order.delivery_fees }}
                                            {{ $t("sar") }}
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label>{{
                                                $t("extra_fees")
                                            }}</label>
                                            {{ order.extra_fees }}
                                            {{ $t("sar") }}
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label>{{ $t("vat") }}</label>
                                            {{ order.vat }} {{ $t("sar") }}
                                        </div>
                                        <div
                                            class="form-group col-md-6"
                                            v-if="order.coupon"
                                        >
                                            <label>{{ $t("discount") }}</label>
                                            {{ (order.coupon || {}).discount }}
                                            {{ $t("sar") }}
                                            <span
                                                class="btn btn-sm btn-light text-sm text-muted"
                                                >{{
                                                    (order.coupon || {}).code
                                                }}</span
                                            >
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label>{{ $t("total") }}</label>
                                            {{
                                                order.total_price === 0
                                                    ? $t("pending_price")
                                                    : order.total_price
                                            }}
                                            {{
                                                order.total_price !== 0
                                                    ? $t("sar")
                                                    : ""
                                            }}
                                        </div>
                                        <div
                                            class="form-group col-md-6"
                                            v-if="order.payment_method"
                                        >
                                            <label>{{
                                                $t("payment_method")
                                            }}</label>
                                            <span
                                                v-tooltip="order.payment_method"
                                                >{{
                                                    $t(order.payment_method)
                                                }}
                                                -
                                                {{ order.payment_option }}</span
                                            >
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label>{{
                                                $t("order_type")
                                            }}</label>
                                            {{ $t(order.order_type) }}
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label>{{
                                                $t("dispatch_number")
                                            }}</label>
                                            {{
                                                order.shipping_dispatch_confirmation_number ||
                                                    "-"
                                            }}
                                        </div>

                                        <div
                                            class="form-group col-md-12"
                                            v-if="order.status == 'CANCELLED'"
                                        >
                                            <label>{{ $t("c_reason") }}</label>
                                            {{
                                                (order.c_reason || {})[
                                                    "name_" + $i18n.locale
                                                ] || "-"
                                            }}
                                        </div>
                                        <div class="form-group col-md-12">
                                            <label>{{ $t("notes") }}</label>
                                            {{ order.notes || "-" }}
                                        </div>

                                        <button
                                            v-if="
                                                !['HOLD'].includes(order.status)
                                            "
                                            class="btn btn-success btn-md d-block mx-auto"
                                            @click="
                                                downloadOtoLadingBill(order.id)
                                            "
                                        >
                                            {{ $t("bill_of_lading") }}
                                            <i class="fas fa-truck"></i>
                                        </button>

                                        <button
                                            v-if="
                                                !['HOLD'].includes(order.status)
                                            "
                                            class="btn btn-success btn-md d-block mx-auto"
                                            @click="
                                                showDeliveryOptions(order.id)
                                            "
                                        >
                                            {{ $t("delivery_options") }}
                                            <i class="fas fa-truck"></i>
                                        </button>

                                        <button
                                            class="btn btn-primary btn-md d-block mx-auto"
                                            @click="downloadBill(order.id)"
                                        >
                                            {{ $t("download_bill") }}
                                            <i class="fas fa-file-invoice"></i>
                                        </button>

                                        <button
                                            v-if="
                                                [
                                                    'DELIVERING',
                                                    'DELIVERED',
                                                    'RATING',
                                                    'COMPLETED',
                                                    'IS_READY'
                                                ].includes(order.status) &&
                                                    (delivery_company || {})
                                                        .main_name === 'dhl'
                                            "
                                            class="btn btn-primary btn-md d-block mx-auto"
                                            @click="
                                                downloadLadingBill(order.id)
                                            "
                                        >
                                            {{ $t("bill_of_lading") }}
                                            <i class="fas fa-file-invoice"></i>
                                        </button>
                                    </div>
                                </b-card-body>
                            </b-card>
                        </b-card-text>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "LiveOrder",
    data() {
        return {
            id: "",
            order: {},
            user: {},
            vendor: {},
            store: {},
            shippingCity: {},
            delivery_company: {},
            orders: [],
            otoCompanies: [],
            cancel_success: "",
            cancel_error: "",
            cancel_errors: {},
            cancel_data: {
                reason_id: ""
            },
            cancel_disabled: false,
            c_reasons: []
        };
    },
    created() {
        this.id = this.$route.params.id;
        this.getOrder();
        this.getOtoDeliveryCompanies();
        this.getCReasons();
    },

    methods: {
        getOrder() {
            return this.axios
                .get("orders/" + this.id)
                .then(response => {
                    let res = response.data;
                    if (res.error_flag == 0) {
                        this.order = res.result.order;
                        this.user = res.result.user;
                        this.vendor = res.result.vendor;
                        this.store = res.result.store;
                        this.shippingCity = res.result.shippingCity;
                        this.delivery_company = res.result.delivery_company;
                        console.log(res.result);
                        // console.log(res.result.user);
                    } else {
                        this.$router.push("/orders");
                    }
                })
                .catch(error => {
                    this.handleError("getOrder", error);
                })
                .finally(() => {
                    this.load = true;
                });
        },
        getOtoDeliveryCompanies() {
            return this.axios
                .get("shipping/getDeliveryOptions")
                .then(response => {
                    let res = response.data.options;
                    this.otoCompanies = res;
                    console.log(res);
                })
                .catch(error => {
                    this.handleError("getOtoDeliveryCompanies", error);
                });
        },
        chooseOtoShippingCompany(id) {
            this.axios
                .post(
                    "orders/selectDeliveryOptions/" +
                        id +
                        "/" +
                        this.oto_delivery_company
                )
                .then(response => {
                    let res = response.data;
                    if (res.success == true) {
                        this.oto_delivery_options_success = this.$t(
                            "shippment_created_successfully"
                        );
                        this.$noty.success(
                            this.$t("shippment_created_successfully")
                        );
                        this.$router.push("/live_orders");
                    } else {
                        this.alert(res.message, this.$t("error"));

                        this.oto_delivery_options_error = this.$t(
                            "created_shippment_failed"
                        );
                    }
                })
                .catch(error => {
                    this.handleError("chooseOtoShippingCompany", error);
                })
                .finally(() => {
                    // this.delivery_options_disabled = false;
                });
        },
        createOtoLadinBill(id) {
            return this.axios
                .get("orders/createOtoLadinBill/" + id)
                .then(response => {
                    let res = response.data.result.orderStatus;

                    const url = res.printAWBURL;
                    window.open(url, "_blank");
                })
                .catch(error => {
                    this.handleError("createOtoLadinBill", error);
                });
        },
        trackOtoOrder(id) {
            return this.axios
                .get("orders/createOtoLadinBill/" + id)
                .then(response => {
                    let res = response.data.result.orderStatus;

                    const url = res.trackingUrl;
                    window.open(url, "_blank");
                })
                .catch(error => {
                    this.handleError("trackOtoOrder", error);
                });
        },
        updateStatus(id) {
            let index = this.findIndex(this.orders, "id", id);

            this.$bvModal
                .msgBoxConfirm(this.$t("change_order_msg"), {
                    okVariant: "danger",
                    okTitle: this.$t("yes"),
                    cancelTitle: this.$t("no"),
                    centered: true,
                    footerClass: "custom_modal_footer",
                    contentClass: "custom_modal_content"
                })
                .then(value => {
                    if (value) {
                        this.axios
                            .post("orders/update_status/" + id)
                            .then(response => {
                                let res = response.data;

                                if (res.error_flag == 0) {
                                    // this.$set(
                                    //     this.orders[index],
                                    //     "status",
                                    //     res.result.new_status_track.status
                                    // );
                                    // this.orders[index].tracking.push({
                                    //     ...res.result.new_status_track
                                    // });
                                    this.$noty.success(res.message);
                                    this.$router.push("/live_orders");
                                } else if (res.error_flag == 1) {
                                    this.alert(res.message, this.$t("error"));
                                } else {
                                    this.handleError(
                                        "updateStatus",
                                        res.message
                                    );
                                }
                            })
                            .catch(error => {
                                this.handleError("updateStatus", error);
                            });
                    }
                });
        },
        showCancel(id) {
            this.cancel_success = this.cancel_error = "";
            this.cancel_errors = {};
            this.$set(this.cancel_data, "id", id);
            this.$refs.cancel_modal.show();
        },
        cancelOrder() {
            this.cancel_disabled = true;
            this.cancel_error = "";
            let index = this.cancel_data.id;
            // let index = this.orders.findIndex(e => e.id == this.cancel_data.id);
            this.axios
                .post("orders/cancel_order", this.cancel_data)
                .then(response => {
                    this.cancel_errors = {};
                    let res = response.data;
                    if (res.error_flag == 0) {
                        this.$set(
                            this.orders[index],
                            "status",
                            res.result.new_status_track.status
                        );
                        this.orders[index].tracking.push({
                            ...res.result.new_status_track
                        });
                        this.$noty.success(res.message);
                        this.$refs.order_modal.hide();
                        this.$refs.cancel_modal.hide();
                    } else if (res.error_flag == 1) {
                        if (typeof res.message === "object") {
                            this.cancel_errors = res.message;
                        } else {
                            this.cancel_error = res.message;
                        }
                    } else {
                        this.handleError("cancelOrder", res.message);
                    }
                })
                .catch(error => {
                    this.handleError("cancelOrder", error);
                })
                .finally(() => {
                    this.cancel_disabled = false;
                });
        },
        getOrders() {
            this.load = false;
            return this.axios
                .get("orders", {
                    params: {
                        page: this.currentPage,
                        search_key: this.search_key,
                        status: this.order_status,
                        sort_key: this.sort_key,
                        sort_type: this.sort_type,
                        page_type: this.page_type
                    }
                })
                .then(response => {
                    let res = response.data;
                    if (res.error_flag == 0) {
                        this.orders = res.result.orders.data;
                        this.rows = res.result.orders.pagination.total;
                        this.perPage = res.result.orders.pagination.per_page;
                        this.image_url = res.result.image_url;
                    }
                })
                .catch(error => {
                    this.handleError("getOrders", error);
                })
                .finally(() => {
                    this.load = true;
                });
        },
        getCReasons() {
            return this.axios
                .get("c_reasons", {
                    params: {
                        status: "on"
                    }
                })
                .then(response => {
                    let res = response.data;
                    if (res.error_flag == 0) {
                        this.c_reasons = res.result.c_reasons;
                    }
                });
        }
    }
};
</script>

<style scoped>
.img_div {
    margin: 15px;
    display: inline-block;
    position: relative;
}

.img_div img {
    height: 120px;
    width: 120px;
}

.img_div div {
    background: #ff0018;
    position: absolute;
    top: -10px;
    color: white;
    left: -10px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
}
</style>
