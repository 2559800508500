import { render, staticRenderFns } from "./AppNotifications.vue?vue&type=template&id=4346a2e6&"
import script from "./AppNotifications.vue?vue&type=script&lang=js&"
export * from "./AppNotifications.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports