<template>
  <div>
    <div class="load-content" v-if="!load">
      <b-spinner variant="primary"></b-spinner>
    </div>
    <!-- Main content -->
    <section class="content" v-if="load">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <br />
            <form class="col-md-12 p-0">
              <b-alert
                :show="edit_error || edit_success ? true : false"
                :variant="edit_error ? 'danger' : 'success'"
                dismissible
                @dismissed="edit_error = edit_success = ''"
              >
                {{ edit_error || edit_success }}
              </b-alert>
              <b-tabs
                pills
                card
                no-key-nav
                content-class="custom-card"
                nav-wrapper-class="custom-nav-card"
              >
                <b-tab
                  no-body
                  v-for="(setting, index) in settings"
                  :key="index"
                  :title="setting['tabe_title_' + $i18n.locale]"
                  :active="index === 0"
                >
                  <template v-slot:title>
                    {{ setting["tabe_title_" + $i18n.locale] }}
                  </template>
                  <b-card-text>
                    <b-card no-body class="card">
                      <b-card-body>
                        <div class="row col-8">
                          <!-- <div class="form-group col-md-12" v-if="!load">
                            <i class="fa fa-sync  fa-spin load-con"></i>
                          </div> -->
                          <div
                            v-for="(item, i) in setting.items"
                            :key="i"
                            :class="`form-group col-md-${item.field.col_size}`"
                          >
                            <label :for="item.key">{{
                              item["label_" + $i18n.locale]
                            }}</label>
                            <!--  -->
                            <input
                              class="form-control"
                              v-if="types.includes(item.field.type)"
                              v-model="settings_values[item.key]"
                              :id="item.key"
                              :type="item.field.type"
                            />
                            <!--  -->
                            <input
                              class="form-control mx-2"
                              style="
                                        height: 17px;
                                        width: 17px;
                                        vertical-align: sub;
                                        display: inline-block;
                                      "
                              v-if="['boolean'].includes(item.field.type)"
                              :id="item.key"
                              v-model="settings_values[item.key]"
                              :true-value="1"
                              :false-value="0"
                              type="checkbox"
                            />
                            <!--  -->
                            <textarea
                              class="form-control"
                              v-if="['textarea'].includes(item.field.type)"
                              v-model="settings_values[item.key]"
                              :id="item.key"
                            ></textarea>
                            <!--  -->

                            <b-form-file
                              :placeholder="$t('choose_file')"
                              v-if="['file'].includes(item.field.type)"
                              v-model="files[item.key]"
                            ></b-form-file>

                            <b-tags
                              v-if="['array'].includes(item.field.type)"
                              input-id="tags-basic"
                              placeholder=""
                              :add-button-text="$t('add')"
                              :tag-pills="true"
                              v-model="settings_values[item.key]"
                            ></b-tags>
                            <div
                              class="form-group col-md-12"
                              v-if="['image'].includes(item.field.type)"
                            >
                              <div class="image_div">
                                <img
                                  :src="
                                    typeof images[i][item.key] === 'object'
                                      ? createImg(images[i][item.key])
                                      : images[i][item.key]
                                  "
                                  :ref="item.key"
                                  @error="
                                    $event.target.src =
                                      '/images/placeholder.jpg'
                                  "
                                />
                                <!-- <i
                                  class="fa fa-times"
                                  @click.prevent="images[item.key] = null"
                                ></i> -->
                              </div>
                              <b-form-file
                                :placeholder="$t('choose_image')"
                                v-if="images[i][item.key]"
                                accept="image/*"
                                @change.prevent="
                                  onChangeImageSettings($event, item.key, i)
                                "
                                v-model="images[item.key]"
                              ></b-form-file>
                            </div>
                          </div>
                        </div>
                      </b-card-body>
                      <!-- /.card-body -->
                    </b-card>
                  </b-card-text></b-tab
                >
                <b-tab
                  no-body
                  :title="$t('add')"
                  v-if="$can('add settings') && false"
                >
                  <template v-slot:title>
                    <i class="fa fa-plus ml-2 mr-2"></i> {{ $t("add") }}
                    {{ $t("setting_2") }}
                  </template>
                  <b-card-text>
                    <b-card no-body>
                      <b-card-body>
                        <form class="col-md-8">
                          <b-alert
                            :show="add_error || add_success ? true : false"
                            variant="danger"
                            dismissible
                            @dismissed="add_error = add_success = ''"
                          >
                            {{ add_error || add_success }}
                          </b-alert>
                          <b-tabs>
                            <b-tab :title="$t('ar')">
                              <br />
                              <div class="form-row ">
                                <div class="form-group col-md-12">
                                  <label
                                    >{{ $t("title") }} | {{ $t("ar") }}</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="add_data.setting_name_ar"
                                  />
                                </div>

                                <div class="form-group col-md-12">
                                  <label
                                    >{{ $t("content") }} | {{ $t("ar") }}</label
                                  >
                                </div>
                              </div>
                            </b-tab>
                            <b-tab :title="$t('en')">
                              <br />
                              <div class="form-row ">
                                <div class="form-group col-md-12">
                                  <label
                                    >{{ $t("title") }} | {{ $t("en") }}</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="add_data.setting_name_en"
                                  />
                                </div>
                              </div>
                            </b-tab>
                          </b-tabs>

                          <div class="form-group col-md-6">
                            <label>{{ $t("menu") }}</label>
                            <select
                              class="form-control pt-0"
                              v-model="add_data.menu"
                            >
                              <option value="1"> 1</option>
                              <option value="2">2</option>
                            </select>
                          </div>

                          <button
                            type="submit"
                            class="btn btn-info mt-2"
                            :disabled="add_disabled"
                            @click="addsetting"
                          >
                            {{ $t("add") }}
                            <b-spinner small v-if="add_disabled"></b-spinner>
                          </button>
                        </form>
                      </b-card-body>
                      <!-- /.card-body -->
                    </b-card>
                  </b-card-text></b-tab
                >
              </b-tabs>

              <div class="form-group col-md-12">
                <button
                  type="submit"
                  class="btn btn-info mt-2"
                  :disabled="edit_disabled"
                  @click.prevent="updateSetting"
                >
                  {{ $t("save") }}
                  <b-spinner small v-if="edit_disabled"></b-spinner>
                </button>
              </div>
            </form>
            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: "Settings",
  data() {
    return {
      load: false,
      settings: [],
      search_fields: ["setting_name_ar"],
      types: [
        "text",
        "tel",
        "number",
        "date",
        "email",
        "month",
        "password",
        "search",
        "time",
        "url",
        "week"
      ],
      settings_values: {},
      search_key: "",
      disabled_s: [],
      currentSetting: 1,
      perSetting: 1,
      rows: 1,
      setting_icon: null,
      add_data: { menu: "1" },
      add_disabled: false,
      add_success: "",
      add_error: "",
      add_progress: 0,
      edit_data: {},
      edit_disabled: false,
      edit_success: "",
      edit_error: "",
      sort_key: "",
      sort_type: "asc",
      search_disabled: false,
      old_search_key: "",
      images: [],
      key_images: [],
      files: {},
      percent: 0
    };
  },
  created() {
    this.getSettings();
  },
  watch: {
    setting_icon(newVal, oldVal) {
      console.log(newVal, oldVal);
    }
    // images() {
    //   let arr = [];
    //   arr = this.images;
    //   this.images = [...new Set(arr)];
    // }
  },
  methods: {
    createImg(f) {
      return URL.createObjectURL(f);
    },
    getSettings() {
      // this.load = false;
      return this.axios
        .get("settings", {
          params: {
            search_key: this.search_key,
            sort_key: this.sort_key,
            sort_type: this.sort_type
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.images = [];
            this.key_images = [];
            this.settings = res.result.settings;
            let arr = res.result.settings;

            arr.forEach((a, i) => {
              arr[i].items.forEach(item => {
                if (["image"].includes(item.field.type)) {
                  this.images.push({
                    [item.key]: res.result.settings_values[item.key]
                  });
                  this.key_images.push(item.key);
                }
              });
            });
            this.settings_values = res.result.settings_values;
          }
        })
        .catch(error => {
          this.handleError("getSettings", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    sortTable(key) {
      if (this.sort_key != key) {
        this.sort_type = "asc";
      } else {
        this.sort_type = this.sort_type == "asc" ? "desc" : "asc";
      }
      this.sort_key = key;
      this.getSettings();
    },
    searchAll() {
      if (this.search_key != this.old_search_key) {
        this.old_search_key = this.search_key;
        this.search_disabled = true;
        this.getSettings().then(() => {
          this.search_disabled = false;
        });
      }
    },
    addsetting() {
      this.add_disabled = true;
      this.axios
        .post("settings/add", this.add_data)
        .then(response => {
          let res = response.data;
          this.add_error = this.add_success = "";
          if (res.error_flag == 0) {
            this.add_success = res.message;
            if (this.currentSetting == 1) {
              this.settings.splice(this.perSetting - 1, 1);
              this.rows += 1;
              this.settings.unshift(res.result.setting);
            }
            this.add_data = {};
          } else if (res.error_flag == 1) {
            this.add_error = res.message;
          } else {
            this.handleError("addsetting", res.message);
          }
        })
        .catch(error => {
          this.handleError("addsetting", error);
        })
        .finally(() => {
          this.add_disabled = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    // handleImages(key, value) {
    //   var arr = [];
    //   return arr.push({ [key]: value }), (this.images = arr);
    // },
    onChangeImageSettings(ev, key, index) {
      this.images[index][key] = ev.target.files[0];
      this.$refs[key].currentSrc = URL.createObjectURL(ev.target.files[0]);
    },
    updateSetting() {
      this.edit_disabled = true;
      let fd = new FormData();
      Object.entries(this.settings_values).forEach(([key]) => {
        this.images.forEach((image, index) => {
          if (typeof this.images[index][key] === "object") {
            fd.append(
              key,
              this.images[index][key],
              this.images[index][key].name
            );
          }
        });
        if (
          typeof this.settings_values[key] == "object" &&
          this.settings_values[key]
        ) {
          this.settings_values[key].forEach((element, i) => {
            fd.append(key + "[" + i + "]", element);
          });
        } else if (this.settings_values[key]) {
          fd.append(key, this.settings_values[key]);
        }
      });

      Object.entries(this.files).forEach(([key]) => {
        fd.append(key, this.files[key], this.files[key].name);
      });

      this.edit_error = this.edit_success = "";
      this.axios
        .post("settings/update", fd)
        .then(response => {
          let res = response.data;
          this.edit_errors = {};
          if (res.error_flag == 0) {
            this.edit_success = res.message;
            this.files = {};
            this.getSettings();
          }
        })
        .catch(error => {
          if (error.response.data.error_flag == 1) {
            if (typeof error.response.data.message === "object") {
              this.edit_error = error.response.data.message;
            } else {
              this.edit_error = error.response.data.message;
            }
          } else {
            this.handleError("updateSetting", error.response.data.message);
          }
        })
        .finally(() => {
          this.edit_disabled = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    showEditsetting(id) {
      this.edit_success = this.edit_error = "";
      this.edit_errors = {};
      this.edit_data = { ...this.settings.find(e => e.id == id) };
      this.$refs.edit_setting_modal.show();
    }
  }
};
</script>
<style>
[dir="rtl"] .b-form-tag > button.b-form-tag-remove {
  margin-right: 0.25rem;
  margin-left: 0;
}
</style>
