<template>
  <div>
    <br />
    <div class="load-content" v-if="!load">
      <b-spinner variant="primary"></b-spinner>
    </div>
    <!-- Main content -->
    <section class="content" v-else>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-3 col-6" v-if="$can('show users')">
            <!-- small box -->
            <router-link to="/users">
              <div class="small-box bg-white">
                <div class="inner">
                  <h3>{{ dashboard.users_count || 0 }}</h3>
                  <p>{{ $t("users") }}</p>
                </div>
                <div class="icon">
                  <i class="fas fa-users"></i>
                </div>
              </div>
            </router-link>
          </div>
          <!-- ./col -->
          <div class="col-lg-3 col-6" v-if="$can('show vendors')">
            <!-- small box -->
            <router-link to="/vendors">
              <div class="small-box bg-white">
                <div class="inner">
                  <h3>{{ dashboard.vendors_count || 0 }}</h3>
                  <p>{{ $t("vendors") }}</p>
                </div>
                <div class="icon">
                  <i class="fas fa-users"></i>
                </div>
              </div>
            </router-link>
          </div>
          <!-- ./col -->

          <div class="col-lg-3 col-6" v-if="$can('show stores')">
            <!-- small box -->
            <router-link to="/stores">
              <div class="small-box bg-white">
                <div class="inner">
                  <h3>{{ dashboard.stores_count || 0 }}</h3>
                  <p>{{ $t("stores") }}</p>
                </div>
                <div class="icon">
                  <i class="fas fa-store"></i>
                </div>
              </div>
            </router-link>
          </div>
          <!-- ./col -->

          <div class="col-lg-3 col-6" v-if="$can('show products')">
            <!-- small box -->
            <router-link to="/products">
              <div class="small-box bg-white">
                <div class="inner">
                  <h3>{{ dashboard.products_count || 0 }}</h3>
                  <p>{{ $t("products") }}</p>
                </div>
                <div class="icon">
                  <i class="fas fa-shopping-bag"></i>
                </div>
              </div>
            </router-link>
          </div>
          <!-- ./col -->
          <div class="col-lg-3 col-6" v-if="$can('show categories')">
            <!-- small box -->
            <router-link to="/categories">
              <div class="small-box bg-white">
                <div class="inner">
                  <h3>{{ dashboard.categories_count || 0 }}</h3>
                  <p>{{ $t("categories") }}</p>
                </div>
                <div class="icon">
                  <i class="fa fa-tags"></i>
                </div>
              </div>
            </router-link>
          </div>

          <div class="col-lg-3 col-6" v-if="$can('show orders')">
            <!-- small box -->
            <router-link to="/live_orders">
              <div class="small-box bg-white">
                <div class="inner">
                  <h3>{{ dashboard.orders_count || 0 }}</h3>
                  <p>{{ $t("orders") }}</p>
                </div>
                <div class="icon">
                  <i class="fas fa-shopping-cart"></i>
                </div>
              </div>
            </router-link>
          </div>
          <!-- ./col -->

          <!-- ./col -->
          <div class="col-lg-3 col-6" v-if="$can('show incomes')">
            <!-- small box -->
            <router-link to="/incomes">
              <div class="small-box bg-white">
                <div class="inner">
                  <h3>{{ dashboard.incomes || 0.0 }} {{ $t("sar") }}</h3>
                  <p>{{ $t("incomes") }}</p>
                </div>
                <div class="icon">
                  <i class="fas fa-money-bill-wave"></i>
                </div>
              </div>
            </router-link>
          </div>
          <!-- ./col -->
        </div>
        <!-- /.row -->

        <div class="row">
          <div class="col-md-4 mb-3">
            <b-card no-body class="home-card">
              <b-card-header class="home-card-title">{{
                $t("top_5_shops")
              }}</b-card-header>
              <b-card-body>
                <apexchart
                  v-if="enable_top_shops_chart && load"
                  type="bar"
                  height="250"
                  :options="top_shops_chart"
                  :series="top_shops_chart.series"
                  style="margin-top:-25px"
                ></apexchart>
              </b-card-body>
            </b-card>
          </div>
          <div class="col-md-4 mb-3">
            <b-card no-body class="home-card">
              <b-card-header class="home-card-title">{{
                $t("top_5_products")
              }}</b-card-header>
              <b-card-body>
                <ul class="products_list">
                  <li v-for="(product, i) in top_products" :key="i">
                    <img
                      :src="products_image_url + product.image"
                      @error="$event.target.src = '/images/placeholder.jpg'"
                    />
                    <div>
                      {{ product["name_" + $i18n.locale] }}
                      <span>{{ (product.store || {}).store_name || "-" }}</span>
                    </div>
                  </li>
                </ul>
              </b-card-body>
            </b-card>
          </div>

          <div class="col-md-4 mb-3">
            <b-card no-body class="home-card">
              <b-card-header class="home-card-title">{{
                $t("top_5_vendors")
              }}</b-card-header>
              <b-card-body>
                <ul class="products_list vendors_list">
                  <li v-for="(vendor, i) in top_vendors" :key="i">
                    <img
                      :src="users_image_url + vendor.photo"
                      @error="$event.target.src = '/images/placeholder.jpg'"
                    />
                    <div>
                      {{ vendor["name"] }}
                    </div>
                  </li>
                </ul>
              </b-card-body>
            </b-card>
          </div>

          <div class="col-md-8 mb-3">
            <b-card no-body class="home-card">
              <b-card-header class="home-card-title"
                >{{ $t("last_orders") }}
              </b-card-header>
              <b-card-body>
                <apexchart
                  height="258"
                  v-if="enable_orders_chart"
                  type="bar"
                  :options="orders_chart"
                  :series="orders_chart.series"
                ></apexchart>
              </b-card-body>
            </b-card>
          </div>

          <div class="col-md-4 mb-3">
            <b-card no-body class="home-card">
              <b-card-header class="home-card-title">{{
                $t("today_orders_status")
              }}</b-card-header>
              <b-card-body>
                <apexchart
                  height="300"
                  v-if="enable_today_orders_chart"
                  type="donut"
                  :options="today_orders_chart"
                  :series="today_orders_chart.series"
                ></apexchart>
              </b-card-body>
            </b-card>
          </div>

          <!------------------------------------------------->
          <!----------- recent vendors ---------------------->
          <!------------------------------------------------->

          <div class="col-md-6 mb-3">
            <b-card no-body class="home-card">
              <b-card-header class="home-card-title">{{
                $t("recent_added_vendors")
              }}</b-card-header>
              <b-card-body>
                <div class="table-responsive">
                  <table class="table  home-table table-striped">
                    <thead>
                      <tr>
                        <th></th>
                        <th>{{ $t("name") }}</th>
                        <th>{{ $t("date") }}</th>
                        <th>{{ $t("status") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(vendor, i) in recent_vendors" :key="i">
                        <td>
                          <img
                            :src="users_image_url + (vendor.user || {}).photo"
                            @error="
                              $event.target.src = '/images/placeholder.jpg'
                            "
                          />
                        </td>
                        <td>{{ vendor.name }}</td>
                        <td v-html="dateTimeFormat(vendor.created_at)"></td>
                        <td>
                          <span class="badge badge-success">{{
                            $t(vendor.status)
                          }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-card-body>
            </b-card>
          </div>

          <!------------------------------------------------->
          <!----------- recent products --------------------->
          <!------------------------------------------------->

          <div class="col-md-6 mb-3">
            <b-card no-body class="home-card">
              <b-card-header class="home-card-title">{{
                $t("recent_added_products")
              }}</b-card-header>
              <b-card-body>
                <div class="table-responsive">
                  <table class="table  home-table table-striped">
                    <thead>
                      <tr>
                        <th></th>
                        <th>{{ $t("name") }}</th>
                        <th>{{ $t("date") }}</th>
                        <th>{{ $t("status") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(product, i) in recent_products" :key="i">
                        <td>
                          <img src="/images/placeholder.jpg" />
                        </td>
                        <td>{{ product["name_" + $i18n.locale] }}</td>
                        <td v-html="dateTimeFormat(product.created_at)"></td>
                        <td>
                          <span class="badge badge-success">{{
                            $t(product.status)
                          }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-card-body>
            </b-card>
          </div>

          <!------------------------------------------------->
          <!----------- recent users    --------------------->
          <!------------------------------------------------->

          <div class="col-md-6 mb-3">
            <b-card no-body class="home-card">
              <b-card-header class="home-card-title">{{
                $t("users")
              }}</b-card-header>
              <b-card-body>
                <div class="row">
                  <div
                    class="col-md-4"
                    v-for="(user, i) in recent_users"
                    :key="i"
                  >
                    <div class="home-user-item">
                      <img
                        :src="users_image_url + user.photo"
                        @error="$event.target.src = '/images/placeholder.jpg'"
                      />
                      <b>{{ user.name }}</b>
                      <span dir="ltr">{{ user.email || user.mobile }}</span>
                      <b>-</b>
                    </div>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </div>

          <!------------------------------------------------->
          <!----------- recent orders --------------------->
          <!------------------------------------------------->

          <div class="col-md-6 mb-3">
            <b-card no-body class="home-card">
              <b-card-header class="home-card-title">{{
                $t("recent_orders")
              }}</b-card-header>
              <b-card-body>
                <div class="table-responsive">
                  <table class="table  home-table table-striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>{{ $t("amount") }}</th>
                        <th>{{ $t("date") }}</th>
                        <th>{{ $t("status") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(order, i) in recent_orders" :key="i">
                        <td>{{ order.id }}</td>
                        <td>{{ order.total_price }}</td>
                        <td v-html="dateTimeFormat(order.created_at)"></td>
                        <td>
                          <span class="badge badge-success">{{
                            $t(order.status)
                          }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </div>

        <!------------- end admin section ---------------->
      </div>

      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      load: false,
      dashboard: {},
      top_products: [],
      products_image_url: "",
      recent_vendors: [],
      recent_products: [],
      recent_orders: [],
      recent_users: [],
      enable_top_shops_chart: false,
      top_shops_chart: {
        chart: {
          type: "bar",
          toolbar: false,
          height: 250
        },
        colors: ["#336b69"],
        plotOptions: {
          bar: {
            barHeight: "50",
            borderRadius: 0,
            horizontal: true,
            dataLabels: {
              position: this.$i18n.locale == "en" ? "bottom" : "top"
            }
          }
        },
        tooltip: {
          enabled: false
        },
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            colors: ["#fff"]
          },
          formatter: function(val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
          }
        },
        yaxis: {
          floating: true,
          show: false,
          lines: {
            show: false
          },
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          }
        },
        xaxis: {
          lines: {
            show: false
          },
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          categories: ["Store 1", "Store 2", "Store 3", "Store 4", "Store 5"]
        },
        grid: {
          show: false,
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        },
        series: [
          {
            data: [400, 430, 448, 470, 540]
          }
        ]
      },

      enable_today_orders_chart: false,
      enable_orders_chart: false,
      orders_chart: {
        series: [
          {
            name: "",
            data: []
          }
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            distributed: false,
            columnWidth: "15",
            borderRadius: 10,
            dataLabels: {
              position: "top"
            }
          }
        },
        colors: ["#336b69"],
        chart: {
          toolbar: {
            show: false
          },
          type: "line"
        },
        tooltip: {
          enabled: true
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: [],
          labels: {
            formatter: w => {
              return this.$t("week_days." + w);
            },
            style: {
              colors: "#000",
              fontFamily: "cairo"
            }
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: [
          {
            axisBorder: {
              show: true,
              color: "#d8dfe3"
            },
            axisTicks: {
              show: false
            },
            labels: {
              style: {
                colors: "#000"
              }
            }
          }
        ],
        legend: {
          show: false
        },
        grid: {
          show: true,
          borderColor: "#d8dfe3",
          strokeDashArray: 1,
          position: "back",
          xaxis: {
            lines: {
              show: false
            }
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        }
      },
      today_orders_chart: {
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              size: "50%",
              labels: {
                show: true,
                total: {
                  show: true,
                  label: this.$t("total"),
                  fontFamily: "cairo",
                  color: "#373d3f",
                  formatter: w => {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  }
                }
              }
            }
          }
        },
        legend: {
          show: true,
          position: "bottom",
          fontFamily: "cairo",
          formatter: w => {
            return this.$t(w);
          }
        },
        stroke: {
          show: false,
          width: 0
        },
        colors: ["#33d2a7", "#f56464", "#c57afe", "#1dd9fe"],
        expandOnClick: false,
        dataLabels: {
          enabled: true,
          formatter: (value, { seriesIndex, w }) => {
            return w.config.series[seriesIndex];
          }
        },
        labels: ["NEW", "DELIVERING", "COMPLETED", "CANCELLED"],
        series: []
      }
    };
  },
  created() {
    this.getDashboard();
  },
  watch: {
    "$i18n.locale"() {
      this.$set(
        this.top_shops_chart.plotOptions.bar.dataLabels,
        "position",
        this.$i18n.locale == "en" ? "bottom" : "top"
      );
      this.enable_top_shops_chart = false;
      this.enable_orders_chart = false;
      this.enable_today_orders_chart = false;
      setTimeout(() => {
        this.enable_top_shops_chart = true;
        this.enable_orders_chart = true;
        this.enable_today_orders_chart = true;
      }, 500);
    }
  },
  methods: {
    getDashboard() {
      this.axios
        .get("dashboard")
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.dashboard = res.result.dashboard;
            this.top_products = res.result.top_products;
            this.top_vendors = res.result.top_vendors;
            this.recent_vendors = res.result.recent_vendors;
            this.recent_products = res.result.recent_products;
            this.recent_orders = res.result.recent_orders;
            this.recent_users = res.result.recent_users;
            this.products_image_url = res.result.products_image_url;
            this.users_image_url = res.result.users_image_url;

            let top_shops = res.result.top_shops;
            this.$set(
              this.top_shops_chart.xaxis,
              "categories",
              top_shops.map(e => e.store_name)
            );
            this.$set(
              this.top_shops_chart.series[0],
              "data",
              top_shops.map(e => e.count)
            );

            let orders_by_days = res.result.orders_by_days;
            this.$set(
              this.orders_chart.xaxis,
              "categories",
              orders_by_days.map(e => e.day)
            );
            this.$set(
              this.orders_chart.series[0],
              "data",
              orders_by_days.map(e => e.orders)
            );
            let today_orders_statuses = res.result.today_orders_statuses;

            this.$set(this.today_orders_chart, "series", [
              today_orders_statuses["NEW"] || 0,
              today_orders_statuses["DELIVERING"] || 0,
              today_orders_statuses["COMPLETED"] || 0,
              today_orders_statuses["CANCELLED"] || 0
            ]);

            this.$nextTick(() => {
              this.enable_today_orders_chart = true;
              this.enable_orders_chart = true;
              this.enable_top_shops_chart = true;
            });
          } else {
            this.handleError("getDashboard", res.message, 1);
          }
        })
        .catch(error => {
          this.handleError("getDashboard", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    copyThis(text) {
      this.$copyText(text).then(
        () => {
          this.$noty.success(this.$t("copied"));
        },
        function() {
          this.$noty.warning(this.$t("error"));
        }
      );
    }
  }
};
</script>
