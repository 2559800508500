<template>
  <div>
    <b-modal
      ref="edit_pack_modal"
      :title="$t('edit') + ' ' + $t('pack_2')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <form class="col-md-12">
          <b-alert
            :show="edit_error || edit_success ? true : false"
            :variant="edit_error ? 'danger' : 'success'"
            dismissible
            @dismissed="edit_error = edit_success = ''"
          >
            {{ edit_error || edit_success }}
          </b-alert>

          <div class="form-row ">
            <div class="form-group col-md-12">
              <label>{{ $t("delivery_company") }}</label>
              <select
                class="form-control pt-0"
                :class="{ 'is-invalid': edit_errors.delivery_company_id }"
                v-model="edit_data.delivery_company_id"
              >
                <option disabled value="">{{ $t("select") }}</option>
                <option
                  v-for="(company, i) in delivery_companies"
                  :key="i"
                  :value="company.id"
                  >{{ company.name }}</option
                >
              </select>
              <span class="error invalid-feedback">{{
                (edit_errors.delivery_company_id || []).join("-")
              }}</span>
            </div>

            <div class="form-group col-md-6">
              <label>{{ $t("length") }}</label>
              <input
                type="number"
                min="0"
                class="form-control"
                :class="{ 'is-invalid': edit_errors.p_length }"
                v-model="edit_data.p_length"
              />
              <span class="error invalid-feedback">{{
                (edit_errors.p_length || []).join("-")
              }}</span>
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("width") }}</label>
              <input
                type="number"
                min="0"
                class="form-control"
                :class="{ 'is-invalid': edit_errors.p_width }"
                v-model="edit_data.p_width"
              />
              <span class="error invalid-feedback">{{
                (edit_errors.p_width || []).join("-")
              }}</span>
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("height") }}</label>
              <input
                type="number"
                min="0"
                class="form-control"
                :class="{ 'is-invalid': edit_errors.p_height }"
                v-model="edit_data.p_height"
              />
              <span class="error invalid-feedback">{{
                (edit_errors.p_height || []).join("-")
              }}</span>
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("weight") }}</label>
              <input
                type="number"
                min="0"
                class="form-control"
                :class="{ 'is-invalid': edit_errors.p_weight }"
                v-model="edit_data.p_weight"
              />
              <span class="error invalid-feedback">{{
                (edit_errors.p_weight || []).join("-")
              }}</span>
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-info mt-2"
            :disabled="edit_disabled"
            @click.prevent="updatePack"
          >
            {{ $t("save") }}
            <b-spinner small v-if="edit_disabled"></b-spinner>
          </button>
        </form>
      </div>
    </b-modal>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <b-tabs
              v-model="c_tab"
              pills
              card
              no-key-nav
              content-class="custom-card"
              nav-wrapper-class="custom-nav-card"
            >
              <b-tab no-body :title="$t('all')" active>
                <template v-slot:title>
                  <i class="fa fa-list ml-2 mr-2"></i> {{ $t("all") }}
                </template>
                <b-card-text>
                  <b-card no-body class="card">
                    <b-card-header>
                      <div class="card-tools">
                        <div class="input-group input-group-sm">
                          <input
                            type="text"
                            class="form-control float-right"
                            :placeholder="$t('search')"
                            v-model="search_key"
                          />
                          <div class="input-group-append">
                            <button
                              type="submit"
                              :disabled="search_disabled"
                              @click.prevent="searchAll"
                              class="btn btn-default"
                            >
                              <i
                                class="fa fa-spinner fa-spin"
                                v-if="search_disabled"
                              ></i>
                              <i class="fas fa-search" v-else></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-card-header>
                    <!-- /.card-header -->
                    <b-card-body class="table-responsive p-0">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th>
                              #
                            </th>
                            <th>
                              {{ $t("name") }}
                            </th>
                            <th>
                              {{ $t("delivery_company") }}
                            </th>
                            <th class="sort" @click="sortTable('created_at')">
                              {{ $t("created_at") }}
                            </th>
                            <th>{{ $t("actions") }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="5" v-if="!load">
                              <i class="fa fa-sync  fa-spin load-con"></i>
                            </td>
                            <td
                              colspan="5"
                              class="no_data"
                              v-if="load && packs.length == 0"
                            >
                              {{ $t("no_data") }}
                            </td>
                          </tr>
                          <tr v-for="(item, i) in packs" :key="i" v-show="load">
                            <td>{{ i + 1 }}</td>

                            <td>
                              {{ item.p_length }} × {{ item.p_width }} ×
                              {{ item.p_height }} × {{ item.p_weight }}
                            </td>
                            <td>
                              {{ (item.delivery_company || {}).name || "" }}
                            </td>

                            <td v-html="dateTimeFormat(item.created_at)"></td>
                            <td class="actions-group">
                              <button
                                v-if="$can('edit packs')"
                                class="btn btn-info btn-sm"
                                v-tooltip="$t('edit')"
                                @click="showEditPack(item.id)"
                              >
                                <i class="fas fa-pencil-alt"> </i>
                              </button>
                              <button
                                v-if="$can('delete packs')"
                                @click="deletePack(item.id)"
                                class="btn btn-danger btn-sm"
                                v-tooltip="$t('delete')"
                              >
                                <i class="fas fa-trash"> </i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-card-body>
                    <!-- /.card-body -->
                    <b-card-footer class="card-footer clearfix">
                      <b-pagination
                        class="m-2"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        :prev-text="$t('previous')"
                        :next-text="$t('next')"
                        align="right"
                        :first-number="true"
                        :last-number="true"
                      ></b-pagination>
                    </b-card-footer>
                  </b-card> </b-card-text
              ></b-tab>
              <b-tab no-body :title="$t('add')" v-if="$can('add packs')">
                <template v-slot:title>
                  <i class="fa fa-plus ml-2 mr-2"></i> {{ $t("add") }}
                </template>
                <b-card-text>
                  <b-card no-body>
                    <b-card-body>
                      <form class="col-md-8">
                        <b-alert
                          :show="add_error || add_success ? true : false"
                          :variant="add_error ? 'danger' : 'success'"
                          dismissible
                          @dismissed="add_error = add_success = ''"
                        >
                          {{ add_error || add_success }}
                        </b-alert>
                        <div class="form-row ">
                          <div class="form-group col-md-12">
                            <label>{{ $t("delivery_company") }}</label>
                            <select
                              class="form-control pt-0"
                              :class="{
                                'is-invalid': add_errors.delivery_company_id
                              }"
                              v-model="add_data.delivery_company_id"
                            >
                              <option disabled value="">{{
                                $t("select")
                              }}</option>
                              <option
                                v-for="(company, i) in delivery_companies"
                                :key="i"
                                :value="company.id"
                                >{{ company.name }}</option
                              >
                            </select>
                            <span class="error invalid-feedback">{{
                              (add_errors.delivery_company_id || []).join("-")
                            }}</span>
                          </div>
                          <div class="form-group col-md-6">
                            <label>{{ $t("length") }}</label>
                            <input
                              type="number"
                              min="0"
                              class="form-control"
                              :class="{ 'is-invalid': add_errors.p_length }"
                              v-model="add_data.p_length"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.p_length || []).join("-")
                            }}</span>
                          </div>
                          <div class="form-group col-md-6">
                            <label>{{ $t("width") }}</label>
                            <input
                              type="number"
                              min="0"
                              class="form-control"
                              :class="{ 'is-invalid': add_errors.p_width }"
                              v-model="add_data.p_width"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.p_width || []).join("-")
                            }}</span>
                          </div>
                          <div class="form-group col-md-6">
                            <label>{{ $t("height") }}</label>
                            <input
                              type="number"
                              min="0"
                              class="form-control"
                              :class="{ 'is-invalid': add_errors.p_height }"
                              v-model="add_data.p_height"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.p_height || []).join("-")
                            }}</span>
                          </div>
                          <div class="form-group col-md-6">
                            <label>{{ $t("weight") }}</label>
                            <input
                              type="number"
                              min="0"
                              class="form-control"
                              :class="{ 'is-invalid': add_errors.p_weight }"
                              v-model="add_data.p_weight"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.p_weight || []).join("-")
                            }}</span>
                          </div>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-info mt-2"
                          :disabled="add_disabled"
                          @click="addPack"
                        >
                          {{ $t("add") }}
                          <b-spinner small v-if="add_disabled"></b-spinner>
                        </button>
                        <div class="icheck-primary  add_with_new">
                          <input
                            type="checkbox"
                            id="add_with_new"
                            value="1"
                            v-model="add_with_new"
                          />
                          <label for="add_with_new">
                            {{ $t("add_with_new") }}
                          </label>
                        </div>
                      </form>
                    </b-card-body>
                    <!-- /.card-body -->
                  </b-card>
                </b-card-text></b-tab
              >
            </b-tabs>

            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: "Packs",
  data() {
    return {
      load: false,
      packs: [],
      search_key: "",
      old_search_key: "",
      search_disabled: false,
      disabled_s: [],
      currentPage: 1,
      perPage: 1,
      rows: 1,
      add_data: {
        delivery_company_id: ""
      },
      add_disabled: false,
      add_success: "",
      add_error: "",
      add_errors: {},
      add_progress: 0,
      edit_data: {},
      edit_disabled: false,
      edit_success: "",
      edit_error: "",
      edit_errors: {},
      add_with_new: 0,
      c_tab: 0,
      sort_type: "",
      sort_key: "",
      delivery_companies: []
    };
  },
  created() {
    this.getPacks();
    this.getDeliveryCompanies();
  },
  watch: {
    currentPage() {
      this.getPacks();
    }
  },
  methods: {
    getPacks() {
      return this.axios
        .get("packs", {
          params: {
            page: this.currentPage,
            search_key: this.search_key
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.packs = res.result.packs.data;
            this.rows = res.result.packs.total;
            this.perPage = res.result.packs.per_page;
          }
        })
        .catch(error => {
          this.handleError("getPacks", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    searchAll() {
      if (this.search_key != this.old_search_key) {
        this.old_search_key = this.search_key;
        this.search_disabled = true;
        this.getPacks().then(() => {
          this.search_disabled = false;
        });
      }
    },
    sortTable(key) {
      if (this.sort_key != key) {
        this.sort_type = "asc";
      } else {
        this.sort_type = this.sort_type == "asc" ? "desc" : "asc";
      }
      this.sort_key = key;
      this.getPacks();
    },
    getDeliveryCompanies() {
      return this.axios
        .get("delivery_companies")
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.delivery_companies = res.result.delivery_companies;
          }
        })
        .catch(error => {
          this.handleError("getDeliveryCompanies", error);
        });
    },
    addPack() {
      this.add_disabled = true;
      let fd = new FormData();
      Object.keys(this.add_data).forEach(key => {
        fd.append(key, this.add_data[key]);
      });
      this.add_error = this.add_success = "";
      this.add_errors = {};
      this.axios
        .post("packs/add", fd)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.add_success = res.message;
            this.getPacks().then(() => {
              if (!this.add_with_new) {
                this.c_tab = 0;
              }
            });
            this.add_data = {};
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.add_errors = res.message;
            } else {
              this.add_error = res.message;
            }
          } else {
            this.handleError("addPack", res.message);
          }
        })
        .catch(error => {
          this.handleError("addPack", error);
        })
        .finally(() => {
          this.add_disabled = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    updatePack() {
      this.edit_disabled = true;
      let fd = new FormData();
      Object.keys(this.edit_data).forEach(key => {
        if (this.edit_data[key]) {
          fd.append(key, this.edit_data[key]);
        }
      });
      this.edit_error = this.edit_success = "";
      this.edit_errors = {};
      this.axios
        .post("packs/update", fd)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.edit_success = res.message;
            this.edit_data = { ...res.result.pack };
            let i = this.packs.findIndex(e => e.id == this.edit_data.id);
            this.$set(this.packs, i, res.result.pack);
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.edit_errors = res.message;
            } else {
              this.edit_error = res.message;
            }
          } else {
            this.handleError("updatePack", res.message);
          }
        })
        .catch(error => {
          this.handleError("updatePack", error);
        })
        .finally(() => {
          this.edit_disabled = false;
        });
    },
    deletePack(id) {
      let index = this.findIndex(this.packs, "id", id);

      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("packs/delete/" + id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.packs.splice(index, 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deletePack", res.message);
                }
              })
              .catch(error => {
                this.handleError("deletePack", error);
              });
          }
        });
    },
    showEditPack(id) {
      this.edit_success = this.edit_error = "";
      this.edit_data = { ...this.packs.find(e => e.id == id) };
      this.$refs.edit_pack_modal.show();
    }
  }
};
</script>
