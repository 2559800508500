<template>
    <div>

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <b-tabs
                            v-model="c_tab"
                            pills
                            card
                            no-key-nav
                            content-class="custom-card"
                            nav-wrapper-class="custom-nav-card"
                        >
                            <b-tab no-body :title="$t('all')" active>
                                <template v-slot:title>
                                    <i class="fa fa-list ml-2 mr-2"></i> {{ $t("all") }}
                                </template>
                                <b-card-text>
                                    <b-card no-body class="card">
                                        <b-card-header>
                                            <!-- <div class="card-tools">
                                                <div class="input-group input-group-sm">
                                                    <i
                                                        class="fas fa-cog c_pointer customize_table"
                                                        v-tooltip="$t('customize_table')"
                                                        @click="customizeTable"
                                                    ></i>
                                                    <input
                                                        type="text"
                                                        class="form-control float-right"
                                                        :placeholder="$t('search')"
                                                        v-model="search_key"
                                                    />
                                                    <div class="input-group-append">
                                                        <button
                                                            type="submit"
                                                            :disabled="search_disabled"
                                                            @click.prevent="searchAll"
                                                            class="btn btn-default"
                                                        >
                                                            <i
                                                                class="fa fa-spinner fa-spin"
                                                                v-if="search_disabled"
                                                            ></i>
                                                            <i class="fas fa-search" v-else></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div> -->
                                        </b-card-header>
                                        <!-- /.card-header -->
                                        <b-card-body class="table-responsive p-0">
                                            <table class="table table-hover">
                                                <thead>
                                                <tr>
                                                    <th>
                                                        #
                                                    </th>
                                                    <th>{{ $t('store') }}</th>
                                                    <th>
                                                        {{ $t("actions") }}
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td
                                                        :colspan="table_selected_fields.length"
                                                        v-if="!load"
                                                    >
                                                        <i class="fa fa-sync  fa-spin load-con"></i>
                                                    </td>
                                                    <td
                                                        :colspan="table_selected_fields.length"
                                                        class="no_data"
                                                        v-if="load && important_stores.length == 0"
                                                    >
                                                        {{ $t("no_data") }}
                                                    </td>
                                                </tr>
                                                <tr
                                                    v-for="(item, i) in important_stores"
                                                    :key="i"
                                                    v-show="load"
                                                >
                                                    <td >
                                                        {{ i + 1 }}
                                                    </td>
                                                    <td >
                                                        {{ item.store.store_name }}
                                                    </td>
                                                    <td
                                                        class="actions-group"
                                                    >
                                                        <button
                                                            v-if="$can('delete important_stores')"
                                                            @click="deleteImportantStore(item.id)"
                                                            class="btn btn-danger btn-sm"
                                                            v-tooltip="$t('delete')"
                                                        >
                                                            <i class="fas fa-trash"> </i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </b-card-body>

                                    </b-card>
                                </b-card-text
                                >
                            </b-tab>
                            <b-tab no-body :title="$t('add')"  v-if="$can('add important_stores')">
                                <template v-slot:title>
                                    <i class="fa fa-plus ml-2 mr-2"></i> {{ $t("add") }}
                                </template>
                                <b-card-text>
                                    <b-card no-body>
                                        <b-card-body>
                                            <form class="col-md-8">
                                                <b-alert
                                                    :show="add_error || add_success ? true : false"
                                                    :variant="add_error ? 'danger' : 'success'"
                                                    dismissible
                                                    @dismissed="add_error = add_success = ''"
                                                >
                                                    {{ add_error || add_success }}
                                                </b-alert>
                                                <div class="form-row ">

                                                    <div class="form-group col-md-6">
                                                        <label>{{ $t("vendor") }}</label>
                                                        <select
                                                            class="form-control custom-select"
                                                            :class="{
                                      'is-invalid': add_errors.vendor_id
                                    }"
                                                            v-model="add_data.vendor_id"
                                                        >
                                                            <option selected=""  value="">{{
                                                                    $t("select")
                                                                }}
                                                            </option>
                                                            <option
                                                                v-for="(vendor, i) in vendors"
                                                                :key="i"
                                                                :value="vendor.id"
                                                            >{{ vendor["name"] }}
                                                            </option
                                                            >
                                                        </select>
                                                        <span class="error invalid-feedback">{{
                                                                (add_errors.vendor_id || []).join("-")
                                                            }}</span>
                                                    </div>

                                                    <div  class="form-group col-md-6">
                                                        <label>{{ $t("store") }}</label>
                                                        <select
                                                            :disabled="
                                      !add_data.vendor_id || stores.length == 0
                                    "
                                                            class="form-control custom-select"
                                                            :class="{
                                      'is-invalid': add_errors.store_id
                                    }"
                                                            v-model="add_data.store_id"
                                                        >
                                                            <option selected="" disabled="" value="">{{
                                                                    $t("select")
                                                                }}
                                                            </option>
                                                            <option
                                                                v-for="(store, i) in stores"
                                                                :key="i"
                                                                :value="store.id"
                                                            >{{ store["store_name"] }}
                                                            </option
                                                            >
                                                        </select>
                                                        <span class="error invalid-feedback">{{
                                                                (add_errors.store_id || []).join("-")
                                                            }}</span>
                                                    </div>

                                                </div>

                                                <button
                                                    type="submit"
                                                    class="btn btn-info mt-2"
                                                    :disabled="add_disabled"
                                                    @click="addStore"
                                                >
                                                    {{ $t("add") }}
                                                    <b-spinner small v-if="add_disabled"></b-spinner>
                                                </button>

                                            </form>
                                        </b-card-body>
                                        <!-- /.card-body -->
                                    </b-card>
                                </b-card-text>
                            </b-tab>
                        </b-tabs>

                        <!-- /.card -->
                    </div>
                </div>
                <!-- /.row -->
            </div>
            <!-- /.container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>

<script>
export default {
    name: "ImportantStores",
    data() {
        return {
            load: false,
            important_stores: [],
            vendors: [],
            stores: [],
            search_key: "",
            old_search_key: "",
            search_disabled: false,
            disabled_s: [],
            currentPage: 1,
            perPage: 1,
            rows: 1,
            add_data: {},
            add_disabled: false,
            add_success: "",
            add_error: "",
            add_errors: {},
            add_progress: 0,
            add_data_icon: null,
            add_data_image: null,
            add_data_icon_icon: null,
            add_data_icon_image: null,
            edit_data_icon: null,
            edit_data_image: null,
            edit_data_icon_icon: null,
            edit_data_icon_image: null,
            image_url: "",
            edit_data: {},
            edit_disabled: false,
            edit_success: "",
            edit_error: "",
            edit_errors: {},
            sort_key: "",
            sort_type: "asc",
            table_fields: ["#", "name", "created_at", "status", "actions"],
            table_selected_fields: [],
            add_with_new: 0,
            c_tab: 0
        };
    },
    created() {
        this.table_selected_fields = this.$store.state.brands_selected_fields;
        this.getImportantStores();
        this.getVendors();
    },
    watch: {
        currentPage() {
            this.getImportantStores();
        },
        table_selected_fields() {
            this.$store.commit("setBrandsSelectedFields", this.table_selected_fields);
        },
        "add_data.vendor_id"() {
            if (this.add_data.vendor_id) {
                this.getVendorStores(this.add_data.vendor_id);
            } else {
                this.$set(this.add_data, "store_id", "");
            }
        },
    },
    methods: {
        getImportantStores() {
            return this.axios
                .get("stores/important/show", {
                    params: {}
                })
                .then(response => {
                    let res = response.data;
                    if (res.error_flag == 0) {
                        this.important_stores = res.result.important_stores;
                    }
                })
                .catch(error => {
                    this.handleError("getImportantStores", error);
                })
                .finally(() => {
                    this.load = true;
                });
        },
        getVendors() {
            return this.axios.get("products/vendors").then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                    this.vendors = res.result.vendors;
                }
            });
        },
        getVendorStores(vendor_id) {
            return this.axios
                .get("products/vendorStores/" + vendor_id)
                .then(response => {
                    let res = response.data;
                    if (res.error_flag == 0) {
                        this.stores = res.result.stores;
                    }
                })
                .catch(error => {
                    this.handleError("getVendorStores", error);
                });
        },
        sortTable(key) {
            if (this.sort_key != key) {
                this.sort_type = "asc";
            } else {
                this.sort_type = this.sort_type == "asc" ? "desc" : "asc";
            }
            this.sort_key = key;
            this.getImportantStores();
        },
        searchAll() {
            if (this.search_key != this.old_search_key) {
                this.old_search_key = this.search_key;
                this.search_disabled = true;
                this.getImportantStores().then(() => {
                    this.search_disabled = false;
                });
            }
        },
        addStore() {
            this.add_disabled = true;
            let fd = new FormData();

            if (this.add_data_icon) {
                fd.append("image_input", this.add_data_icon, this.add_data_icon.name);
            }
            if (this.add_data_icon_icon) {
                fd.append(
                    "icon_input",
                    this.add_data_icon_icon,
                    this.add_data_icon_icon.name
                );
            }
            Object.keys(this.add_data).forEach(key => {
                fd.append(key, this.add_data[key]);
            });
            this.add_error = this.add_success = "";
            this.add_errors = {};
            this.axios
                .post("stores/important/add/"+ this.add_data.store_id, fd)
                .then(response => {
                    let res = response.data;
                    if (res.error_flag == 0) {
                        this.add_success = res.message;
                        this.getImportantStores().then(() => {
                            if (!this.add_with_new) {
                                this.c_tab = 0;
                            }
                        });
                        this.add_data = {};
                        this.add_data_image = null;
                        this.add_data_icon = null;
                        this.add_data_icon_image = null;
                        this.add_data_icon_icon = null;
                    } else if (res.error_flag == 1) {
                        if (typeof res.message === "object") {
                            this.add_errors = res.message;
                        } else {
                            this.add_error = res.message;
                        }
                    } else {
                        this.handleError("addStore", res.message);
                    }
                })
                .catch(error => {
                    this.handleError("addStore", error);
                })
                .finally(() => {
                    this.add_disabled = false;
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: "smooth"
                    });
                });
        },

        deleteImportantStore(id) {
            let index = this.findIndex(this.important_stores, "id", id);

            this.$bvModal
                .msgBoxConfirm(this.$t("are_you_sure"), {
                    okVariant: "danger",
                    okTitle: this.$t("yes"),
                    cancelTitle: this.$t("no"),
                    centered: true,
                    footerClass: "custom_modal_footer",
                    contentClass: "custom_modal_content"
                })
                .then(value => {
                    if (value) {
                        this.axios
                            .post("stores/important/remove/" + id)
                            .then(response => {
                                let res = response.data;
                                if (res.error_flag == 0) {
                                    this.important_stores.splice(index, 1);
                                    this.$noty.success(res.message);
                                } else if (res.error_flag == 1) {
                                    this.alert(res.message, this.$t("error"));
                                } else {
                                    this.handleError("deleteImportantStore", res.message);
                                }
                            })
                            .catch(error => {
                                this.handleError("deleteImportantStore", error);
                            });
                    }
                });
        },
    }
};
</script>
