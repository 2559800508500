<template>
    <div>
        <b-modal
            ref="show_payroll_modal"
            :title="$t('details') + ' ' + $t('payroll')"
            header-class="new_modal_header"
            hide-footer
        >
            <div class="d-block">
                <b-card-text>
                    <b-card no-body>
                        <b-card-body>
                            <form class="col-md-12">
                                <b-alert
                                    :show="
                                        edit_error || show_success
                                            ? true
                                            : false
                                    "
                                    :variant="edit_error ? 'danger' : 'success'"
                                    dismissible
                                    @dismissed="edit_error = show_success = ''"
                                >
                                    {{ edit_error || show_success }}
                                </b-alert>
                                <div class="form-row ">
                                    <div class="form-group col-md-6">
                                        <label>{{ $t("vendor_name") }}</label>
                                        <input
                                            type="text"
                                            class="form-control ltr"
                                            v-model="vendorDetails.name"
                                            readonly
                                        />
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label>{{ $t("mobile") }}</label>
                                        <input
                                            type="text"
                                            class="form-control ltr"
                                            v-model="vendorDetails.mobile"
                                            readonly
                                        />
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label>{{
                                            $t("deserved_amount")
                                        }}</label>
                                        <input
                                            type="text"
                                            class="form-control ltr"
                                            v-model="show_data.amount"
                                            readonly
                                        />
                                        {{ $t("sar") }}
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label>{{
                                            $t("association_name")
                                        }}</label>
                                        <input
                                            type="text"
                                            class="form-control ltr"
                                            v-model="
                                                vendorDetails.association_no
                                            "
                                            readonly
                                        />
                                    </div>
                                    <hr />

                                    <table class="table table-hover">
                                        <thead>
                                            <tr>
                                                <th
                                                    v-if="
                                                        wallet_table_selected_fields.includes(
                                                            '#'
                                                        )
                                                    "
                                                >
                                                    #
                                                </th>

                                                <th
                                                    v-if="
                                                        wallet_table_selected_fields.includes(
                                                            'order_id'
                                                        )
                                                    "
                                                    class="sort"
                                                >
                                                    {{ $t("order_id") }}
                                                </th>

                                                <th
                                                    v-if="
                                                        wallet_table_selected_fields.includes(
                                                            'amount'
                                                        )
                                                    "
                                                    class="sort"
                                                >
                                                    {{ $t("amount") }}
                                                </th>

                                                <th
                                                    v-if="
                                                        wallet_table_selected_fields.includes(
                                                            'is_due'
                                                        )
                                                    "
                                                    class="sort"
                                                    @click="sortTable('is_due')"
                                                >
                                                    {{ $t("is_due") }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td
                                                    :colspan="
                                                        wallet_table_selected_fields.length
                                                    "
                                                    v-if="!wallet_load"
                                                >
                                                    <i
                                                        class="fa fa-sync  fa-spin load-con"
                                                    ></i>
                                                </td>
                                                <td
                                                    :colspan="
                                                        wallet_table_selected_fields.length
                                                    "
                                                    class="no_data"
                                                    v-if="
                                                        wallet_load &&
                                                            invoices_f.length ==
                                                                0
                                                    "
                                                >
                                                    {{ $t("no_data") }}
                                                </td>
                                            </tr>
                                            <tr
                                                v-for="(item, i) in wallet"
                                                :key="i"
                                                v-show="wallet_load"
                                            >
                                                <td
                                                    v-if="
                                                        wallet_table_selected_fields.includes(
                                                            '#'
                                                        )
                                                    "
                                                >
                                                    {{ i + 1 }}
                                                </td>

                                                <td
                                                    v-if="
                                                        wallet_table_selected_fields.includes(
                                                            'order_id'
                                                        )
                                                    "
                                                >
                                                    {{ item.order_id || "-" }}
                                                </td>

                                                <td
                                                    v-if="
                                                        wallet_table_selected_fields.includes(
                                                            'amount'
                                                        )
                                                    "
                                                >
                                                    {{ item.amount }}
                                                </td>

                                                <td
                                                    v-if="
                                                        wallet_table_selected_fields.includes(
                                                            'is_due'
                                                        )
                                                    "
                                                >
                                                    {{
                                                        item.is_due
                                                            ? $t("is_due")
                                                            : $t("not_due")
                                                    }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <!-- /.card-body -->
                                    <b-card-footer class="card-footer clearfix">
                                        <b-pagination
                                            class="m-2"
                                            v-model="wallet_currentPage"
                                            :total-rows="wallet_rows"
                                            :per-page="wallet_perPage"
                                            :prev-text="$t('previous')"
                                            :next-text="$t('next')"
                                            align="right"
                                            :first-number="true"
                                            :last-number="true"
                                        ></b-pagination>
                                    </b-card-footer>
                                </div>
                            </form>
                        </b-card-body>
                    </b-card>
                </b-card-text>
            </div>
        </b-modal>

        <b-modal
            ref="show_c_payroll_modal"
            :title="$t('details') + ' ' + $t('payroll')"
            header-class="new_modal_header"
            hide-footer
        >
            <div class="d-block">
                <b-card-text>
                    <b-card no-body>
                        <b-card-body>
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th
                                            v-if="
                                                wallet_table_selected_fields.includes(
                                                    '#'
                                                )
                                            "
                                        >
                                            #
                                        </th>

                                        <th
                                            v-if="
                                                wallet_table_selected_fields.includes(
                                                    'order_id'
                                                )
                                            "
                                            class="sort"
                                        >
                                            {{ $t("order_id") }}
                                        </th>

                                        <th
                                            v-if="
                                                wallet_table_selected_fields.includes(
                                                    'amount'
                                                )
                                            "
                                            class="sort"
                                        >
                                            {{ $t("amount") }}
                                        </th>

                                        <!-- <th
                                            v-if="
                                                wallet_table_selected_fields.includes(
                                                    'is_due'
                                                )
                                            "
                                            class="sort"
                                            @click="sortTable('is_due')"
                                        >
                                            {{ $t("is_due") }}
                                        </th> -->

                                        <th
                                            v-if="
                                                wallet_table_selected_fields.includes(
                                                    'created_at'
                                                )
                                            "
                                            class="sort"
                                            @click="sortTable('created_at')"
                                        >
                                            {{ $t("created_at") }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td
                                            :colspan="
                                                wallet_table_selected_fields.length
                                            "
                                            v-if="!wallet_load"
                                        >
                                            <i
                                                class="fa fa-sync  fa-spin load-con"
                                            ></i>
                                        </td>
                                        <td
                                            :colspan="
                                                wallet_table_selected_fields.length
                                            "
                                            class="no_data"
                                            v-if="
                                                wallet_load &&
                                                    c_wallet.length == 0
                                            "
                                        >
                                            {{ $t("no_data") }}
                                        </td>
                                    </tr>
                                    <tr
                                        v-for="(item, i) in c_wallet"
                                        :key="i"
                                        v-show="wallet_load"
                                    >
                                        <td
                                            v-if="
                                                wallet_table_selected_fields.includes(
                                                    '#'
                                                )
                                            "
                                        >
                                            {{ i + 1 }}
                                        </td>

                                        <td
                                            v-if="
                                                wallet_table_selected_fields.includes(
                                                    'order_id'
                                                )
                                            "
                                        >
                                            {{ item.order_id || "-" }}
                                        </td>

                                        <td
                                            v-if="
                                                wallet_table_selected_fields.includes(
                                                    'amount'
                                                )
                                            "
                                        >
                                            {{ item.amount }} {{ $t("sar") }}
                                        </td>

                                        <!-- <td
                                            v-if="
                                                wallet_table_selected_fields.includes(
                                                    'is_due'
                                                )
                                            "
                                        >
                                            {{
                                                item.is_due
                                                    ? $t("is_due")
                                                    : $t("not_due")
                                            }}
                                        </td> -->

                                        <td
                                            v-if="
                                                wallet_table_selected_fields.includes(
                                                    'created_at'
                                                )
                                            "
                                            v-html="
                                                dateTimeFormat(item.created_at)
                                            "
                                        ></td>
                                    </tr>
                                </tbody>
                            </table>
                        </b-card-body>
                    </b-card>
                </b-card-text>
            </div>
        </b-modal>

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <br />

                        <b-tabs
                            pills
                            card
                            no-key-nav
                            content-class="custom-card"
                            nav-wrapper-class="custom-nav-card"
                        >
                            <b-tab :title="$t('vendor_payrolls')" no-body>
                                <b-card-text>
                                    <b-card no-body class="card">
                                        <b-card-header>
                                            {{ $t("select") }}
                                            {{ $t("batch") }}:
                                            <div
                                                class="form-group mb-0"
                                                style="display: inline-block;"
                                            >
                                                <select
                                                    class="form-control custom-select"
                                                    style="width: max-content;"
                                                    v-model="batch_filter"
                                                >
                                                    <option value="" disabled>{{
                                                        $t("select")
                                                    }}</option>
                                                    <option
                                                        v-for="(batch,
                                                        i) in batches"
                                                        :key="i"
                                                        >{{ batch }}</option
                                                    >
                                                </select>
                                            </div>
                                            <div
                                                class="card-tools"
                                                style="margin-top:5px"
                                                v-if="batch_summary.amount"
                                            >
                                                <span class="mr-2 ml-2">
                                                    {{ $t("amounts_due") }}:
                                                    <b
                                                        >{{
                                                            batch_summary.amount
                                                        }}
                                                        {{ $t("sar") }}</b
                                                    >
                                                </span>
                                                <span class="mr-2 ml-2">
                                                    {{ $t("vendors_count") }}:
                                                    <b
                                                        >{{
                                                            batch_summary.payrolls_count
                                                        }}
                                                    </b>
                                                </span>
                                                <span class="mr-2 ml-2">
                                                    {{ $t("status") }}:
                                                    <span
                                                        :class="
                                                            'badge badge-' +
                                                                (batch_summary.is_paid
                                                                    ? 'info'
                                                                    : 'danger')
                                                        "
                                                    >
                                                        {{
                                                            batch_summary.is_paid
                                                                ? $t("is_paid")
                                                                : $t("not_paid")
                                                        }}
                                                    </span>
                                                </span>
                                            </div>
                                        </b-card-header>
                                        <b-card-header>
                                            <button
                                                v-if="invoices.length > 0"
                                                class="btn btn-sm btn-info"
                                                @click="downloadExcel"
                                                :disabled="excel_disabled"
                                            >
                                                {{ $t("export_excel") }}
                                                <i
                                                    class="fa fa-download mr-1 ml-1"
                                                    v-if="!excel_disabled"
                                                ></i>
                                                <b-spinner
                                                    small
                                                    v-else
                                                    class="mr-1 ml-1"
                                                ></b-spinner>
                                            </button>

                                            <button
                                                v-if="
                                                    invoices.length > 0 &&
                                                        batch_summary.is_paid
                                                "
                                                class="btn btn-sm btn-success mr-3 ml-3"
                                                @click="paidDone"
                                                :disabled="paid_done_disabled"
                                            >
                                                {{ batch_summary.paid_at }}
                                                <i
                                                    class="fa fa-check mr-1 ml-1"
                                                    v-if="!paid_done_disabled"
                                                ></i>
                                                <b-spinner
                                                    small
                                                    v-else
                                                    class="mr-1 ml-1"
                                                ></b-spinner>
                                            </button>

                                            <div class="card-tools">
                                                <div
                                                    class="input-group input-group-sm"
                                                >
                                                    <input
                                                        type="text"
                                                        class="form-control float-right"
                                                        :placeholder="
                                                            $t('search')
                                                        "
                                                        v-model="search_key"
                                                    />
                                                    <div
                                                        class="input-group-append"
                                                    >
                                                        <button
                                                            type="submit"
                                                            :disabled="
                                                                search_disabled
                                                            "
                                                            @click.prevent="
                                                                searchAll
                                                            "
                                                            class="btn btn-default"
                                                        >
                                                            <i
                                                                class="fa fa-spinner fa-spin"
                                                                v-if="
                                                                    search_disabled
                                                                "
                                                            ></i>
                                                            <i
                                                                class="fas fa-search"
                                                                v-else
                                                            ></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-card-header>
                                        <b-card-header>
                                            <div class="row">
                                                <div
                                                    class="input-group col-md-3"
                                                >
                                                    <label
                                                        >{{ $t("from") }}
                                                    </label>
                                                    <input
                                                        type="date"
                                                        class="form-control"
                                                        v-model="from_date"
                                                    />
                                                </div>
                                                <div
                                                    class="input-group col-md-3"
                                                >
                                                    <label
                                                        >{{ $t("to") }}
                                                    </label>
                                                    <input
                                                        type="date"
                                                        class="form-control"
                                                        v-model="to_date"
                                                    />
                                                </div>
                                                <div
                                                    class="input-group col-md-3"
                                                >
                                                    <button
                                                        type="submit"
                                                        :disabled="
                                                            search_disabled
                                                        "
                                                        @click.prevent="
                                                            searchByDate
                                                        "
                                                        class="btn btn-primary"
                                                    >
                                                        {{ $t("search") }}
                                                    </button>
                                                </div>
                                            </div>
                                        </b-card-header>
                                        <!-- /.card-header -->
                                        <b-card-body
                                            class="table-responsive p-0"
                                        >
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            #
                                                        </th>
                                                        <th>
                                                            {{
                                                                $t(
                                                                    "vendor_name"
                                                                )
                                                            }}
                                                        </th>
                                                        <th>
                                                            {{ $t("mobile") }}
                                                        </th>
                                                        <th>
                                                            {{
                                                                $t(
                                                                    "deserved_amount"
                                                                )
                                                            }}
                                                        </th>
                                                        <th>
                                                            {{
                                                                $t(
                                                                    "association_name"
                                                                )
                                                            }}
                                                        </th>
                                                        <th>
                                                            {{ $t("date") }}
                                                        </th>

                                                        <th v-if="true">
                                                            {{ $t("actions") }}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td
                                                            colspan="4"
                                                            v-if="!load"
                                                        >
                                                            <i
                                                                class="fa fa-sync  fa-spin load-con"
                                                            ></i>
                                                        </td>

                                                        <td
                                                            colspan="4"
                                                            class="no_data"
                                                            v-if="
                                                                load &&
                                                                    invoices.length ==
                                                                        0
                                                            "
                                                        >
                                                            {{ $t("no_data") }}
                                                        </td>
                                                    </tr>
                                                    <tr
                                                        v-for="(item,
                                                        i) in invoices"
                                                        :key="i"
                                                        v-show="load"
                                                    >
                                                        <td>
                                                            {{ i + 1 }}
                                                        </td>

                                                        <td>
                                                            <router-link
                                                                :to="
                                                                    '/vendors/' +
                                                                        (item.vendor
                                                                            ? item
                                                                                  .vendor
                                                                                  .id
                                                                            : '')
                                                                "
                                                            >
                                                                {{
                                                                    (
                                                                        item.vendor ||
                                                                        {}
                                                                    ).name ||
                                                                        "-"
                                                                }}
                                                            </router-link>
                                                        </td>
                                                        <td>
                                                            {{
                                                                (
                                                                    item.vendor ||
                                                                    {}
                                                                ).mobile || "-"
                                                            }}
                                                        </td>
                                                        <td>
                                                            {{ item.amount }}
                                                            {{ $t("sar") }}
                                                        </td>
                                                        <td>
                                                            {{
                                                                item.vendor
                                                                    ? item
                                                                          .vendor
                                                                          .association_no
                                                                    : ""
                                                            }}
                                                        </td>
                                                        <td
                                                            v-html="
                                                                dateTimeFormat(
                                                                    item.created_at
                                                                )
                                                            "
                                                        ></td>

                                                        <td
                                                            class="actions-group"
                                                            v-if="true"
                                                        >
                                                            <button
                                                                v-if="
                                                                    $can(
                                                                        'show invoices'
                                                                    )
                                                                "
                                                                class="btn btn-info btn-sm"
                                                                v-tooltip="
                                                                    $t('show')
                                                                "
                                                                @click="
                                                                    showPayrollAdmin(
                                                                        item.id
                                                                    )
                                                                "
                                                            >
                                                                <i
                                                                    class="fas fa-eye"
                                                                >
                                                                </i>
                                                            </button>

                                                            <button
                                                                v-if="
                                                                    $can(
                                                                        'delete invoices'
                                                                    )
                                                                "
                                                                @click="
                                                                    deleteInvoice(
                                                                        item.id
                                                                    )
                                                                "
                                                                class="btn btn-danger btn-sm"
                                                                v-tooltip="
                                                                    $t('delete')
                                                                "
                                                            >
                                                                <i
                                                                    class="fas fa-trash"
                                                                >
                                                                </i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </b-card-body>
                                        <!-- /.card-body -->
                                        <b-card-footer
                                            class="card-footer clearfix"
                                        >
                                            <b-pagination
                                                class="m-2"
                                                v-model="currentPage"
                                                :total-rows="rows"
                                                :per-page="perPage"
                                                :prev-text="$t('previous')"
                                                :next-text="$t('next')"
                                                align="right"
                                                :first-number="true"
                                                :last-number="true"
                                            ></b-pagination>
                                        </b-card-footer>
                                    </b-card>
                                </b-card-text>
                            </b-tab>
                            <b-tab
                                :title="$t('delivery_company_payrolls')"
                                no-body
                            >
                                <b-card-text>
                                    <b-card no-body class="card">
                                        <b-card-header>
                                            {{ $t("select") }}
                                            {{ $t("batch") }}:
                                            <div
                                                class="form-group mb-0"
                                                style="display: inline-block;"
                                            >
                                                <select
                                                    class="form-control custom-select"
                                                    style="width: max-content;"
                                                    v-model="c_batch_filter"
                                                >
                                                    <option value="" disabled>{{
                                                        $t("select")
                                                    }}</option>
                                                    <option
                                                        v-for="(batch,
                                                        i) in c_batches"
                                                        :key="i"
                                                        >{{ batch }}</option
                                                    >
                                                </select>
                                            </div>
                                            <div
                                                class="card-tools"
                                                style="margin-top:5px"
                                                v-if="c_batch_summary.amount"
                                            >
                                                <span class="mr-2 ml-2">
                                                    {{ $t("amounts_due") }}:
                                                    <b
                                                        >{{
                                                            c_batch_summary.amount
                                                        }}
                                                        {{ $t("sar") }}</b
                                                    >
                                                </span>
                                                <span class="mr-2 ml-2">
                                                    {{ $t("companies_count") }}:
                                                    <b
                                                        >{{
                                                            c_batch_summary.payrolls_count
                                                        }}
                                                    </b>
                                                </span>
                                                <span class="mr-2 ml-2">
                                                    {{ $t("status") }}:
                                                    <span
                                                        :class="
                                                            'badge badge-' +
                                                                (c_batch_summary.is_paid
                                                                    ? 'info'
                                                                    : 'danger')
                                                        "
                                                    >
                                                        {{
                                                            c_batch_summary.is_paid
                                                                ? $t("is_paid")
                                                                : $t("not_paid")
                                                        }}
                                                    </span>
                                                </span>
                                            </div>
                                        </b-card-header>
                                        <b-card-header>
                                            <button
                                                v-if="c_invoices.length > 0"
                                                class="btn btn-sm btn-info"
                                                @click="c_downloadExcel"
                                                :disabled="c_excel_disabled"
                                            >
                                                {{ $t("export_excel") }}
                                                <i
                                                    class="fa fa-download mr-1 ml-1"
                                                    v-if="!c_excel_disabled"
                                                ></i>
                                                <b-spinner
                                                    small
                                                    v-else
                                                    class="mr-1 ml-1"
                                                ></b-spinner>
                                            </button>

                                            <button
                                                v-if="
                                                    c_invoices.length > 0 &&
                                                        c_batch_summary.is_paid
                                                "
                                                class="btn btn-sm btn-success mr-3 ml-3"
                                                @click="c_paidDone"
                                                :disabled="c_paid_done_disabled"
                                            >
                                                {{ c_batch_summary.paid_at }}

                                                <i
                                                    class="fa fa-check mr-1 ml-1"
                                                    v-if="!c_paid_done_disabled"
                                                ></i>
                                                <b-spinner
                                                    small
                                                    v-else
                                                    class="mr-1 ml-1"
                                                ></b-spinner>
                                            </button>

                                            <div class="card-tools">
                                                <div
                                                    class="input-group input-group-sm"
                                                >
                                                    <input
                                                        type="text"
                                                        class="form-control float-right"
                                                        :placeholder="
                                                            $t('search')
                                                        "
                                                        v-model="c_search_key"
                                                    />
                                                    <div
                                                        class="input-group-append"
                                                    >
                                                        <button
                                                            type="submit"
                                                            :disabled="
                                                                c_search_disabled
                                                            "
                                                            @click.prevent="
                                                                c_searchAll
                                                            "
                                                            class="btn btn-default"
                                                        >
                                                            <i
                                                                class="fa fa-spinner fa-spin"
                                                                v-if="
                                                                    c_search_disabled
                                                                "
                                                            ></i>
                                                            <i
                                                                class="fas fa-search"
                                                                v-else
                                                            ></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-card-header>
                                        <b-card-header>
                                            <div class="row">
                                                <div
                                                    class="input-group col-md-3"
                                                >
                                                    <label
                                                        >{{ $t("from") }}
                                                    </label>
                                                    <input
                                                        type="date"
                                                        class="form-control"
                                                        v-model="
                                                            from_date_company
                                                        "
                                                    />
                                                </div>
                                                <div
                                                    class="input-group col-md-3"
                                                >
                                                    <label
                                                        >{{ $t("to") }}
                                                    </label>
                                                    <input
                                                        type="date"
                                                        class="form-control"
                                                        v-model="
                                                            to_date_company
                                                        "
                                                    />
                                                </div>
                                                <div
                                                    class="input-group col-md-3"
                                                >
                                                    <button
                                                        type="submit"
                                                        :disabled="
                                                            c_search_disabled
                                                        "
                                                        @click.prevent="
                                                            c_searchByDate
                                                        "
                                                        class="btn btn-primary"
                                                    >
                                                        {{ $t("search") }}
                                                    </button>
                                                </div>
                                            </div>
                                        </b-card-header>

                                        <!-- /.card-header -->
                                        <b-card-body
                                            class="table-responsive p-0"
                                        >
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            #
                                                        </th>
                                                        <th>
                                                            {{
                                                                $t(
                                                                    "company_name"
                                                                )
                                                            }}
                                                        </th>
                                                        <th>
                                                            {{ $t("date") }}
                                                        </th>
                                                        <th>
                                                            {{
                                                                $t(
                                                                    "deserved_amount"
                                                                )
                                                            }}
                                                        </th>

                                                        <th v-if="true">
                                                            {{ $t("actions") }}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td
                                                            colspan="4"
                                                            v-if="!load"
                                                        >
                                                            <i
                                                                class="fa fa-sync  fa-spin load-con"
                                                            ></i>
                                                        </td>
                                                        <td
                                                            colspan="4"
                                                            class="no_data"
                                                            v-if="
                                                                load &&
                                                                    c_invoices.length ==
                                                                        0
                                                            "
                                                        >
                                                            {{ $t("no_data") }}
                                                        </td>
                                                    </tr>
                                                    <tr
                                                        v-for="(item,
                                                        i) in c_invoices"
                                                        :key="i"
                                                        v-show="load"
                                                    >
                                                        <td>
                                                            {{ i + 1 }}
                                                        </td>

                                                        <td>
                                                            <router-link
                                                                :to="{
                                                                    name:
                                                                        'DeliveryCompany',
                                                                    params: {
                                                                        id:
                                                                            item
                                                                                .delivery_company
                                                                                .id
                                                                    }
                                                                }"
                                                            >
                                                                {{
                                                                    (
                                                                        item.delivery_company ||
                                                                        {}
                                                                    ).name ||
                                                                        "-"
                                                                }}
                                                            </router-link>
                                                        </td>
                                                        <td
                                                            v-html="
                                                                dateTimeFormat(
                                                                    item.created_at
                                                                )
                                                            "
                                                        ></td>
                                                        <td>
                                                            {{ item.amount }}
                                                            {{ $t("sar") }}
                                                        </td>

                                                        <td
                                                            class="actions-group"
                                                            v-if="true"
                                                        >
                                                            <button
                                                                v-if="
                                                                    $can(
                                                                        'show invoices'
                                                                    )
                                                                "
                                                                class="btn btn-info btn-sm"
                                                                v-tooltip="
                                                                    $t('show')
                                                                "
                                                                @click="
                                                                    showPayrollCompanies(
                                                                        item.id
                                                                    )
                                                                "
                                                            >
                                                                <i
                                                                    class="fas fa-eye"
                                                                >
                                                                </i>
                                                            </button>

                                                            <!-- <button
                                                                v-if="
                                                                    $can(
                                                                        'delete invoices'
                                                                    )
                                                                "
                                                                @click="
                                                                    deleteInvoice(
                                                                        item.id
                                                                    )
                                                                "
                                                                class="btn btn-danger btn-sm"
                                                                v-tooltip="
                                                                    $t('delete')
                                                                "
                                                            >
                                                                <i
                                                                    class="fas fa-trash"
                                                                >
                                                                </i>
                                                            </button> -->
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </b-card-body>
                                        <!-- /.card-body -->
                                        <b-card-footer
                                            class="card-footer clearfix"
                                        >
                                            <b-pagination
                                                class="m-2"
                                                v-model="c_currentPage"
                                                :total-rows="c_rows"
                                                :per-page="c_perPage"
                                                :prev-text="$t('previous')"
                                                :next-text="$t('next')"
                                                align="right"
                                                :first-number="true"
                                                :last-number="true"
                                            ></b-pagination>
                                        </b-card-footer>
                                    </b-card>
                                </b-card-text>
                            </b-tab>
                        </b-tabs>
                        <!-- /.card -->
                    </div>
                </div>
                <!-- /.row -->
            </div>
            <!-- /.container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>

<script>
export default {
    name: "Invoices",
    data() {
        return {
            load: false,
            wallet_load: false,
            wallet_table_fields: ["#", "order_id", "amount", "is_due"],
            invoices: [],
            c_invoices: [],
            search_fields: ["name", "mobile"],
            search_key: "",
            c_search_key: "",
            old_search_key: "",
            c_old_search_key: "",
            from_date: "",
            old_from_date: "",
            to_date: "",
            old_to_date: "",
            from_date_company: "",
            old_from_date_company: "",
            to_date_company: "",
            old_to_date_company: "",
            search_disabled: false,
            c_search_disabled: false,
            disabled_s: [],
            c_disabled_s: [],
            currentPage: 1,
            c_currentPage: 1,
            perPage: 1,
            c_perPage: 1,
            rows: 0,
            c_rows: 0,
            w_request: {},
            sort_key: "",
            c_sort_key: "",
            sort_type: "asc",
            c_sort_type: "asc",
            batch_filter: "",
            c_batch_filter: "",
            batches: [],
            batch_summary: {},
            excel_disabled: false,
            paid_done_disabled: false,
            c_batches: [],
            c_batch_summary: {},
            c_excel_disabled: false,
            c_paid_done_disabled: false,
            show_data: {},
            c_show_data: {},
            vendorDetails: {},
            deliveryCompanyDetails: {},
            edit_disabled: false,
            edit_success: "",
            edit_error: "",
            show_success: "",
            wallet_table_selected_fields: [],
            wallet: [],
            c_wallet: [],
            wallet_search_key: "",
            wallet_old_search_key: "",
            wallet_currentPage: 1,
            wallet_perPage: 1,
            wallet_rows: 1,
            wallet_search_disabled: false,
            // wallet_sort_key: "",
            // wallet_sort_type: "asc",
            vendorId: "",
            companyId: ""
        };
    },
    created() {
        this.getInvoices();
        this.c_getInvoices();
        this.wallet_table_selected_fields = this.$store.state.wallet_selected_fields;
        this.getWallet();
        this.getC_Wallet();
        // this.id = this.$route.params.id;
    },
    computed: {
        invoices_f() {
            return this.kimoSearch(this.invoices, this.search_fields);
        }
    },
    watch: {
        currentPage() {
            this.getInvoices();
        },
        c_currentPage() {
            this.c_getInvoices();
        },
        table_selected_fields() {
            this.$store.commit(
                "setInvoicesSelectedFields",
                this.table_selected_fields
            );
            this.$store.commit(
                "setWalletSelectedFields",
                this.table_selected_fields
            );
        },
        batch_filter() {
            this.load = false;
            this.batch_summary = {};
            this.getInvoices();
        },
        c_batch_filter() {
            this.load = false;
            this.c_batch_summary = {};
            this.c_getInvoices();
        },
        wallet_currentPage() {
            this.getWallet();
        }
    },
    methods: {
        getInvoices() {
            return this.axios
                .get("vendor_payrolls", {
                    params: {
                        page: this.currentPage,
                        search_key: this.search_key,
                        from_date: this.from_date,
                        to_date: this.to_date,
                        sort_key: this.sort_key,
                        sort_type: this.sort_type,
                        batch_filter: this.batch_filter
                    }
                })
                .then(response => {
                    let res = response.data;
                    if (res.error_flag == 0) {
                        this.invoices = res.result.payrolls.data;
                        this.rows = res.result.payrolls.total;
                        this.perPage = res.result.payrolls.per_page;
                        this.batches = res.result.batches;
                        this.batch_summary = res.result.batch_summary;
                        this.batch_filter = this.batch_summary.batch_filter;
                    }
                })
                .catch(error => {
                    this.handleError("getInvoices", error);
                })
                .finally(() => {
                    this.load = true;
                });
        },
        c_getInvoices() {
            return this.axios
                .get("delivery_company_payrolls", {
                    params: {
                        page: this.c_currentPage,
                        search_key: this.c_search_key,
                        from_date_company: this.from_date_company,
                        to_date_company: this.to_date_company,
                        sort_key: this.c_sort_key,
                        sort_type: this.c_sort_type,
                        batch_filter: this.c_batch_filter
                    }
                })
                .then(response => {
                    let res = response.data;
                    if (res.error_flag == 0) {
                        this.c_invoices = res.result.payrolls.data;
                        this.c_rows = res.result.payrolls.total;
                        this.c_perPage = res.result.payrolls.per_page;
                        this.c_batches = res.result.batches;
                        this.c_batch_summary = res.result.batch_summary;
                        this.c_batch_filter = this.batch_summary.batch_filter;
                    }
                })
                .catch(error => {
                    this.handleError("c_getInvoices", error);
                })
                .finally(() => {
                    this.load = true;
                });
        },
        sortTable(key) {
            if (this.sort_key != key) {
                this.sort_type = "asc";
            } else {
                this.sort_type = this.sort_type == "asc" ? "desc" : "asc";
            }
            this.sort_key = key;
            this.getInvoices();
        },
        searchAll() {
            if (this.search_key != this.old_search_key) {
                this.old_search_key = this.search_key;
                this.search_disabled = true;
                this.getInvoices().then(() => {
                    this.search_disabled = false;
                });
            }
        },
        searchByDate() {
            console.log(this.from_date);
            if (this.from_date != this.old_from_date) {
                this.old_from_date = this.from_date;
                this.search_disabled = true;
                this.getInvoices().then(() => {
                    this.search_disabled = false;
                });
            }
        },
        deleteInvoice(id) {
            let index = this.findIndex(this.invoices, "id", id);

            this.$bvModal
                .msgBoxConfirm(this.$t("are_you_sure"), {
                    okVariant: "danger",
                    okTitle: this.$t("yes"),
                    cancelTitle: this.$t("no"),
                    centered: true,
                    footerClass: "custom_modal_footer",
                    contentClass: "custom_modal_content"
                })
                .then(value => {
                    if (value) {
                        this.axios
                            .post("vendor_payrolls/delete/" + id)
                            .then(response => {
                                let res = response.data;
                                if (res.error_flag == 0) {
                                    this.invoices.splice(index, 1);
                                    this.$noty.success(res.message);
                                } else if (res.error_flag == 1) {
                                    this.alert(res.message, this.$t("error"));
                                } else {
                                    this.handleError(
                                        "deleteInvoice",
                                        res.message
                                    );
                                }
                            })
                            .catch(error => {
                                this.handleError("deleteInvoice", error);
                            });
                    }
                });
        },
        paidDone() {
            //let index = this.findIndex(this.invoices, "id", id);
            this.$bvModal
                .msgBoxConfirm(this.$t("are_you_sure"), {
                    okVariant: "danger",
                    okTitle: this.$t("yes"),
                    cancelTitle: this.$t("no"),
                    centered: true,
                    footerClass: "custom_modal_footer",
                    contentClass: "custom_modal_content"
                })
                .then(value => {
                    if (value) {
                        this.paid_done_disabled = true;
                        this.axios
                            .post(
                                "vendor_payrolls/paid_done/" + this.batch_filter
                            )
                            .then(response => {
                                let res = response.data;
                                if (res.error_flag == 0) {
                                    this.$set(this.batch_summary, "is_paid", 1);
                                    this.$noty.success(res.message);
                                } else if (res.error_flag == 1) {
                                    this.alert(res.message, this.$t("error"));
                                } else {
                                    this.handleError("paidDone", res.message);
                                }
                            })
                            .catch(error => {
                                this.handleError("paidDone", error);
                            })
                            .finally(() => {
                                this.paid_done_disabled = false;
                            });
                    }
                });
        },
        downloadExcel() {
            this.excel_disabled = true;
            let time = new Date().getTime();
            this.axios
                .get("vendor_payrolls/download_excel", {
                    params: {
                        batch_filter: this.batch_filter,
                        search_key: this.search_key,
                        from_date: this.from_date,
                        to_date: this.to_date
                    },
                    responseType: "arraybuffer"
                })
                .then(response => {
                    let blob = new Blob([response.data], {
                        type:
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = "Invoices_" + time + ".xlsx";
                    link.click();
                })
                .catch(error => {
                    this.handleError("downloadExcel", error);
                })
                .finally(() => {
                    this.excel_disabled = false;
                });
        },
        c_sortTable(key) {
            if (this.c_sort_key != key) {
                this.c_sort_type = "asc";
            } else {
                this.c_sort_type = this.c_sort_type == "asc" ? "desc" : "asc";
            }
            this.c_sort_key = key;
            this.c_getInvoices();
        },
        c_searchAll() {
            if (this.c_search_key != this.c_old_search_key) {
                this.c_old_search_key = this.c_search_key;
                this.c_search_disabled = true;
                this.c_getInvoices().then(() => {
                    this.c_search_disabled = false;
                });
            }
        },
        c_searchByDate() {
            console.log(this.from_date_company);
            if (this.from_date_company != this.old_from_date_company) {
                this.old_from_date_company = this.from_date_company;
                this.c_search_disabled = true;
                this.c_getInvoices().then(() => {
                    this.c_search_disabled = false;
                });
            }
        },
        c_deleteInvoice(id) {
            let index = this.findIndex(this.c_invoices, "id", id);

            this.$bvModal
                .msgBoxConfirm(this.$t("are_you_sure"), {
                    okVariant: "danger",
                    okTitle: this.$t("yes"),
                    cancelTitle: this.$t("no"),
                    centered: true,
                    footerClass: "custom_modal_footer",
                    contentClass: "custom_modal_content"
                })
                .then(value => {
                    if (value) {
                        this.axios
                            .post("delivery_company_payrolls/delete/" + id)
                            .then(response => {
                                let res = response.data;
                                if (res.error_flag == 0) {
                                    this.c_invoices.splice(index, 1);
                                    this.$noty.success(res.message);
                                } else if (res.error_flag == 1) {
                                    this.alert(res.message, this.$t("error"));
                                } else {
                                    this.handleError(
                                        "deleteInvoice",
                                        res.message
                                    );
                                }
                            })
                            .catch(error => {
                                this.handleError("deleteInvoice", error);
                            });
                    }
                });
        },
        c_paidDone() {
            //let index = this.findIndex(this.invoices, "id", id);
            this.$bvModal
                .msgBoxConfirm(this.$t("are_you_sure"), {
                    okVariant: "danger",
                    okTitle: this.$t("yes"),
                    cancelTitle: this.$t("no"),
                    centered: true,
                    footerClass: "custom_modal_footer",
                    contentClass: "custom_modal_content"
                })
                .then(value => {
                    if (value) {
                        this.c_paid_done_disabled = true;
                        this.axios
                            .post(
                                "delivey_company_payrolls/paid_done/" +
                                    this.c_batch_filter
                            )
                            .then(response => {
                                let res = response.data;
                                if (res.error_flag == 0) {
                                    this.$set(
                                        this.c_batch_summary,
                                        "is_paid",
                                        1
                                    );
                                    this.$noty.success(res.message);
                                } else if (res.error_flag == 1) {
                                    this.alert(res.message, this.$t("error"));
                                } else {
                                    this.handleError("paidDone", res.message);
                                }
                            })
                            .catch(error => {
                                this.handleError("paidDone", error);
                            })
                            .finally(() => {
                                this.c_paid_done_disabled = false;
                            });
                    }
                });
        },
        c_downloadExcel() {
            this.c_excel_disabled = true;
            let time = new Date().getTime();
            this.axios
                .get("delivery_company_payrolls/download_excel", {
                    params: {
                        batch_filter: this.c_batch_filter,
                        search_key: this.c_search_key,
                        from_date_company: this.from_date_company,
                        to_date_company: this.to_date_company
                    },
                    responseType: "arraybuffer"
                })
                .then(response => {
                    let blob = new Blob([response.data], {
                        type:
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = "Invoices_" + time + ".xlsx";
                    link.click();
                })
                .catch(error => {
                    this.handleError("downloadExcel", error);
                })
                .finally(() => {
                    this.c_excel_disabled = false;
                });
        },
        showPayrollAdmin(id) {
            this.show_success = this.edit_error = "";
            this.show_data = { ...this.invoices.find(e => e.id == id) };
            this.vendorDetails = this.show_data.vendor;
            this.vendorId = this.vendorDetails.id;
            this.getWallet();
            this.$refs.show_payroll_modal.show();
        },
        showPayrollCompanies(id) {
            this.show_success = this.edit_error = "";
            this.c_show_data = { ...this.c_invoices.find(e => e.id == id) };
            this.deliveryCompanyDetails = this.c_show_data.delivery_company;
            this.companyId = this.deliveryCompanyDetails.id;
            this.getC_Wallet();
            this.$refs.show_c_payroll_modal.show();
        },
        getC_Wallet() {
            if (this.companyId !== "") {
                return this.axios
                    .get("delivery_companies/wallet/" + this.companyId, {
                        params: {
                            page: this.wallet_currentPage,
                            search_key: this.wallet_search_key
                            // sort_key: this.wallet_sort_key,
                            // sort_type: this.wallet_sort_type
                        }
                    })
                    .then(response => {
                        let res = response.data;
                        if (res.error_flag == 0) {
                            this.c_wallet = res.result.wallet.data;
                            this.wallet_rows = res.result.wallet.total;
                            this.wallet_perPage = res.result.wallet.per_page;
                            this.balance = res.result.balance;
                        }
                    })
                    .catch(error => {
                        this.handleError("getWallet", error);
                    })
                    .finally(() => {
                        this.wallet_load = true;
                    });
            }
        },
        getWallet() {
            // console.log(this.vendorId + "sss");

            if (this.vendorId !== "") {
                return this.axios
                    .get("vendors/wallet/" + this.vendorId)
                    .then(response => {
                        let res = response.data;
                        if (res.error_flag == 0) {
                            this.wallet = res.result.wallet.data;
                            this.wallet_rows = res.result.wallet.total;
                            this.wallet_perPage = res.result.wallet.per_page;
                            this.balance = res.result.balance;
                        }
                    })
                    .catch(error => {
                        this.handleError("getWallet", error);
                    })
                    .finally(() => {
                        this.wallet_load = true;
                    });
            }
        },
        // wallet_sortTable(key) {
        //     if (this.wallet_sort_key != key) {
        //         this.wallet_sort_type = "asc";
        //     } else {
        //         this.wallet_sort_type =
        //             this.wallet_sort_type == "asc" ? "desc" : "asc";
        //     }
        //     this.wallet_sort_key = key;
        //     this.getWallet();
        // },
        wallet_searchAll() {
            if (this.wallet_search_key != this.wallet_old_search_key) {
                this.wallet_old_search_key = this.wallet_search_key;
                this.wallet_search_disabled = true;
                this.getWallet().then(() => {
                    this.wallet_search_disabled = false;
                });
            }
        },
        wallet_customizeTable() {
            this.$refs.wallet_customize_table_modal.show();
        }
    }
};
</script>
