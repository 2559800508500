<template>
  <div>
    <b-modal
      ref="customize_table_modal"
      centered
      :title="$t('customize_table')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <div class="row clearfix">
          <div class="col-md-12">
            <div class="form-row" style="padding: 0 20px">
              <div
                class="form-group col-md-6"
                v-for="(field, i) in table_fields"
                :key="i"
              >
                <input
                  type="checkbox"
                  class="form-check-input"
                  aria-label="Checkbox for following text input"
                  :value="field"
                  :id="'f_' + field"
                  v-model="table_selected_fields"
                  style="vertical-align: middle;"
                /><label
                  class="form-check-label"
                  :for="'f_' + field"
                  style="color: blue;"
                >
                  {{ $t(field.toLowerCase()) }}</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <b-tabs
              pills
              card
              no-key-nav
              content-class="custom-card"
              nav-wrapper-class="custom-nav-card"
            >
              <b-tab no-body :title="$t('all')" active>
                <template v-slot:title>
                  <i class="fa fa-list ml-2 mr-2"></i> {{ $t("all") }}
                </template>
                <b-card-text>
                  <b-card no-body class="card">
                    <b-card-header>
                      <div class="card-tools">
                        <div class="input-group input-group-sm">
                          <i
                            class="fas fa-cog c_pointer customize_table"
                            :title="$t('customize_table')"
                            @click="customizeTable"
                          ></i>
                          <input
                            type="text"
                            class="form-control float-right"
                            :placeholder="$t('search')"
                            v-model="search_key"
                          />
                          <div class="input-group-append">
                            <button
                              type="submit"
                              :disabled="search_disabled"
                              @click.prevent="searchAll"
                              class="btn btn-default"
                            >
                              <i
                                class="fa fa-spinner fa-spin"
                                v-if="search_disabled"
                              ></i>
                              <i class="fas fa-search" v-else></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        :disabled="top_disabled"
                        @click.prevent="topUsers"
                        class="btn btn-sm btn-info"
                      >
                        <i
                          class="fa fa-spinner fa-spin"
                          v-if="top_disabled"
                        ></i>
                        {{ $t("top_users") }}
                      </button>
                    </b-card-header>
                    <!-- /.card-header -->
                    <b-card-body class="table-responsive p-0">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th v-if="table_selected_fields.includes('#')">
                              #
                            </th>
                            <th
                              v-if="table_selected_fields.includes('name')"
                              class="sort"
                              @click="sortTable('name')"
                            >
                              {{ $t("name") }}
                            </th>
                            <th
                              v-if="table_selected_fields.includes('mobile')"
                              class="sort"
                              @click="sortTable('mobile')"
                            >
                              {{ $t("mobile") }}
                            </th>
                            <th
                              v-if="table_selected_fields.includes('email')"
                              class="sort"
                              @click="sortTable('email')"
                            >
                              {{ $t("email") }}
                            </th>
                            <th
                              v-if="
                                table_selected_fields.includes('device_type')
                              "
                              class="sort"
                              @click="sortTable('device_type')"
                            >
                              {{ $t("device_type") }}
                            </th>
                            <th
                              v-if="
                                table_selected_fields.includes('live_orders')
                              "
                              class="sort"
                              @click="sortTable('live_orders')"
                            >
                              {{ $t("live_orders") }}
                            </th>
                            <th
                              v-if="
                                table_selected_fields.includes(
                                  'completed_orders'
                                )
                              "
                              class="sort"
                              @click="sortTable('completed_orders')"
                            >
                              {{ $t("completed_orders") }}
                            </th>
                            <th
                              v-if="
                                table_selected_fields.includes(
                                  'cancelled_orders'
                                )
                              "
                              class="sort"
                              @click="sortTable('cancelled_orders')"
                            >
                              {{ $t("cancelled_orders") }}
                            </th>
                            <th
                              v-if="table_selected_fields.includes('wallet')"
                              class="sort"
                              @click="sortTable('wallet')"
                            >
                              {{ $t("wallet") }}
                            </th>

                            <th
                              v-if="
                                table_selected_fields.includes('created_at')
                              "
                              class="sort"
                              @click="sortTable('created_at')"
                            >
                              {{ $t("created_at") }}
                            </th>
                            <th
                              v-if="table_selected_fields.includes('status')"
                              class="sort"
                              @click="sortTable('status')"
                            >
                              {{ $t("status") }}
                            </th>
                            <th
                              v-if="table_selected_fields.includes('actions')"
                            >
                              {{ $t("actions") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              :colspan="table_selected_fields.length"
                              v-if="!load"
                            >
                              <i class="fa fa-sync  fa-spin load-con"></i>
                            </td>
                            <td
                              :colspan="table_selected_fields.length"
                              class="no_data"
                              v-if="load && users.length == 0"
                            >
                              {{ $t("no_data") }}
                            </td>
                          </tr>
                          <tr v-for="(item, i) in users" :key="i" v-show="load">
                            <td v-if="table_selected_fields.includes('#')">
                              {{ i + 1 }}
                            </td>
                            <td v-if="table_selected_fields.includes('name')">
                              {{ item["name"] || "-" }}
                            </td>
                            <td v-if="table_selected_fields.includes('mobile')">
                              {{ item["mobile"] || "-" }}
                            </td>
                            <td v-if="table_selected_fields.includes('email')">
                              {{ item["email"] || "-" }}
                            </td>
                            <td
                              v-if="
                                table_selected_fields.includes('device_type')
                              "
                            >
                              {{ item["device_type"] || "-" }}
                            </td>
                            <td
                              v-if="
                                table_selected_fields.includes('live_orders')
                              "
                            >
                              {{ item.live_orders }}
                            </td>
                            <td
                              v-if="
                                table_selected_fields.includes(
                                  'completed_orders'
                                )
                              "
                            >
                              {{ item.completed_orders }}
                            </td>
                            <td
                              v-if="
                                table_selected_fields.includes(
                                  'cancelled_orders'
                                )
                              "
                            >
                              {{ item.cancelled_orders }}
                            </td>
                            <td v-if="table_selected_fields.includes('wallet')">
                              {{ item.wallet }}
                            </td>

                            <td
                              v-if="
                                table_selected_fields.includes('created_at')
                              "
                              v-html="dateTimeFormat(item.created_at)"
                            ></td>
                            <td v-if="table_selected_fields.includes('status')">
                              <span
                                :class="
                                  'badge badge-' +
                                    (item.status == 'on' ? 'info' : 'danger')
                                "
                                >{{ $t(item.status) }}</span
                              >
                            </td>
                            <td
                              class="actions-group"
                              v-if="table_selected_fields.includes('actions')"
                            >
                              <button
                                v-if="$can('edit users')"
                                class="btn btn-primary btn-sm"
                                v-tooltip="$t('change_status')"
                                @click="changeStatus(item.id)"
                              >
                                <b-spinner small v-if="disabled_s[i]">
                                </b-spinner>
                                <i class="fas fa-exchange-alt" v-else></i>
                              </button>
                              <router-link
                                v-if="$can('edit users')"
                                :to="'/users/' + item.id"
                                class="btn btn-info btn-sm"
                                v-tooltip="$t('view')"
                              >
                                <i class="fas fa-eye"> </i>
                              </router-link>
                              <button
                                v-if="$can('delete users') && false"
                                @click="deleteUser(item.id)"
                                class="btn btn-danger btn-sm"
                                v-tooltip="$t('delete')"
                              >
                                <i class="fas fa-trash"> </i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-card-body>
                    <!-- /.card-body -->
                    <b-card-footer class="card-footer clearfix">
                      <b-pagination
                        class="m-2"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        :prev-text="$t('previous')"
                        :next-text="$t('next')"
                        align="right"
                        :first-number="true"
                        :last-number="true"
                      ></b-pagination>
                    </b-card-footer>
                  </b-card> </b-card-text
              ></b-tab>
              <b-tab no-body :title="$t('add')" v-if="$can('add users')">
                <template v-slot:title>
                  <i class="fa fa-plus ml-2 mr-2"></i> {{ $t("add") }}
                </template>
                <b-card-text>
                  <b-card no-body>
                    <b-card-body>
                      <form class="col-md-8">
                        <b-alert
                          :show="add_error || add_success ? true : false"
                          variant="danger"
                          dismissible
                          @dismissed="add_error = add_success = ''"
                        >
                          {{ add_error || add_success }}
                        </b-alert>

                        <div>
                          <b-tabs content-class="mt-3" no-key-nav>
                            <b-tab :title="$t('info')" active>
                              <div class="form-row ">
                                <div class="form-group col-md-12">
                                  <label>{{ $t("name") }}</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="add_data.name"
                                  />
                                </div>
                                <div class="form-group col-md-6">
                                  <label>{{ $t("email") }}</label>
                                  <input
                                    type="text"
                                    class="form-control ltr"
                                    v-model="add_data.email"
                                  />
                                </div>

                                <div class="form-group col-md-6">
                                  <label>{{ $t("phone") }}</label>
                                  <input
                                    type="number"
                                    min="0"
                                    class="form-control ltr"
                                    v-model="add_data.mobile"
                                  />
                                </div>
                              </div>
                            </b-tab>

                            <b-tab :title="$t('address')" v-if="false">
                              <div class="form-row col-md-12">
                                <div class="form-group col-md-12">
                                  <label
                                    >{{ $t("address") }} | {{ $t("ar") }}</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="add_data.address_ar"
                                  />
                                </div>
                                <div class="form-group col-md-12">
                                  <label
                                    >{{ $t("address") }} | {{ $t("en") }}</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control ltr"
                                    v-model="add_data.address_en"
                                  />
                                </div>
                                <div
                                  class="form-group col-md-12"
                                  style="height:250px;border-radius:0.25rem;overflow:hidden;"
                                >
                                  <GmapMap
                                    :center="{
                                      lat: add_data.lat,
                                      lng: add_data.lng
                                    }"
                                    :zoom="8"
                                    map-type-id="terrain"
                                    :options="{
                                      mapTypeControl: false,
                                      streetViewControl: false
                                    }"
                                    style=" height: 250px"
                                  >
                                    <GmapMarker
                                      :position="{
                                        lat: add_data.lat,
                                        lng: add_data.lng
                                      }"
                                      :draggable="true"
                                      @dragend="updatePosition"
                                    />
                                  </GmapMap>
                                </div>
                              </div>
                            </b-tab>
                          </b-tabs>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-info mt-2"
                          :disabled="add_disabled"
                          @click="addUser"
                        >
                          {{ $t("add") }}
                          <b-spinner small v-if="add_disabled"></b-spinner>
                        </button>
                      </form>
                    </b-card-body>
                    <!-- /.card-body -->
                  </b-card>
                </b-card-text></b-tab
              >
            </b-tabs>

            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: "Users",
  data() {
    return {
      load: false,
      users: [],
      search_key: "",
      old_search_key: "",
      disabled_s: [],
      currentPage: 1,
      perPage: 1,
      rows: 1,
      add_disabled: false,
      add_success: "",
      add_error: "",
      add_progress: 0,
      add_data_icon: null,
      add_data_image: null,
      add_data: {
        city_id: "",
        lat: 1,
        lng: 1
      },
      image_url: "",
      cities: [],
      search_disabled: false,
      disabled: false,
      sort_key: "",
      sort_type: "asc",
      table_fields: [
        "#",
        "name",
        "mobile",
        "email",
        "live_orders",
        "completed_orders",
        "cancelled_orders",
        "wallet",
        "created_at",
        "status",
        "device_type",
        "actions"
      ],
      table_selected_fields: [],
      top_disabled: false,
      page_type: ""
    };
  },
  created() {
    this.table_selected_fields = this.$store.state.users_selected_fields;
    this.getUsers();
  },
  watch: {
    currentPage() {
      this.getUsers();
    },
    table_selected_fields() {
      this.$store.commit("setUsersSelectedFields", this.table_selected_fields);
    }
  },
  methods: {
    getUsers() {
      return this.axios
        .get("users", {
          params: {
            page: this.currentPage,
            search_key: this.search_key,
            page_type: this.page_type,
            sort_type: this.sort_type,
            sort_key: this.sort_key
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            // this.cities = res.result.cities;
            this.users = res.result.users.data;
            this.rows = res.result.users.pagination.total;
            this.perPage = res.result.users.pagination.per_page;
            this.image_url = res.result.image_url;
          }
        })
        .catch(error => {
          this.handleError("getUsers", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    sortTable(key) {
      if (this.sort_key != key) {
        this.sort_type = "asc";
      } else {
        this.sort_type = this.sort_type == "asc" ? "desc" : "asc";
      }
      this.sort_key = key;
      this.getUsers();
    },
    searchAll() {
      if (this.search_key != this.old_search_key) {
        this.old_search_key = this.search_key;
        this.search_disabled = true;
        this.getUsers().then(() => {
          this.search_disabled = false;
        });
      }
    },
    addUser() {
      this.add_disabled = true;
      let fd = new FormData();

      if (this.add_data_icon) {
        fd.append("photo_input", this.add_data_icon, this.add_data_icon.name);
      }
      Object.keys(this.add_data).forEach(key => {
        if (this.add_data[key]) {
          fd.append(key, this.add_data[key]);
        }
      });
      this.axios
        .post("users/add", fd)
        .then(response => {
          let res = response.data;
          this.add_error = this.add_success = "";
          if (res.error_flag == 0) {
            this.add_success = res.message;
            this.getUsers();
            this.add_data = {
              city_id: "",
              lat: 1,
              lng: 1
            };
            this.add_data_image = null;
            this.add_data_icon = null;
          } else if (res.error_flag == 1) {
            this.add_error = res.message;
          } else {
            this.handleError("addUser", res.message);
          }
        })
        .catch(error => {
          this.handleError("addUser", error);
        })
        .finally(() => {
          this.add_disabled = false;
          this.add_progress = 0;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    changeStatus(id) {
      let index = this.findIndex(this.users, "id", id);
      let i = this.findIndex(this.users, "id", id);
      this.$set(this.disabled_s, i, true);
      this.axios
        .post("users/update_status/" + id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.users[index].status = res.result.status;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("changeStatus", res.message);
          }
        })
        .catch(error => {
          this.handleError("chnageStatus", error);
        })
        .finally(() => {
          this.$set(this.disabled_s, i, false);
        });
    },
    deleteUser(id) {
      let index = this.findIndex(this.users, "id", id);

      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("users/delete/" + id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.users.splice(index, 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deleteUser", res.message);
                }
              })
              .catch(error => {
                this.handleError("deleteUser", error);
              });
          }
        });
    },
    updatePosition(event) {
      this.$set(this.add_data, "lat", event.latLng.lat());
      this.$set(this.add_data, "lng", event.latLng.lng());
    },
    topUsers() {
      this.page_type = "top_users";
      this.top_disabled = true;
      this.getUsers().then(() => {
        this.top_disabled = false;
      });
    }
  }
};
</script>

<style scoped>
.img_div {
  margin: 15px;
  display: inline-block;
  position: relative;
}

.img_div img {
  height: 120px;
  width: 120px;
}

.img_div div {
  background: #ff0018;
  position: absolute;
  top: -10px;
  color: white;
  left: -10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
</style>
