<template>
  <div>
    <b-modal
      ref="add_wallet_modal"
      :title="$t('add_to_wallet')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <form class="col-md-12">
          <b-alert
            :show="add_wallet_error || add_wallet_success ? true : false"
            :variant="add_wallet_error ? 'danger' : 'success'"
            dismissible
            @dismissed="add_wallet_error = add_wallet_success = ''"
          >
            {{ add_wallet_error || add_wallet_success }}
          </b-alert>
          <div class="form-row ">
            <div class="form-group col-md-12">
              <label>{{ $t("amount") }}</label>
              <input
                type="number"
                class="form-control"
                :class="{
                  'is-invalid': add_wallet_errors.amount
                }"
                v-model="add_wallet_data.amount"
              />
              <span class="error invalid-feedback d-block">{{
                (add_wallet_errors.amount || []).join("-")
              }}</span>
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("transaction_alias") }}</label>
              <input
                type="text"
                class="form-control"
                :class="{
                  'is-invalid': add_wallet_errors.transaction_alias
                }"
                v-model="add_wallet_data.transaction_alias"
              />
              <span class="error invalid-feedback d-block">{{
                (add_wallet_errors.transaction_alias || []).join("-")
              }}</span>
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-info mt-2"
            :disabled="add_wallet_disabled"
            @click.prevent="addToWallet"
          >
            {{ $t("add") }}
            <b-spinner small v-if="add_wallet_disabled"></b-spinner>
          </button>
        </form>
      </div>
    </b-modal>

    <b-modal
      ref="wallet_customize_table_modal"
      centered
      :title="$t('customize_table')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <div class="row clearfix">
          <div class="col-md-12">
            <div class="form-row" style="padding: 0 20px">
              <div
                class="form-group col-md-6"
                v-for="(field, i) in wallet_table_fields"
                :key="i"
              >
                <input
                  type="checkbox"
                  class="form-check-input"
                  aria-label="Checkbox for following text input"
                  :value="field"
                  :id="'f_' + field"
                  v-model="wallet_table_selected_fields"
                  style="vertical-align: middle;"
                /><label
                  class="form-check-label"
                  :for="'f_' + field"
                  style="color: blue;"
                >
                  {{ $t(field.toLowerCase()) }}</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <div class="load-content" v-if="!load">
      <b-spinner variant="primary"></b-spinner>
    </div>
    <div v-else>
      <section class="content-header">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-6">
              <h1>{{ $t("user_2") }} [ {{ user["name"] || "..." }} ]</h1>
            </div>
          </div>
        </div>
      </section>

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <b-tabs
                pills
                card
                no-key-nav
                content-class="custom-card"
                nav-wrapper-class="custom-nav-card"
              >
                <b-tab no-body :title="$t('info')" active v-if="true">
                  <template v-slot:title>
                    <i class="fa fa-pen ml-2 mr-2"></i>
                    {{ $t("info") }}
                  </template>
                  <b-card-text>
                    <b-card no-body>
                      <b-card-body>
                        <form class="col-md-8">
                          <b-alert
                            :show="info_error || info_success ? true : false"
                            :variant="info_error ? 'danger' : 'success'"
                            dismissible
                            @dismissed="info_error = info_success = ''"
                          >
                            {{ info_error || info_success }}
                          </b-alert>

                          <div>
                            <b-tabs content-class="mt-3" no-key-nav>
                              <b-tab :title="$t('info')" active>
                                <div class="form-row ">
                                  <div class="form-group col-md-12">
                                    <label>{{ $t("name") }}</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="user.name"
                                    />
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label>{{ $t("email") }}</label>
                                    <input
                                      type="text"
                                      class="form-control ltr"
                                      v-model="user.email"
                                    />
                                  </div>

                                  <div class="form-group col-md-6">
                                    <label>{{ $t("mobile") }}</label>
                                    <input
                                      type="number"
                                      min="0"
                                      class="form-control ltr"
                                      v-model="user.mobile"
                                    />
                                  </div>
                                </div>
                              </b-tab>

                              <b-tab :title="$t('address')" v-if="false">
                                <div class="form-row col-md-12">
                                  <div class="form-group col-md-12">
                                    <label
                                      >{{ $t("address") }} |
                                      {{ $t("ar") }}</label
                                    >
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="user.address_ar"
                                    />
                                  </div>
                                  <div class="form-group col-md-12">
                                    <label
                                      >{{ $t("address") }} |
                                      {{ $t("en") }}</label
                                    >
                                    <input
                                      type="text"
                                      class="form-control ltr"
                                      v-model="user.address_en"
                                    />
                                  </div>
                                  <div
                                    class="form-group col-md-12"
                                    style="height:250px;border-radius:0.25rem;overflow:hidden;"
                                  >
                                    <GmapMap
                                      :center="{
                                        lat: user.lat,
                                        lng: user.lng
                                      }"
                                      :zoom="8"
                                      map-type-id="terrain"
                                      :options="{
                                        mapTypeControl: false,
                                        streetViewControl: false
                                      }"
                                      style=" height: 250px"
                                    >
                                      <GmapMarker
                                        :position="{
                                          lat: user.lat,
                                          lng: user.lng
                                        }"
                                        :draggable="true"
                                        @dragend="updatePosition"
                                      />
                                    </GmapMap>
                                  </div>
                                </div>
                              </b-tab>
                            </b-tabs>
                          </div>

                          <button
                            v-if="true"
                            type="submit"
                            class="btn btn-info mt-2"
                            :disabled="info_disabled"
                            @click.prevent="updateUser"
                          >
                            {{ $t("save") }}
                            <b-spinner small v-if="info_disabled"></b-spinner>
                          </button>
                        </form>
                      </b-card-body>
                      <!-- /.card-body -->
                    </b-card>
                  </b-card-text></b-tab
                >
                <b-tab no-body :title="$t('wallet')">
                  <template v-slot:title>
                    <i class="fas fa-wallet ml-2 mr-2"></i> {{ $t("wallet") }}
                  </template>
                  <b-card-text>
                    <b-card no-body class="card">
                      <b-card-header>
                        {{ $t("balance") }}: {{ balance }} {{ $t("sar") }}
                        <button
                          class="btn btn-success btn-sm mr-2 ml-2"
                          @click="showAddToWallet"
                        >
                          <i class="fa fa-plus mr-1 ml-1"></i
                          >{{ $t("add_to_wallet") }}
                        </button>
                        <div class="card-tools">
                          <div class="input-group input-group-sm">
                            <i
                              class="fas fa-cog c_pointer customize_table"
                              :title="$t('customize_table')"
                              @click="wallet_customizeTable"
                            ></i>
                            <input
                              type="text"
                              class="form-control float-right"
                              :placeholder="$t('search')"
                              v-model="wallet_search_key"
                            />
                            <div class="input-group-append">
                              <button
                                type="submit"
                                :disabled="wallet_search_disabled"
                                @click.prevent="wallet_searchAll"
                                class="btn btn-default"
                              >
                                <i
                                  class="fa fa-spinner fa-spin"
                                  v-if="wallet_search_disabled"
                                ></i>
                                <i class="fas fa-search" v-else></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </b-card-header>
                      <!-- /.card-header -->
                      <b-card-body class="table-responsive p-0">
                        <table class="table table-hover">
                          <thead>
                            <tr>
                              <th
                                v-if="
                                  wallet_table_selected_fields.includes('#')
                                "
                              >
                                #
                              </th>
                              <th
                                v-if="
                                  wallet_table_selected_fields.includes(
                                    'transaction_id'
                                  )
                                "
                              >
                                {{ $t("transaction_id") }}
                              </th>
                              <th
                                v-if="
                                  wallet_table_selected_fields.includes(
                                    'order_id'
                                  )
                                "
                                class="sort"
                                @click="wallet_sortTable('order_id')"
                              >
                                {{ $t("order_id") }}
                              </th>
                              <th
                                v-if="
                                  wallet_table_selected_fields.includes(
                                    'transaction_type'
                                  )
                                "
                                class="sort"
                                @click="wallet_sortTable('transaction_type')"
                              >
                                {{ $t("transaction_type") }}
                              </th>
                              <th
                                v-if="
                                  wallet_table_selected_fields.includes(
                                    'transaction_alias'
                                  )
                                "
                                class="sort"
                                @click="wallet_sortTable('transaction_alias')"
                              >
                                {{ $t("transaction_alias") }}
                              </th>
                              <th
                                v-if="
                                  wallet_table_selected_fields.includes(
                                    'amount'
                                  )
                                "
                                class="sort"
                                @click="wallet_sortTable('amount')"
                              >
                                {{ $t("amount") }}
                              </th>
                              <th
                                v-if="
                                  wallet_table_selected_fields.includes(
                                    'balance'
                                  )
                                "
                                class="sort"
                                @click="wallet_sortTable('close_balance')"
                              >
                                {{ $t("balance") }}
                              </th>

                              <th
                                v-if="
                                  wallet_table_selected_fields.includes(
                                    'created_at'
                                  )
                                "
                                class="sort"
                                @click="sortTable('created_at')"
                              >
                                {{ $t("created_at") }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                :colspan="wallet_table_selected_fields.length"
                                v-if="!wallet_load"
                              >
                                <i class="fa fa-sync  fa-spin load-con"></i>
                              </td>
                              <td
                                :colspan="wallet_table_selected_fields.length"
                                class="no_data"
                                v-if="wallet_load && wallet.length == 0"
                              >
                                {{ $t("no_data") }}
                              </td>
                            </tr>
                            <tr
                              v-for="(item, i) in wallet"
                              :key="i"
                              v-show="wallet_load"
                            >
                              <td
                                v-if="
                                  wallet_table_selected_fields.includes('#')
                                "
                              >
                                {{ i + 1 }}
                              </td>
                              <td
                                v-if="
                                  wallet_table_selected_fields.includes(
                                    'transaction_id'
                                  )
                                "
                              >
                                {{ item.transaction_id }}
                              </td>
                              <td
                                v-if="
                                  wallet_table_selected_fields.includes(
                                    'order_id'
                                  )
                                "
                              >
                                {{ item.order_id || "-" }}
                              </td>
                              <td
                                v-if="
                                  wallet_table_selected_fields.includes(
                                    'transaction_type'
                                  )
                                "
                              >
                                {{ item.transaction_type }}
                              </td>
                              <td
                                v-if="
                                  wallet_table_selected_fields.includes(
                                    'transaction_alias'
                                  )
                                "
                              >
                                {{ item.transaction_alias }}
                              </td>
                              <td
                                v-if="
                                  wallet_table_selected_fields.includes(
                                    'amount'
                                  )
                                "
                              >
                                {{ item.amount }}
                              </td>
                              <td
                                v-if="
                                  wallet_table_selected_fields.includes(
                                    'balance'
                                  )
                                "
                              >
                                {{ item.close_balance }}
                              </td>
                              <td
                                v-if="
                                  wallet_table_selected_fields.includes(
                                    'created_at'
                                  )
                                "
                                v-html="dateTimeFormat(item.created_at)"
                              ></td>
                            </tr>
                          </tbody>
                        </table>
                      </b-card-body>
                      <!-- /.card-body -->
                      <b-card-footer class="card-footer clearfix">
                        <b-pagination
                          class="m-2"
                          v-model="wallet_currentPage"
                          :total-rows="wallet_rows"
                          :per-page="wallet_perPage"
                          :prev-text="$t('previous')"
                          :next-text="$t('next')"
                          align="right"
                          :first-number="true"
                          :last-number="true"
                        ></b-pagination>
                      </b-card-footer>
                    </b-card> </b-card-text
                ></b-tab>
              </b-tabs>

              <!-- /.card -->
            </div>
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </section>
      <!-- /.content -->
    </div>
  </div>
</template>

<script>
export default {
  name: "User",
  data() {
    return {
      load: false,
      user: [],
      disabled_s: [],
      add_disabled: false,
      add_success: "",
      add_error: "",
      add_wallet_data: {},
      add_wallet_disabled: false,
      add_wallet_success: "",
      add_wallet_error: "",
      add_wallet_errors: {},
      info_disabled: false,
      info_success: "",
      info_error: "",
      add_progress: 0,
      user_icon: null,
      user_image: null,
      info_icon: null,
      info_image: null,
      image_url: "",
      cities: [],
      disabled: false,
      id: "",
      wallet_search_key: "",
      wallet_old_search_key: "",
      wallet_currentPage: 1,
      wallet_perPage: 1,
      wallet_rows: 0,
      wallet_search_disabled: false,
      wallet_sort_key: "",
      wallet_sort_type: "asc",
      wallet_table_fields: [
        "#",
        "transaction_id",
        "order_id",
        "transaction_alias",
        "transaction_type",
        "amount",
        "balance",
        "created_at"
      ],
      wallet_table_selected_fields: [],
      wallet: [],
      balance: 0,
      wallet_load: false
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getUser();
    this.wallet_table_selected_fields = this.$store.state.wallet_selected_fields;
    this.getWallet();
  },
  computed: {},
  watch: {
    wallet_currentPage() {
      this.getWallet();
    },
    wallet_table_selected_fields() {
      this.$store.commit(
        "setWalletSelectedFields",
        this.wallet_table_selected_fields
      );
    }
  },
  methods: {
    getUser() {
      return this.axios
        .get("users/" + this.id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.cities = res.result.cities;
            this.user = res.result.user;
            this.$set(this.user, "lat", parseFloat(this.user.lat));
            this.$set(this.user, "lng", parseFloat(this.user.lng));
            this.image_url = res.result.image_url;
          }
        })
        .catch(error => {
          this.handleError("getUser", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    updateUser() {
      this.info_disabled = true;
      let fd = new FormData();

      if (this.info_icon) {
        fd.append("photo_input", this.info_icon, this.info_icon.name);
      }
      Object.keys(this.user).forEach(key => {
        if (this.user[key]) {
          fd.append(key, this.user[key]);
        }
      });
      this.info_error = this.info_success = "";
      this.axios
        .post("users/update", fd)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.info_success = res.message;
            this.info_image = null;
            this.info_icon = null;
            this.user = res.result.user;
          } else if (res.error_flag == 1) {
            this.info_error = res.message;
          } else {
            this.handleError("updateUser", res.message);
          }
        })
        .catch(error => {
          this.handleError("updateUser", error);
        })
        .finally(() => {
          this.info_disabled = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    changeStatus(id) {
      let index = this.findIndex(this.items, "id", id);
      let i = this.findIndex(this.items_f, "id", id);
      this.$set(this.disabled_s, i, true);
      this.axios
        .post("items/update_status/" + id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.$noty.success(res.message);
            this.items[index].status = res.result.status;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("changeStatus", res.message);
          }
        })
        .catch(error => {
          this.handleError("changeStatus", error);
        })
        .finally(() => {
          this.$set(this.disabled_s, i, false);
        });
    },
    updatePosition(event) {
      this.$set(this.user, "lat", event.latLng.lat());
      this.$set(this.user, "lng", event.latLng.lng());
    },
    getWallet() {
      return this.axios
        .get("users/wallet/" + this.id, {
          params: {
            page: this.wallet_currentPage,
            search_key: this.wallet_search_key,
            sort_key: this.wallet_sort_key,
            sort_type: this.wallet_sort_type
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.wallet = res.result.wallet.data;
            this.wallet_rows = res.result.wallet.total;
            this.wallet_perPage = res.result.wallet.per_page;
            this.balance = res.result.balance;
          }
        })
        .catch(error => {
          this.handleError("getWallet", error);
        })
        .finally(() => {
          this.wallet_load = true;
        });
    },
    wallet_sortTable(key) {
      if (this.wallet_sort_key != key) {
        this.wallet_sort_type = "asc";
      } else {
        this.wallet_sort_type = this.wallet_sort_type == "asc" ? "desc" : "asc";
      }
      this.wallet_sort_key = key;
      this.getWallet();
    },
    wallet_searchAll() {
      if (this.wallet_search_key != this.wallet_old_search_key) {
        this.wallet_old_search_key = this.wallet_search_key;
        this.wallet_search_disabled = true;
        this.getWallet().then(() => {
          this.wallet_search_disabled = false;
        });
      }
    },
    wallet_customizeTable() {
      this.$refs.wallet_customize_table_modal.show();
    },
    showAddToWallet() {
      this.add_wallet_success = this.add_wallet_error = "";
      this.add_wallet_errors = {};
      this.$refs.add_wallet_modal.show();
    },
    addToWallet() {
      this.add_wallet_disabled = true;
      this.add_wallet_error = this.add_wallet_success = "";
      this.axios
        .post("users/addToWallet", {
          ...this.add_wallet_data,
          user_id: this.id
        })
        .then(response => {
          let res = response.data;
          this.add_wallet_errors = {};
          if (res.error_flag == 0) {
            this.add_wallet_success = res.message;
            this.add_wallet_data = {};
            this.getWallet();
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.add_wallet_errors = res.message;
            } else {
              this.add_wallet_error = res.message;
            }
          } else {
            this.handleError("addToWallet", res.message);
          }
        })
        .catch(error => {
          this.handleError("addToWallet", error);
        })
        .finally(() => {
          this.add_wallet_disabled = false;
        });
    }
  }
};
</script>

<style scoped>
.img_div {
  margin: 15px;
  display: inline-block;
  position: relative;
}

.img_div img {
  height: 120px;
  width: 120px;
}

.img_div div {
  background: #ff0018;
  position: absolute;
  top: -10px;
  color: white;
  left: -10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
</style>
