import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { BootstrapVue } from "bootstrap-vue";
Vue.use(BootstrapVue);

import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);
axios.defaults.baseURL = store.state.api_url;

axios.interceptors.request.use(async config => {
    console.log(config.url);
    
    if (store.state.access_token) {
        if (config.url != "login" && store.state.user_type) {
            if (store.state.user_type == "vendor") {
                console.log(config.url);
            } else {
                config.url = store.state.user_type + "/" + config.url;
            }
        }
        config.headers["Authorization"] = "Bearer " + store.state.access_token;
    } else {
        if (config.url == "login") {
            config.url = "admin/" + config.url;
        }
    }
    config.headers["lang"] = localStorage.locale || "ar";

    return config;
});

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (
            error &&
            error.response &&
            401 === error.response.status &&
            router.currentRoute.name != "Login"
        ) {
            store.commit("setAlertError", false);
            store.commit("setAccessToken", null);
            store.commit("setUser", null);
            store.commit("setUserType", "");
            store.commit("setPermissions", []);
            window.location = "/login";
        } else {
            store.commit("setAlertError", true);
            return Promise.reject(error);
        }
    }
);

import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

Vue.use(CoolLightBox);

import VueApexCharts from "vue-apexcharts";
Vue.component("apexchart", VueApexCharts);

import VueNoty from "vuejs-noty";
Vue.use(VueNoty, {
    timeout: 1500,
    killer: true,
    layout: "topRight"
});

import VueSweetalert2 from "vue-sweetalert2";

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2);

import VueSlimScroll from "vue-slimscroll";
Vue.use(VueSlimScroll);

const moment = require("moment-timezone");
require("moment/locale/ar");
require("moment/locale/en-gb");

Vue.use(require("vue-moment"), {
    moment
});

import VTooltip from "v-tooltip";
Vue.use(VTooltip);
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

let map_key = "AIzaSyCISuwa7MHFNKG1i3t54W7wI1eDU8af4vE";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
    load: {
        key: map_key,
        libraries: "places" // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    }
});

import Geocoder from "@pderas/vue2-geocoder";

Vue.use(Geocoder, {
    defaultCountryCode: null, // e.g. 'CA'
    defaultLanguage: "ar", // e.g. 'en'
    defaultMode: "lat-lng", // or 'lat-lng'
    googleMapsApiKey: map_key
});

import tinymce from "vue-tinymce-editor";
Vue.component("tinymce", tinymce);

import $ from "jquery";
$(document).on("focusin", function(e) {
    if (
        $(e.target).closest(
            ".tox-tinymce-aux, .tox-dialog, .moxman-window, .tam-assetmanager-root"
        ).length
    ) {
        e.stopImmediatePropagation();
    }
});

Vue.mixin({
    data: function() {
        return {
            rand_img: Date.now()
        };
    },
    methods: {
        $can(action) {
            return store.state.permissions.indexOf(action) !== -1
                ? true
                : false;
        },
        toTop() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
        },
        changeLang(l) {
            if (l == "ar") {
                document.dir = "rtl";
                localStorage.setItem("locale", "ar");
            } else {
                document.dir = "";
                localStorage.setItem("locale", "en");
            }
            i18n.locale = l;
        },
        changeTimezone(t) {
            if (t) {
                store.commit("setTimezone", t);
            } else {
                store.commit("setTimezone", "Africa/Cairo");
            }
        },
        alert(msg = "", title = "") {
            return this.$bvModal.msgBoxOk(msg, {
                title: title,
                hideHeaderClose: true,
                centered: true,
                okTitle: this.$t("ok"),
                okVariant: "secondary",
                bodyClass: "red"
            });
        },
        handleError(method = " ", msg = " ", flag) {
            if (store.state.alert_error) {
                if (store.state.debug) {
                    return this.alert(
                        "[ " + method + " ]: " + msg,
                        this.$t("error")
                    );
                } else if (flag) {
                    if (
                        this.$router.currentRoute.path !=
                        "/" + store.state.error_path
                    ) {
                        return this.$router.push("/" + store.state.error_path);
                    }
                } else {
                    return this.alert(this.$t("error_msg"), this.$t("error"));
                }
            }
        },
        isImage(img) {
            let images_exts = [
                "image/gif",
                "image/png",
                "image/jpeg",
                "image/jpg",
                "image/bmp",
                "image/webp",
                "image/vnd.microsoft.icon"
            ];

            if (images_exts.indexOf(img["type"]) != -1) return true;
            return false;
        },
        toBase64(blob) {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            return new Promise(resolve => {
                reader.onloadend = () => {
                    resolve(reader.result);
                };
            });
        },
        getProfile() {
            return this.axios
                .get("profile")
                .then(response => {
                    let res = response.data;
                    if (res.error_flag == 0) {
                        let profile = res.result.profile;
                        profile.image_url = res.result.image_url;
                        store.commit(
                            "setPermissions",
                            res.result.permissions || []
                        );
                        store.commit("setUser", profile);
                        store.commit("setWebsiteLink", res.result.website_link);
                    }
                })
                .catch(error => {
                    this.handleError("getProfile", error);
                });
        },
        isArabicChars(text) {
            var arabic = /[\u0600-\u06FF]/;
            let result = arabic.test(text);
            return result;
        },
        kimoSort(table, field, sub_field = null) {
            let sort = 1;
            if (this.current_sort == "asc") {
                sort = 1;
                this.current_sort = "desc";
            } else {
                sort = -1;
                this.current_sort = "asc";
            }
            table.sort((a, b) => {
                let nameA;
                let nameB;
                if (!sub_field) {
                    nameA = !a[field] ? "" : a[field].toString().toUpperCase();
                    nameB = !b[field] ? "" : b[field].toString().toUpperCase();
                } else {
                    nameA = !a[field][sub_field]
                        ? ""
                        : a[field][sub_field].toString().toUpperCase();
                    nameB = !b[field][sub_field]
                        ? ""
                        : b[field][sub_field].toString().toUpperCase();
                }

                if (nameA < nameB) {
                    return -1 * sort;
                }
                if (nameA > nameB) {
                    return 1 * sort;
                }
                return 0;
            });
        },
        kimoSearch(arr, fields = [], search_key = "search_key") {
            let split_field = [];
            let new_field = null;
            return arr.filter(e => {
                let conditions = fields.length > 0 ? false : true;
                for (let i = 0; i < fields.length; i++) {
                    split_field = fields[i].split(".");
                    new_field = split_field[1]
                        ? e[split_field[0]][split_field[1]]
                        : e[split_field[0]];

                    if (!new_field) {
                        new_field = "";
                    }
                    conditions =
                        conditions ||
                        new_field
                            .toString()
                            .toLowerCase()
                            .includes(this[search_key].trim());
                }
                return conditions;
            });
        },
        $safeValue(fn, defaultVal) {
            try {
                return fn();
            } catch (e) {
                return defaultVal;
            }
        },
        findIndex(obj, key, value) {
            return obj.findIndex(e => e[key] == value);
        },
        onImageChange(e, attr, key_name) {
            let attr_icon = attr + "_icon";
            let attr_image = attr + "_image";
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            if (files.length > 1) {
                this[attr_icon] = [];
                this[attr_image] = [];
                files.forEach(e => {
                    if (this.isImage(e)) {
                        this[attr_icon].push(e);
                        this.toBase64(e).then(res => {
                            this[attr_image].push(res);
                        });
                    }
                });
            } else {
                let icon = files[0];
                if (!this.isImage(icon)) {
                    if (key_name) {
                        this[attr_icon][key_name] = null;
                        this[attr_image][key_name] = null;
                        this.$refs[attr_icon][key_name].reset();
                    } else {
                        this[attr_icon] = null;
                        this[attr_image] = null;
                        this.$refs[attr_icon].reset();
                    }
                    return this.alert(this.$t("image_must_image"));
                }
                if (key_name) {
                    this.$set(this[attr_icon], key_name, icon);
                    this.toBase64(icon).then(res => {
                        this.$set(this[attr_image], key_name, res);
                    });
                } else {
                    this[attr_icon] = icon;
                    this.toBase64(icon).then(res => {
                        this[attr_image] = res;
                    });
                }
            }
        },
        logOut() {
            this.$store.commit("setAccessToken", null);
            this.$store.commit("setUser", null);
            this.$store.commit("setUserType", "");
            this.$store.commit("setPermissions", []);
            if (this.$router.currentRoute.path != "/login") {
                return this.$router.push("/login");
            }
        },
        dateTimeFormat(date) {
            if (!date) {
                return "-";
            }
            return (
                this.$moment(date)
                    .tz(this.$store.state.timezone)
                    .format("DD-MM-Y") +
                "<br/>" +
                this.$moment(date)
                    .tz(this.$store.state.timezone)
                    .format("HH:mm")
            );
        },
        customizeTable() {
            this.$refs.customize_table_modal.show();
        },
        convertImage(file) {
            return URL.createObjectURL(file);
        }
    },
    computed: {
        isLogin() {
            return this.$store.state.access_token ? true : false;
        },
        isAdmin() {
            return this.$store.state.user_type == "admin";
        },
        isAdvertiser() {
            return this.$store.state.user_type == "advertiser";
        },
        isUser() {
            return this.$store.state.user_type == "user";
        }
    }
});

Vue.config.productionTip = false;

import { i18n } from "@/plugins/i18n";
new Vue({
    router,
    i18n,
    store,
    render: h => h(App)
}).$mount("#app");
