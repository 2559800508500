<template>
    <div>
        <b-modal
            ref="customize_table_modal"
            centered
            :title="$t('customize_table')"
            header-class="new_modal_header"
            hide-footer
        >
            <div class="d-block">
                <div class="row clearfix">
                    <div class="col-md-12">
                        <div class="form-row" style="padding: 0 20px">
                            <div
                                class="form-group col-md-6"
                                v-for="(field, i) in table_fields"
                                :key="i"
                            >
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    aria-label="Checkbox for following text input"
                                    :value="field"
                                    :id="'f_' + field"
                                    v-model="table_selected_fields"
                                    style="vertical-align: middle;"
                                /><label
                                    class="form-check-label"
                                    :for="'f_' + field"
                                    style="color: blue;"
                                >
                                    {{ $t(field.toLowerCase()) }}</label
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>

        <b-modal
            ref="discount_modal"
            :title="$t('discount')"
            header-class="new_modal_header"
            hide-footer
        >
            <div class="d-block">
                <form class="col-md-12">
                    <b-alert
                        :show="
                            discount_error || discount_success ? true : false
                        "
                        :variant="discount_error ? 'danger' : 'success'"
                        dismissible
                        @dismissed="discount_error = discount_success = ''"
                    >
                        {{ discount_error || discount_success }}
                    </b-alert>
                    <div class="form-row ">
                        <div class="form-group col-md-12">
                            <label>{{ $t("discount_type") }}</label>
                            <select
                                class="form-control custom-select"
                                :class="{
                                    'is-invalid': discount_errors.discount_type
                                }"
                                v-model="discount_data.discount_type"
                            >
                                <option value="percentage">{{
                                    $t("percentage")
                                }}</option>
                                <option value="fixed">{{
                                    $t("fixed_value")
                                }}</option>
                            </select>
                            <span class="error invalid-feedback">{{
                                (discount_errors.discount_type || []).join("-")
                            }}</span>
                        </div>

                        <div class="form-group col-md-12">
                            <label>{{ $t("discount_value") }}</label>
                            <input
                                type="number"
                                min="0"
                                :max="
                                    discount_data.discount_type == 'percentage'
                                        ? 99
                                        : discount_data.price
                                "
                                class="form-control ltr"
                                :class="{
                                    'is-invalid': discount_errors.discount_value
                                }"
                                v-model="discount_data.discount_value"
                            />
                            <span class="error invalid-feedback">{{
                                (discount_errors.discount_value || []).join("-")
                            }}</span>
                        </div>
                        <div class="form-group col-md-6">
                            <label>{{ $t("discount_start") }}</label>
                            <input
                                type="date"
                                class="form-control"
                                :class="{
                                    'is-invalid': discount_errors.discount_start
                                }"
                                v-model="discount_data.discount_start"
                            />
                            <span class="error invalid-feedback">{{
                                (discount_errors.discount_start || []).join("-")
                            }}</span>
                        </div>
                        <div class="form-group col-md-6">
                            <label>{{ $t("discount_end") }}</label>
                            <input
                                type="date"
                                class="form-control"
                                :class="{
                                    'is-invalid': discount_errors.discount_end
                                }"
                                v-model="discount_data.discount_end"
                            />
                            <span class="error invalid-feedback">{{
                                (discount_errors.discount_end || []).join("-")
                            }}</span>
                        </div>
                    </div>

                    <button
                        type="submit"
                        class="btn btn-info mt-2"
                        :disabled="discount_disabled"
                        @click.prevent="updateDiscount"
                    >
                        {{ $t("save") }}
                        <b-spinner small v-if="discount_disabled"></b-spinner>
                    </button>
                </form>
            </div>
        </b-modal>

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <br />
                        <b-card no-body class="card">
                            <b-card-header>
                                <div class="card-tools">
                                    <div class="input-group input-group-sm">
                                        <i
                                            class="fas fa-cog c_pointer customize_table"
                                            :title="$t('customize_table')"
                                            @click="customizeTable"
                                        ></i>
                                        <input
                                            type="text"
                                            class="form-control float-right"
                                            :placeholder="$t('search')"
                                            v-model="search_key"
                                        />
                                        <div class="input-group-append">
                                            <button
                                                type="submit"
                                                :disabled="search_disabled"
                                                @click.prevent="searchAll"
                                                class="btn btn-default"
                                            >
                                                <i
                                                    class="fa fa-spinner fa-spin"
                                                    v-if="search_disabled"
                                                ></i>
                                                <i
                                                    class="fas fa-search"
                                                    v-else
                                                ></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </b-card-header>
                            <!-- /.card-header -->
                            <b-card-body class="table-responsive p-0">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th
                                                v-if="
                                                    table_selected_fields.includes(
                                                        '#'
                                                    )
                                                "
                                            >
                                                #
                                            </th>
                                            <th
                                                v-if="
                                                    table_selected_fields.includes(
                                                        'name'
                                                    )
                                                "
                                                class="sort"
                                                @click="
                                                    sortTable('store_name_en')
                                                "
                                            >
                                                {{ $t("name") }}
                                            </th>

                                            <th
                                                v-if="
                                                    table_selected_fields.includes(
                                                        'vendor'
                                                    )
                                                "
                                            >
                                                {{ $t("vendor") }}
                                            </th>
                                            <th
                                                v-if="
                                                    table_selected_fields.includes(
                                                        'discount'
                                                    )
                                                "
                                            >
                                                {{ $t("discount") }}
                                            </th>
                                            <th
                                                v-if="
                                                    table_selected_fields.includes(
                                                        'region'
                                                    )
                                                "
                                                class="sort"
                                                @click="sortTable('region_id')"
                                            >
                                                {{ $t("region") }}
                                            </th>
                                            <th
                                                v-if="
                                                    table_selected_fields.includes(
                                                        'city'
                                                    )
                                                "
                                                class="sort"
                                                @click="sortTable('city_id')"
                                            >
                                                {{ $t("city") }}
                                            </th>
                                            <th
                                                v-if="
                                                    table_selected_fields.includes(
                                                        'address'
                                                    )
                                                "
                                                class="sort"
                                                @click="sortTable('address')"
                                            >
                                                {{ $t("address") }}
                                            </th>
                                            <th
                                                v-if="
                                                    table_selected_fields.includes(
                                                        'created_at'
                                                    )
                                                "
                                                class="sort"
                                                @click="sortTable('created_at')"
                                            >
                                                {{ $t("created_at") }}
                                            </th>
                                            <th
                                                v-if="
                                                    table_selected_fields.includes(
                                                        'status'
                                                    )
                                                "
                                                class="sort"
                                                @click="sortTable('status')"
                                            >
                                                {{ $t("status") }}
                                            </th>

                                            <th
                                                v-if="
                                                    table_selected_fields.includes(
                                                        'actions'
                                                    )
                                                "
                                            >
                                                {{ $t("actions") }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td
                                                :colspan="
                                                    table_selected_fields.length
                                                "
                                                v-if="!load"
                                            >
                                                <i
                                                    class="fa fa-sync  fa-spin load-con"
                                                ></i>
                                            </td>
                                            <td
                                                :colspan="
                                                    table_selected_fields.length
                                                "
                                                class="no_data"
                                                v-if="
                                                    load && stores.length == 0
                                                "
                                            >
                                                {{ $t("no_data") }}
                                            </td>
                                        </tr>
                                        <tr
                                            v-for="(item, i) in stores"
                                            :key="i"
                                            v-show="load"
                                        >
                                            <td
                                                v-if="
                                                    table_selected_fields.includes(
                                                        '#'
                                                    )
                                                "
                                            >
                                                {{ i + 1 }}
                                            </td>
                                            <td
                                                v-if="
                                                    table_selected_fields.includes(
                                                        'name'
                                                    )
                                                "
                                            >
                                                {{ item.store_name }}
                                            </td>
                                            <td
                                                v-if="
                                                    table_selected_fields.includes(
                                                        'vendor'
                                                    ) && item.vendor
                                                "
                                            >
                                                <router-link
                                                    :to="
                                                        '/vendors/' +
                                                        item.vendor
                                                            ? item.vendor.id
                                                            : 0
                                                    "
                                                    v-tooltip="$t('view')"
                                                >
                                                    {{
                                                        item.vendor
                                                            ? item.vendor[
                                                                  "name"
                                                              ]
                                                            : "-"
                                                    }}
                                                    <br />
                                                    {{
                                                        item.vendor
                                                            ? item.vendor[
                                                                  "contact_mobile"
                                                              ]
                                                            : "-"
                                                    }}
                                                </router-link>
                                            </td>
                                            <td v-else></td>
                                            <td
                                                v-if="
                                                    table_selected_fields.includes(
                                                        'discount'
                                                    )
                                                "
                                            >
                                                <span v-if="item.discount">
                                                    {{
                                                        item.discount
                                                            .discount_value +
                                                            "" +
                                                            (item.discount
                                                                .discount_type ==
                                                            "percentage"
                                                                ? "%"
                                                                : " " +
                                                                  $t("sar"))
                                                    }}
                                                </span>
                                                <button
                                                    v-if="$can('edit stores')"
                                                    class="btn btn-info btn-sm"
                                                    @click="
                                                        showDiscount(item.id)
                                                    "
                                                >
                                                    <i
                                                        :class="
                                                            item.discount
                                                                ? 'fas fa-eye'
                                                                : 'fa fa-plus'
                                                        "
                                                    ></i>
                                                </button>
                                            </td>
                                            <td
                                                v-if="
                                                    table_selected_fields.includes(
                                                        'region'
                                                    )
                                                "
                                            >
                                                {{
                                                    (item.region || {})[
                                                        "name_" + $i18n.locale
                                                    ] || "-"
                                                }}
                                            </td>
                                            <td
                                                v-if="
                                                    table_selected_fields.includes(
                                                        'city'
                                                    )
                                                "
                                            >
                                                {{
                                                    (item.city || {})[
                                                        "name_" + $i18n.locale
                                                    ] || "-"
                                                }}
                                            </td>
                                            <td
                                                v-if="
                                                    table_selected_fields.includes(
                                                        'address'
                                                    )
                                                "
                                            >
                                                {{ item.address }}
                                            </td>
                                            <td
                                                v-if="
                                                    table_selected_fields.includes(
                                                        'created_at'
                                                    )
                                                "
                                                v-html="
                                                    dateTimeFormat(
                                                        item.created_at
                                                    )
                                                "
                                            ></td>
                                            <td
                                                v-if="
                                                    table_selected_fields.includes(
                                                        'status'
                                                    )
                                                "
                                            >
                                                <span
                                                    :class="
                                                        'badge badge-' +
                                                            (item.status == 'on'
                                                                ? 'info'
                                                                : 'danger')
                                                    "
                                                    >{{ $t(item.status) }}</span
                                                >
                                            </td>
                                            <td
                                                class="actions-group"
                                                v-if="
                                                    table_selected_fields.includes(
                                                        'actions'
                                                    )
                                                "
                                            >
                                                <button
                                                    v-if="
                                                        $can('edit stores') &&
                                                            item.status ==
                                                                'pending'
                                                    "
                                                    class="btn btn-primary btn-sm"
                                                    v-tooltip="$t('accept')"
                                                    @click="
                                                        acceptStore(item.id)
                                                    "
                                                >
                                                    <b-spinner
                                                        small
                                                        v-if="disabled_s[i]"
                                                    >
                                                    </b-spinner>
                                                    <i
                                                        class="fas fa-check"
                                                        v-else
                                                    ></i>
                                                </button>

                                                <button
                                                    v-if="
                                                        $can('edit stores') &&
                                                            item.status !=
                                                                'pending'
                                                    "
                                                    class="btn btn-primary btn-sm"
                                                    v-tooltip="
                                                        $t('change_status')
                                                    "
                                                    @click="
                                                        changeStatus(item.id)
                                                    "
                                                >
                                                    <b-spinner
                                                        small
                                                        v-if="disabled_s[i]"
                                                    >
                                                    </b-spinner>
                                                    <i
                                                        class="fas fa-exchange-alt"
                                                        v-else
                                                    ></i>
                                                </button>

                                                <router-link
                                                    v-if="
                                                        $can('edit stores') &&
                                                            item.vendor
                                                    "
                                                    :to="
                                                        '/vendors/' +
                                                            item.vendor.id +
                                                            '/products'
                                                    "
                                                    class="btn btn-info btn-sm"
                                                    v-tooltip="$t('view')"
                                                >
                                                    <i class="fas fa-eye"> </i>
                                                </router-link>

                                                <!-- <router-link
                          v-if="$can('edit stores') && item.vendor"
                          :to="'/vendors/' + item.vendor ? item.vendor.id : 0"
                          class="btn btn-info btn-sm"
                          v-tooltip="$t('view')"
                        >
                          <i class="fas fa-eye"> </i>
                        </router-link> -->
                                                <button
                                                    v-if="$can('delete stores')"
                                                    @click="
                                                        deleteStore(item.id)
                                                    "
                                                    class="btn btn-danger btn-sm"
                                                    v-tooltip="$t('delete')"
                                                >
                                                    <i class="fas fa-trash">
                                                    </i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </b-card-body>
                            <!-- /.card-body -->
                            <b-card-footer class="card-footer clearfix">
                                <b-pagination
                                    class="m-2"
                                    v-model="currentPage"
                                    :total-rows="rows"
                                    :per-page="perPage"
                                    :prev-text="$t('previous')"
                                    :next-text="$t('next')"
                                    align="right"
                                    :first-number="true"
                                    :last-number="true"
                                ></b-pagination>
                            </b-card-footer>
                        </b-card>

                        <!-- /.card -->
                    </div>
                </div>
                <!-- /.row -->
            </div>
            <!-- /.container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>

<script>
export default {
    name: "Stores",
    data() {
        return {
            load: false,
            stores: [],
            search_key: "",
            old_search_key: "",
            disabled_s: [],
            currentPage: 1,
            perPage: 1,
            rows: 1,
            add_disabled: false,
            add_success: "",
            add_error: "",
            add_errors: {},
            add_progress: 0,
            add_data_icon: null,
            add_data_image: null,
            add_data: {
                add_store: true,
                region_id: "",
                city_id: "",
                lat: 24.774265,
                lng: 46.738586
            },
            image_url: "",
            regions: [],
            cities: [],
            search_disabled: false,
            disabled: false,
            sort_key: "",
            sort_type: "asc",
            table_fields: [
                "#",
                "name",
                "vendor",
                "region",
                "discount",
                "city",
                "address",
                "created_at",
                "status",
                "actions"
            ],
            table_selected_fields: [],
            discount_data: {
                discount_type: "percentage"
            },
            discount_disabled: false,
            discount_success: "",
            discount_error: "",
            discount_errors: {}
        };
    },
    created() {
        this.table_selected_fields = this.$store.state.all_stores_selected_fields;
        this.getStores();
        this.getRegions();
    },
    watch: {
        currentPage() {
            this.getStores();
        },
        table_selected_fields() {
            this.$store.commit(
                "setAllStoresSelectedFields",
                this.table_selected_fields
            );
        },
        "add_data.region_id"() {
            this.add_data.city_id = "";
            if (this.add_data.region_id) {
                this.cities = this.regions.find(
                    e => e.id == this.add_data.region_id
                ).cities;
            } else {
                this.cities = [];
            }
        }
    },
    methods: {
        getStores() {
            return this.axios
                .get("stores", {
                    params: {
                        page: this.currentPage,
                        search_key: this.search_key,
                        sort_key: this.sort_key,
                        sort_type: this.sort_type
                    }
                })
                .then(response => {
                    let res = response.data;
                    if (res.error_flag == 0) {
                        this.stores = res.result.stores.data;
                        this.rows = res.result.stores.total;
                        this.perPage = res.result.stores.per_page;
                        this.image_url = res.result.image_url;
                    }
                })
                .catch(error => {
                    this.handleError("getStores", error);
                })
                .finally(() => {
                    this.load = true;
                });
        },

        sortTable(key) {
            if (this.sort_key != key) {
                this.sort_type = "asc";
            } else {
                this.sort_type = this.sort_type == "asc" ? "desc" : "asc";
            }
            this.sort_key = key;
            this.getStores();
        },
        searchAll() {
            if (this.search_key != this.old_search_key) {
                this.old_search_key = this.search_key;
                this.search_disabled = true;
                this.getStores().then(() => {
                    this.search_disabled = false;
                });
            }
        },
        getRegions() {
            return this.axios
                .get("stores/regions")
                .then(response => {
                    let res = response.data;
                    if (res.error_flag == 0) {
                        this.regions = res.result.regions;
                    }
                })
                .catch(error => {
                    this.handleError("getRegions", error);
                });
        },
        addStore() {
            this.add_disabled = true;
            let fd = new FormData();

            if (this.add_data_icon && this.add_data.add_store) {
                fd.append(
                    "store_logo_input",
                    this.add_data_icon,
                    this.add_data_icon.name
                );
            }
            Object.keys(this.add_data).forEach(key => {
                if (this.add_data[key]) {
                    fd.append(key, this.add_data[key]);
                }
            });
            this.axios
                .post("stores/add", fd)
                .then(response => {
                    let res = response.data;
                    this.add_error = this.add_success = "";
                    if (res.error_flag == 0) {
                        this.add_success = res.message;
                        this.getStores();
                        this.add_data = {
                            add_store: true,
                            region_id: "",
                            city_id: "",
                            lat: 24.774265,
                            lng: 46.738586
                        };
                        this.add_data_image = null;
                        this.add_data_icon = null;
                        this.add_errors = {};
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth"
                        });
                    } else if (res.error_flag == 1) {
                        if (typeof res.message === "object") {
                            this.add_errors = res.message;
                        } else {
                            this.add_error = res.message;
                            this.add_errors = {};
                            window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: "smooth"
                            });
                        }
                    } else {
                        this.handleError("addStore", res.message);
                    }
                })
                .catch(error => {
                    this.handleError("addStore", error);
                })
                .finally(() => {
                    this.add_disabled = false;
                    this.add_progress = 0;
                });
        },
        changeStatus(id) {
            let index = this.findIndex(this.stores, "id", id);
            let i = this.findIndex(this.stores, "id", id);
            this.$set(this.disabled_s, i, true);
            this.axios
                .post("stores/update_status/" + id)
                .then(response => {
                    let res = response.data;
                    if (res.error_flag == 0) {
                        this.stores[index].status = res.result.status;
                        this.$noty.success(res.message);
                    } else if (res.error_flag == 1) {
                        this.alert(res.message, this.$t("error"));
                    } else {
                        this.handleError("changeStatus", res.message);
                    }
                })
                .catch(error => {
                    this.handleError("chnageStatus", error);
                })
                .finally(() => {
                    this.$set(this.disabled_s, i, false);
                });
        },
        acceptStore(id) {
            let index = this.findIndex(this.stores, "id", id);
            let i = this.findIndex(this.stores, "id", id);
            this.$set(this.disabled_s, i, true);
            this.axios
                .post("stores/acceptStore/" + id)
                .then(response => {
                    let res = response.data;
                    if (res.error_flag == 0) {
                        this.stores[index].status = "on";
                        this.$noty.success(res.message);
                    } else if (res.error_flag == 1) {
                        this.alert(res.message, this.$t("error"));
                    } else {
                        this.handleError("acceptStore", res.message);
                    }
                })
                .catch(error => {
                    this.handleError("acceptStore", error);
                })
                .finally(() => {
                    this.$set(this.disabled_s, i, false);
                });
        },
        deleteStore(id) {
            let index = this.findIndex(this.stores, "id", id);

            this.$bvModal
                .msgBoxConfirm(this.$t("are_you_sure"), {
                    okVariant: "danger",
                    okTitle: this.$t("yes"),
                    cancelTitle: this.$t("no"),
                    centered: true,
                    footerClass: "custom_modal_footer",
                    contentClass: "custom_modal_content"
                })
                .then(value => {
                    if (value) {
                        this.axios
                            .post("stores/delete/" + id)
                            .then(response => {
                                let res = response.data;
                                if (res.error_flag == 0) {
                                    this.stores.splice(index, 1);
                                    this.$noty.success(res.message);
                                } else if (res.error_flag == 1) {
                                    this.alert(res.message, this.$t("error"));
                                } else {
                                    this.handleError(
                                        "deleteStore",
                                        res.message
                                    );
                                }
                            })
                            .catch(error => {
                                this.handleError("deleteStore", error);
                            });
                    }
                });
        },
        updatePosition(event, flag = null) {
            let pos = {};
            if (flag) {
                pos = event;
            } else {
                pos = { lat: event.latLng.lat(), lng: event.latLng.lng() };
            }

            this.$set(this.add_data, "lat", pos.lat);
            this.$set(this.add_data, "lng", pos.lng);
            this.$geocoder.send(pos, response => {
                if (response.status == "OK") {
                    let address = response.results[0].formatted_address;
                    this.$set(this.add_data, "address", address);
                    this.$refs.autocomplete.$refs.input.value = address;
                }
            });
        },
        setPlace(place) {
            if (place.geometry) {
                this.$set(this.add_data, "lat", place.geometry.location.lat());
                this.$set(this.add_data, "lng", place.geometry.location.lng());
                this.$set(
                    this.add_data,
                    "address",
                    this.$refs.autocomplete.$refs.input.value
                );
            }
        },
        customizeTable() {
            this.$refs.customize_table_modal.show();
        },
        getLocation() {
            if (navigator.geolocation)
                navigator.geolocation.getCurrentPosition(
                    this.setLocation,
                    this.locationError
                );
            else alert("Geolocation is not supported by this browser.");
        },
        setLocation(pos) {
            let map_pos = {
                lat: pos.coords.latitude,
                lng: pos.coords.longitude
            };
            this.updatePosition(map_pos, 1);
        },
        locationError() {
            /*if (error.PERMISSION_DENIED)
        alert("User denied the request for Geolocation.");
      else
       if (error.POSITION_UNAVAILABLE)
        alert("Location information is unavailable.");
      else if (error.TIMEOUT)
        alert("The request to get user location timed out.");
      else alert("An unknown error occurred.");*/
        },
        showDiscount(store_id) {
            this.discount_success = this.discount_error = "";
            this.discount_errors = {};
            if (this.stores.find(e => e.id == store_id).discount) {
                this.discount_data = {
                    ...this.stores.find(e => e.id == store_id).discount
                };
            } else {
                this.discount_data = { discount_type: "percentage" };
                this.$set(this.discount_data, "store_id", store_id);
            }
            this.$refs.discount_modal.show();
        },
        updateDiscount() {
            this.discount_disabled = true;
            this.discount_error = this.discount_success = "";
            this.discount_errors = {};
            this.axios
                .post("stores/update_discount", this.discount_data)
                .then(response => {
                    let res = response.data;
                    if (res.error_flag == 0) {
                        this.discount_success = res.message;
                        let i = this.stores.findIndex(
                            e => e.id == this.discount_data.store_id
                        );
                        if (res.result.discount) {
                            this.discount_data = { ...res.result.discount };
                            this.$set(
                                this.stores[i],
                                "discount",
                                res.result.discount
                            );
                        } else {
                            this.discount_data = {
                                discount_type: "percentage",
                                store_id: this.discount_data.store_id
                            };
                        }
                    } else if (res.error_flag == 1) {
                        if (typeof res.message === "object") {
                            this.discount_errors = res.message;
                        } else {
                            this.discount_error = res.message;
                        }
                    } else {
                        this.handleError("updateDiscount", res.message);
                    }
                })
                .catch(error => {
                    this.handleError("updateDiscount", error);
                })
                .finally(() => {
                    this.discount_disabled = false;
                });
        }
    }
};
</script>

<style scoped>
.img_div {
    margin: 15px;
    display: inline-block;
    position: relative;
}

.img_div img {
    height: 120px;
    width: 120px;
}

.img_div div {
    background: #ff0018;
    position: absolute;
    top: -10px;
    color: white;
    left: -10px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
}
</style>
