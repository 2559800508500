<template>
  <div>
    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <br />

            <b-card no-body class="card">
              <b-card-header>
                <div class="card-tools">
                  <div class="input-group input-group-sm">
                    <input
                      type="text"
                      class="form-control float-right"
                      :placeholder="$t('search')"
                      v-model="search_key"
                    />
                    <div class="input-group-append">
                      <button
                        type="submit"
                        :disabled="search_disabled"
                        @click.prevent="searchAll"
                        class="btn btn-default"
                      >
                        <i
                          class="fa fa-spinner fa-spin"
                          v-if="search_disabled"
                        ></i>
                        <i class="fas fa-search" v-else></i>
                      </button>
                    </div>
                  </div>
                </div>
              </b-card-header>
              <!-- /.card-header -->
              <b-card-body class="table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>
                        #
                      </th>
                      <th class="sort" @click="sortTable('name')">
                        {{ $t("vendor") }}
                      </th>
                      <th class="sort" @click="sortTable('amount')">
                        {{ $t("amount") }}
                      </th>
                      <th class="sort" @click="sortTable('from')">
                        {{ $t("from") }}
                      </th>
                      <th class="sort" @click="sortTable('to')">
                        {{ $t("to") }}
                      </th>
                      <th class="sort" @click="sortTable('created_at')">
                        {{ $t("created_at") }}
                      </th>
                      <th>
                        {{ $t("actions") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="7" v-if="!load">
                        <i class="fa fa-sync  fa-spin load-con"></i>
                      </td>
                      <td
                        colspan="7"
                        class="no_data"
                        v-if="load && w_requests.length == 0"
                      >
                        {{ $t("no_data") }}
                      </td>
                    </tr>
                    <tr v-for="(item, i) in w_requests" :key="i" v-show="load">
                      <td>
                        {{ i + 1 }}
                      </td>

                      <td>
                        <router-link
                          :to="
                            '/vendors/' + (item.vendor ? item.vendor.id : '')
                          "
                        >
                          {{ (item.vendor || {}).name || "-" }}</router-link
                        >
                      </td>
                      <td>{{ item.amount }} {{ $t("sar") }}</td>
                      <td>
                        {{ item.from }}
                      </td>
                      <td>
                        {{ item.to }}
                      </td>
                      <td>
                        {{ item.created_at }}
                      </td>
                      <td class="actions-group">
                        <button
                          v-if="$can('delete w_requests')"
                          @click="deleteWRequest(item.id)"
                          class="btn btn-danger btn-sm"
                          v-tooltip="$t('delete')"
                        >
                          <i class="fas fa-trash"> </i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-card-body>
              <!-- /.card-body -->
              <b-card-footer class="card-footer clearfix">
                <b-pagination
                  class="m-2"
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  :prev-text="$t('previous')"
                  :next-text="$t('next')"
                  align="right"
                  :first-number="true"
                  :last-number="true"
                ></b-pagination>
              </b-card-footer>
            </b-card>

            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: "WRequests",
  data() {
    return {
      load: false,
      w_requests: [],
      search_key: "",
      old_search_key: "",
      search_disabled: false,
      disabled_s: [],
      currentPage: 1,
      perPage: 1,
      rows: 0,
      w_request: {},
      sort_key: "",
      sort_type: "asc"
    };
  },
  created() {
    this.getWRequests();
  },
  watch: {
    currentPage() {
      this.getWRequests();
    },
    table_selected_fields() {
      this.$store.commit(
        "setWRequestsSelectedFields",
        this.table_selected_fields
      );
    }
  },
  methods: {
    getWRequests() {
      return this.axios
        .get("w_requests", {
          params: {
            page: this.currentPage,
            search_key: this.search_key,
            sort_key: this.sort_key,
            sort_type: this.sort_type
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.w_requests = res.result.w_requests.data;
            this.rows = res.result.w_requests.total;
            this.perPage = res.result.w_requests.per_page;
          }
        })
        .catch(error => {
          this.handleError("getWRequests", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    sortTable(key) {
      if (this.sort_key != key) {
        this.sort_type = "asc";
      } else {
        this.sort_type = this.sort_type == "asc" ? "desc" : "asc";
      }
      this.sort_key = key;
      this.getWRequests();
    },
    searchAll() {
      if (this.search_key != this.old_search_key) {
        this.old_search_key = this.search_key;
        this.search_disabled = true;
        this.getWRequests().then(() => {
          this.search_disabled = false;
        });
      }
    },
    deleteWRequest(id) {
      let index = this.findIndex(this.w_requests, "id", id);

      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("w_requests/delete/" + id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.w_requests.splice(index, 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deleteWRequest", res.message);
                }
              })
              .catch(error => {
                this.handleError("deleteWRequest", error);
              });
          }
        });
    },
    changeStatus(id) {
      let index = this.findIndex(this.w_requests, "id", id);
      this.axios
        .post("w_requests/update_status/" + id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.w_requests[index].is_read = 1;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("changeStatus", res.message);
          }
        })
        .catch(error => {
          this.handleError("chnageStatus", error);
        })
        .finally(() => {});
    },
    showWRequest(id) {
      this.w_request = { ...this.w_requests.find(e => e.id == id) };
      if (!this.w_request.is_read) {
        this.changeStatus(id);
      }
      this.$refs.w_request_modal.show();
    }
  }
};
</script>
