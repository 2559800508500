<template>
  <div>
    <b-modal
      ref="edit_group_modal"
      id="edit_group_modal"
      :title="$t('edit') + ' ' + $t('group_2')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <form class="col-md-12">
          <b-alert
            :show="edit_error || edit_success ? true : false"
            :variant="edit_error ? 'danger' : 'success'"
            dismissible
            @dismissed="edit_error = edit_success = ''"
          >
            {{ edit_error || edit_success }}
          </b-alert>
          <div class="form-row ">
            <div class="form-group col-md-12">
              <label>{{ $t("name") }}</label>
              <input
                type="text"
                class="form-control"
                v-model="edit_data.name"
              />
            </div>
            <div class="col-md-12">
              <label>{{ $t("permissions") }}</label>
              <table class="table ">
                <thead class="thead-light">
                  <tr>
                    <th>{{ $t("g_section") }}</th>
                    <th>{{ $t("view") }}</th>
                    <th>{{ $t("add") }}</th>
                    <th>{{ $t("edit") }}</th>
                    <th>{{ $t("delete") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>
                      {{ $t("select_all") }}
                    </th>
                    <th>
                      <input
                        style="height: 17px; width: 17px"
                        type="checkbox"
                        @change="selectAll($event, 'edit_data', 'show')"
                      />
                    </th>
                    <th>
                      <input
                        style="height: 17px; width: 17px"
                        type="checkbox"
                        @change="selectAll($event, 'edit_data', 'add')"
                      />
                    </th>
                    <th>
                      <input
                        style="height: 17px; width: 17px"
                        type="checkbox"
                        @change="selectAll($event, 'edit_data', 'edit')"
                      />
                    </th>
                    <th>
                      <input
                        style="height: 17px; width: 17px"
                        type="checkbox"
                        @change="selectAll($event, 'edit_data', 'delete')"
                      />
                    </th>
                  </tr>
                  <tr v-for="(per, i) in permissions" :key="i">
                    <td>
                      {{ $t(per) }}
                    </td>
                    <td>
                      <input
                        style="height:17px;width:17px"
                        v-model="edit_data.permissions"
                        type="checkbox"
                        :value="'show ' + per"
                        :disabled="
                          (edit_data.permissions || []).some(
                            e =>
                              [
                                `add ${per}`,
                                `edit ${per}`,
                                `delete ${per}`
                              ].indexOf(e) !== -1
                          )
                        "
                      />
                    </td>
                    <td>
                      <input
                        style="height:17px;width:17px"
                        v-model="edit_data.permissions"
                        type="checkbox"
                        :value="'add ' + per"
                      />
                    </td>
                    <td>
                      <input
                        style="height:17px;width:17px"
                        v-model="edit_data.permissions"
                        type="checkbox"
                        :value="'edit ' + per"
                      />
                    </td>
                    <td>
                      <input
                        style="height:17px;width:17px"
                        v-model="edit_data.permissions"
                        type="checkbox"
                        :value="'delete ' + per"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-info mt-2"
            :disabled="edit_disabled"
            @click.prevent="updateGroup"
          >
            {{ $t("save") }}
            <b-spinner small v-if="edit_disabled"></b-spinner>
          </button>
        </form>
      </div>
    </b-modal>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <b-tabs
              pills
              card
              no-key-nav
              content-class="custom-card"
              nav-wrapper-class="custom-nav-card"
            >
              <b-tab no-body :title="$t('all')" active>
                <template v-slot:title>
                  <i class="fa fa-list ml-2 mr-2"></i> {{ $t("all") }}
                </template>
                <b-card-text>
                  <b-card no-body class="card">
                    <b-card-header>
                      <div class="card-tools">
                        <div class="input-group input-group-sm">
                          <input
                            type="text"
                            class="form-control float-right"
                            :placeholder="$t('search')"
                            v-model="search_key"
                          />
                        </div>
                      </div>
                    </b-card-header>
                    <!-- /.card-header -->
                    <b-card-body class="table-responsive p-0">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th class="sort" @click="kimoSort(groups, 'id')">
                              #
                            </th>
                            <th class="sort" @click="kimoSort(groups, 'name')">
                              {{ $t("name") }}
                            </th>
                            <th
                              class="sort"
                              @click="kimoSort(groups, 'created_at')"
                            >
                              {{ $t("created_at") }}
                            </th>
                            <th>{{ $t("actions") }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="7" v-if="!load">
                              <i class="fa fa-sync  fa-spin load-con"></i>
                            </td>
                            <td
                              colspan="7"
                              class="no_data"
                              v-if="load && groups_f.length == 0"
                            >
                              {{ $t("no_data") }}
                            </td>
                          </tr>
                          <tr
                            v-for="(item, i) in groups_f"
                            :key="i"
                            v-show="load"
                          >
                            <td>{{ item.id }}</td>
                            <td>{{ item["name"] }}</td>
                            <td v-html="dateTimeFormat(item.created_at)"></td>
                            <td class="actions-group">
                              <button
                                v-if="$can('edit groups')"
                                class="btn btn-info btn-sm"
                                v-tooltip="$t('edit')"
                                @click="showEditGroup(item.id)"
                              >
                                <i class="fas fa-pencil-alt"> </i>
                              </button>
                              <button
                                v-if="$can('delete groups')"
                                @click="deleteAdmin(item.id)"
                                class="btn btn-danger btn-sm"
                                v-tooltip="$t('delete')"
                              >
                                <i class="fas fa-trash"> </i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-card-body>
                    <!-- /.card-body -->
                    <b-card-footer class="card-footer clearfix">
                      <b-pagination
                        class="m-2"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        :prev-text="$t('previous')"
                        :next-text="$t('next')"
                        align="right"
                        :first-number="true"
                        :last-number="true"
                      ></b-pagination>
                    </b-card-footer>
                  </b-card> </b-card-text
              ></b-tab>
              <b-tab no-body :title="$t('add')" v-if="$can('add groups')">
                <template v-slot:title>
                  <i class="fa fa-plus ml-2 mr-2"></i> {{ $t("add") }}
                </template>
                <b-card-text>
                  <b-card no-body>
                    <b-card-body>
                      <form class="col-md-8">
                        <b-alert
                          :show="add_error || add_success ? true : false"
                          :variant="add_error ? 'danger' : 'success'"
                          dismissible
                          @dismissed="add_error = add_success = ''"
                        >
                          {{ add_error || add_success }}
                        </b-alert>
                        <div class="form-row ">
                          <div class="form-group col-md-12">
                            <label>{{ $t("name") }}</label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="add_data.name"
                            />
                          </div>
                          <div class="col-md-12">
                            <label>{{ $t("permissions") }}</label>
                            <table class="table ">
                              <thead class="thead-light">
                                <tr>
                                  <th>{{ $t("section") }}</th>
                                  <th>{{ $t("view") }}</th>
                                  <th>{{ $t("add") }}</th>
                                  <th>{{ $t("edit") }}</th>
                                  <th>{{ $t("delete") }}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th>
                                    {{ $t("select_all") }}
                                  </th>
                                  <th>
                                    <input
                                      style="height: 17px; width: 17px"
                                      type="checkbox"
                                      @change="
                                        selectAll($event, 'add_data', 'show')
                                      "
                                    />
                                  </th>
                                  <th>
                                    <input
                                      style="height: 17px; width: 17px"
                                      type="checkbox"
                                      @change="
                                        selectAll($event, 'add_data', 'add')
                                      "
                                    />
                                  </th>
                                  <th>
                                    <input
                                      style="height: 17px; width: 17px"
                                      type="checkbox"
                                      @change="
                                        selectAll($event, 'add_data', 'edit')
                                      "
                                    />
                                  </th>
                                  <th>
                                    <input
                                      style="height: 17px; width: 17px"
                                      type="checkbox"
                                      @change="
                                        selectAll($event, 'add_data', 'delete')
                                      "
                                    />
                                  </th>
                                </tr>
                                <tr v-for="(per, i) in permissions" :key="i">
                                  <td>
                                    {{ $t(per) }}
                                  </td>
                                  <td>
                                    <input
                                      style="height:17px;width:17px"
                                      v-model="add_data.permissions"
                                      type="checkbox"
                                      :value="'show ' + per"
                                      :disabled="
                                        add_data.permissions.some(
                                          e =>
                                            [
                                              `add ${per}`,
                                              `edit ${per}`,
                                              `delete ${per}`
                                            ].indexOf(e) !== -1
                                        )
                                      "
                                    />
                                  </td>
                                  <td>
                                    <input
                                      style="height:17px;width:17px"
                                      v-model="add_data.permissions"
                                      type="checkbox"
                                      :value="'add ' + per"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      style="height:17px;width:17px"
                                      v-model="add_data.permissions"
                                      type="checkbox"
                                      :value="'edit ' + per"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      style="height:17px;width:17px"
                                      v-model="add_data.permissions"
                                      type="checkbox"
                                      :value="'delete ' + per"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-info mt-2"
                          :disabled="add_disabled"
                          @click="addGroup"
                        >
                          {{ $t("add") }}
                          <b-spinner small v-if="add_disabled"></b-spinner>
                        </button>
                      </form>
                    </b-card-body>
                    <!-- /.card-body -->
                  </b-card>
                </b-card-text></b-tab
              >
            </b-tabs>

            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: "Groups",
  data() {
    return {
      load: false,
      groups: [],
      search_fields: ["name"],
      search_key: "",
      disabled_s: [],
      currentPage: 1,
      perPage: 1,
      rows: 1,
      add_data: {
        permissions: []
      },
      add_disabled: false,
      add_success: "",
      add_error: "",
      add_progress: 0,
      add_data_icon: null,
      add_data_image: null,
      edit_data_icon: null,
      edit_data_image: null,
      edit_data: {},
      edit_disabled: false,
      edit_success: "",
      edit_error: "",
      permissions: [
        "home",
        "settings",
        "orders",
        "users",
        "categories",
        "sub_categories",
        "vendors",
        "stores",
        "products",
        "options",
        "ads",
        "coupons",
        "brands",
        "contacts",
        "admins",
        "groups",
        "pages",
        "welcome_pages",
        "c_reasons",
        "help",
        "incomes",
        "wallet",
        "delivery_companies",
        "packs",
        "p_groups",
        "collections",
        "invoices",
        "regions",
        "cities",
        "countries",
        "offers",
        "important_stores",
        "notification_templates",
        "notifications",
        "app_notifications",
        "email_templates",
        "home_sections",
      ]
    };
  },
  created() {
    this.getGroups();
  },
  computed: {
    groups_f() {
      return this.kimoSearch(this.groups, this.search_fields);
    }
  },
  watch: {
    currentPage() {
      this.getGroups();
    },
    "add_data.permissions"() {
      const i = this.add_data.permissions.at(-1);
      const flag = /(add|edit|delete)/g.test(i);
      if (flag) {
        const without = i.replace(/(add|edit|delete)\s/g, "");
        if (this.add_data.permissions.indexOf(`show ${without}`) === -1) {
          this.add_data.permissions.push(`show ${without}`);
        }
      }
    },
    "edit_data.permissions"() {
      const i = this.edit_data.permissions.at(-1);
      const flag = /(add|edit|delete)/g.test(i);
      if (flag) {
        const without = i.replace(/(add|edit|delete)\s/g, "");
        if (this.edit_data.permissions.indexOf(`show ${without}`) === -1) {
          this.edit_data.permissions.push(`show ${without}`);
        }
      }
    }
  },
  methods: {
    getGroups() {
      return this.axios
        .get("groups", {
          params: {
            page: this.currentPage
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.groups = res.result.groups.data;
            this.rows = res.result.groups.total;
            this.perPage = res.result.groups.per_page;
          }
        })
        .catch(error => {
          this.handleError("getGroups", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    addGroup() {
      this.add_disabled = true;
      this.axios
        .post("groups/add", this.add_data)
        .then(response => {
          let res = response.data;
          this.add_error = this.add_success = "";
          if (res.error_flag == 0) {
            this.add_success = res.message;
            if (this.currentPage == 1) {
              this.groups.splice(this.perPage - 1, 1);
              this.rows += 1;
              this.groups.unshift(res.result.group);
            }
            this.add_data = {
              permissions: []
            };
          } else if (res.error_flag == 1) {
            this.add_error = res.message;
          } else {
            this.handleError("addGroup", res.message);
          }
        })
        .catch(error => {
          this.handleError("addGroup", error);
        })
        .finally(() => {
          this.add_disabled = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    updateGroup() {
      let index = this.findIndex(this.groups, "id", this.edit_data.id);
      this.edit_disabled = true;
      this.axios
        .post("groups/update", this.edit_data)
        .then(response => {
          let res = response.data;
          this.edit_error = this.edit_success = "";
          if (res.error_flag == 0) {
            this.edit_success = res.message;
            this.$set(this.groups, index, res.result.group);
          } else if (res.error_flag == 1) {
            this.edit_error = res.message;
          } else {
            this.handleError("updateGroup", res.message);
          }
        })
        .catch(error => {
          this.handleError("updateGroup", error);
        })
        .finally(() => {
          this.edit_disabled = false;
          document.getElementById("edit_group_modal").scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    changeStatus(id) {
      let index = this.findIndex(this.groups, "id", id);
      let i = this.findIndex(this.groups_f, "id", id);
      this.$set(this.disabled_s, i, true);
      this.axios
        .post("groups/update_status/" + id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.$noty.success(res.message);
            this.groups[index].status = res.result.status;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("changeStatus", res.message);
          }
        })
        .catch(error => {
          this.handleError("chnageStatus", error);
        })
        .finally(() => {
          this.$set(this.disabled_s, i, false);
        });
    },
    deleteAdmin(id) {
      let index = this.findIndex(this.groups, "id", id);

      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("groups/delete/" + id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.groups.splice(index, 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deleteAdmin", res.message);
                }
              })
              .catch(error => {
                this.handleError("deleteAdmin", error);
              });
          }
        });
    },
    showEditGroup(id) {
      this.edit_success = this.edit_error = "";
      this.edit_data = { ...this.groups.find(e => e.id == id) };
      this.edit_data.permissions = this.edit_data.role.permissions.map(
        e => e.name
      );
      this.$refs.edit_group_modal.show();
    },
    selectAll(event, elem, type) {
      let checked = event.target.checked;
      let data = [];
      data = this[elem].permissions;
      this.permissions.forEach(permission => {
        if (checked) {
          if (["add", "edit", "delete"].includes(type)) {
            data.push(`show ${permission}`);
          }
          data.push(`${type} ${permission}`);
        } else {
          let i = data.indexOf(`${type} ${permission}`);

          data.splice(i, 1);
        }
      });
      this[elem] = { ...this[elem], permissions: [...new Set(data)] };
    }
  }
};
</script>
