<template>
  <div>
    <b-modal
      ref="edit_page_modal"
      id="edit_page_modal"
      :title="$t('edit') + ' ' + $t('page_2')"
      header-class="new_modal_header"
      hide-footer
      size="lg"
      no-enforce-focus
    >
      <div class="d-block">
        <form class="col-md-12">
          <b-alert
            :show="edit_error || edit_success ? true : false"
            :variant="edit_error ? 'danger' : 'success'"
            dismissible
            @dismissed="edit_error = edit_success = ''"
          >
            {{ edit_error || edit_success }}
          </b-alert>
          <b-tabs>
            <b-tab :title="$t('ar')">
              <br />

              <div class="form-row ">
                <div class="form-group col-md-12">
                  <label>{{ $t("name") }} | {{ $t("ar") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="edit_data.name_ar"
                  />
                </div>
                <div class="form-group col-md-12">
                  <label>{{ $t("content") }} | {{ $t("ar") }}</label>
                  <tinymce
                    :other_options="options"
                    @editorInit="e => e.setContent(edit_data.content_ar)"
                    id="d2"
                    v-model="edit_data.content_ar"
                    :plugins="plugins"
                  ></tinymce>
                </div>
              </div>
            </b-tab>
            <b-tab :title="$t('en')">
              <br />

              <div class="form-row ">
                <div class="form-group col-md-12">
                  <label>{{ $t("name") }} | {{ $t("en") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="edit_data.name_en"
                  />
                </div>
                <div class="form-group col-md-12">
                  <label>{{ $t("content") }} | {{ $t("en") }}</label>
                  <tinymce
                    :other_options="options"
                    @editorInit="e => e.setContent(edit_data.content_en)"
                    id="d2en"
                    v-model="edit_data.content_en"
                    :plugins="plugins"
                  ></tinymce>
                </div>
              </div>
            </b-tab>
          </b-tabs>
          <div class="form-group col-md-6" v-if="false">
            <label>{{ $t("menu") }}</label>
            <select class="form-control pt-0" v-model="edit_data.menu">
              <option value="1"> 1</option>
              <option value="2">2</option>
            </select>
          </div>
          <button
            type="submit"
            class="btn btn-info mt-2"
            :disabled="edit_disabled"
            @click.prevent="updatepage"
          >
            {{ $t("save") }}
            <b-spinner small v-if="edit_disabled"></b-spinner>
          </button>
        </form>
      </div>
    </b-modal>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <b-tabs
              pills
              card
              no-key-nav
              content-class="custom-card"
              nav-wrapper-class="custom-nav-card"
            >
              <b-tab no-body :title="$t('all')" active>
                <template v-slot:title>
                  <i class="fa fa-list ml-2 mr-2"></i> {{ $t("all") }}
                </template>
                <b-card-text>
                  <b-card no-body class="card">
                    <b-card-header>
                      <div class="card-tools">
                        <div class="input-group input-group-sm">
                          <input
                            type="text"
                            class="form-control float-right"
                            :placeholder="$t('search')"
                            v-model="search_key"
                          />
                        </div>
                      </div>
                    </b-card-header>
                    <!-- /.card-header -->
                    <b-card-body class="table-responsive p-0">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th>
                              #
                            </th>
                            <th>
                              {{ $t("name") }}
                            </th>
                            <th
                              v-if="false"
                              class="sort"
                              @click="kimoSort(pages, 'created_at')"
                            >
                              {{ $t("created_at") }}
                            </th>
                            <th
                              v-if="false"
                              class="sort"
                              @click="kimoSort(pages, 'status')"
                            >
                              {{ $t("status") }}
                            </th>
                            <th>{{ $t("actions") }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="3" v-if="!load">
                              <i class="fa fa-sync  fa-spin load-con"></i>
                            </td>
                            <td
                              colspan="3"
                              class="no_data"
                              v-if="load && pages_f.length == 0"
                            >
                              {{ $t("no_data") }}
                            </td>
                          </tr>
                          <tr
                            v-for="(item, i) in pages_f"
                            :key="i"
                            v-show="load"
                          >
                            <td>{{ i + 1 }}</td>

                            <td>{{ item["name_ar"] }}</td>

                            <td v-if="false">
                              {{
                                $moment(item.created_at)
                                  .tz($store.state.timezone)
                                  .format("Y-MM-DD hh:mm A")
                              }}
                            </td>
                            <td v-if="false">
                              <span
                                :class="
                                  'badge badge-' +
                                    (item.status == 'on' ? 'info' : 'danger')
                                "
                                >{{ $t(item.status) }}</span
                              >
                            </td>
                            <td class="actions-group">
                              <button
                                v-if="$can('edit pages') && false"
                                class="btn btn-primary btn-sm"
                                v-tooltip="$t('change_status')"
                                @click="changeStatus(item.id)"
                              >
                                <b-spinner small v-if="disabled_s[i]">
                                </b-spinner>
                                <i class="fas fa-exchange-alt" v-else></i>
                              </button>
                              <button
                                v-if="$can('edit pages')"
                                class="btn btn-info btn-sm"
                                v-tooltip="$t('edit')"
                                @click="showEditpage(item.id)"
                              >
                                <i class="fas fa-pencil-alt"> </i>
                              </button>
                              <button
                                v-if="$can('delete pages') && false"
                                @click="deletepage(item.id)"
                                class="btn btn-danger btn-sm"
                                v-tooltip="$t('delete')"
                              >
                                <i class="fas fa-trash"> </i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-card-body>
                    <!-- /.card-body -->
                    <b-card-footer class="card-footer clearfix">
                      <b-pagination
                        class="m-2"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        :prev-text="$t('previous')"
                        :next-text="$t('next')"
                        align="right"
                        :first-number="true"
                        :last-number="true"
                      ></b-pagination>
                    </b-card-footer>
                  </b-card> </b-card-text
              ></b-tab>
              <b-tab
                no-body
                :title="$t('add')"
                v-if="$can('add pages') && false"
              >
                <template v-slot:title>
                  <i class="fa fa-plus ml-2 mr-2"></i> {{ $t("add") }}
                  {{ $t("page_2") }}
                </template>
                <b-card-text>
                  <b-card no-body>
                    <b-card-body>
                      <form class="col-md-8">
                        <b-alert
                          :show="add_error || add_success ? true : false"
                          :variant="add_error ? 'danger' : 'success'"
                          dismissible
                          @dismissed="add_error = add_success = ''"
                        >
                          {{ add_error || add_success }}
                        </b-alert>
                        <b-tabs>
                          <b-tab :title="$t('ar')">
                            <br />
                            <div class="form-row ">
                              <div class="form-group col-md-12">
                                <label
                                  >{{ $t("title") }} | {{ $t("ar") }}</label
                                >
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="add_data.name_ar"
                                />
                              </div>

                              <div class="form-group col-md-12">
                                <label
                                  >{{ $t("content") }} | {{ $t("ar") }}</label
                                >
                                <tinymce
                                  images_upload_url="postAcceptor.php"
                                  :other_options="options"
                                  id="d1"
                                  v-model="add_data.content_ar"
                                  :plugins="plugins"
                                ></tinymce>
                              </div>
                            </div>
                          </b-tab>
                          <b-tab :title="$t('en')">
                            <br />
                            <div class="form-row ">
                              <div class="form-group col-md-12">
                                <label
                                  >{{ $t("title") }} | {{ $t("en") }}</label
                                >
                                <input
                                  type="text"
                                  class="form-control"
                                  v-model="add_data.name_en"
                                />
                              </div>

                              <div class="form-group col-md-12">
                                <label
                                  >{{ $t("content") }} | {{ $t("en") }}</label
                                >
                                <tinymce
                                  images_upload_url="postAcceptor.php"
                                  :other_options="options"
                                  id="d1en"
                                  v-model="add_data.content_en"
                                  :plugins="plugins"
                                ></tinymce>
                              </div>
                            </div>
                          </b-tab>
                        </b-tabs>

                        <div class="form-group col-md-6" v-if="false">
                          <label>{{ $t("menu") }}</label>
                          <select
                            class="form-control pt-0"
                            v-model="add_data.menu"
                          >
                            <option value="1"> 1</option>
                            <option value="2">2</option>
                          </select>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-info mt-2"
                          :disabled="add_disabled"
                          @click="addpage"
                        >
                          {{ $t("add") }}
                          <b-spinner small v-if="add_disabled"></b-spinner>
                        </button>
                      </form>
                    </b-card-body>
                    <!-- /.card-body -->
                  </b-card>
                </b-card-text></b-tab
              >
            </b-tabs>

            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: "Pages",
  data() {
    return {
      load: false,
      pages: [],
      search_fields: ["name_ar"],
      search_key: "",
      disabled_s: [],
      currentPage: 1,
      perPage: 1,
      rows: 1,
      add_data: { menu: "1" },
      add_disabled: false,
      add_success: "",
      add_error: "",
      add_progress: 0,
      edit_data: {},
      edit_disabled: false,
      edit_success: "",
      edit_error: "",
      plugins: [
        "advlist autolink lists link image charmap preview hr anchor pagebreak",
        "searchreplace wordcount visualblocks visualchars fullscreen",
        "insertdatetime media nonbreaking save table contextmenu directionality",
        "paste textcolor colorpicker textpattern imagetools  emoticons hr codesample"
      ],
      options: {
        language_url: "/ar.js",
        height: 250
        // images_upload_url: 'postAcceptor.php',
      },
      show_editor: true
    };
  },
  created() {
    this.getPages();
  },
  computed: {
    pages_f() {
      return this.kimoSearch(this.pages, this.search_fields);
    }
  },
  watch: {
    currentPage() {
      this.getPages();
    }
  },
  methods: {
    getPages() {
      return this.axios
        .get("pages", {
          params: {
            page: this.currentPage
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.pages = res.result.pages.data;
            this.rows = res.result.pages.total;
            this.perPage = res.result.pages.per_page;
          }
        })
        .catch(error => {
          this.handleError("getPages", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    addpage() {
      this.add_disabled = true;

      this.axios
        .post("pages/add", this.add_data)
        .then(response => {
          let res = response.data;
          this.add_error = this.add_success = "";
          if (res.error_flag == 0) {
            this.add_success = res.message;
            if (this.currentPage == 1) {
              this.pages.splice(this.perPage - 1, 1);
              this.rows += 1;
              this.pages.unshift(res.result.page);
            }
            this.add_data = {};
          } else if (res.error_flag == 1) {
            this.add_error = res.message;
          } else {
            this.handleError("addpage", res.message);
          }
        })
        .catch(error => {
          this.handleError("addpage", error);
        })
        .finally(() => {
          this.add_disabled = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    updatepage() {
      let index = this.findIndex(this.pages, "id", this.edit_data.id);
      this.edit_disabled = true;
      this.axios
        .post("pages/update", this.edit_data)
        .then(response => {
          let res = response.data;
          this.edit_error = this.edit_success = "";
          if (res.error_flag == 0) {
            this.edit_success = res.message;
            this.$set(this.pages, index, res.result.page);
          } else if (res.error_flag == 1) {
            this.edit_error = res.message;
          } else {
            this.handleError("updatepage", res.message);
          }
        })
        .catch(error => {
          this.handleError("updatepage", error);
        })
        .finally(() => {
          this.edit_disabled = false;
          document.getElementById("edit_page_modal").scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    changeStatus(id) {
      let index = this.findIndex(this.pages, "id", id);
      let i = this.findIndex(this.pages_f, "id", id);
      this.$set(this.disabled_s, i, true);
      this.axios
        .post("pages/update_status/" + id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.$noty.success(res.message);
            this.pages[index].status = res.result.status;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("changeStatus", res.message);
          }
        })
        .catch(error => {
          this.handleError("chnageStatus", error);
        })
        .finally(() => {
          this.$set(this.disabled_s, i, false);
        });
    },
    deletepage(id) {
      let index = this.findIndex(this.pages, "id", id);

      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("pages/delete/" + id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.pages.splice(index, 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deletepage", res.message);
                }
              })
              .catch(error => {
                this.handleError("deletepage", error);
              });
          }
        });
    },
    showEditpage(id) {
      this.edit_success = this.edit_error = "";
      this.edit_data = { ...this.pages.find(e => e.id == id) };
      this.$refs.edit_page_modal.show();
    }
  }
};
</script>
