<template>
    <div>
        <b-modal
            ref="edit_homeSection_modal"
            :title="$t('edit') + ' ' + $t('home_section')"
            header-class="new_modal_header"
            hide-footer
        >
            <div class="d-block">
                <form class="col-md-12">
                    <b-alert
                        :show="edit_error || edit_success ? true : false"
                        :variant="edit_error ? 'danger' : 'success'"
                        dismissible
                        @dismissed="edit_error = edit_success = ''"
                    >
                        {{ edit_error || edit_success }}
                    </b-alert>
                    <div class="form-row ">
                        <div class="form-group col-md-12">
                            <label>{{ $t("image") }}</label>
                            <div class="image_div" v-if="edit_data_image || edit_data.image">
                                <img
                                    :src="edit_data_image || image_url + edit_data.image"
                                    @error="$event.target.src = '/images/placeholder.jpg'"
                                />
                                <i
                                    v-if="edit_data_image"
                                    class="fa fa-times"
                                    @click.prevent="edit_data_image = edit_data_icon = null"
                                ></i>
                            </div>
                            <b-form-file
                                v-if="!edit_data_image"
                                accept="image/*"
                                @change.prevent="onImageChange($event, 'edit_data')"
                                ref="edit_data_icon"
                            ></b-form-file>
                            <span class="error invalid-feedback">{{
                                    (edit_errors.image_input || []).join("-")
                                }}</span>
                        </div>
                        <div class="form-group col-md-6">
                            <label>{{ $t("title") }} | {{ $t("ar") }}</label>
                            <input
                                type="text"
                                class="form-control rtl"
                                :class="{ 'is-invalid': edit_errors.title_ar }"
                                v-model="edit_data.title_ar"
                            />
                            <span class="error invalid-feedback">{{
                                    (edit_errors.title_ar || []).join("-")
                                }}</span>
                        </div>
                        <div class="form-group col-md-6">
                            <label>{{ $t("title") }} | {{ $t("en") }}</label>
                            <input
                                type="text"
                                class="form-control ltr"
                                :class="{ 'is-invalid': edit_errors.title_en }"
                                v-model="edit_data.title_en"
                            />
                            <span class="error invalid-feedback">{{
                                    (edit_errors.title_en || []).join("-")
                                }}</span>
                        </div>

                        <div class="form-group col-md-6">
                            <label>{{ $t("desc") }} | {{ $t("ar") }}</label>
                            <input
                                type="text"
                                class="form-control rtl"
                                :class="{ 'is-invalid': edit_errors.desc_ar }"
                                v-model="edit_data.desc_ar"
                            />
                            <span class="error invalid-feedback">{{
                                    (edit_errors.desc_ar || []).join("-")
                                }}</span>
                        </div>
                        <div class="form-group col-md-6">
                            <label>{{ $t("desc") }} | {{ $t("en") }}</label>
                            <input
                                type="text"
                                class="form-control ltr"
                                :class="{ 'is-invalid': edit_errors.desc_en }"
                                v-model="edit_data.desc_en"
                            />
                            <span class="error invalid-feedback">{{
                                    (edit_errors.desc_en || []).join("-")
                                }}</span>
                        </div>

                    </div>

                    <button
                        type="submit"
                        class="btn btn-info mt-2"
                        :disabled="edit_disabled"
                        @click.prevent="updatehomeSection"
                    >
                        {{ $t("save") }}
                        <b-spinner small v-if="edit_disabled"></b-spinner>
                    </button>
                </form>
            </div>
        </b-modal>

        <b-modal
            ref="customize_table_modal"
            centered
            :title="$t('customize_table')"
            header-class="new_modal_header"
            hide-footer
        >
            <div class="d-block">
                <div class="row clearfix">
                    <div class="col-md-12">
                        <div class="form-row" style="padding: 0 20px">
                            <div
                                class="form-group col-md-6"
                                v-for="(field, i) in table_fields"
                                :key="i"
                            >
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    aria-label="Checkbox for following text input"
                                    :value="field"
                                    :id="'f_' + field"
                                    v-model="table_selected_fields"
                                    style="vertical-align: middle;"
                                /><label
                                class="form-check-label"
                                :for="'f_' + field"
                                style="color: blue;"
                            >
                                {{ $t(field.toLowerCase()) }}</label
                            >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <b-tabs
                            v-model="c_tab"
                            pills
                            card
                            no-key-nav
                            content-class="custom-card"
                            nav-wrapper-class="custom-nav-card"
                        >
                            <b-tab no-body :title="$t('all')" active>
                                <template v-slot:title>
                                    <i class="fa fa-list ml-2 mr-2"></i> {{ $t("all") }}
                                </template>
                                <b-card-text>
                                    <b-card no-body class="card">
                                        <b-card-header>
                                            <div class="card-tools">
                                                <div class="input-group input-group-sm">
                                                    <i
                                                        class="fas fa-cog c_pointer customize_table"
                                                        v-tooltip="$t('customize_table')"
                                                        @click="customizeTable"
                                                    ></i>
                                                    <input
                                                        type="text"
                                                        class="form-control float-right"
                                                        :placeholder="$t('search')"
                                                        v-model="search_key"
                                                    />
                                                    <div class="input-group-append">
                                                        <button
                                                            type="submit"
                                                            :disabled="search_disabled"
                                                            @click.prevent="searchAll"
                                                            class="btn btn-default"
                                                        >
                                                            <i
                                                                class="fa fa-spinner fa-spin"
                                                                v-if="search_disabled"
                                                            ></i>
                                                            <i class="fas fa-search" v-else></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-card-header>
                                        <!-- /.card-header -->
                                        <b-card-body class="table-responsive p-0">
                                            <table class="table table-hover">
                                                <thead>
                                                <tr>
                                                    <th v-if="table_selected_fields.includes('#')">
                                                        #
                                                    </th>
                                                    <th v-if="table_selected_fields.includes('image')">
                                                        {{ $t("image") }}
                                                    </th>
                                                    <th
                                                        v-if="table_selected_fields.includes('title')"
                                                        class="sort"
                                                        @click="sortTable('title_' + $i18n.locale)"
                                                    >
                                                        {{ $t("title") }}
                                                    </th>

                                                    <th
                                                        v-if="table_selected_fields.includes('desc')"
                                                        class="sort"
                                                        @click="sortTable('desc_' + $i18n.locale)"
                                                    >
                                                        {{ $t("desc") }}
                                                    </th>
                                                    <th
                                                        v-if="
                                table_selected_fields.includes('created_at')
                              "
                                                        class="sort"
                                                        @click="sortTable('created_at')"
                                                    >
                                                        {{ $t("created_at") }}
                                                    </th>
                                                    <th
                                                        v-if="table_selected_fields.includes('status')"
                                                        class="sort"
                                                        @click="sortTable('status')"
                                                    >
                                                        {{ $t("status") }}
                                                    </th>
                                                    <th
                                                        v-if="table_selected_fields.includes('actions')"
                                                    >
                                                        {{ $t("actions") }}
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td
                                                        :colspan="table_selected_fields.length"
                                                        v-if="!load"
                                                    >
                                                        <i class="fa fa-sync  fa-spin load-con"></i>
                                                    </td>
                                                    <td
                                                        :colspan="table_selected_fields.length"
                                                        class="no_data"
                                                        v-if="load && homeSections.length == 0"
                                                    >
                                                        {{ $t("no_data") }}
                                                    </td>
                                                </tr>
                                                <tr
                                                    v-for="(item, i) in homeSections"
                                                    :key="i"
                                                    v-show="load"
                                                >
                                                    <td v-if="table_selected_fields.includes('#')">
                                                        {{ i + 1 }}
                                                    </td>
                                                    <td v-if="table_selected_fields.includes('image')">
                                                        <img
                                                            :src="image_url + '/' + item.image"
                                                            @error="
                                  $event.target.src = '/images/placeholder.jpg'
                                "
                                                        />
                                                    </td>
                                                    <td v-if="table_selected_fields.includes('title')">
                                                        {{ item["title_" + $i18n.locale] }}
                                                    </td>

                                                    <td v-if="table_selected_fields.includes('desc')">
                                                        {{ item["desc_" + $i18n.locale] }}
                                                    </td>

                                                    <td
                                                        v-if="
                                table_selected_fields.includes('created_at')
                              "
                                                        v-html="dateTimeFormat(item.created_at)"
                                                    ></td>
                                                    <td
                                                        class="pb-0"
                                                        v-if="table_selected_fields.includes('status')"
                                                    >
                                                        <label class="switch mr-2 ml-2">
                                                            <input
                                                                type="checkbox"
                                                                :disabled="!$can('edit home_sections')"
                                                                v-model="item.status"
                                                                :true-value="'on'"
                                                                :false-value="'off'"
                                                                @change="
                                    $can('edit home_sections')
                                      ? changeStatus(item.id)
                                      : false
                                  "
                                                            />
                                                            <div class="slider round">
                                                                <span class="on">{{ $t("on") }}</span>
                                                                <span class="off">{{ $t("off") }}</span>
                                                            </div>
                                                        </label>
                                                    </td>
                                                    <td
                                                        class="actions-group"
                                                        v-if="table_selected_fields.includes('actions')"
                                                    >
                                                        <button
                                                            v-if="$can('edit home_sections')"
                                                            class="btn btn-info btn-sm"
                                                            v-tooltip="$t('edit')"
                                                            @click="showEdithomeSection(item.id)"
                                                        >
                                                            <i class="fas fa-pencil-alt"> </i>
                                                        </button>
                                                        <!--    <button
                                                                v-if="$can('delete home_sections')"
                                                                @click="deletehomeSection(item.id)"
                                                                class="btn btn-danger btn-sm"
                                                                v-tooltip="$t('delete')"
                                                            >
                                                                <i class="fas fa-trash"> </i>
                                                            </button> -->
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </b-card-body>
                                        <!-- /.card-body -->
                                        <b-card-footer class="card-footer clearfix">
                                            <b-pagination
                                                class="m-2"
                                                v-model="currentPage"
                                                :total-rows="rows"
                                                :per-page="perPage"
                                                :prev-text="$t('previous')"
                                                :next-text="$t('next')"
                                                align="right"
                                                :first-number="true"
                                                :last-number="true"
                                            ></b-pagination>
                                        </b-card-footer>
                                    </b-card>
                                </b-card-text
                                >
                            </b-tab>
                            <b-tab no-body :title="$t('add')" v-if="$can('add home_sections')">
                                <template v-slot:title>
                                    <i class="fa fa-plus ml-2 mr-2"></i> {{ $t("add") }}
                                </template>
                                <b-card-text>
                                    <b-card no-body>
                                        <b-card-body>
                                            <form class="col-md-8">
                                                <b-alert
                                                    :show="add_error || add_success ? true : false"
                                                    :variant="add_error ? 'danger' : 'success'"
                                                    dismissible
                                                    @dismissed="add_error = add_success = ''"
                                                >
                                                    {{ add_error || add_success }}
                                                </b-alert>
                                                <div class="form-row ">
                                                    <div class="form-group col-md-12">
                                                        <label>{{ $t("image") }}</label>

                                                        <div class="image_div" v-if="add_data_image">
                                                            <img :src="add_data_image"/>
                                                            <i
                                                                class="fa fa-times"
                                                                @click.prevent="
                                  add_data_image = add_data_icon = null
                                "
                                                            ></i>
                                                        </div>
                                                        <b-form-file
                                                            v-else
                                                            :placeholder="$t('choose_file')"
                                                            accept="image/*"
                                                            multiple
                                                            @change.prevent="
                                onImageChange($event, 'add_data')
                              "
                                                            ref="add_data_icon"
                                                        ></b-form-file>
                                                        <span class="error invalid-feedback d-block">{{
                                                                (add_errors.image_input || []).join("-")
                                                            }}</span>
                                                    </div>

                                                    <div class="form-group col-md-6">
                                                        <label>{{ $t("title") }} | {{ $t("ar") }}</label>
                                                        <input
                                                            type="text"
                                                            class="form-control rtl"
                                                            :class="{ 'is-invalid': add_errors.title_ar }"
                                                            v-model="add_data.title_ar"
                                                        />
                                                        <span class="error invalid-feedback">{{
                                                                (add_errors.title_ar || []).join("-")
                                                            }}</span>
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <label>{{ $t("title") }} | {{ $t("en") }}</label>
                                                        <input
                                                            type="text"
                                                            class="form-control ltr"
                                                            :class="{ 'is-invalid': add_errors.title_en }"
                                                            v-model="add_data.title_en"
                                                        />
                                                        <span class="error invalid-feedback">{{
                                                                (add_errors.title_en || []).join("-")
                                                            }}</span>
                                                    </div>
                                                </div>

                                                <button
                                                    type="submit"
                                                    class="btn btn-info mt-2"
                                                    :disabled="add_disabled"
                                                    @click="addhomeSection"
                                                >
                                                    {{ $t("add") }}
                                                    <b-spinner small v-if="add_disabled"></b-spinner>
                                                </button>
                                                <div class="icheck-primary  add_with_new">
                                                    <input
                                                        type="checkbox"
                                                        id="add_with_new"
                                                        value="1"
                                                        v-model="add_with_new"
                                                    />
                                                    <label for="add_with_new">
                                                        {{ $t("add_with_new") }}
                                                    </label>
                                                </div>
                                            </form>
                                        </b-card-body>
                                        <!-- /.card-body -->
                                    </b-card>
                                </b-card-text>
                            </b-tab
                            >
                        </b-tabs>

                        <!-- /.card -->
                    </div>
                </div>
                <!-- /.row -->
            </div>
            <!-- /.container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>

<script>
export default {
    name: "HomeSection",
    data() {
        return {
            load: false,
            homeSections: [],
            search_key: "",
            old_search_key: "",
            search_disabled: false,
            disabled_s: [],
            currentPage: 1,
            perPage: 1,
            rows: 1,
            add_data: {},
            add_disabled: false,
            add_success: "",
            add_error: "",
            add_errors: {},
            add_progress: 0,
            add_data_icon: null,
            add_data_image: null,
            add_data_icon_icon: null,
            add_data_icon_image: null,
            edit_data_icon: null,
            edit_data_image: null,
            edit_data_icon_icon: null,
            edit_data_icon_image: null,
            image_url: "",
            edit_data: {},
            edit_disabled: false,
            edit_success: "",
            edit_error: "",
            edit_errors: {},
            sort_key: "",
            sort_type: "asc",
            table_fields: ["#", "image", "title", 'desc', "created_at", "status", "actions"],
            table_selected_fields: [],
            add_with_new: 0,
            c_tab: 0
        };
    },
    created() {
        this.table_selected_fields = this.$store.state.home_section_selected_fields;
        this.gethomeSections();
    },
    watch: {
        currentPage() {
            this.gethomeSections();
        },
        table_selected_fields() {
            this.$store.commit("setHomeSectionsSelectedFields", this.table_selected_fields);
        },
        "add_data.title_ar"(newVal, oldVal) {
            if (!this.isArabicChars(newVal) && newVal !== oldVal) {
                this.$set(this.add_data, "title_ar", "");
            }
        },
        "edit_data.title_ar"(newVal, oldVal) {
            if (!this.isArabicChars(newVal) && newVal !== oldVal) {
                this.$set(this.edit_data, "title_ar", "");
            }
        }
    },
    methods: {
        gethomeSections() {
            return this.axios
                .get("home_section", {
                    params: {
                        page: this.currentPage,
                        search_key: this.search_key,
                        sort_key: this.sort_key,
                        sort_type: this.sort_type
                    }
                })
                .then(response => {
                    let res = response.data;
                    if (res.error_flag == 0) {
                        this.homeSections = res.result.home_sections.data;
                        this.rows = res.result.home_sections.total;
                        this.perPage = res.result.home_sections.per_page;
                        this.image_url = res.result.image_url;
                    }
                })
                .catch(error => {
                    this.handleError("gethomeSections", error);
                })
                .finally(() => {
                    this.load = true;
                });
        },
        sortTable(key) {
            if (this.sort_key != key) {
                this.sort_type = "asc";
            } else {
                this.sort_type = this.sort_type == "asc" ? "desc" : "asc";
            }
            this.sort_key = key;
            this.gethomeSections();
        },
        searchAll() {
            if (this.search_key != this.old_search_key) {
                this.old_search_key = this.search_key;
                this.search_disabled = true;
                this.gethomeSections().then(() => {
                    this.search_disabled = false;
                });
            }
        },
        addhomeSection() {
            this.add_disabled = true;
            let fd = new FormData();

            if (this.add_data_icon) {
                fd.append("image_input", this.add_data_icon, this.add_data_icon.name);
            }
            if (this.add_data_icon_icon) {
                fd.append(
                    "icon_input",
                    this.add_data_icon_icon,
                    this.add_data_icon_icon.name
                );
            }
            Object.keys(this.add_data).forEach(key => {
                fd.append(key, this.add_data[key]);
            });
            this.add_error = this.add_success = "";
            this.add_errors = {};
            this.axios
                .post("home_section/add", fd)
                .then(response => {
                    let res = response.data;
                    if (res.error_flag == 0) {
                        this.add_success = res.message;
                        this.gethomeSections().then(() => {
                            if (!this.add_with_new) {
                                this.c_tab = 0;
                            }
                        });
                        this.add_data = {};
                        this.add_data_image = null;
                        this.add_data_icon = null;
                        this.add_data_icon_image = null;
                        this.add_data_icon_icon = null;

                    } else if (res.error_flag == 1) {
                        if (typeof res.message === "object") {
                            this.add_errors = res.message;
                        } else {
                            this.add_error = res.message;
                        }
                    } else {
                        this.handleError("addhomeSection", res.message);
                    }
                })
                .catch(error => {
                    this.handleError("addhomeSection", error);
                })
                .finally(() => {
                    this.add_disabled = false;
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: "smooth"
                    });
                });
        },
        updatehomeSection() {
            this.edit_disabled = true;
            let fd = new FormData();

            if (this.edit_data_icon) {
                fd.append("image_input", this.edit_data_icon, this.edit_data_icon.name);
            }

            if (this.edit_data_icon_icon) {
                fd.append(
                    "icon_input",
                    this.edit_data_icon_icon,
                    this.edit_data_icon_icon.name
                );
            }

            Object.keys(this.edit_data).forEach(key => {
                if (this.edit_data[key]) {
                    fd.append(key, this.edit_data[key]);
                }
            });
            this.edit_error = this.edit_success = "";
            this.edit_errors = {};
            this.axios
                .post("home_section/update", fd)
                .then(response => {
                    let res = response.data;
                    if (res.error_flag == 0) {
                        this.edit_success = res.message;
                        this.edit_data = {...res.result.home_section};
                        let i = this.homeSections.findIndex(e => e.id == this.edit_data.id);
                        this.$set(this.homeSections, i, res.result.home_section);
                        this.edit_data_image = null;
                        this.edit_data_icon = null;
                        this.edit_data_icon_image = null;
                        this.edit_data_icon_icon = null;
                    } else if (res.error_flag == 1) {
                        if (typeof res.message === "object") {
                            this.edit_errors = res.message;
                        } else {
                            this.edit_error = res.message;
                        }
                    } else {
                        this.handleError("updatehomeSection", res.message);
                    }
                })
                .catch(error => {
                    this.handleError("updatehomeSection", error);
                })
                .finally(() => {
                    this.edit_disabled = false;
                });
        },
        changeStatus(id) {
            let index = this.findIndex(this.homeSections, "id", id);
            let i = this.findIndex(this.homeSections, "id", id);
            this.$set(this.disabled_s, i, true);
            this.axios
                .post("home_section/update_status/" + id)
                .then(response => {
                    let res = response.data;
                    if (res.error_flag == 0) {
                        this.$noty.success(res.message);
                        this.homeSections[index].status = res.result.status;
                    } else if (res.error_flag == 1) {
                        this.alert(res.message, this.$t("error"));
                    } else {
                        this.handleError("changeStatus", res.message);
                    }
                })
                .catch(error => {
                    this.handleError("chnageStatus", error);
                })
                .finally(() => {
                    this.$set(this.disabled_s, i, false);
                });
        },
        deletehomeSection(id) {
            let index = this.findIndex(this.homeSections, "id", id);

            this.$bvModal
                .msgBoxConfirm(this.$t("are_you_sure"), {
                    okVariant: "danger",
                    okTitle: this.$t("yes"),
                    cancelTitle: this.$t("no"),
                    centered: true,
                    footerClass: "custom_modal_footer",
                    contentClass: "custom_modal_content"
                })
                .then(value => {
                    if (value) {
                        this.axios
                            .post("home_section/delete/" + id)
                            .then(response => {
                                let res = response.data;
                                if (res.error_flag == 0) {
                                    this.homeSections.splice(index, 1);
                                    this.$noty.success(res.message);
                                } else if (res.error_flag == 1) {
                                    this.alert(res.message, this.$t("error"));
                                } else {
                                    this.handleError("deletehomeSection", res.message);
                                }
                            })
                            .catch(error => {
                                this.handleError("deletehomeSection", error);
                            });
                    }
                });
        },
        showEdithomeSection(id) {
            this.edit_success = this.edit_error = "";
            this.edit_data = {...this.homeSections.find(e => e.id == id)};
            this.edit_data_icon = null;
            this.edit_data_image = null;
            this.edit_data_icon_icon = null;
            this.edit_data_icon_image = null;
            this.$refs.edit_homeSection_modal.show();
        }
    }
};
</script>
