export default function auth({ next, store, to }) {
  if (!store.state.access_token) {
    return next({
      name: "Login",
      query: {
        redirect: to.fullPath
      }
    });
  }
  return next();
}
