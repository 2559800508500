<template>
  <div>
    <b-modal
      ref="edit_Help_modal"
      :title="$t('edit') + ' ' + $t('help_2')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <form class="col-md-12">
          <b-alert
            :show="edit_error || edit_success ? true : false"
            :variant="edit_error ? 'danger' : 'success'"
            dismissible
            @dismissed="edit_error = edit_success = ''"
          >
            {{ edit_error || edit_success }}
          </b-alert>
          <div class="form-row ">
            <div class="form-group col-md-6">
              <label>{{ $t("title") }} | {{ $t("ar") }}</label>
              <input
                type="text"
                class="form-control rtl"
                :class="{ 'is-invalid': edit_errors.title_ar }"
                v-model="edit_data.title_ar"
              />
              <span class="error invalid-feedback">{{
                (edit_errors.title_ar || []).join("-")
              }}</span>
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("title") }} | {{ $t("en") }}</label>
              <input
                type="text"
                class="form-control ltr"
                :class="{ 'is-invalid': edit_errors.title_en }"
                v-model="edit_data.title_en"
              />
              <span class="error invalid-feedback">{{
                (edit_errors.title_en || []).join("-")
              }}</span>
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-info mt-2"
            :disabled="edit_disabled"
            @click.prevent="updateHelp"
          >
            {{ $t("save") }}
            <b-spinner small v-if="edit_disabled"></b-spinner>
          </button>
        </form>
      </div>
    </b-modal>

    <b-modal
      ref="add_question_modal"
      :title="$t('add') + ' ' + $t('question')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <form class="col-md-12">
          <b-alert
            :show="add_question_error || add_question_success ? true : false"
            :variant="add_question_error ? 'danger' : 'success'"
            dismissible
            @dismissed="add_question_error = add_question_success = ''"
          >
            {{ add_question_error || add_question_success }}
          </b-alert>
          <div class="form-row ">
            <div class="form-group col-md-12">
              <label>{{ $t("question") }} | {{ $t("ar") }}</label>
              <input
                type="text"
                class="form-control rtl"
                :class="{ 'is-invalid': add_question_errors.question_ar }"
                v-model="add_question_data.question_ar"
              />
              <span class="error invalid-feedback">{{
                (add_question_errors.question_ar || []).join("-")
              }}</span>
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("question") }} | {{ $t("en") }}</label>
              <input
                type="text"
                class="form-control ltr"
                :class="{ 'is-invalid': add_question_errors.question_en }"
                v-model="add_question_data.question_en"
              />
              <span class="error invalid-feedback">{{
                (add_question_errors.question_en || []).join("-")
              }}</span>
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("answer") }} | {{ $t("ar") }}</label>
              <textarea
                class="form-control rtl"
                :class="{ 'is-invalid': add_question_errors.answer_ar }"
                v-model="add_question_data.answer_ar"
              ></textarea>
              <span class="error invalid-feedback">{{
                (add_question_errors.answer_ar || []).join("-")
              }}</span>
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("answer") }} | {{ $t("en") }}</label>
              <textarea
                class="form-control ltr"
                :class="{ 'is-invalid': add_question_errors.answer_en }"
                v-model="add_question_data.answer_en"
              ></textarea>
              <span class="error invalid-feedback">{{
                (add_question_errors.answer_en || []).join("-")
              }}</span>
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-info mt-2"
            :disabled="add_question_disabled"
            @click.prevent="addQuestion"
          >
            {{ $t("add") }}
            <b-spinner small v-if="add_question_disabled"></b-spinner>
          </button>
        </form>
      </div>
    </b-modal>

    <b-modal
      ref="edit_question_modal"
      :title="$t('edit') + ' ' + $t('question')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <form class="col-md-12">
          <b-alert
            :show="edit_question_error || edit_question_success ? true : false"
            :variant="edit_question_error ? 'danger' : 'success'"
            dismissible
            @dismissed="edit_question_error = edit_question_success = ''"
          >
            {{ edit_question_error || edit_question_success }}
          </b-alert>
          <div class="form-row ">
            <div class="form-group col-md-12">
              <label>{{ $t("question") }} | {{ $t("ar") }}</label>
              <input
                type="text"
                class="form-control rtl"
                :class="{ 'is-invalid': edit_question_errors.question_ar }"
                v-model="edit_question_data.question_ar"
              />
              <span class="error invalid-feedback">{{
                (edit_question_errors.question_ar || []).join("-")
              }}</span>
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("question") }} | {{ $t("en") }}</label>
              <input
                type="text"
                class="form-control ltr"
                :class="{ 'is-invalid': edit_question_errors.question_en }"
                v-model="edit_question_data.question_en"
              />
              <span class="error invalid-feedback">{{
                (edit_question_errors.question_en || []).join("-")
              }}</span>
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("answer") }} | {{ $t("ar") }}</label>
              <textarea
                class="form-control rtl"
                :class="{ 'is-invalid': edit_question_errors.answer_ar }"
                v-model="edit_question_data.answer_ar"
              ></textarea>
              <span class="error invalid-feedback">{{
                (edit_question_errors.answer_ar || []).join("-")
              }}</span>
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("answer") }} | {{ $t("en") }}</label>
              <textarea
                class="form-control ltr"
                :class="{ 'is-invalid': edit_question_errors.answer_en }"
                v-model="edit_question_data.answer_en"
              ></textarea>
              <span class="error invalid-feedback">{{
                (edit_question_errors.answer_en || []).join("-")
              }}</span>
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-info mt-2"
            :disabled="edit_question_disabled"
            @click.prevent="updateQuestion"
          >
            {{ $t("save") }}
            <b-spinner small v-if="edit_question_disabled"></b-spinner>
          </button>
        </form>
      </div>
    </b-modal>

    <b-modal
      ref="questions_modal"
      :title="$t('questions')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <table
          class="table table-hover questions_table"
          width="100%"
          style="table-layout: fixed;"
        >
          <tr v-for="(question, i) in questions" :key="i">
            <td style="width:85%;">
              <span
                style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;display: block;"
              >
                <i
                  v-if="i > 0"
                  class="fa fa-arrow-up m-1 c_pointer order_arrow"
                  @click="orderQuestions(i, i - 1)"
                ></i>
                <i
                  v-if="i < questions.length - 1 && questions.length > 1"
                  class="fa fa-arrow-down m-1 c_pointer order_arrow"
                  @click="orderQuestions(i, i + 1)"
                ></i>
                {{ i + 1 }}- {{ question["question_" + $i18n.locale] }}
              </span>
            </td>
            <td>
              <button
                class="btn btn-info btn-sm"
                v-tooltip="$t('edit')"
                @click="showEditQuestion(question.id)"
              >
                <i class="fas fa-pencil-alt"> </i>
              </button>
            </td>
            <td>
              <button
                @click="deleteQuestion(question.id)"
                class="btn btn-danger btn-sm"
                v-tooltip="$t('delete')"
              >
                <i class="fas fa-trash"> </i>
              </button>
            </td>
          </tr>
        </table>
      </div>
    </b-modal>

    <b-modal
      ref="customize_table_modal"
      centered
      :title="$t('customize_table')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <div class="row clearfix">
          <div class="col-md-12">
            <div class="form-row" style="padding: 0 20px">
              <div
                class="form-group col-md-6"
                v-for="(field, i) in table_fields"
                :key="i"
              >
                <input
                  type="checkbox"
                  class="form-check-input"
                  aria-label="Checkbox for following text input"
                  :value="field"
                  :id="'f_' + field"
                  v-model="table_selected_fields"
                  style="vertical-align: middle;"
                /><label
                  class="form-check-label"
                  :for="'f_' + field"
                  style="color: blue;"
                >
                  {{ $t(field.toLowerCase()) }}</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <b-tabs
              pills
              card
              no-key-nav
              content-class="custom-card"
              nav-wrapper-class="custom-nav-card"
            >
              <b-tab no-body :title="$t('all')" active>
                <template v-slot:title>
                  <i class="fa fa-list ml-2 mr-2"></i> {{ $t("all") }}
                </template>
                <b-card-text>
                  <b-card no-body class="card">
                    <b-card-header>
                      <div class="card-tools">
                        <div class="input-group input-group-sm">
                          <i
                            class="fas fa-cog c_pointer customize_table"
                            :title="$t('customize_table')"
                            @click="customizeTable"
                          ></i>
                          <input
                            type="text"
                            class="form-control float-right"
                            :placeholder="$t('search')"
                            v-model="search_key"
                          />
                          <div class="input-group-append">
                            <button
                              type="submit"
                              :disabled="search_disabled"
                              @click.prevent="searchAll"
                              class="btn btn-default"
                            >
                              <i
                                class="fa fa-spinner fa-spin"
                                v-if="search_disabled"
                              ></i>
                              <i class="fas fa-search" v-else></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-card-header>
                    <!-- /.card-header -->
                    <b-card-body class="table-responsive p-0">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th v-if="table_selected_fields.includes('#')">
                              #
                            </th>
                            <th v-if="table_selected_fields.includes('image')">
                              {{ $t("image") }}
                            </th>
                            <th
                              class="sort"
                              @click="sortTable('title_' + $i18n.locale)"
                              v-if="table_selected_fields.includes('title')"
                            >
                              {{ $t("title") }}
                            </th>
                            <th>
                              {{ $t("questions") }}
                            </th>
                            <th
                              class="sort"
                              @click="sortTable('created_at')"
                              v-if="
                                table_selected_fields.includes('created_at')
                              "
                            >
                              {{ $t("created_at") }}
                            </th>
                            <th
                              class="sort"
                              @click="sortTable('status')"
                              v-if="table_selected_fields.includes('status')"
                            >
                              {{ $t("status") }}
                            </th>
                            <th
                              v-if="table_selected_fields.includes('actions')"
                            >
                              {{ $t("actions") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              :colspan="table_selected_fields.length"
                              v-if="!load"
                            >
                              <i class="fa fa-sync  fa-spin load-con"></i>
                            </td>
                            <td
                              :colspan="table_selected_fields.length"
                              class="no_data"
                              v-if="load && helps.length == 0"
                            >
                              {{ $t("no_data") }}
                            </td>
                          </tr>
                          <tr v-for="(item, i) in helps" :key="i" v-show="load">
                            <td v-if="table_selected_fields.includes('#')">
                              {{ i + 1 }}
                            </td>
                            <td v-if="table_selected_fields.includes('title')">
                              {{ item["title_" + $i18n.locale] }}
                            </td>
                            <td
                              v-if="table_selected_fields.includes('questions')"
                            >
                              <button
                                class="btn btn-info btn-sm mr-1 ml-1"
                                v-tooltip="$t('add')"
                                @click="showAddQuestion(item.id)"
                              >
                                <i class="fas fa-plus"> </i>
                              </button>
                              <button
                                class="btn btn-success btn-sm mr-1 ml-1"
                                v-tooltip="$t('show')"
                                @click="showQuestions(item.id)"
                              >
                                <i class="fas fa-eye"> </i>
                              </button>
                            </td>

                            <td
                              v-if="
                                table_selected_fields.includes('created_at')
                              "
                              v-html="dateTimeFormat(item.created_at)"
                            ></td>
                            <td
                              class="pb-0"
                              v-if="table_selected_fields.includes('status')"
                            >
                              <label class="switch mr-2 ml-2">
                                <input
                                  type="checkbox"
                                  :disabled="!$can('edit help')"
                                  v-model="item.status"
                                  :true-value="'on'"
                                  :false-value="'off'"
                                  @change="
                                    $can('edit help')
                                      ? changeStatus(item.id)
                                      : false
                                  "
                                />
                                <div class="slider round">
                                  <span class="on">{{ $t("on") }}</span>
                                  <span class="off">{{ $t("off") }}</span>
                                </div>
                              </label>
                            </td>
                            <td
                              class="actions-group"
                              v-if="table_selected_fields.includes('actions')"
                            >
                              <button
                                v-if="$can('edit help')"
                                class="btn btn-info btn-sm"
                                v-tooltip="$t('edit')"
                                @click="showEditHelp(item.id)"
                              >
                                <i class="fas fa-pencil-alt"> </i>
                              </button>
                              <button
                                v-if="$can('delete help')"
                                @click="deleteHelp(item.id)"
                                class="btn btn-danger btn-sm"
                                v-tooltip="$t('delete')"
                              >
                                <i class="fas fa-trash"> </i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-card-body>
                    <!-- /.card-body -->
                    <b-card-footer class="card-footer clearfix">
                      <b-pagination
                        class="m-2"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        :prev-text="$t('previous')"
                        :next-text="$t('next')"
                        align="right"
                        :first-number="true"
                        :last-number="true"
                      ></b-pagination>
                    </b-card-footer>
                  </b-card> </b-card-text
              ></b-tab>
              <b-tab no-body :title="$t('add')" v-if="$can('add help')">
                <template v-slot:title>
                  <i class="fa fa-plus ml-2 mr-2"></i> {{ $t("add") }}
                </template>
                <b-card-text>
                  <b-card no-body>
                    <b-card-body>
                      <form class="col-md-8">
                        <b-alert
                          :show="add_error || add_success ? true : false"
                          :variant="add_error ? 'danger' : 'success'"
                          dismissible
                          @dismissed="add_error = add_success = ''"
                        >
                          {{ add_error || add_success }}
                        </b-alert>
                        <div class="form-row ">
                          <div class="form-group col-md-6">
                            <label>{{ $t("title") }} | {{ $t("ar") }}</label>
                            <input
                              type="text"
                              class="form-control rtl"
                              :class="{ 'is-invalid': add_errors.title_ar }"
                              v-model="add_data.title_ar"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.title_ar || []).join("-")
                            }}</span>
                          </div>
                          <div class="form-group col-md-6">
                            <label>{{ $t("title") }} | {{ $t("en") }}</label>
                            <input
                              type="text"
                              class="form-control ltr"
                              :class="{ 'is-invalid': add_errors.title_en }"
                              v-model="add_data.title_en"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.title_en || []).join("-")
                            }}</span>
                          </div>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-info mt-2"
                          :disabled="add_disabled"
                          @click="addHelp"
                        >
                          {{ $t("add") }}
                          <b-spinner small v-if="add_disabled"></b-spinner>
                        </button>
                      </form>
                    </b-card-body>
                    <!-- /.card-body -->
                  </b-card>
                </b-card-text></b-tab
              >
            </b-tabs>

            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: "Helps",
  data() {
    return {
      load: false,
      helps: [],
      search_key: "",
      old_search_key: "",
      search_disabled: false,
      disabled_s: [],
      currentPage: 1,
      perPage: 1,
      rows: 1,
      add_data: {},
      add_disabled: false,
      add_success: "",
      add_error: "",
      add_errors: {},
      add_question_data: {},
      add_question_disabled: false,
      add_question_success: "",
      add_question_error: "",
      add_question_errors: {},
      edit_question_data: {},
      edit_question_disabled: false,
      edit_question_success: "",
      edit_question_error: "",
      edit_question_errors: {},
      add_progress: 0,
      image_url: "",
      edit_data: {},
      edit_disabled: false,
      edit_success: "",
      edit_error: "",
      edit_errors: {},
      sort_type: "",
      sort_key: "",
      table_fields: [
        "#",
        "title",
        "questions",
        "created_at",
        "status",
        "actions"
      ],
      table_selected_fields: [],
      questions: []
    };
  },
  created() {
    this.table_selected_fields = this.$store.state.help_selected_fields;
    this.getHelps();
  },
  watch: {
    currentPage() {
      this.getHelps();
    },
    table_selected_fields() {
      this.$store.commit("setHelpSelectedFields", this.table_selected_fields);
    },
    "add_data.title_ar"(newVal, oldVal) {
      if (!this.isArabicChars(newVal) && newVal !== oldVal) {
        this.$set(this.add_data, "title_ar", "");
      }
    },
    "edit_data.title_ar"(newVal, oldVal) {
      if (!this.isArabicChars(newVal) && newVal !== oldVal) {
        this.$set(this.edit_data, "title_ar", "");
      }
    },
    "add_question_data.question_ar"(newVal, oldVal) {
      if (!this.isArabicChars(newVal) && newVal !== oldVal) {
        this.$set(this.add_question_data, "question_ar", "");
      }
    },
    "edit_question_data.question_ar"(newVal, oldVal) {
      if (!this.isArabicChars(newVal) && newVal !== oldVal) {
        this.$set(this.edit_question_data, "question_ar", "");
      }
    },
    "add_question_data.answer_ar"(newVal, oldVal) {
      if (!this.isArabicChars(newVal) && newVal !== oldVal) {
        this.$set(this.add_question_data, "answer_ar", "");
      }
    },
    "edit_question_data.answer_ar"(newVal, oldVal) {
      if (!this.isArabicChars(newVal) && newVal !== oldVal) {
        this.$set(this.edit_question_data, "answer_ar", "");
      }
    }
  },
  methods: {
    getHelps() {
      return this.axios
        .get("help", {
          params: {
            page: this.currentPage,
            search_key: this.search_key,
            sort_key: this.sort_key,
            sort_type: this.sort_type
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.helps = res.result.helps.data;
            this.rows = res.result.helps.total;
            this.perPage = res.result.helps.per_page;
            this.image_url = res.result.image_url;
          }
        })
        .catch(error => {
          this.handleError("getHelps", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    sortTable(key) {
      if (this.sort_key != key) {
        this.sort_type = "asc";
      } else {
        this.sort_type = this.sort_type == "asc" ? "desc" : "asc";
      }
      this.sort_key = key;
      this.getHelps();
    },
    searchAll() {
      if (this.search_key != this.old_search_key) {
        this.old_search_key = this.search_key;
        this.search_disabled = true;
        this.getHelps().then(() => {
          this.search_disabled = false;
        });
      }
    },
    addHelp() {
      this.add_disabled = true;
      let fd = new FormData();

      if (this.add_data_icon) {
        fd.append("image_input", this.add_data_icon, this.add_data_icon.name);
      }
      if (this.add_data_icon_icon) {
        fd.append(
          "icon_input",
          this.add_data_icon_icon,
          this.add_data_icon_icon.name
        );
      }
      Object.keys(this.add_data).forEach(key => {
        fd.append(key, this.add_data[key]);
      });
      this.add_error = this.add_success = "";
      this.add_errors = {};
      this.axios
        .post("help/add", fd)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.add_success = res.message;
            this.getHelps();
            this.add_data = {};
            this.add_data_image = null;
            this.add_data_icon = null;
            this.add_data_icon_image = null;
            this.add_data_icon_icon = null;
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.add_errors = res.message;
            } else {
              this.add_error = res.message;
            }
          } else {
            this.handleError("addHelp", res.message);
          }
        })
        .catch(error => {
          this.handleError("addHelp", error);
        })
        .finally(() => {
          this.add_disabled = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    updateHelp() {
      this.edit_disabled = true;
      let fd = new FormData();

      Object.keys(this.edit_data).forEach(key => {
        if (this.edit_data[key]) {
          fd.append(key, this.edit_data[key]);
        }
      });
      this.edit_error = this.edit_success = "";

      this.axios
        .post("help/update", fd)
        .then(response => {
          let res = response.data;
          this.edit_errors = {};
          if (res.error_flag == 0) {
            this.edit_success = res.message;
            this.edit_data = { ...res.result.help };
            let i = this.helps.findIndex(e => e.id == this.edit_data.id);
            this.$set(this.helps, i, res.result.help);
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.edit_errors = res.message;
            } else {
              this.edit_error = res.message;
            }
          } else {
            this.handleError("updateHelp", res.message);
          }
        })
        .catch(error => {
          this.handleError("updateHelp", error);
        })
        .finally(() => {
          this.edit_disabled = false;
        });
    },
    changeStatus(id) {
      let index = this.findIndex(this.helps, "id", id);
      let i = this.findIndex(this.helps, "id", id);
      this.$set(this.disabled_s, i, true);
      this.axios
        .post("help/update_status/" + id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.$noty.success(res.message);
            this.helps[index].status = res.result.status;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("changeStatus", res.message);
          }
        })
        .catch(error => {
          this.handleError("chnageStatus", error);
        })
        .finally(() => {
          this.$set(this.disabled_s, i, false);
        });
    },
    deleteHelp(id) {
      let index = this.findIndex(this.helps, "id", id);

      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("help/delete/" + id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.helps.splice(index, 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deleteHelp", res.message);
                }
              })
              .catch(error => {
                this.handleError("deleteHelp", error);
              });
          }
        });
    },
    showEditHelp(id) {
      this.edit_success = this.edit_error = "";
      this.edit_errors = {};
      this.edit_data = { ...this.helps.find(e => e.id == id) };
      this.$refs.edit_Help_modal.show();
    },
    showAddQuestion(id) {
      this.add_question_success = this.add_question_error = "";
      this.add_question_errors = {};
      this.$set(this.add_question_data, "help_id", id);
      this.$refs.add_question_modal.show();
    },
    addQuestion() {
      this.add_question_disabled = true;
      this.add_question_error = this.add_question_success = "";
      this.axios
        .post("help/add_question", this.add_question_data)
        .then(response => {
          this.add_question_errors = {};
          let res = response.data;
          if (res.error_flag == 0) {
            this.add_question_success = res.message;
            this.add_question_data = {
              help_id: this.add_question_data.help_id
            };
            this.helps
              .find(e => e.id == this.add_question_data.help_id)
              .questions.push({ ...res.result.help_question });
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.add_question_errors = res.message;
            } else {
              this.add_question_error = res.message;
            }
          } else {
            this.handleError("addQuestion", res.message);
          }
        })
        .catch(error => {
          this.handleError("addQuestion", error);
        })
        .finally(() => {
          this.add_question_disabled = false;
        });
    },
    showQuestions(id) {
      this.questions = this.helps.find(e => e.id == id).questions;
      this.$refs.questions_modal.show();
    },
    showEditQuestion(id) {
      this.edit_question_success = this.edit_question_error = "";
      this.edit_question_errors = {};
      this.edit_question_data = { ...this.questions.find(e => e.id == id) };
      this.$refs.edit_question_modal.show();
    },
    updateQuestion() {
      this.edit_question_disabled = true;
      this.edit_question_error = this.edit_question_success = "";
      this.axios
        .post("help/update_question", this.edit_question_data)
        .then(response => {
          this.edit_question_errors = {};
          let res = response.data;
          if (res.error_flag == 0) {
            this.edit_question_success = res.message;
            this.$set(
              this.questions,
              this.questions.findIndex(e => e.id == this.edit_question_data.id),
              res.result.help_question
            );
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.edit_question_errors = res.message;
            } else {
              this.edit_question_error = res.message;
            }
          } else {
            this.handleError("updateQuestion", res.message);
          }
        })
        .catch(error => {
          this.handleError("updateQuestion", error);
        })
        .finally(() => {
          this.edit_question_disabled = false;
        });
    },
    deleteQuestion(id) {
      let index = this.findIndex(this.questions, "id", id);
      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("help/delete_question/" + id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.questions.splice(index, 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deleteQuestion", res.message);
                }
              })
              .catch(error => {
                this.handleError("deleteQuestion", error);
              });
          }
        });
    },
    orderQuestions(index_1, index_2) {
      let id_1 = this.questions[index_1].id;
      let id_2 = this.questions[index_2].id;
      this.axios
        .post("help/orderQuestions", {
          id_1: id_1,
          id_2: id_2
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            var tmp = this.questions[index_1];
            this.$set(this.questions, index_1, this.questions[index_2]);
            this.$set(this.questions, index_2, tmp);
          } else {
            this.handleError("orderQuestions", res.message);
          }
        })
        .catch(error => {
          this.handleError("orderQuestions", error);
        });
    }
  }
};
</script>

<style scoped>
.questions_table tr:first-child td {
  border: none;
}
</style>
