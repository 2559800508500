<template>
  <div>
    <br />
    <div class="load-content" v-if="load">
      <b-spinner variant="primary"></b-spinner>
    </div>
    <!-- Main content -->
    <section class="content" v-else>
      <div class="container-fluid">
            Hoooooome
        <!------------- end admin section ---------------->
      </div>

      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      load: false,
      dashboard: {},
      top_products: [],
      products_image_url: "",
      recent_vendors: [],
      recent_products: [],
      recent_orders: [],
      recent_users: [],
      enable_top_shops_chart: false,

    };
  },
  created() {
   //this.getDashboard();
  },
  watch: {

  },
  methods: {
    getDashboard() {

    },
    copyThis(text) {
      this.$copyText(text).then(
        () => {
          this.$noty.success(this.$t("copied"));
        },
        function() {
          this.$noty.warning(this.$t("error"));
        }
      );
    }
  }
};
</script>
