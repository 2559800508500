<template>
  <div>
    <b-modal
      ref="customize_table_modal"
      centered
      :title="$t('customize_table')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <div class="row clearfix">
          <div class="col-md-12">
            <div class="form-row" style="padding: 0 20px">
              <div
                class="form-group col-md-6"
                v-for="(field, i) in table_fields"
                :key="i"
              >
                <input
                  type="checkbox"
                  class="form-check-input"
                  aria-label="Checkbox for following text input"
                  :value="field"
                  :id="'f_' + field"
                  v-model="table_selected_fields"
                  style="vertical-align: middle;"
                /><label
                  class="form-check-label"
                  :for="'f_' + field"
                  style="color: blue;"
                >
                  {{ $t(field.toLowerCase()) }}</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="contact_modal"
      :title="$t('contact_2') + ' #' + contact.id"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <div class="col-md-12 contact_det">
          <div class="form-row" v-if="contact.id">
            <div class="form-group col-md-12">
              <label>{{ $t("name") }}</label>

              {{ contact.name }}
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("mobile") }}</label>
              {{ contact.mobile }}
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("email") }}</label>
              {{ contact.email }}
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("subject") }}</label>
              {{ contact.subject }}
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("source") }}</label>
              {{ contact.msg_source }}
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("msg") }}</label>
              <pre
                class="p-0 m-0"
              ><p style="font-family:cairo">{{ contact.msg.trim()}}</p></pre>
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("date") }}</label>
              <span v-html="dateTimeFormat(contact.created_at)"></span>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <br />

            <b-card no-body class="card">
              <b-card-header>
                <div class="card-tools">
                  <div class="input-group input-group-sm">
                    <i
                      class="fas fa-cog c_pointer customize_table"
                      :title="$t('customize_table')"
                      @click="customizeTable"
                    ></i>
                    <input
                      type="text"
                      class="form-control float-right"
                      :placeholder="$t('search')"
                      v-model="search_key"
                    />
                    <div class="input-group-append">
                      <button
                        type="submit"
                        :disabled="search_disabled"
                        @click.prevent="searchAll"
                        class="btn btn-default"
                      >
                        <i
                          class="fa fa-spinner fa-spin"
                          v-if="search_disabled"
                        ></i>
                        <i class="fas fa-search" v-else></i>
                      </button>
                    </div>
                  </div>
                </div>
              </b-card-header>
              <!-- /.card-header -->
              <b-card-body class="table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th v-if="table_selected_fields.includes('#')">
                        #
                      </th>
                      <th
                        v-if="table_selected_fields.includes('name')"
                        class="sort"
                        @click="sortTable('name')"
                      >
                        {{ $t("name") }}
                      </th>
                      <th
                        v-if="table_selected_fields.includes('mobile')"
                        class="sort"
                        @click="sortTable('mobile')"
                      >
                        {{ $t("mobile") }}
                      </th>
                      <th
                        v-if="table_selected_fields.includes('email')"
                        class="sort"
                        @click="sortTable('email')"
                      >
                        {{ $t("email") }}
                      </th>
                      <th
                        v-if="table_selected_fields.includes('subject')"
                        class="sort"
                        @click="sortTable('subject')"
                      >
                        {{ $t("subject") }}
                      </th>
                      <th
                        v-if="table_selected_fields.includes('source')"
                        class="sort"
                        @click="sortTable('msg_source')"
                      >
                        {{ $t("source") }}
                      </th>
                      <th
                        v-if="table_selected_fields.includes('status')"
                        class="sort"
                        @click="sortTable('is_read')"
                      >
                        {{ $t("status") }}
                      </th>

                      <th
                        v-if="table_selected_fields.includes('created_at')"
                        class="sort"
                        @click="sortTable('created_at')"
                      >
                        {{ $t("created_at") }}
                      </th>
                      <th v-if="table_selected_fields.includes('actions')">
                        {{ $t("actions") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td :colspan="table_selected_fields.length" v-if="!load">
                        <i class="fa fa-sync  fa-spin load-con"></i>
                      </td>
                      <td
                        :colspan="table_selected_fields.length"
                        class="no_data"
                        v-if="load && contacts.length == 0"
                      >
                        {{ $t("no_data") }}
                      </td>
                    </tr>
                    <tr v-for="(item, i) in contacts" :key="i" v-show="load">
                      <td v-if="table_selected_fields.includes('#')">
                        {{ i + 1 }}
                      </td>

                      <td v-if="table_selected_fields.includes('name')">
                        {{ item.name }}
                      </td>
                      <td v-if="table_selected_fields.includes('mobile')">
                        {{ item.mobile }}
                      </td>
                      <td v-if="table_selected_fields.includes('email')">
                        {{ item.email }}
                      </td>
                      <td v-if="table_selected_fields.includes('subject')">
                        {{ item.subject }}
                      </td>
                      <td v-if="table_selected_fields.includes('source')">
                        {{ item.msg_source }}
                      </td>
                      <td v-if="table_selected_fields.includes('status')">
                        <span class="badge badge-info" v-if="item.is_read">{{
                          $t("is_read")
                        }}</span>
                        <span class="badge badge-danger" v-else>{{
                          $t("is_new")
                        }}</span>
                      </td>

                      <td
                        v-if="table_selected_fields.includes('created_at')"
                        v-html="dateTimeFormat(item.created_at)"
                      ></td>
                      <td
                        class="actions-group"
                        v-if="table_selected_fields.includes('actions')"
                      >
                        <button
                          v-if="$can('show contacts')"
                          class="btn btn-info btn-sm"
                          v-tooltip="$t('show')"
                          @click="showContact(item.id)"
                        >
                          <i class="fas fa-eye"> </i>
                        </button>
                        <button
                          v-if="$can('delete contacts')"
                          @click="deleteContact(item.id)"
                          class="btn btn-danger btn-sm"
                          v-tooltip="$t('delete')"
                        >
                          <i class="fas fa-trash"> </i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-card-body>
              <!-- /.card-body -->
              <b-card-footer class="card-footer clearfix">
                <b-pagination
                  class="m-2"
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  :prev-text="$t('previous')"
                  :next-text="$t('next')"
                  align="right"
                  :first-number="true"
                  :last-number="true"
                ></b-pagination>
              </b-card-footer>
            </b-card>

            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: "Contacts",
  data() {
    return {
      load: false,
      contacts: [],
      search_key: "",
      old_search_key: "",
      search_disabled: false,
      disabled_s: [],
      currentPage: 1,
      perPage: 1,
      rows: 0,
      add_data: {},
      add_disabled: false,
      add_success: "",
      add_error: "",
      add_errors: {},
      add_progress: 0,
      contact: {},
      edit_disabled: false,
      edit_success: "",
      edit_error: "",
      edit_errors: {},
      sort_key: "",
      sort_type: "asc",
      table_fields: [
        "#",
        "name",
        "mobile",
        "email",
        "subject",
        "source",
        "created_at",
        "status",
        "actions"
      ],
      table_selected_fields: []
    };
  },
  created() {
    this.table_selected_fields = this.$store.state.contacts_selected_fields;
    this.getContacts();
  },
  watch: {
    currentPage() {
      this.getContacts();
    },
    table_selected_fields() {
      this.$store.commit(
        "setContactsSelectedFields",
        this.table_selected_fields
      );
    }
  },
  methods: {
    getContacts() {
      return this.axios
        .get("contacts", {
          params: {
            page: this.currentPage,
            search_key: this.search_key,
            sort_key: this.sort_key,
            sort_type: this.sort_type
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.contacts = res.result.contacts.data;
            this.rows = res.result.contacts.total;
            this.perPage = res.result.contacts.per_page;
          }
        })
        .catch(error => {
          this.handleError("getContacts", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    sortTable(key) {
      if (this.sort_key != key) {
        this.sort_type = "asc";
      } else {
        this.sort_type = this.sort_type == "asc" ? "desc" : "asc";
      }
      this.sort_key = key;
      this.getContacts();
    },
    searchAll() {
      if (this.search_key != this.old_search_key) {
        this.old_search_key = this.search_key;
        this.search_disabled = true;
        this.getContacts().then(() => {
          this.search_disabled = false;
        });
      }
    },
    deleteContact(id) {
      let index = this.findIndex(this.contacts, "id", id);

      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("contacts/delete/" + id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.contacts.splice(index, 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deleteContact", res.message);
                }
              })
              .catch(error => {
                this.handleError("deleteContact", error);
              });
          }
        });
    },
    changeStatus(id) {
      let index = this.findIndex(this.contacts, "id", id);
      this.axios
        .post("contacts/update_status/" + id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.contacts[index].is_read = 1;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("changeStatus", res.message);
          }
        })
        .catch(error => {
          this.handleError("chnageStatus", error);
        })
        .finally(() => {});
    },
    showContact(id) {
      this.contact = { ...this.contacts.find(e => e.id == id) };
      if (!this.contact.is_read) {
        this.changeStatus(id);
      }
      this.$refs.contact_modal.show();
    }
  }
};
</script>
