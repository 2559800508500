<template>
  <div>
    <CoolLightBox
      :items="album_items"
      :index="album_index"
      @close="album_index = null"
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
    >
    </CoolLightBox>
    <b-modal
      ref="customize_table_modal"
      centered
      :title="$t('customize_table')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <div class="row clearfix">
          <div class="col-md-12">
            <div class="form-row" style="padding: 0 20px">
              <div
                class="form-group col-md-6"
                v-for="(field, i) in table_fields"
                :key="i"
              >
                <input
                  type="checkbox"
                  class="form-check-input"
                  aria-label="Checkbox for following text input"
                  :value="field"
                  :id="'f_' + field"
                  v-model="table_selected_fields"
                  style="vertical-align: middle;"
                /><label
                  class="form-check-label"
                  :for="'f_' + field"
                  style="color: blue;"
                >
                  {{ $t(field.toLowerCase()) }}</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <div class="load-content" v-if="!load">
      <b-spinner variant="primary"></b-spinner>
    </div>

    <section class="content-header" v-if="load">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h1>{{ vendor["name"] }}</h1>
          </div>
        </div>
      </div>
    </section>

    <!-- Main content -->
    <section class="content" v-if="load">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <b-tabs
              pills
              card
              no-key-nav
              content-class="custom-card"
              nav-wrapper-class="custom-nav-card"
            >
              <b-tab no-body :title="$t('all')" active>
                <template v-slot:title>
                  <i class="fa fa-list ml-2 mr-2"></i> {{ $t("all") }}
                </template>
                <b-card-text>
                  <b-card no-body class="card">
                    <b-card-header>
                      <div class="table_filters">
                        <label>{{ $t("filtering") }}:</label>
                        <select class="form-control" v-model="filters.store">
                          <option value="">{{ $t("store") }}</option>
                          <option
                            v-for="(store, i) in stores"
                            :key="i"
                            :value="store.id"
                            >{{ store["store_name"] }}</option
                          >
                        </select>
                        <select class="form-control" v-model="filters.category">
                          <option value="">{{ $t("category") }}</option>
                          <option
                            v-for="(cat, i) in categories"
                            :key="i"
                            :value="cat.id"
                            >{{ cat["name_" + $i18n.locale] }}</option
                          >
                        </select>
                        <button
                          class="btn btn-info"
                          :disabled="filtering_load"
                          @click="applyFilters"
                        >
                          {{ $t("apply") }}
                          <i
                            class="fa fa-spinner fa-spin"
                            v-if="filtering_load"
                          ></i>
                        </button>
                      </div>
                      <div class="card-tools">
                        <div class="input-group input-group-sm">
                          <i
                            class="fas fa-cog c_pointer customize_table"
                            :title="$t('customize_table')"
                            @click="customizeTable"
                          ></i>
                          <input
                            type="text"
                            class="form-control float-right"
                            :placeholder="$t('search')"
                            v-model="search_key"
                          />
                          <div class="input-group-append">
                            <button
                              type="submit"
                              :disabled="search_disabled"
                              @click.prevent="searchAll"
                              class="btn btn-default"
                            >
                              <i
                                class="fa fa-spinner fa-spin"
                                v-if="search_disabled"
                              ></i>
                              <i class="fas fa-search" v-else></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-card-header>
                    <!-- /.card-header -->
                    <b-card-body class="table-responsive p-0">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th v-if="table_selected_fields.includes('#')">
                              #
                            </th>
                            <th v-if="table_selected_fields.includes('image')">
                              {{ $t("image") }}
                            </th>
                            <th
                              v-if="table_selected_fields.includes('name')"
                              class="sort"
                              @click="sortTable('name_' + $i18n.locale)"
                            >
                              {{ $t("name") }}
                            </th>
                            <th
                              v-if="table_selected_fields.includes('store')"
                              class="sort"
                              @click="sortTable('store_id')"
                            >
                              {{ $t("store") }}
                            </th>
                            <th
                              v-if="table_selected_fields.includes('category')"
                              class="sort"
                              @click="sortTable('category_id')"
                            >
                              {{ $t("categories") }}
                            </th>
                            <th
                              v-if="
                                table_selected_fields.includes('sub_category')
                              "
                              class="sort"
                              @click="sortTable('sub_category_id')"
                            >
                              {{ $t("sub_categories") }}
                            </th>
                            <th
                              v-if="table_selected_fields.includes('price')"
                              class="sort"
                              @click="sortTable('price')"
                            >
                              {{ $t("price") }}
                            </th>
                            <th
                              v-if="
                                table_selected_fields.includes('prepared_time')
                              "
                              class="sort"
                              @click="sortTable('prepared_time')"
                            >
                              {{ $t("prepared_time") }}
                            </th>
                            <th
                              v-if="table_selected_fields.includes('qty')"
                              class="sort"
                              @click="sortTable('qty')"
                            >
                              {{ $t("quantity") }}
                            </th>
                            <th
                              v-if="
                                table_selected_fields.includes('created_at')
                              "
                              class="sort"
                              @click="sortTable('created_at')"
                            >
                              {{ $t("created_at") }}
                            </th>
                            <th
                              v-if="table_selected_fields.includes('in_stock')"
                              class="sort"
                              @click="sortTable('in_stock')"
                            >
                              {{ $t("in_stock") }}
                            </th>
                            <th
                              v-if="table_selected_fields.includes('status')"
                              class="sort"
                              @click="sortTable('status')"
                            >
                              {{ $t("status") }}
                            </th>
                            <th
                              v-if="table_selected_fields.includes('actions')"
                            >
                              {{ $t("actions") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              :colspan="table_selected_fields.length"
                              v-if="!load"
                            >
                              <i class="fa fa-sync  fa-spin load-con"></i>
                            </td>
                            <td
                              :colspan="table_selected_fields.length"
                              class="no_data"
                              v-if="load && products.length == 0"
                            >
                              {{ $t("no_data") }}
                            </td>
                          </tr>
                          <tr
                            v-for="(item, i) in products"
                            :key="i"
                            v-show="load"
                          >
                            <td v-if="table_selected_fields.includes('#')">
                              {{ i + 1 }}
                            </td>
                            <td v-if="table_selected_fields.includes('image')">
                              <img
                                @click="showProductAlbum(item.id)"
                                :src="image_url + '/' + item.image"
                                @error="
                                  $event.target.src = '/images/placeholder.jpg'
                                "
                              />
                            </td>
                            <td v-if="table_selected_fields.includes('name')">
                              {{ item["name_" + $i18n.locale] }}
                            </td>
                            <td v-if="table_selected_fields.includes('store')">
                              {{ (item.store || {})["store_name"] }}
                            </td>
                            <td
                              v-if="table_selected_fields.includes('category')"
                            >
                              {{
                                (item.categories || [])
                                  .map(e => e["name_" + $i18n.locale])
                                  .join("-")
                              }}
                            </td>
                            <td
                              v-if="
                                table_selected_fields.includes('sub_category')
                              "
                            >
                              {{
                                (item.sub_categories || [])
                                  .map(e => e["name_" + $i18n.locale])
                                  .join("-") || "-"
                              }}
                            </td>
                            <td v-if="table_selected_fields.includes('price')">
                              <span v-if="item.discount_value">
                                <strike class="ml-1"
                                  >{{ item["price"] }}
                                </strike>
                                <b v-if="item.discount_type == 'percentage'">
                                  {{
                                    (
                                      item.price -
                                      (item.discount_value / 100) * item.price
                                    ).toFixed(2)
                                  }}
                                </b>
                                <b v-if="item.discount_type == 'fixed'">
                                  {{ item.price - item.discount_value }}
                                </b>
                              </span>
                              <span v-else>
                                {{ item["price"] }}
                              </span>
                            </td>
                            <td
                              v-if="
                                table_selected_fields.includes('prepared_time')
                              "
                            >
                              {{ item.prepared_time || "-" }} {{ $t("hour") }}
                            </td>
                            <td v-if="table_selected_fields.includes('qty')">
                              {{ item.qty }}
                            </td>

                            <td
                              v-if="
                                table_selected_fields.includes('created_at')
                              "
                              v-html="dateTimeFormat(item.created_at)"
                            ></td>
                            <td
                              class="pb-0"
                              v-if="table_selected_fields.includes('in_stock')"
                            >
                              <label class="switch mr-2 ml-2">
                                <input
                                  type="checkbox"
                                  :disabled="!$can('edit products')"
                                  v-model="item.in_stock"
                                  :true-value="'1'"
                                  :false-value="'0'"
                                  @change="
                                    $can('edit products')
                                      ? changeInStock(item.id)
                                      : false
                                  "
                                />
                                <div class="slider round y_n_switch">
                                  <span class="on">{{ $t("yes") }}</span>
                                  <span class="off">{{ $t("no") }}</span>
                                </div>
                              </label>
                            </td>
                            <td v-if="table_selected_fields.includes('status')">
                              <span
                                v-if="item.status == 'pending'"
                                class="badge badge-info"
                                >{{ $t(item.status) }}
                              </span>

                              <label class="switch mr-2 ml-2" v-else>
                                <input
                                  type="checkbox"
                                  :disabled="!$can('edit products')"
                                  v-model="item.status"
                                  :true-value="'on'"
                                  :false-value="'off'"
                                  @change="
                                    $can('edit products')
                                      ? changeStatus(item.id)
                                      : false
                                  "
                                />
                                <div class="slider round">
                                  <span class="on">{{ $t("on") }}</span>
                                  <span class="off">{{ $t("off") }}</span>
                                </div>
                              </label>
                            </td>
                            <td
                              class="actions-group"
                              v-if="table_selected_fields.includes('actions')"
                            >
                              <button
                                v-if="
                                  $can('edit products') &&
                                    item.status == 'pending'
                                "
                                class="btn btn-primary btn-sm"
                                v-tooltip="$t('accept')"
                                @click="acceptProduct(item.id)"
                              >
                                <b-spinner small v-if="disabled_s[i]">
                                </b-spinner>
                                <i class="fas fa-check" v-else></i>
                              </button>

                              <router-link
                                v-if="$can('edit products')"
                                :to="'/product/' + item.id"
                                class="btn btn-info btn-sm"
                                v-tooltip="$t('edit')"
                              >
                                <i class="fas fa-pencil-alt"> </i>
                              </router-link>
                              <button
                                v-if="$can('delete products')"
                                @click="deleteProduct(item.id)"
                                class="btn btn-danger btn-sm"
                                v-tooltip="$t('delete')"
                              >
                                <i class="fas fa-trash"> </i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-card-body>
                    <!-- /.card-body -->
                    <b-card-footer class="card-footer clearfix">
                      <b-pagination
                        class="m-2"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        :prev-text="$t('previous')"
                        :next-text="$t('next')"
                        align="right"
                        :first-number="true"
                        :last-number="true"
                      ></b-pagination>
                    </b-card-footer>
                  </b-card> </b-card-text
              ></b-tab>
              <b-tab no-body :title="$t('add')" v-if="$can('add products')">
                <template v-slot:title>
                  <i class="fa fa-plus ml-2 mr-2"></i> {{ $t("add") }}
                </template>
                <b-card-text>
                  <b-card no-body>
                    <b-card-body>
                      <form class="col-md-8">
                        <b-alert
                          :show="add_error || add_success ? true : false"
                          :variant="add_error ? 'danger' : 'success'"
                          dismissible
                          @dismissed="add_error = add_success = ''"
                        >
                          {{ add_error || add_success }}
                        </b-alert>

                        <div>
                          <b-tabs content-class="mt-3" no-key-nav>
                            <b-tab
                              :title="$t('info')"
                              active
                              :title-link-class="
                                (Object.keys(add_errors) || []).some(e =>
                                  [
                                    'image_input',
                                    'name_ar',
                                    'name_en',
                                    'desc_ar',
                                    'desc_en',
                                    'category_id',
                                    'size_id',
                                    'sku',
                                    'qty',
                                    'price',
                                    'prepared_time',
                                    'weight',
                                    'dimension_id'
                                  ].includes(e)
                                )
                                  ? 'invalid-tab'
                                  : ''
                              "
                            >
                              <div class="form-row ">
                                <div class="form-group col-md-12">
                                  <label>{{ $t("image") }}</label>

                                  <div class="image_div" v-if="add_data_image">
                                    <img :src="add_data_image" />
                                    <i
                                      class="fa fa-times"
                                      @click.prevent="
                                        add_data_image = null;
                                        add_data_icon = null;
                                      "
                                    ></i>
                                  </div>
                                  <b-form-file
                                    v-else
                                    :placeholder="$t('choose_file')"
                                    accept="image/*"
                                    @change.prevent="
                                      onImageChange($event, 'add_data')
                                    "
                                    ref="add_data_icon"
                                  ></b-form-file>
                                  <span
                                    class="error invalid-feedback d-block"
                                    >{{
                                      (add_errors.image_input || []).join("-")
                                    }}</span
                                  >
                                </div>

                                <div class="form-group col-md-12">
                                  <label>{{ $t("store") }}</label>
                                  <select
                                    class="form-control custom-select"
                                    :class="{
                                      'is-invalid': add_errors.store_id
                                    }"
                                    v-model="add_data.store_id"
                                  >
                                    <option selected="" disabled="" value="">{{
                                      $t("select")
                                    }}</option>
                                    <option
                                      v-for="(store, i) in stores"
                                      :key="i"
                                      :value="store.id"
                                      >{{ store["store_name"] }}</option
                                    >
                                  </select>
                                  <span class="error invalid-feedback">{{
                                    (add_errors.store_id || []).join("-")
                                  }}</span>
                                </div>

                                <div class="form-group col-md-6">
                                  <label
                                    >{{ $t("name") }} | {{ $t("ar") }}</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control rtl"
                                    :class="{
                                      'is-invalid': add_errors.name_ar
                                    }"
                                    v-model="add_data.name_ar"
                                  />
                                  <span class="error invalid-feedback">{{
                                    (add_errors.name_ar || []).join("-")
                                  }}</span>
                                </div>
                                <div class="form-group col-md-6">
                                  <label
                                    >{{ $t("name") }} | {{ $t("en") }}</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control ltr"
                                    :class="{
                                      'is-invalid': add_errors.name_en
                                    }"
                                    v-model="add_data.name_en"
                                  />
                                  <span class="error invalid-feedback">{{
                                    (add_errors.name_en || []).join("-")
                                  }}</span>
                                </div>
                                <div class="form-group col-md-6">
                                  <label
                                    >{{ $t("desc") }} | {{ $t("ar") }}</label
                                  >
                                  <textarea
                                    class="form-control rtl"
                                    :class="{
                                      'is-invalid': add_errors.desc_ar
                                    }"
                                    rows="4"
                                    v-model="add_data.desc_ar"
                                  ></textarea>
                                  <span class="error invalid-feedback">{{
                                    (add_errors.desc_ar || []).join("-")
                                  }}</span>
                                </div>

                                <div class="form-group col-md-6">
                                  <label
                                    >{{ $t("desc") }} | {{ $t("en") }}</label
                                  >
                                  <textarea
                                    class="form-control ltr"
                                    :class="{
                                      'is-invalid': add_errors.desc_en
                                    }"
                                    rows="4"
                                    v-model="add_data.desc_en"
                                  ></textarea>
                                  <span class="error invalid-feedback">{{
                                    (add_errors.desc_en || []).join("-")
                                  }}</span>
                                </div>

                                <div class="form-group col-md-6">
                                  <label>{{ $t("categories") }}</label>
                                  <multiselect
                                    selectLabel=""
                                    selectedLabel=""
                                    deselectLabel=""
                                    v-model="add_data.categories_ids"
                                    :placeholder="
                                      $t('select') + ' ' + $t('categories')
                                    "
                                    :multiple="true"
                                    :searchable="true"
                                    :internal-search="true"
                                    :options-limit="300"
                                    :show-no-results="false"
                                    :hide-selected="true"
                                    :options="categories"
                                    track-by="id"
                                    :label="'name_' + $i18n.locale"
                                    :custom-label="
                                      option => {
                                        return option['name_' + $i18n.locale];
                                      }
                                    "
                                  >
                                  </multiselect>
                                  <span
                                    class="error invalid-feedback d-block"
                                    >{{
                                      (add_errors.categories_ids || []).join(
                                        "-"
                                      )
                                    }}</span
                                  >
                                </div>

                                <div class="form-group col-md-6">
                                  <label>{{ $t("sub_categories") }}</label>
                                  <multiselect
                                    selectLabel=""
                                    selectedLabel=""
                                    deselectLabel=""
                                    v-model="add_data.sub_categories_ids"
                                    :placeholder="
                                      $t('select') + ' ' + $t('sub_categories')
                                    "
                                    :multiple="true"
                                    :searchable="true"
                                    :internal-search="true"
                                    :options-limit="300"
                                    :show-no-results="false"
                                    :hide-selected="true"
                                    :group-select="true"
                                    :options="sub_categories"
                                    group-values="sub_categories"
                                    group-label="category"
                                    track-by="id"
                                    :label="'name_' + $i18n.locale"
                                    :custom-label="
                                      option => {
                                        return option['name_' + $i18n.locale];
                                      }
                                    "
                                  >
                                  </multiselect>
                                  <span
                                    class="error invalid-feedback d-block"
                                    >{{
                                      (
                                        add_errors.sub_categories_ids || []
                                      ).join("-")
                                    }}</span
                                  >
                                </div>

                                <div class="form-group col-md-6">
                                  <label>{{ $t("sku") }}</label>
                                  <input
                                    type="text"
                                    class="form-control ltr"
                                    :class="{ 'is-invalid': add_errors.sku }"
                                    v-model="add_data.sku"
                                  />
                                  <span class="error invalid-feedback">{{
                                    (add_errors.sku || []).join("-")
                                  }}</span>
                                </div>
                                <div class="form-group col-md-6">
                                  <label>{{ $t("quantity") }}</label>
                                  <input
                                    type="number"
                                    min="0"
                                    class="form-control ltr"
                                    :class="{ 'is-invalid': add_errors.qty }"
                                    v-model="add_data.qty"
                                  />
                                  <span class="error invalid-feedback">{{
                                    (add_errors.qty || []).join("-")
                                  }}</span>
                                </div>
                                <div class="form-group col-md-6">
                                  <label
                                    >{{ $t("price") }} ({{ $t("sar") }})</label
                                  >
                                  <input
                                    type="number"
                                    min="0"
                                    class="form-control ltr"
                                    :class="{ 'is-invalid': add_errors.price }"
                                    v-model="add_data.price"
                                  />
                                  <span class="error invalid-feedback">{{
                                    (add_errors.price || []).join("-")
                                  }}</span>
                                </div>
                                <div class="form-group col-md-6">
                                  <label
                                    >{{ $t("prepared_time") }} ({{
                                      $t("hour")
                                    }})</label
                                  >
                                  <input
                                    type="number"
                                    min="0"
                                    class="form-control ltr"
                                    :class="{
                                      'is-invalid': add_errors.prepared_time
                                    }"
                                    v-model="add_data.prepared_time"
                                  />
                                  <span class="error invalid-feedback">{{
                                    (add_errors.prepared_time || []).join("-")
                                  }}</span>
                                </div>
                              </div>
                            </b-tab>
                            <b-tab
                              :title="$t('discount')"
                              :title-link-class="
                                (Object.keys(add_errors) || []).some(e =>
                                  [
                                    'discount_type',
                                    'discount_value',
                                    'discount_start',
                                    'discount_end'
                                  ].includes(e)
                                )
                                  ? 'invalid-tab'
                                  : ''
                              "
                            >
                              <div class="form-row col-md-12">
                                <div class="form-group col-md-12">
                                  <label>{{ $t("discount_type") }}</label>
                                  <select
                                    class="form-control custom-select"
                                    :class="{
                                      'is-invalid': add_errors.discount_type
                                    }"
                                    v-model="add_data.discount_type"
                                  >
                                    <option value="percentage">{{
                                      $t("percentage")
                                    }}</option>
                                    <option value="fixed">{{
                                      $t("fixed_value")
                                    }}</option>
                                  </select>
                                  <span class="error invalid-feedback">{{
                                    (add_errors.discount_type || []).join("-")
                                  }}</span>
                                </div>

                                <div class="form-group col-md-12">
                                  <label>{{ $t("discount_value") }}</label>
                                  <input
                                    type="number"
                                    min="0"
                                    :max="
                                      add_data.discount_type == 'percentage'
                                        ? 99
                                        : add_data.price
                                    "
                                    class="form-control ltr"
                                    :class="{
                                      'is-invalid': add_errors.discount_value
                                    }"
                                    v-model="add_data.discount_value"
                                  />
                                  <span class="error invalid-feedback">{{
                                    (add_errors.discount_value || []).join("-")
                                  }}</span>
                                </div>
                                <div class="form-group col-md-6">
                                  <label>{{ $t("discount_start") }}</label>
                                  <input
                                    type="date"
                                    class="form-control"
                                    :class="{
                                      'is-invalid': add_errors.discount_start
                                    }"
                                    v-model="add_data.discount_start"
                                  />
                                  <span class="error invalid-feedback">{{
                                    (add_errors.discount_start || []).join("-")
                                  }}</span>
                                </div>
                                <div class="form-group col-md-6">
                                  <label>{{ $t("discount_end") }}</label>
                                  <input
                                    type="date"
                                    class="form-control"
                                    :class="{
                                      'is-invalid': add_errors.discount_end
                                    }"
                                    v-model="add_data.discount_end"
                                  />
                                  <span class="error invalid-feedback">{{
                                    (add_errors.discount_end || []).join("-")
                                  }}</span>
                                </div>
                              </div>
                            </b-tab>

                            <b-tab
                              :title="$t('images')"
                              :title-link-class="
                                (Object.keys(add_errors) || []).some(e =>
                                  ['images_input'].includes(e)
                                )
                                  ? 'invalid-tab'
                                  : ''
                              "
                            >
                              <div class="form-row col-md-12">
                                <div class="form-row">
                                  <div class="form-group new-input col-md-12">
                                    <label>{{ $t("images") }}</label>
                                    <b-form-file
                                      @change.prevent="upImages"
                                      multiple
                                      v-model="images_input"
                                      :file-name-formatter="formatNames"
                                      accept="image/*"
                                    ></b-form-file>
                                  </div>

                                  <div class="col-md-12">
                                    <div
                                      class="img_div"
                                      v-for="(img, i) in images_arr"
                                      :key="i"
                                    >
                                      <img :src="img.image" />
                                      <div
                                        @click.prevent="removeImg(i)"
                                        v-if="!disabled"
                                      >
                                        <i class="fa fa-times"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </b-tab>
                          </b-tabs>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-info mt-2"
                          :disabled="add_disabled"
                          @click="addProduct"
                        >
                          {{ $t("add") }}
                          <b-spinner small v-if="add_disabled"></b-spinner>
                        </button>
                      </form>
                    </b-card-body>
                    <!-- /.card-body -->
                  </b-card>
                </b-card-text></b-tab
              >
            </b-tabs>

            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  name: "Products",
  components: {
    Multiselect
  },
  data() {
    return {
      load: false,
      products: [],
      search_key: "",
      old_search_key: "",
      disabled_s: [],
      currentPage: 1,
      perPage: 1,
      rows: 1,
      add_disabled: false,
      add_success: "",
      add_error: "",
      add_errors: {},
      add_progress: 0,
      add_data_icon: null,
      add_data_image: null,
      add_data: {
        store_id: "",
        categories_ids: [],
        sub_categories_ids: [],
        discount_type: "percentage"
      },
      image_url: "",
      stores: [],
      categories: [],
      sub_categories: [],
      search_disabled: false,
      attribute_sets: [],
      attributes: [],
      images_input: [],
      images_arr: [],
      disabled: false,
      vendor_id: "",
      vendor: {},
      sort_key: "",
      sort_type: "asc",
      table_fields: [
        "#",
        "image",
        "name",
        "store",
        "category",
        "sub_category",
        "price",
        "prepared_time",
        "created_at",
        "status",
        "qty",
        "in_stock",
        "actions"
      ],
      table_selected_fields: [],
      filters: {
        store: "",
        category: ""
      },
      old_filters: {},
      filtering_load: false,
      dimensions: [],
      album_items: [],
      album_index: null
    };
  },
  created() {
    this.table_selected_fields = this.$store.state.vendor_products_selected_fields;
    this.vendor_id = this.$route.params.id;
    this.getProducts();
    this.getStores();
    this.getCategories();
    //this.getDimensions();
  },
  watch: {
    currentPage() {
      this.getProducts();
    },
    table_selected_fields() {
      this.$store.commit(
        "setVendorProductsSelectedFields",
        this.table_selected_fields
      );
    },
    "add_data.categories_ids"() {
      this.sub_categories = [];
      if (this.add_data.categories_ids.length > 0) {
        this.add_data.categories_ids.forEach(e => {
          // if (!this.sub_categories.find(m => m.id == e.id)) {
          this.sub_categories.push({
            id: e.id,
            category: e["name_" + this.$i18n.locale],
            sub_categories: e.sub_categories
          });
          //}
        });
        this.$set(
          this.add_data,
          "sub_categories_ids",
          this.add_data.sub_categories_ids.filter(value => {
            return this.add_data.categories_ids
              .map(e => e.id)
              .includes(value.category_id);
          })
        );
      } else {
        this.sub_categories = [];
        this.$set(this.add_data, "sub_categories_ids", []);
      }
    },
    "add_data.name_ar"(newVal, oldVal) {
      if (!this.isArabicChars(newVal) && newVal !== oldVal) {
        this.$set(this.add_data, "name_ar", "");
      }
    },
    "add_data.desc_ar"(newVal, oldVal) {
      if (!this.isArabicChars(newVal) && newVal !== oldVal) {
        this.$set(this.add_data, "desc_ar", "");
      }
    }
  },
  methods: {
    getProducts() {
      return this.axios
        .get("products", {
          params: {
            vendor_id: this.vendor_id,
            page: this.currentPage,
            search_key: this.search_key,
            sort_key: this.sort_key,
            sort_type: this.sort_type,
            ...this.filters
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.products = res.result.products.data;
            this.rows = res.result.products.total;
            this.perPage = res.result.products.per_page;
            this.image_url = res.result.image_url;
            this.vendor = res.result.vendor;
          } else {
            this.$router.push("/vendors");
          }
        })
        .catch(error => {
          this.handleError("getProducts", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    applyFilters() {
      if (JSON.stringify(this.filters) == JSON.stringify(this.old_filters)) {
        return true;
      }

      this.filtering_load = true;
      this.getProducts().then(() => {
        this.filtering_load = false;
      });
      this.old_filters = { ...this.filters };
    },
    sortTable(key) {
      if (this.sort_key != key) {
        this.sort_type = "asc";
      } else {
        this.sort_type = this.sort_type == "asc" ? "desc" : "asc";
      }
      this.sort_key = key;
      this.getProducts();
    },
    getCategories() {
      return this.axios
        .get("categories")
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.categories = res.result.categories;
          }
        })
        .catch(error => {
          this.handleError("getCategories", error);
        });
    },
    getStores() {
      return this.axios
        .get("stores/vendor_stores/" + this.vendor_id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.stores = res.result.stores;
          }
        })
        .catch(error => {
          this.handleError("getStores", error);
        });
    },
    searchAll() {
      if (this.search_key != this.old_search_key) {
        this.old_search_key = this.search_key;
        this.search_disabled = true;
        this.getProducts().then(() => {
          this.search_disabled = false;
        });
      }
    },
    getDimensions() {
      return this.axios
        .get("dimensions")
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.dimensions = res.result.dimensions.data;
          }
        })
        .catch(error => {
          this.handleError("getDimensions", error);
        });
    },
    addProduct() {
      this.add_disabled = true;
      let fd = new FormData();

      if (this.add_data_icon) {
        fd.append("image_input", this.add_data_icon, this.add_data_icon.name);
      }
      Object.keys(this.add_data).forEach(key => {
        if (this.add_data[key]) {
          if (key == "categories_ids") {
            this.add_data[key].forEach((e, i) => {
              fd.append("categories_ids[" + i + "]", e.id);
            });
          } else if (key == "sub_categories_ids") {
            this.add_data[key].forEach((e, i) => {
              fd.append("sub_categories_ids[" + i + "]", e.id);
            });
          } else {
            fd.append(key, this.add_data[key]);
          }
        }
      });

      this.images_input.forEach((e, i) => {
        if (this.isImage(e)) {
          fd.append("images_input[" + i + "]", e, e.name);
        }
      });

      this.add_error = this.add_success = "";
      this.axios
        .post("products/add", fd)
        .then(response => {
          let res = response.data;
          this.add_errors = {};
          if (res.error_flag == 0) {
            this.add_success = res.message;
            this.getProducts();
            this.add_data = {
              category_id: "",
              discount_type: "percentage"
            };
            this.add_data_image = null;
            this.add_data_icon = null;
            this.images_input = [];
            this.images_arr = [];
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth"
            });
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.add_errors = res.message;
            } else {
              this.add_error = res.message;
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
              });
            }
          } else {
            this.handleError("addProduct", res.message);
          }
        })
        .catch(error => {
          this.handleError("addProduct", error);
        })
        .finally(() => {
          this.add_disabled = false;
          this.add_progress = 0;
          /*window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });*/
        });
    },
    changeStatus(id) {
      let index = this.findIndex(this.products, "id", id);
      let i = this.findIndex(this.products, "id", id);
      this.$set(this.disabled_s, i, true);
      this.axios
        .post("products/update_status/" + id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.$noty.success(res.message);
            this.products[index].status = res.result.status;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("changeStatus", res.message);
          }
        })
        .catch(error => {
          this.handleError("chnageStatus", error);
        })
        .finally(() => {
          this.$set(this.disabled_s, i, false);
        });
    },
    acceptProduct(id) {
      let index = this.findIndex(this.products, "id", id);
      let i = this.findIndex(this.products, "id", id);
      this.$set(this.disabled_s, i, true);
      this.axios
        .post("products/acceptProduct/" + id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.products[index].status = "on";
            this.$noty.success(res.message);
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("acceptProduct", res.message);
          }
        })
        .catch(error => {
          this.handleError("acceptProduct", error);
        })
        .finally(() => {
          this.$set(this.disabled_s, i, false);
        });
    },
    changeInStock(id) {
      let index = this.findIndex(this.products, "id", id);
      let i = this.findIndex(this.products, "id", id);
      this.$set(this.disabled_s, i, true);
      this.axios
        .post("products/update_in_stock/" + id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.$noty.success(res.message);
            this.products[index].in_stock = res.result.in_stock;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("changeInStock", res.message);
          }
        })
        .catch(error => {
          this.handleError("changeInStock", error);
        })
        .finally(() => {
          this.$set(this.disabled_s, i, false);
        });
    },
    deleteProduct(id) {
      let index = this.findIndex(this.products, "id", id);

      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("products/delete/" + id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.products.splice(index, 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deleteProduct", res.message);
                }
              })
              .catch(error => {
                this.handleError("deleteProduct", error);
              });
          }
        });
    },
    updatePosition(event) {
      this.$set(this.add_data, "lat", event.latLng.lat());
      this.$set(this.add_data, "lng", event.latLng.lng());
    },
    formatNames(files) {
      return files.length === 1
        ? files[0].name
        : `${files.length} files selected`;
    },
    upImages(evt) {
      evt.target.files.forEach(e => {
        if (this.isImage(e)) {
          this.toBase64(e).then(res => {
            this.images_arr.push({ name: e.name, image: res });
          });
        }
      });
    },
    removeImg(i) {
      this.images_input.splice(i, 1);
      this.images_arr.splice(i, 1);
    },
    showProductAlbum(id) {
      let product = this.products.find(e => e.id == id);
      if (product.image) {
        this.album_items = [
          this.image_url + "/" + product.image,
          ...product.images.map(e => this.image_url + "/" + e.image)
        ];
        this.album_index = 0;
      }
    }
  }
};
</script>

<style scoped>
.img_div {
  margin: 15px;
  display: inline-block;
  position: relative;
}

.img_div img {
  height: 120px;
  width: 120px;
}

.img_div div {
  background: #ff0018;
  position: absolute;
  top: -10px;
  color: white;
  left: -10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
</style>
