<template>
    <div>
        <b-modal
            ref="customize_table_modal"
            centered
            :title="$t('customize_table')"
            header-class="new_modal_header"
            hide-footer
        >
            <div class="d-block">
                <div class="row clearfix">
                    <div class="col-md-12">
                        <div class="form-row" style="padding: 0 20px">
                            <div
                                class="form-group col-md-6"
                                v-for="(field, i) in table_fields"
                                :key="i"
                            >
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    aria-label="Checkbox for following text input"
                                    :value="field"
                                    :id="'f_' + field"
                                    v-model="table_selected_fields"
                                    style="vertical-align: middle;"
                                /><label
                                class="form-check-label"
                                :for="'f_' + field"
                                style="color: blue;"
                            >
                                {{ $t(field.toLowerCase()) }}</label
                            >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>

        <b-modal
            ref="edit_city_modal"
            :title="$t('edit') + ' ' + $t('city_2')"
            header-class="new_modal_header"
            hide-footer
        >
            <div class="d-block">
                <form class="col-md-12">
                    <b-alert
                        :show="edit_error || edit_success ? true : false"
                        :variant="edit_error ? 'danger' : 'success'"
                        dismissible
                        @dismissed="edit_error = edit_success = ''"
                    >
                        {{ edit_error || edit_success }}
                    </b-alert>
                    <div class="form-row ">

                        <div class="form-group col-md-6">
                            <label>{{ $t("name") }} | {{ $t("ar") }}</label>
                            <input
                                type="text"
                                class="form-control rtl"
                                :class="{ 'is-invalid': edit_errors.name_ar }"
                                v-model="edit_data.name_ar"
                            />
                            <span class="error invalid-feedback">{{
                                    (edit_errors.name_ar || []).join("-")
                                }}</span>
                        </div>
                        <div class="form-group col-md-6">
                            <label>{{ $t("name") }} | {{ $t("en") }}</label>
                            <input
                                type="text"
                                class="form-control ltr"
                                :class="{ 'is-invalid': edit_errors.name_en }"
                                v-model="edit_data.name_en"
                            />
                            <span class="error invalid-feedback">{{
                                    (edit_errors.name_en || []).join("-")
                                }}</span>
                        </div>
                    </div>

                    <button
                        type="submit"
                        class="btn btn-info mt-2"
                        :disabled="edit_disabled"
                        @click.prevent="updateCity"
                    >
                        {{ $t("save") }}
                        <b-spinner small v-if="edit_disabled"></b-spinner>
                    </button>
                </form>
            </div>
        </b-modal>

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <b-tabs
                            pills
                            card
                            no-key-nav
                            content-class="custom-card"
                            nav-wrapper-class="custom-nav-card"
                        >
                            <b-tab no-body :title="$t('all')" active>
                                <template v-slot:title>
                                    <i class="fa fa-list ml-2 mr-2"></i> {{ $t("all") }}
                                </template>
                                <b-card-text>
                                    <b-card no-body class="card">
                                        <b-card-header>

                                            <div class="card-tools">
                                                <div class="input-group flex-wrap input-group-sm">
                                                    <i
                                                        class="fas fa-cog c_pointer customize_table"
                                                        :title="$t('customize_table')"
                                                        @click="customizeTable"
                                                    ></i>

                                                    <input
                                                        type="text"
                                                        class="form-control float-right"
                                                        :placeholder="$t('search')"
                                                        v-model="search_key"
                                                    />
                                                    <div class="input-group-append">
                                                        <button
                                                            type="submit"
                                                            :disabled="search_disabled"
                                                            @click.prevent="searchAll"
                                                            class="btn btn-default"
                                                        >
                                                            <i
                                                                class="fa fa-spinner fa-spin"
                                                                v-if="search_disabled"
                                                            ></i>
                                                            <i class="fas fa-search" v-else></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 mt-2">
                                              <!--  <button
                                                    type="submit"
                                                    :disabled="top_disabled"
                                                    @click.prevent="topCountries"
                                                    class="btn btn-sm btn-info"
                                                >
                                                    <i
                                                        class="fa fa-spinner fa-spin"
                                                        v-if="top_disabled"
                                                    ></i>
                                                    {{ $t("top_countries") }}
                                                </button> -->
                                            </div>
                                        </b-card-header>
                                        <!-- /.card-header -->
                                        <b-card-body class="table-responsive p-0">
                                            <table class="table table-hover">
                                                <thead>
                                                <tr>
                                                    <th v-if="table_selected_fields.includes('#')">
                                                        #
                                                    </th>
                                                    <th
                                                        class="sort"
                                                        @click="sortTable('name_' + $i18n.locale)"
                                                        v-if="table_selected_fields.includes('name')"
                                                    >
                                                        {{ $t("name") }}
                                                    </th>

                                                    <th
                                                        class="sort"
                                                        @click="sortTable('created_at')"
                                                        v-if="
                                table_selected_fields.includes('created_at')
                              "
                                                    >
                                                        {{ $t("created_at") }}
                                                    </th>
                                                    <th
                                                        class="sort"
                                                        @click="sortTable('status')"
                                                        v-if="table_selected_fields.includes('status')"
                                                    >
                                                        {{ $t("status") }}
                                                    </th>
                                                    <th
                                                        v-if="table_selected_fields.includes('actions')"
                                                    >
                                                        {{ $t("actions") }}
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td
                                                        :colspan="table_selected_fields.length"
                                                        v-if="!load"
                                                    >
                                                        <i class="fa fa-sync  fa-spin load-con"></i>
                                                    </td>
                                                    <td
                                                        :colspan="table_selected_fields.length"
                                                        class="no_data"
                                                        v-if="load && countries.length == 0"
                                                    >
                                                        {{ $t("no_data") }}
                                                    </td>
                                                </tr>
                                                <tr
                                                    v-for="(item, i) in countries"
                                                    :key="i"
                                                    v-show="load"
                                                >
                                                    <td v-if="table_selected_fields.includes('#')">
                                                        {{ i + 1 }}
                                                    </td>

                                                    <td v-if="table_selected_fields.includes('name')">
                                                        {{ item["name_" + $i18n.locale] }}
                                                    </td>


                                                    <td
                                                        v-if="
                                table_selected_fields.includes('created_at')
                              "
                                                        v-html="dateTimeFormat(item.created_at)"
                                                    ></td>
                                                    <td v-if="table_selected_fields.includes('status')">
                              <span
                                  :class="
                                  'badge badge-' +
                                    (item.status == 'on' ? 'info' : 'danger')
                                "
                              >{{ $t(item.status) }}</span
                              >
                                                    </td>
                                                    <td
                                                        class="actions-group"
                                                        v-if="table_selected_fields.includes('actions')"
                                                    >
                                                        <button
                                                            class="btn btn-primary btn-sm"
                                                            v-tooltip="$t('change_status')"
                                                            @click.prevent="changeStatus(item.id)"
                                                        >
                                                            <b-spinner small v-if="disabled_s[i]">
                                                            </b-spinner>
                                                            <i class="fas fa-exchange-alt" v-else></i>
                                                        </button>
                                                        <button
                                                            v-if="$can('edit countries')"
                                                            class="btn btn-info btn-sm"
                                                            v-tooltip="$t('edit')"
                                                            @click="showEditCity(item.id)"
                                                        >
                                                            <i class="fas fa-pencil-alt"> </i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </b-card-body>
                                        <!-- /.card-body -->
                                        <b-card-footer class="card-footer clearfix">
                                            <b-pagination
                                                class="m-2"
                                                v-model="currentPage"
                                                :total-rows="rows"
                                                :per-page="perPage"
                                                :prev-text="$t('previous')"
                                                :next-text="$t('next')"
                                                align="right"
                                                :first-number="true"
                                                :last-number="true"
                                            ></b-pagination>
                                        </b-card-footer>
                                    </b-card>
                                </b-card-text
                                >
                            </b-tab>
                            <b-tab no-body :title="$t('add')" v-if="$can('add countries')">
                                <template v-slot:title>
                                    <i class="fa fa-plus ml-2 mr-2"></i> {{ $t("add") }}
                                </template>
                                <b-card-text>
                                    <b-card no-body>
                                        <b-card-body>
                                            <form class="col-md-8">
                                                <b-alert
                                                    :show="add_error || add_success ? true : false"
                                                    :variant="add_error ? 'danger' : 'success'"
                                                    dismissible
                                                    @dismissed="add_error = add_success = ''"
                                                >
                                                    {{ add_error || add_success }}
                                                </b-alert>
                                                <div class="form-row ">

                                                    <div class="form-group col-md-6">
                                                        <label>{{ $t("name") }} | {{ $t("ar") }}</label>
                                                        <input
                                                            type="text"
                                                            class="form-control rtl"
                                                            :class="{ 'is-invalid': add_errors.name_ar }"
                                                            v-model="add_data.name_ar"
                                                        />
                                                        <span class="error invalid-feedback">{{
                                                                (add_errors.name_ar || []).join("-")
                                                            }}</span>
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <label>{{ $t("name") }} | {{ $t("en") }}</label>
                                                        <input
                                                            type="text"
                                                            class="form-control ltr"
                                                            :class="{ 'is-invalid': add_errors.name_en }"
                                                            v-model="add_data.name_en"
                                                        />
                                                        <span class="error invalid-feedback">{{
                                                                (add_errors.name_en || []).join("-")
                                                            }}</span>
                                                    </div>
                                                </div>

                                                <button
                                                    type="submit"
                                                    class="btn btn-info mt-2"
                                                    :disabled="add_disabled"
                                                    @click="addCity"
                                                >
                                                    {{ $t("add") }}
                                                    <b-spinner small v-if="add_disabled"></b-spinner>
                                                </button>
                                            </form>
                                        </b-card-body>
                                        <!-- /.card-body -->
                                    </b-card>
                                </b-card-text>
                            </b-tab
                            >
                        </b-tabs>

                        <!-- /.card -->
                    </div>
                </div>
                <!-- /.row -->
            </div>
            <!-- /.container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>

<script>
export default {
    name: "Countries",
    data() {
        return {
            load: false,
            countries: [],
            rows: 0,
            search_key: "",
            old_search_key: "",
            search_disabled: false,
            disabled_s: [],
            add_disabled: false,
            edit_disabled: false,
            delete_disabled: false,
            currentPage: 1,
            perPage: 1,
            add_progress: 0,
            sort_key: "",
            sort_type: "asc",
            table_fields: [
                "#",
                "name",
                "created_at",
                "status",
                "actions"
            ],
            table_selected_fields: [],
            add_data: {},
            add_success: "",
            add_error: "",
            add_errors: "",
            edit_data: {},
            edit_success: "",
            edit_error: "",
            edit_errors: "",
            top_disabled: false,
            page_type: ""
        };
    },
    created() {
        this.table_selected_fields = this.$store.state.countries_selected_fields;
        this.getCountries();
    },
    watch: {
        currentPage() {
            this.getCountries();
        },
        table_selected_fields() {
            this.$store.commit("setCountriesSelectedFields", this.table_selected_fields);
        },
        "add_data.name_ar"(newVal, oldVal) {
            if (!this.isArabicChars(newVal) && newVal !== oldVal) {
                this.$set(this.add_data, "name_ar", "");
            }
        },
        "edit_data.name_ar"(newVal, oldVal) {
            if (!this.isArabicChars(newVal) && newVal !== oldVal) {
                this.$set(this.edit_data, "name_ar", "");
            }
        },

    },
    methods: {

        getCountries() {
            return this.axios
                .get("countries", {
                    params: {
                        page: this.currentPage,
                        search_key: this.search_key,
                        sort_key: this.sort_key,
                        sort_type: this.sort_type,
                        page_type: this.page_type
                    }
                })
                .then(response => {
                    let res = response.data;
                    if (res.error_flag == 0) {
                        this.countries = res.result.countries.data;
                        this.rows = res.result.countries.pagination.total;
                        this.perPage = res.result.countries.pagination.per_page;
                    }
                })
                .catch(error => {
                    this.handleError("getCountries", error);
                })
                .finally(() => {
                    this.load = true;
                });
        },
        searchAll() {
            if (this.search_key != this.old_search_key) {
                this.old_search_key = this.search_key;
                this.search_disabled = true;
                this.getCountries().then(() => {
                    this.search_disabled = false;
                });
            }
        },
        sortTable(key) {
            if (this.sort_key != key) {
                this.sort_type = "asc";
            } else {
                this.sort_type = this.sort_type == "asc" ? "desc" : "asc";
            }
            this.sort_key = key;
            this.getCountries();
        },
        addCity() {
            this.add_disabled = true;
            this.add_error = this.add_success = "";
            this.add_errors = {};
            this.axios
                .post("countries/add", this.add_data)
                .then(response => {
                    let res = response.data;
                    if (res.error_flag == 0) {
                        this.add_success = res.message;
                        this.getCountries();
                        this.add_data = {};
                    } else if (res.error_flag == 1) {
                        if (typeof res.message === "object") {
                            this.add_errors = res.message;
                        } else {
                            this.add_error = res.message;
                        }
                    } else {
                        this.handleError("addCity", res.message);
                    }
                })
                .catch(error => {
                    this.handleError("addCity", error);
                })
                .finally(() => {
                    this.add_disabled = false;
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: "smooth"
                    });
                });
        },
        updateCity() {
            this.edit_disabled = true;
            this.edit_error = this.edit_success = "";
            this.axios
                .post("countries/update", this.edit_data)
                .then(response => {
                    let res = response.data;
                    this.edit_errors = {};
                    if (res.error_flag == 0) {
                        this.edit_success = res.message;
                        this.edit_data = {...res.result.country};
                        let i = this.countries.findIndex(e => e.id == this.edit_data.id);
                        this.$set(this.countries, i, res.result.country);
                    } else if (res.error_flag == 1) {
                        if (typeof res.message === "object") {
                            this.edit_errors = res.message;
                        } else {
                            this.edit_error = res.message;
                        }
                    } else {
                        this.handleError("updateCity", res.message);
                    }
                })
                .catch(error => {
                    this.handleError("updateCity", error);
                })
                .finally(() => {
                    this.edit_disabled = false;
                });
        },
        showEditCity(id) {
            this.edit_success = this.edit_error = "";
            this.edit_errors = {};
            this.edit_data = {...this.countries.find(e => e.id == id)};
            this.$refs.edit_city_modal.show();
        },
        changeStatus(id) {
            let index = this.findIndex(this.countries, "id", id);
            this.$set(this.disabled_s, index, true);
            this.axios
                .post("countries/update_status/" + id)
                .then(response => {
                    let res = response.data;
                    if (res.error_flag == 0) {
                        this.countries[index].status = res.result.status;
                        this.$noty.success(res.message);
                    } else {
                        this.handleError("changeStatus", res.message);
                    }
                })
                .catch(error => {
                    this.handleError("changeStatus", error);
                })
                .finally(() => {
                    this.$set(this.disabled_s, index, false);
                });
        },
        topCountries() {
            this.page_type = "top_countries";
            this.top_disabled = true;
            this.getCountries().then(() => {
                this.top_disabled = false;
            });
        }
    }
};
</script>

<style scoped>
.subscribers_table tr:first-child th {
    border: none;
}
</style>
