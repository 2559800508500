<template>
  <div>
    <b-modal
      ref="customize_table_modal"
      centered
      :title="$t('customize_table')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <div class="row clearfix">
          <div class="col-md-12">
            <div class="form-row" style="padding: 0 20px">
              <div
                class="form-group col-md-6"
                v-for="(field, i) in table_fields"
                :key="i"
              >
                <input
                  type="checkbox"
                  class="form-check-input"
                  aria-label="Checkbox for following text input"
                  :value="field"
                  :id="'f_' + field"
                  v-model="table_selected_fields"
                  style="vertical-align: middle;"
                /><label
                  class="form-check-label"
                  :for="'f_' + field"
                  style="color: blue;"
                >
                  {{ $t(field.toLowerCase()) }}</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="edit_coupon_modal"
      :title="$t('edit') + ' ' + $t('coupon_2')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <form class="col-md-12">
          <b-alert
            :show="edit_error || edit_success ? true : false"
            :variant="edit_error ? 'danger' : 'success'"
            dismissible
            @dismissed="edit_error = edit_success = ''"
          >
            {{ edit_error || edit_success }}
          </b-alert>

          <div class="form-row">

              <div class="form-group col-md-6">
                  <label>{{ $t("name") }} | {{ $t("ar") }}</label>
                  <input
                      type="text"
                      class="form-control rtl"
                      :class="{
                                  'is-invalid': edit_errors.name_ar
                                }"
                      v-model="edit_data.name_ar"
                  />
                  <span class="error invalid-feedback">{{
                          (edit_errors.name_ar || []).join("-")
                      }}</span>
              </div>
              <div class="form-group col-md-6">
                  <label>{{ $t("name") }} | {{ $t("en") }}</label>
                  <input
                      type="text"
                      class="form-control ltr"
                      :class="{
                                  'is-invalid': edit_errors.name_en
                                }"
                      v-model="edit_data.name_en"
                  />
                  <span class="error invalid-feedback">{{
                          (edit_errors.name_en || []).join("-")
                      }}</span>
              </div>

            <div class="form-group col-md-6">
              <label>{{ $t("code") }}</label>
              <input
                type="text"
                class="form-control"
                :class="{ 'is-invalid': edit_errors.code }"
                v-model="edit_data.code"
              />
              <span class="error invalid-feedback">{{
                (edit_errors.code || []).join("-")
              }}</span>
            </div>

            <div class="form-group col-md-6">
              <label>{{ $t("discount_type") }}</label>
              <select
                class="form-control custom-select"
                :class="{
                  'is-invalid': edit_errors.discount_type
                }"
                v-model="edit_data.discount_type"
              >
                <option value="percentage">{{ $t("percentage") }}</option>
                <option value="fixed">{{ $t("fixed_value") }}</option>
              </select>
              <span class="error invalid-feedback">{{
                (edit_errors.discount_type || []).join("-")
              }}</span>
            </div>

            <div class="form-group col-md-6">
              <label>{{ $t("discount_value") }}</label>
              <input
                type="number"
                min="0"
                class="form-control ltr"
                :class="{
                  'is-invalid': edit_errors.discount_value
                }"
                v-model="edit_data.discount_value"
              />
              <span class="error invalid-feedback">{{
                (edit_errors.discount_value || []).join("-")
              }}</span>
            </div>

            <div class="form-group col-md-6">
              <label>{{ $t("type") }}</label>
              <select
                class="form-control pt-0"
                :class="{
                  'is-invalid': edit_errors.coupon_type
                }"
                v-model="edit_data.coupon_type"
              >
                <option value="products">{{ $t("products_2") }}</option>
                <option value="delivery">{{ $t("delivery") }}</option>
              </select>
              <span class="error invalid-feedback">{{
                (edit_errors.coupon_type || []).join("-")
              }}</span>
            </div>

              <div v-if="edit_data.coupon_type ==='products'" class="form-group col-md-6">
                  <label>{{ $t("vendor") }}</label>
                  <select
                      class="form-control custom-select"
                      :class="{
                                      'is-invalid': edit_errors.vendor_id
                                    }"
                      v-model="edit_data.vendor_id"
                  >
                      <option selected=""  value="">{{
                              $t("select")
                          }}
                      </option>
                      <option
                          v-for="(vendor, i) in vendors"
                          :key="i"
                          :value="vendor.id"
                      >{{ vendor["name"] }}
                      </option
                      >
                  </select>
                  <span class="error invalid-feedback">{{
                          (edit_errors.vendor_id || []).join("-")
                      }}</span>
              </div>

              <div  v-if="edit_data.coupon_type ==='products'" class="form-group col-md-6">
                  <label>{{ $t("store") }}</label>
                  <select
                      :disabled="stores.length == 0"
                      class="form-control custom-select"
                      :class="{
                                      'is-invalid': edit_errors.store_id
                                    }"
                      v-model="edit_data.store_id"
                  >
                      <option selected="" disabled="" value="">{{
                              $t("select")
                          }}
                      </option>
                      <option
                          v-for="(store, i) in stores"
                          :key="i"
                          :value="store.id"
                      >{{ store["store_name"] }}
                      </option
                      >
                  </select>
                  <span class="error invalid-feedback">{{
                          (edit_errors.store_id || []).join("-")
                      }}</span>
              </div>


              <div  v-if="edit_data.coupon_type ==='products'" class="form-group col-md-12">
                  <label>{{ $t("products") }}</label>
                  <multiselect
                      selectLabel=""
                      selectedLabel=""
                      deselectLabel=""
                      v-model="edit_data.products_ids"
                      :placeholder="
                                      $t('select') + ' ' + $t('products')
                                    "
                      :multiple="true"
                      :searchable="true"
                      :internal-search="true"
                      :options-limit="100"
                      :show-no-results="false"
                      :hide-selected="true"
                      :options="edit_option_products"
                      track-by="id"
                      :label="'name_' + $i18n.locale"
                      :custom-label="
                                      option => {
                                        return option['name_' + $i18n.locale];
                                      }
                                    "
                  >
                  </multiselect>
                  <span
                      class="error invalid-feedback d-block"
                  >{{
                          (edit_errors.products_ids || []).join(
                              "-"
                          )
                      }}</span
                  >
              </div>


              <div class="form-group col-md-6">
              <label>{{ $t("vendor_per") }} %</label>
              <input
                type="number"
                min="0"
                max="100"
                class="form-control ltr"
                :class="{
                  'is-invalid': edit_errors.vendor_per
                }"
                v-model="edit_data.vendor_per"
              />
              <span class="error invalid-feedback">{{
                (edit_errors.vendor_per || []).join("-")
              }}</span>
            </div>

            <div class="form-group col-md-6">
              <label>{{ $t("min_amount") }} ( {{ $t("sar") }} )</label>
              <input
                type="number"
                min="0"
                class="form-control ltr"
                :class="{
                  'is-invalid': edit_errors.min_amount
                }"
                v-model="edit_data.min_amount"
              />
              <span class="error invalid-feedback">{{
                (edit_errors.min_amount || []).join("-")
              }}</span>
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("max_discount") }} ( {{ $t("sar") }} )</label>
              <input
                type="number"
                min="0"
                class="form-control ltr"
                :class="{
                  'is-invalid': edit_errors.max_discount
                }"
                v-model="edit_data.max_discount"
                :disabled="edit_data.discount_type === 'fixed'"
              />
              <span class="error invalid-feedback">{{
                (edit_errors.max_discount || []).join("-")
              }}</span>
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("coupon_limit") }}</label>
              <input
                type="number"
                min="0"
                class="form-control ltr"
                :class="{
                  'is-invalid': edit_errors.coupon_limit
                }"
                v-model="edit_data.coupon_limit"
              />
              <span class="error invalid-feedback">{{
                (edit_errors.coupon_limit || []).join("-")
              }}</span>
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("user_limit") }}</label>
              <input
                type="number"
                min="0"
                class="form-control ltr"
                :class="{
                  'is-invalid': edit_errors.user_limit
                }"
                v-model="edit_data.user_limit"
              />
              <span class="error invalid-feedback">{{
                (edit_errors.user_limit || []).join("-")
              }}</span>
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("discount_start") }}</label>
              <input
                type="date"
                class="form-control"
                :class="{
                  'is-invalid': edit_errors.start_at
                }"
                v-model="edit_data.start_at"
              />
              <span class="error invalid-feedback">{{
                (edit_errors.start_at || []).join("-")
              }}</span>
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("discount_end") }}</label>
              <input
                type="date"
                class="form-control"
                :class="{
                  'is-invalid': edit_errors.end_at
                }"
                v-model="edit_data.end_at"
              />
              <span class="error invalid-feedback">{{
                (edit_errors.end_at || []).join("-")
              }}</span>
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-info mt-2"
            :disabled="edit_disabled"
            @click.prevent="updateCoupon"
          >
            {{ $t("save") }}
            <b-spinner small v-if="edit_disabled"></b-spinner>
          </button>
        </form>
      </div>
    </b-modal>

    <b-modal
      ref="subscribers_modal"
      :title="subscribers_type ? $t(subscribers_type) : ''"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <table
          class="table table-hover subscribers_table"
          v-if="subscribers_type == 'products'"
        >
          <thead>
            <tr>
              <th>
                {{ $t("product") }}
              </th>
              <th>
                {{ $t("vendor") }}
              </th>
              <th>
                {{ $t("actions") }}
              </th>
            </tr>
          </thead>
          <tr v-for="(product, i) in subscribers" :key="i">
            <td width="80%">
              {{ i + 1 }}- {{ product["name_" + $i18n.locale] }}
            </td>
            <td width="80%">{{ (product.store.vendor || {}).name }}</td>
            <td>
              <button
                @click="deleteSubscriber(product.id)"
                class="btn btn-danger btn-sm"
                v-tooltip="$t('delete')"
              >
                <i class="fas fa-trash"> </i>
              </button>
            </td>
          </tr>
        </table>

        <table
          class="table table-hover subscribers_table"
          v-if="subscribers_type == 'delivery'"
        >
          <thead>
            <tr>
              <th>
                {{ $t("vendor") }}
              </th>
              <th>
                {{ $t("actions") }}
              </th>
            </tr>
          </thead>
          <tr v-for="(vendor, i) in subscribers" :key="i">
            <td width="80%">{{ vendor.name }}</td>
            <td>
              <button
                @click="deleteSubscriber(vendor.id)"
                class="btn btn-danger btn-sm"
                v-tooltip="$t('delete')"
              >
                <i class="fas fa-trash"> </i>
              </button>
            </td>
          </tr>
        </table>

        <p
          class="text-center text-muted text-sm"
          v-if="subscribers.length === 0"
        >
          {{ $t("no_data") }}
        </p>
      </div>
    </b-modal>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <b-tabs
              pills
              card
              no-key-nav
              content-class="custom-card"
              nav-wrapper-class="custom-nav-card"
            >
              <b-tab no-body :title="$t('all')" active>
                <template v-slot:title>
                  <i class="fa fa-list ml-2 mr-2"></i> {{ $t("all") }}
                </template>
                <b-card-text>
                  <b-card no-body class="card">
                    <b-card-header>
                      <div class="card-tools">
                        <div class="input-group input-group-sm">
                          <i
                            class="fas fa-cog c_pointer customize_table"
                            :title="$t('customize_table')"
                            @click="customizeTable"
                          ></i>
                          <input
                            type="text"
                            class="form-control float-right"
                            :placeholder="$t('search')"
                            v-model="search_key"
                          />
                          <div class="input-group-append">
                            <button
                              type="submit"
                              :disabled="search_disabled"
                              @click.prevent="searchAll"
                              class="btn btn-default"
                            >
                              <i
                                class="fa fa-spinner fa-spin"
                                v-if="search_disabled"
                              ></i>
                              <i class="fas fa-search" v-else></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-card-header>
                    <!-- /.card-header -->
                    <b-card-body class="table-responsive p-0">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th v-if="table_selected_fields.includes('#')">
                              #
                            </th>
                            <th
                              class="sort"
                              @click="sortTable('name')"
                              v-if="table_selected_fields.includes('name')"
                            >
                              {{ $t("name") }}
                            </th>
                            <th
                              class="sort"
                              @click="sortTable('code')"
                              v-if="table_selected_fields.includes('code')"
                            >
                              {{ $t("code") }}
                            </th>
                            <th
                              class="sort"
                              @click="sortTable('coupon_type')"
                              v-if="
                                table_selected_fields.includes('coupon_type')
                              "
                            >
                              {{ $t("type") }}
                            </th>
                            <th
                              class="sort"
                              @click="sortTable('discount_value')"
                              v-if="table_selected_fields.includes('discount')"
                            >
                              {{ $t("discount") }}
                            </th>
                            <th
                              class="sort"
                              @click="sortTable('admin_per')"
                              v-if="table_selected_fields.includes('admin_per')"
                            >
                              {{ $t("admin_per") }}
                            </th>
                            <th
                              class="sort"
                              @click="sortTable('vendor_per')"
                              v-if="
                                table_selected_fields.includes('vendor_per')
                              "
                            >
                              {{ $t("vendor_per") }}
                            </th>
                            <th
                              v-if="
                                table_selected_fields.includes('created_at')
                              "
                              class="sort"
                              @click="sortTable('created_at')"
                            >
                              {{ $t("created_at") }}
                            </th>
                            <th
                              v-if="table_selected_fields.includes('status')"
                              class="sort"
                              @click="sortTable('status')"
                            >
                              {{ $t("status") }}
                            </th>
                            <th
                              v-if="table_selected_fields.includes('actions')"
                            >
                              {{ $t("actions") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              :colspan="table_selected_fields.length"
                              v-if="!load"
                            >
                              <i class="fa fa-sync  fa-spin load-con"></i>
                            </td>
                            <td
                              :colspan="table_selected_fields.length"
                              class="no_data"
                              v-if="load && coupons.length == 0"
                            >
                              {{ $t("no_data") }}
                            </td>
                          </tr>
                          <tr
                            v-for="(item, i) in coupons"
                            :key="i"
                            v-show="load"
                          >
                            <td v-if="table_selected_fields.includes('#')">
                              {{ i + 1 }}
                            </td>

                            <td v-if="table_selected_fields.includes('name')">
                              {{ item["name"] }}
                            </td>
                            <td v-if="table_selected_fields.includes('code')">
                              {{ item["code"] }}
                            </td>
                            <td
                              v-if="
                                table_selected_fields.includes('coupon_type')
                              "
                            >
                              {{ $t(item.coupon_type) }}

                              <span v-if="item.coupon_type !== 'delivery'">
                                [{{
                                  item.coupon_type == "products"
                                    ? item.products.length
                                    : item.delivery.length
                                }}]
                              </span>
                              <button
                                v-if="
                                  $can('show coupons') &&
                                    item.coupon_type !== 'delivery'
                                "
                                class="btn btn-info btn-sm mr-1 ml-1"
                                v-tooltip="$t('show')"
                                :disabled="
                                  (item.coupon_type == 'products' &&
                                    item.products.length == 0) ||
                                    (item.coupon_type == 'delivery' &&
                                      item.delivery.length == 0)
                                "
                                @click="
                                  showSubscribers(item.id, item.coupon_type)
                                "
                              >
                                <i class="fas fa-eye"> </i>
                              </button>
                            </td>
                            <td
                              v-if="table_selected_fields.includes('discount')"
                            >
                              {{ item.discount_value
                              }}{{
                                item.discount_type == "percentage"
                                  ? "%"
                                  : $t("sar")
                              }}
                            </td>
                            <td
                              v-if="table_selected_fields.includes('admin_per')"
                            >
                              {{ item["admin_per"] }}%
                            </td>
                            <td
                              v-if="
                                table_selected_fields.includes('vendor_per')
                              "
                            >
                              {{ item["vendor_per"] }}%
                            </td>

                            <td
                              v-if="
                                table_selected_fields.includes('created_at')
                              "
                              v-html="dateTimeFormat(item.created_at)"
                            ></td>
                            <td v-if="table_selected_fields.includes('status')">
                              <label class="switch mr-2 ml-2">
                                <input
                                  type="checkbox"
                                  :disabled="!$can('edit products')"
                                  v-model="item.status"
                                  :true-value="'on'"
                                  :false-value="'off'"
                                  @change="
                                    $can('edit coupons')
                                      ? changeStatus(item.id)
                                      : false
                                  "
                                />
                                <div class="slider round">
                                  <span class="on">{{ $t("on") }}</span>
                                  <span class="off">{{ $t("off") }}</span>
                                </div>
                              </label>
                            </td>
                            <td
                              class="actions-group"
                              v-if="table_selected_fields.includes('actions')"
                            >
                              <button
                                v-if="$can('edit coupons')"
                                class="btn btn-info btn-sm"
                                v-tooltip="$t('edit')"
                                @click="showEditCoupon(item.id)"
                              >
                                <i class="fas fa-pencil-alt"> </i>
                              </button>
                              <button
                                v-if="$can('delete coupons')"
                                @click="deleteCoupon(item.id)"
                                class="btn btn-danger btn-sm"
                                v-tooltip="$t('delete')"
                              >
                                <i class="fas fa-trash"> </i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-card-body>
                    <!-- /.card-body -->
                    <b-card-footer class="card-footer clearfix">
                      <b-pagination
                        class="m-2"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        :prev-text="$t('previous')"
                        :next-text="$t('next')"
                        align="right"
                        :first-number="true"
                        :last-number="true"
                      ></b-pagination>
                    </b-card-footer>
                  </b-card> </b-card-text
              ></b-tab>
              <b-tab no-body :title="$t('add')" v-if="$can('add coupons')">
                <template v-slot:title>
                  <i class="fa fa-plus ml-2 mr-2"></i> {{ $t("add") }}
                </template>
                <b-card-text>
                  <b-card no-body>
                    <b-card-body>
                      <form class="col-md-8">
                        <b-alert
                          :show="add_error || add_success ? true : false"
                          :variant="add_error ? 'danger' : 'success'"
                          dismissible
                          @dismissed="add_error = add_success = ''"
                        >
                          {{ add_error || add_success }}
                        </b-alert>

                        <div class="form-row">

                            <div class="form-group col-md-6">
                                <label>{{ $t("name") }} | {{ $t("ar") }}</label>
                                <input
                                    type="text"
                                    class="form-control rtl"
                                    :class="{
                                  'is-invalid': add_errors.name_ar
                                }"
                                    v-model="add_data.name_ar"
                                />
                                <span class="error invalid-feedback">{{
                                        (add_errors.name_ar || []).join("-")
                                    }}</span>
                            </div>
                            <div class="form-group col-md-6">
                                <label>{{ $t("name") }} | {{ $t("en") }}</label>
                                <input
                                    type="text"
                                    class="form-control ltr"
                                    :class="{
                                  'is-invalid': add_errors.name_en
                                }"
                                    v-model="add_data.name_en"
                                />
                                <span class="error invalid-feedback">{{
                                        (add_errors.name_en || []).join("-")
                                    }}</span>
                            </div>

                          <div class="form-group col-md-6">
                            <label>{{ $t("code") }}</label>
                            <input
                              type="text"
                              class="form-control"
                              :class="{ 'is-invalid': add_errors.code }"
                              v-model="add_data.code"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.code || []).join("-")
                            }}</span>
                          </div>

                          <div class="form-group col-md-6">
                            <label>{{ $t("discount_type") }}</label>
                            <select
                              class="form-control custom-select"
                              :class="{
                                'is-invalid': add_errors.discount_type
                              }"
                              v-model="add_data.discount_type"
                            >
                              <option value="percentage">{{
                                $t("percentage")
                              }}</option>
                              <option value="fixed">{{
                                $t("fixed_value")
                              }}</option>
                            </select>
                            <span class="error invalid-feedback">{{
                              (add_errors.discount_type || []).join("-")
                            }}</span>
                          </div>

                          <div class="form-group col-md-6">
                            <label>{{ $t("discount_value") }}</label>
                            <input
                              type="number"
                              min="0"
                              class="form-control ltr"
                              :class="{
                                'is-invalid': add_errors.discount_value
                              }"
                              v-model="add_data.discount_value"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.discount_value || []).join("-")
                            }}</span>
                          </div>

                          <div class="form-group col-md-6">
                            <label>{{ $t("type") }}</label>
                            <select
                              class="form-control pt-0"
                              :class="{
                                'is-invalid': add_errors.coupon_type
                              }"
                              v-model="add_data.coupon_type"
                            >
                              <option value="products">{{
                                $t("products_2")
                              }}</option>
                              <option value="delivery">{{
                                $t("delivery")
                              }}</option>
                            </select>
                            <span class="error invalid-feedback">{{
                              (add_errors.coupon_type || []).join("-")
                            }}</span>
                          </div>


                            <div v-if="add_data.coupon_type ==='products'" class="form-group col-md-6">
                                <label>{{ $t("vendor") }}</label>
                                <select
                                    class="form-control custom-select"
                                    :class="{
                                      'is-invalid': add_errors.vendor_id
                                    }"
                                    v-model="add_data.vendor_id"
                                >
                                    <option selected=""  value="">{{
                                            $t("select")
                                        }}
                                    </option>
                                    <option
                                        v-for="(vendor, i) in vendors"
                                        :key="i"
                                        :value="vendor.id"
                                    >{{ vendor["name"] }}
                                    </option
                                    >
                                </select>
                                <span class="error invalid-feedback">{{
                                        (add_errors.vendor_id || []).join("-")
                                    }}</span>
                            </div>

                            <div  v-if="add_data.coupon_type ==='products'" class="form-group col-md-6">
                                <label>{{ $t("store") }}</label>
                                <select
                                    :disabled="
                                      !add_data.vendor_id || stores.length == 0
                                    "
                                    class="form-control custom-select"
                                    :class="{
                                      'is-invalid': add_errors.store_id
                                    }"
                                    v-model="add_data.store_id"
                                >
                                    <option selected="" disabled="" value="">{{
                                            $t("select")
                                        }}
                                    </option>
                                    <option
                                        v-for="(store, i) in stores"
                                        :key="i"
                                        :value="store.id"
                                    >{{ store["store_name"] }}
                                    </option
                                    >
                                </select>
                                <span class="error invalid-feedback">{{
                                        (add_errors.store_id || []).join("-")
                                    }}</span>
                            </div>


                            <div  v-if="add_data.coupon_type ==='products'" class="form-group col-md-12">
                                <label>{{ $t("products") }}</label>
                                <multiselect
                                    selectLabel=""
                                    selectedLabel=""
                                    deselectLabel=""
                                    v-model="add_data.products_ids"
                                    :placeholder="
                                      $t('select') + ' ' + $t('products')
                                    "
                                    :multiple="true"
                                    :searchable="true"
                                    :internal-search="true"
                                    :options-limit="100"
                                    :show-no-results="false"
                                    :hide-selected="true"
                                    :options="products"
                                    track-by="id"
                                    :label="'name_' + $i18n.locale"
                                    :custom-label="
                                      option => {
                                        return option['name_' + $i18n.locale];
                                      }
                                    "
                                >
                                </multiselect>
                                <span
                                    class="error invalid-feedback d-block"
                                >{{
                                        (add_errors.products_ids || []).join(
                                            "-"
                                        )
                                    }}</span
                                >
                            </div>




                            <div class="form-group col-md-6">
                            <label>{{ $t("vendor_per") }} %</label>
                            <input
                              type="number"
                              min="0"
                              max="100"
                              class="form-control ltr"
                              :class="{
                                'is-invalid': add_errors.vendor_per
                              }"
                              v-model="add_data.vendor_per"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.vendor_per || []).join("-")
                            }}</span>
                          </div>

                          <div class="form-group col-md-6">
                            <label
                              >{{ $t("min_amount") }} ( {{ $t("sar") }} )</label
                            >
                            <input
                              type="number"
                              min="0"
                              class="form-control ltr"
                              :class="{
                                'is-invalid': add_errors.min_amount
                              }"
                              v-model="add_data.min_amount"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.min_amount || []).join("-")
                            }}</span>
                          </div>
                          <div class="form-group col-md-6">
                            <label
                              >{{ $t("max_discount") }} (
                              {{ $t("sar") }} )</label
                            >
                            <input
                              type="number"
                              min="0"
                              class="form-control ltr"
                              :class="{
                                'is-invalid': add_errors.max_discount
                              }"
                              v-model="add_data.max_discount"
                              :disabled="add_data.discount_type === 'fixed'"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.max_discount || []).join("-")
                            }}</span>
                          </div>
                          <div class="form-group col-md-6">
                            <label>{{ $t("coupon_limit") }}</label>
                            <input
                              type="number"
                              min="0"
                              class="form-control ltr"
                              :class="{
                                'is-invalid': add_errors.coupon_limit
                              }"
                              v-model="add_data.coupon_limit"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.coupon_limit || []).join("-")
                            }}</span>
                          </div>
                          <div class="form-group col-md-6">
                            <label>{{ $t("user_limit") }}</label>
                            <input
                              type="number"
                              min="0"
                              class="form-control ltr"
                              :class="{
                                'is-invalid': add_errors.user_limit
                              }"
                              v-model="add_data.user_limit"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.user_limit || []).join("-")
                            }}</span>
                          </div>
                          <div class="form-group col-md-6">
                            <label>{{ $t("discount_start") }}</label>
                            <input
                              type="date"
                              class="form-control"
                              :class="{
                                'is-invalid': add_errors.start_at
                              }"
                              v-model="add_data.start_at"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.start_at || []).join("-")
                            }}</span>
                          </div>
                          <div class="form-group col-md-6">
                            <label>{{ $t("discount_end") }}</label>
                            <input
                              type="date"
                              class="form-control"
                              :class="{
                                'is-invalid': add_errors.end_at
                              }"
                              v-model="add_data.end_at"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.end_at || []).join("-")
                            }}</span>
                          </div>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-info mt-2"
                          :disabled="add_disabled"
                          @click="addCoupon"
                        >
                          {{ $t("add") }}
                          <b-spinner small v-if="add_disabled"></b-spinner>
                        </button>
                      </form>
                    </b-card-body>
                    <!-- /.card-body -->
                  </b-card>
                </b-card-text></b-tab
              >
            </b-tabs>

            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  name: "Coupons",
    components: {
        Multiselect
    },
  data() {
    return {
      load: false,
      coupons: [],
      vendors: [],
      stores: [],
      products: [],
      edit_option_products: [],
      search_key: "",
      old_search_key: "",
      search_disabled: false,
      disabled_s: [],
      currentPage: 1,
      perPage: 1,
      rows: 1,
      add_data: {
        coupon_type: "products",
        discount_type: "percentage",
          vendor_id: "",
          store_id: "",
          products_ids: [],
      },
      add_disabled: false,
      add_success: "",
      add_error: "",
      add_errors: {},
      add_progress: 0,
      edit_data: {

      },
      edit_disabled: false,
      edit_success: "",
      edit_error: "",
      edit_errors: {},
      load_results: false,
      add_results: [],
      edit_results: [],
      categories: [],
      sort_key: "",
      sort_type: "asc",
      table_fields: [
        "#",
        "name",
        "code",
        "coupon_type",
        "discount",
        "admin_per",
        "vendor_per",
        "created_at",
        "status",
        "actions"
      ],
      table_selected_fields: [],
      subscribers: [],
      subscribers_type: "",
      current_coupon_id: null
    };
  },
  created() {
    this.table_selected_fields = this.$store.state.coupons_selected_fields;
    this.getCoupons();
    this.getVendors();
  },
  watch: {
    currentPage() {
      this.getCoupons();
    },
      "add_data.vendor_id"() {
          if (this.add_data.vendor_id) {
              this.getVendorStores(this.add_data.vendor_id);
              this.$set(this.add_data, "products_ids", []);
              this.products = [];
          } else {
              this.$set(this.add_data, "store_id", "");
          }
      },
      "add_data.store_id"() {
          if (this.add_data.store_id) {
              this.getProductsByStore(this.add_data.store_id,false);
          } else {
              this.$set(this.add_data, "products_ids", []);
          }
      },
    table_selected_fields() {
      this.$store.commit(
        "setCouponsSelectedFields",
        this.table_selected_fields
      );
    },
    "add_data.discount_type"(val, _) {
      if (val === "fixed") {
        this.add_data.max_discount = this.add_data.discount_value;
      } else {
        this.add_data.max_discount = null;
      }
    },
    "edit_data.discount_type"(val, _) {
      if (val === "fixed") {
        this.edit_data.max_discount = this.edit_data.discount_value;
      } else {
        this.edit_data.max_discount = null;
      }
    },
      "edit_data.vendor_id"() {
          if (this.edit_data.vendor_id) {
              this.getVendorStores(this.edit_data.vendor_id);
              this.$set(this.edit_data, "products_ids", []);
              this.edit_option_products = [];
          } else {
              this.$set(this.edit_data, "store_id", "");
          }
      },
      "edit_data.store_id"() {
          if (this.edit_data.store_id) {
              this.getProductsByStore(this.edit_data.store_id,true);

          } else {
              //this.$set(this.edit_data, "products_ids", []);
          }
      },

      "edit_data.products_ids"() {
      }
  },
  methods: {
    getProducts2(query) {
      if (!query) {
        return true;
      }
      this.load_results = true;
      return this.axios
        .get("coupons/products", {
          params: {
            search_key: query
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.edit_results = res.result.products;
          }
        })
        .catch(error => {
          this.handleError("getProducts", error);
        })
        .finally(() => {
          this.load_results = false;
        });
    },
      getVendors() {
          return this.axios.get("products/vendors").then(response => {
              let res = response.data;
              if (res.error_flag == 0) {
                  this.vendors = res.result.vendors;
              }
          });
      },
      getVendorStores(vendor_id) {
          return this.axios
              .get("products/vendorStores/" + vendor_id)
              .then(response => {
                  let res = response.data;
                  if (res.error_flag == 0) {
                      this.stores = res.result.stores;
                  }
              })
              .catch(error => {
                  this.handleError("getVendorStores", error);
              });
      },
      getProductsByStore(store_id,fromEdit) {
          return this.axios.get("coupons/products?store_id="+store_id).then(response => {
              let res = response.data;
              if (res.error_flag == 0) {
                  if(fromEdit){
                      this.edit_option_products =res.result.products;
                  }else{
                      this.products = res.result.products;
                  }

              }
          });
      },
    getCoupons() {
      return this.axios
        .get("coupons", {
          params: {
            page: this.currentPage,
            search_key: this.search_key,
            sort_key: this.sort_key,
            sort_type: this.sort_type
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.coupons = res.result.coupons.data;
            this.rows = res.result.coupons.total;
            this.perPage = res.result.coupons.per_page;
          }
        })
        .catch(error => {
          this.handleError("getCoupons", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    searchAll() {
      if (this.search_key != this.old_search_key) {
        this.old_search_key = this.search_key;
        this.search_disabled = true;
        this.getCoupons().then(() => {
          this.search_disabled = false;
        });
      }
    },
    addCoupon() {
      this.add_disabled = true;
      this.add_error = this.add_success = "";
      this.axios
        .post("coupons/add", this.add_data)
        .then(response => {
          let res = response.data;
          this.add_errors = {};
          if (res.error_flag == 0) {
            this.add_success = res.message;
            this.getCoupons();
            this.add_data = {
              coupon_type: "products",
              discount_type: "percentage"
            };
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.add_errors = res.message;
            } else {
              this.add_error = res.message;
            }
          } else {
            this.handleError("addCoupon", res.message);
          }
        })
        .catch(error => {
          this.handleError("addCoupon", error);
        })
        .finally(() => {
          this.add_disabled = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    updateCoupon() {
      this.edit_disabled = true;
      let fd = new FormData();
      Object.keys(this.edit_data).forEach(key => {
        if (this.edit_data[key]) {
          fd.append(key, this.edit_data[key]);
        }
      });
      this.edit_error = this.edit_success = "";
      this.axios
        .post("coupons/update/"+this.edit_data.id, this.edit_data)
        .then(response => {
          this.edit_errors = {};
          let res = response.data;
          if (res.error_flag == 0) {
            this.edit_success = res.message;
            let i = this.coupons.findIndex(e => e.id == this.edit_data.id);
            this.$set(this.coupons, i, res.result.coupon);
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.edit_errors = res.message;
            } else {
              this.edit_error = res.message;
            }
          } else {
            this.handleError("updateCoupon", res.message);
          }
        })
        .catch(error => {
          this.handleError("updateCoupon", error);
        })
        .finally(() => {
          this.edit_disabled = false;
        });
    },
    deleteCoupon(id) {
      let index = this.findIndex(this.coupons, "id", id);

      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("coupons/delete/" + id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.coupons.splice(index, 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deleteCoupon", res.message);
                }
              })
              .catch(error => {
                this.handleError("deleteCoupon", error);
              });
          }
        });
    },
    changeStatus(id) {
      let index = this.findIndex(this.coupons, "id", id);
      let i = this.findIndex(this.coupons, "id", id);
      this.$set(this.disabled_s, i, true);
      this.axios
        .post("coupons/update_status/" + id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.$noty.success(res.message);
            this.coupons[index].status = res.result.status;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("changeStatus", res.message);
          }
        })
        .catch(error => {
          this.handleError("chnageStatus", error);
        })
        .finally(() => {
          this.$set(this.disabled_s, i, false);
        });
    },
    showEditCoupon(id) {
      this.edit_success = this.edit_error = "";
      this.edit_errors = {};
      this.edit_data = { ...this.coupons.find(e => e.id == id) };

        this.$nextTick(() => {
            this.edit_data.products_ids = this.coupons.find(e => e.id == id).products_ids;
        });

        this.$refs.edit_coupon_modal.show();
    },
    showSubscribers(id, subscribers_type) {
      this.current_coupon_id = id;
      this.subscribers_type = subscribers_type;
      if (subscribers_type == "products") {
        this.subscribers = this.coupons.find(e => e.id == id).products;
      } else {
        this.subscribers = this.coupons.find(e => e.id == id).delivery;
      }

      this.$refs.subscribers_modal.show();
    },
    deleteSubscriber(id) {
      let index = this.findIndex(this.subscribers, "id", id);

      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("coupons/delete_subscriber", {
                coupon_id: this.current_coupon_id,
                id: id
              })
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.subscribers.splice(index, 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deleteCoupon", res.message);
                }
              })
              .catch(error => {
                this.handleError("deleteCoupon", error);
              });
          }
        });
    }
  }
};
</script>

<style scoped>
.subscribers_table tr:first-child th {
  border: none;
}
</style>
