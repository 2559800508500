import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        app_name: "HandsMade",
        api_url:
            process.env.NODE_ENV != "production"
                ? "https://apis.handsmade.app"
                : //:  ? "http://localhost:8001"
                  // ? "http://api.handsmade.fudex-tech.net"
                  "https://apis.handsmade.app",
        //https://apis.handesmade.com/admin
        title_suffix: "HandsMade",
        error_path: "login",
        website_link: "",
        access_token: null,
        user: null,
        user_type: null,
        debug: process.env.NODE_ENV != "production" ? true : false,
        alert_error: true,
        permissions: [],
        timezone: "Asia/Riyadh",
        vendors_selected_fields: [
            "#",
            "name",
            "user",
            "live_orders",
            "completed_orders",
            "cancelled_orders",
            "wallet",
            "created_at",
            "status",
            "device_type",
            "products",
            "actions"
        ],
        users_selected_fields: [
            "#",
            "name",
            "mobile",
            "live_orders",
            "completed_orders",
            "cancelled_orders",
            "wallet",
            "created_at",
            "device_type",
            "status",
            "actions"
        ],
        stores_selected_fields: [
            "#",
            "image",
            "name",
            "vendor",
            "created_at",
            "status",
            "actions"
        ],
        wallet_selected_fields: [
            "#",
            "transaction_id",
            "order_id",
            "transaction_alias",
            "amount",
            "balance",
            "is_due",
            "due_date",
            "created_at"
        ],
        all_stores_selected_fields: [
            "#",
            "image",
            "name",
            "vendor",
            "discount",
            "created_at",
            "status",
            "actions"
        ],
        categories_selected_fields: [
            "#",
            "image",
            "name",
            "products",
            "created_at",
            "status",
            "actions"
        ],
        subcategories_selected_fields: [
            "#",
            "image",
            "name",
            "products",
            "created_at",
            "status",
            "actions"
        ],
        vendor_products_selected_fields: [
            "#",
            "image",
            "name",
            "store",
            "category",
            "price",
            "created_at",
            "status",
            "actions"
        ],
        all_products_selected_fields: [
            "#",
            "image",
            "name",
            "store",
            "vendor",
            "category",
            "price",
            "created_at",
            "status",
            "actions"
        ],
        brands_selected_fields: [
            "#",
            "image",
            "name",
            "created_at",
            "status",
            "actions"
        ],
        delivery_companies_selected_fields: [
            "#",
            "image",
            "name",
            "created_at",
            "status",
            "actions"
        ],
        help_selected_fields: [
            "#",
            "title",
            "questions",
            "created_at",
            "status",
            "actions"
        ],
        contacts_selected_fields: [
            "#",
            "name",
            "mobile",
            "subject",
            "created_at",
            "status",
            "actions"
        ],
        coupons_selected_fields: [
            "#",
            "name",
            "code",
            "coupon_type",
            "discount",
            "admin_per",
            "vendor_per",
            "created_at",
            "status",
            "actions"
        ],
        orders_selected_fields: [
            "#",
            "order_id",
            "user",
            "store",
            "vendor",
            "products_price",
            "delivery_fees",
            "total",
            "status",
            "created_at",
            "actions"
        ],
        options_selected_fields: [
            "#",
            "name",
            "category",
            "status",
            "created_at",
            "actions"
        ],
        incomes_selected_fields: [
            "#",
            "order_id",
            "payment_method",
            "products_price",
            "delivery_fees",
            "total",
            "admin_credit",
            "admin_debit",
            "vendor_credit",
            "vendor_debit",
            "delivery_credit",
            "delivery_debit",
            "created_at"
            //"actions"
        ],
        notification_selected_fields: [
            "#",
            "notification_user_type",
            "notification_type",
            "notification_action",
            "created_at",
            "status",
            "actions"
        ],
        regions_selected_fields: [
            "#",
            "name",
            "created_at",
            "status",
            "actions"
        ],
        cities_selected_fields: [
            "name",
            "region",
            "stores",
            "live_orders",
            "completed_orders",
            "created_at",
            "status",
            "actions"
        ],
        countries_selected_fields: [
            "#",
            "name",
            "created_at",
            "status",
            "actions"
        ],
        offers_selected_fields: [
            "#",
            "name",
            "start_date",
            "end_date",
            "discount_type",
            "created_at",
            "status",
            "actions"
        ],
        home_section_selected_fields: [
            "#",
            "title",
            "desc",
            "created_at",
            "status",
            "actions"
        ]
    },
    getters: {},
    mutations: {
        setAccessToken(state, data) {
            state.access_token = data;
        },
        setUserType(state, data) {
            state.user_type = data;
        },
        setUser(state, data) {
            state.user = data;
        },
        setWebsiteLink(state, data) {
            state.website_link = data;
        },
        setPermissions(state, data) {
            state.permissions = data;
        },
        setAlertError(state, data) {
            state.alert_error = data;
        },
        setTimezone(state, data) {
            state.timezone = data;
        },
        setVendorsSelectedFields(state, data) {
            state.vendors_selected_fields = data;
        },
        setStoresSelectedFields(state, data) {
            state.stores_selected_fields = data;
        },
        setAllStoresSelectedFields(state, data) {
            state.all_stores_selected_fields = data;
        },
        setCategoriesSelectedFields(state, data) {
            state.categories_selected_fields = data;
        },
        setSubCategoriesSelectedFields(state, data) {
            state.subcategories_selected_fields = data;
        },
        setVendorProductsSelectedFields(state, data) {
            state.vendor_products_selected_fields = data;
        },
        setAllProductsSelectedFields(state, data) {
            state.all_products_selected_fields = data;
        },
        setBrandsSelectedFields(state, data) {
            state.brands_selected_fields = data;
        },
        setUsersSelectedFields(state, data) {
            state.users_selected_fields = data;
        },
        setHelpSelectedFields(state, data) {
            state.help_selected_fields = data;
        },
        setContactsSelectedFields(state, data) {
            state.contacts_selected_fields = data;
        },
        setCouponsSelectedFields(state, data) {
            state.coupons_selected_fields = data;
        },
        setOrdersSelectedFields(state, data) {
            state.orders_selected_fields = data;
        },
        setWalletSelectedFields(state, data) {
            state.wallet_selected_fields = data;
        },
        setOptionsSelectedFields(state, data) {
            state.options_selected_fields = data;
        },
        setIncomesSelectedFields(state, data) {
            state.incomes_selected_fields = data;
        },
        setDeliveryCompaniesSelectedFields(state, data) {
            state.delivery_companies_selected_fields = data;
        },
        setNotificationsSelectedFields(state, data) {
            state.notification_selected_fields = data;
        },
        setRegionsSelectedFields(state, data) {
            state.regions_selected_fields = data;
        },
        setCitiesSelectedFields(state, data) {
            state.cities_selected_fields = data;
        },
        setCountriesSelectedFields(state, data) {
            state.countries_selected_fields = data;
        },
        setOffersSelectedFields(state, data) {
            state.offers_selected_fields = data;
        },
        setHomeSectionsSelectedFields(state, data) {
            state.home_section_selected_fields = data;
        }
    },
    actions: {},
    modules: {},
    plugins: [
        createPersistedState({
            storage: {
                getItem: key => {
                    try {
                        return ls.get(key);
                    } catch {
                        return null;
                    }
                },
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            },
            reducer: state => ({
                user: state.user,
                access_token: state.access_token,
                user_type: state.user_type,
                timezone: state.timezone,
                vendors_selected_fields: state.vendors_selected_fields,
                stores_selected_fields: state.stores_selected_fields,
                all_stores_selected_fields: state.all_stores_selected_fields,
                categories_selected_fields: state.categories_selected_fields,
                subcategories_selected_fields:
                    state.subcategories_selected_fields,
                vendor_products_selected_fields:
                    state.vendor_products_selected_fields,
                all_products_selected_fields:
                    state.all_products_selected_fields,
                brands_selected_fields: state.brands_selected_fields,
                users_selected_fields: state.users_selected_fields,
                help_selected_fields: state.help_selected_fields,
                contacts_selected_fields: state.contacts_selected_fields,
                coupons_selected_fields: state.coupons_selected_fields,
                orders_selected_fields: state.orders_selected_fields,
                wallet_selected_fields: state.wallet_selected_fields,
                options_selected_fields: state.options_selected_fields,
                incomes_selected_fields: state.incomes_selected_fields,
                delivery_companies_selected_fields:
                    state.delivery_companies_selected_fields,
                notification_selected_fields:
                    state.notification_selected_fields,
                regions_selected_fields: state.regions_selected_fields,
                cities_selected_fields: state.cities_selected_fields,
                countries_selected_fields: state.countries_selected_fields,
                offers_selected_fields: state.offers_selected_fields,
                home_section_selected_fields: state.home_section_selected_fields
            })
        })
    ]
});
