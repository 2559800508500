<template>
    <div>
        <b-modal
            ref="show_item_modal"
            :title="$t('edit') + ' ' + $t('statistic')"
            header-class="new_modal_header"
            hide-footer
        >
            <div class="d-block">
                <form class="col-md-12">
                    <b-alert
                        :show="edit_error || edit_success ? true : false"
                        :variant="edit_error ? 'danger' : 'success'"
                        dismissible
                        @dismissed="edit_error = edit_success = ''"
                    >
                        {{ edit_error || edit_success }}
                    </b-alert>
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <label>{{ $t("name_ar") }}</label>
                            <input
                                type="text"
                                class="form-control rtl"
                                :class="{ 'is-invalid': edit_errors.title_ar }"
                                v-model="itemDetails.title_ar"
                            />
                            <span class="error invalid-feedback">{{
                                (edit_errors.title_ar || []).join("-")
                            }}</span>
                        </div>

                        <div class="form-group col-md-12">
                            <label>{{ $t("name_en") }}</label>
                            <input
                                type="text"
                                class="form-control ltr"
                                v-model="itemDetails.title_en"
                            />
                        </div>

                        <div class="form-group col-md-4">
                            <label>{{ $t("value") }}</label>
                            <input
                                type="text"
                                class="form-control ltr"
                                v-model="itemDetails.value"
                            />
                        </div>
                    </div>

                    <button
                        type="submit"
                        class="btn btn-info mt-2"
                        :disabled="edit_disabled"
                        @click.prevent="updateStatistic"
                    >
                        {{ $t("save") }}
                        <b-spinner small v-if="edit_disabled"></b-spinner>
                    </button>
                </form>
            </div>
        </b-modal>

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <br />

                        <b-tabs
                            pills
                            card
                            no-key-nav
                            content-class="custom-card"
                            nav-wrapper-class="custom-nav-card"
                        >
                            <b-card-text>
                                <b-card no-body class="card">
                                    <b-card-header>
                                        <form class="col-md-12">
                                            <b-alert
                                                :show="
                                                    edit_error || edit_success
                                                        ? true
                                                        : false
                                                "
                                                :variant="
                                                    edit_error
                                                        ? 'danger'
                                                        : 'success'
                                                "
                                                dismissible
                                                @dismissed="
                                                    edit_error = edit_success =
                                                        ''
                                                "
                                            >
                                                {{ edit_error || edit_success }}
                                            </b-alert>

                                            <div class="row">
                                                <div class="col-md-2">
                                                    {{ $t("about_us_image") }}
                                                </div>

                                                <div
                                                    class="form-group col-md-4"
                                                >
                                                    <label
                                                        >{{ $t("select") }}
                                                        {{
                                                            $t("photo")
                                                        }}:</label
                                                    >
                                                    <br />
                                                    <div
                                                        class="image_div"
                                                        v-if="
                                                            edit_data_image ||
                                                                about_image.value
                                                        "
                                                    >
                                                        <img
                                                            :src="
                                                                edit_data_image ||
                                                                    
                                                                        about_image.value
                                                            "
                                                            @error="
                                                                $event.target.src =
                                                                    '/images/placeholder.jpg'
                                                            "
                                                        />
                                                        <i
                                                            v-if="
                                                                edit_data_image
                                                            "
                                                            class="fa fa-times"
                                                            @click.prevent="
                                                                edit_data_image = edit_data_icon = null
                                                            "
                                                        ></i>
                                                    </div>
                                                    <b-form-file
                                                        v-if="!edit_data_image"
                                                        accept="image/*"
                                                        @change.prevent="
                                                            onImageChange(
                                                                $event,
                                                                'edit_data'
                                                            )
                                                        "
                                                        ref="edit_data_icon"
                                                    ></b-form-file>
                                                    <span
                                                        class="error invalid-feedback"
                                                        >{{
                                                            (
                                                                edit_errors.image_input ||
                                                                []
                                                            ).join("-")
                                                        }}</span
                                                    >
                                                </div>

                                                <div class="col-md-4">
                                                    <button
                                                        type="submit"
                                                        class="btn btn-info mt-2"
                                                        :disabled="
                                                            edit_disabled
                                                        "
                                                        @click.prevent="
                                                            updateAboutUsImage
                                                        "
                                                    >
                                                        {{ $t("save") }}
                                                        <b-spinner
                                                            small
                                                            v-if="edit_disabled"
                                                        ></b-spinner>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </b-card-header>
                                    <b-card-header>
                                        <h5>{{ $t("statistics") }}</h5>

                                        <button
                                            v-if="
                                                items.length > 0 &&
                                                    batch_summary.is_paid
                                            "
                                            class="btn btn-sm btn-success mr-3 ml-3"
                                            @click="paidDone"
                                            :disabled="paid_done_disabled"
                                        >
                                            {{ batch_summary.paid_at }}
                                            <i
                                                class="fa fa-check mr-1 ml-1"
                                                v-if="!paid_done_disabled"
                                            ></i>
                                            <b-spinner
                                                small
                                                v-else
                                                class="mr-1 ml-1"
                                            ></b-spinner>
                                        </button>
                                    </b-card-header>
                                    <!-- /.card-header -->
                                    <b-card-body class="table-responsive p-0">
                                        <table class="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>{{ $t("name_ar") }}</th>
                                                    <th>{{ $t("name_en") }}</th>
                                                    <th>{{ $t("value") }}</th>
                                                    <th v-if="true">
                                                        {{ $t("actions") }}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td
                                                        colspan="4"
                                                        v-if="!load"
                                                    >
                                                        <i
                                                            class="fa fa-sync  fa-spin load-con"
                                                        ></i>
                                                    </td>
                                                    <td
                                                        colspan="4"
                                                        class="no_data"
                                                        v-if="
                                                            load &&
                                                                items.length ==
                                                                    0
                                                        "
                                                    >
                                                        {{ $t("no_data") }}
                                                    </td>
                                                </tr>
                                                <tr
                                                    v-for="(item, i) in items"
                                                    :key="i"
                                                    v-show="load"
                                                >
                                                    <td>{{ i + 1 }}</td>
                                                    <td>
                                                        {{ item.title_ar }}
                                                    </td>
                                                    <td>
                                                        {{ item.title_en }}
                                                    </td>
                                                    <td>
                                                        {{ item.value }}
                                                    </td>
                                                    <td
                                                        class="actions-group"
                                                        v-if="true"
                                                    >
                                                        <button
                                                            v-if="
                                                                $can(
                                                                    'show settings'
                                                                )
                                                            "
                                                            class="btn btn-info btn-sm"
                                                            v-tooltip="
                                                                $t('show')
                                                            "
                                                            @click="
                                                                showPopUpItem(
                                                                    item.id
                                                                )
                                                            "
                                                        >
                                                            <i
                                                                class="fas fa-eye"
                                                            >
                                                            </i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </b-card-body>
                                    <!-- /.card-body -->
                                </b-card>
                            </b-card-text>
                        </b-tabs>
                        <!-- /.card -->
                    </div>
                </div>
                <!-- /.row -->
            </div>
            <!-- /.container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>

<script>
export default {
    name: "AboutUs",
    data() {
        return {
            load: false,
            items: [],
            search_key: "",
            c_search_key: "",
            old_search_key: "",
            search_disabled: false,
            disabled_s: [],
            currentPage: 1,
            perPage: 1,
            rows: 0,
            w_request: {},
            sort_key: "",
            sort_type: "asc",
            batch_filter: "",
            batches: [],
            batch_summary: {},
            excel_disabled: false,
            paid_done_disabled: false,
            show_data: {},
            itemDetails: {},
            edit_disabled: false,
            edit_success: "",
            edit_data: {},
            edit_error: "",
            edit_errors: "",
            show_success: "",
            about_image: {},
            image_url: "",
            edit_data_icon: null,
            edit_data_image: null,
            edit_data_icon_icon: null,
            edit_data_icon_image: null
        };
    },
    created() {
        this.getItems();
        this.getAboutImage();
    },
    watch: {
        currentPage() {
            this.getItems();
        },
        table_selected_fields() {
            this.$store.commit(
                "setInvoicesSelectedFields",
                this.table_selected_fields
            );
        },
        batch_filter() {
            this.load = false;
            this.batch_summary = {};
            this.getItems();
        }
    },
    methods: {
        getItems() {             
            return this.axios
                .get("statistics", {
                    params: {
                        page: this.currentPage,
                        search_key: this.search_key,
                        sort_key: this.sort_key,
                        sort_type: this.sort_type,
                        batch_filter: this.batch_filter
                    }
                })
                .then(response => {
                    let res = response.data;
                    if (res.error_flag == 0) {
                        this.items = res.result.items;
                        this.image_url = res.result.image_url; 
                    }
                })
                .catch(error => {
                    this.handleError("getItems", error);
                })
                .finally(() => {
                    this.load = true;
                });
        },

        getAboutImage() {             
            return this.axios
                .get("statistics/about_image")
                .then(response => {
                    let res = response.data;                     
                    if (res.error_flag == 0) {
                        this.about_image = res.result.about_image;                         
                    }
                })
                .catch(error => {
                    this.handleError("getAboutImage", error);
                })
                .finally(() => {
                    this.load = true;
                });
        },

        sortTable(key) {
            if (this.sort_key != key) {
                this.sort_type = "asc";
            } else {
                this.sort_type = this.sort_type == "asc" ? "desc" : "asc";
            }
            this.sort_key = key;
            this.getItems();
        },
        searchAll() {
            if (this.search_key != this.old_search_key) {
                this.old_search_key = this.search_key;
                this.search_disabled = true;
                this.getItems().then(() => {
                    this.search_disabled = false;
                });
            }
        },
        showPopUpItem(id) {
            this.show_success = this.edit_error = "";
            this.show_data = { ...this.items.find(e => e.id == id) };
            this.itemDetails = this.show_data;
            this.$refs.show_item_modal.show();
        },

        updateAboutUsImage() {
            this.edit_disabled = true;
            let fd = new FormData();

            if (this.edit_data_icon) {
                fd.append(
                    "image_input",
                    this.edit_data_icon,
                    this.edit_data_icon.name
                );
            }

            if (this.edit_data_icon_icon) {
                fd.append(
                    "icon_input",
                    this.edit_data_icon_icon,
                    this.edit_data_icon_icon.name
                );
            }

            this.edit_error = this.edit_success = "";
            this.edit_errors = {};
            this.axios
                .post("statistics/update/about_image", fd)
                .then(response => {
                    let res = response.data;

                    if (res.error_flag == 0) {
                        this.edit_success = res.message;
                        this.edit_data_image = null;
                        this.edit_data_icon = null;
                        this.edit_data_icon_image = null;
                        this.edit_data_icon_icon = null;
                    } else if (res.error_flag == 1) {
                        if (typeof res.message === "object") {
                            this.edit_errors = res.message;
                        } else {
                            this.edit_error = res.message;
                        }
                    } else {
                        this.handleError("updateAboutUsImage", res.message);
                    }
                })
                .catch(error => {
                    this.handleError("updateAboutUsImage", error);
                })
                .finally(() => {
                    this.edit_disabled = false;
                });
        },

        updateStatistic() {
            this.edit_disabled = true;
            this.edit_error = this.edit_success = "";

            this.axios
                .post("statistics/update", this.show_data)
                .then(response => {
                    let res = response.data;
                    this.edit_errors = {};
                    if (res.error_flag == 0) {
                        this.edit_success = res.message;
                        this.show_data = { ...res.result.item };
                        let i = this.items.findIndex(
                            e => e.id == this.show_data.id
                        );
                        this.$set(this.items, i, res.result.item);
                    } else if (res.error_flag == 1) {
                        if (typeof res.message === "object") {
                            this.edit_errors = res.message;
                        } else {
                            this.edit_error = res.message;
                        }
                    } else {
                        this.handleError("updateStatistic", res.message);
                    }
                })
                .catch(error => {
                    this.handleError("updateStatistic", error);
                })
                .finally(() => {
                    this.edit_disabled = false;
                });
        }
    }
};
</script>
