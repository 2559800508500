<template>
    <div>
        <b-modal
            ref="customize_table_modal"
            centered
            :title="$t('customize_table')"
            header-class="new_modal_header"
            hide-footer
        >
            <div class="d-block">
                <div class="row clearfix">
                    <div class="col-md-12">
                        <div class="form-row" style="padding: 0 20px">
                            <div
                                class="form-group col-md-6"
                                v-for="(field, i) in table_fields"
                                :key="i"
                            >
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    aria-label="Checkbox for following text input"
                                    :value="field"
                                    :id="'f_' + field"
                                    v-model="table_selected_fields"
                                    style="vertical-align: middle;"
                                /><label
                                    class="form-check-label"
                                    :for="'f_' + field"
                                    style="color: blue;"
                                >
                                    {{ $t(field.toLowerCase()) }}</label
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>

        <b-modal
            ref="edit_item_modal"
            :title="$t('edit') + ' ' + $t('association')"
            header-class="new_modal_header"
            hide-footer
        >
            <div class="d-block">
                <form class="col-md-12">
                    <b-alert
                        :show="edit_error || edit_success ? true : false"
                        :variant="edit_error ? 'danger' : 'success'"
                        dismissible
                        @dismissed="edit_error = edit_success = ''"
                    >
                        {{ edit_error || edit_success }}
                    </b-alert>
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <label>{{ $t("association_name") }}</label>
                            <input
                                type="text"
                                class="form-control rtl"
                                :class="{ 'is-invalid': edit_errors.name }"
                                v-model="itemDetails.name"
                            />
                            <span class="error invalid-feedback">{{
                                (edit_errors.name || []).join("-")
                            }}</span>
                        </div>

                        <div class="form-group col-md-12">
                            <label>{{ $t("manager") }}</label>
                            <input
                                type="text"
                                class="form-control rtl"
                                :class="{ 'is-invalid': edit_errors.manager }"
                                v-model="itemDetails.manager"
                            />
                            <span class="error invalid-feedback">{{
                                (edit_errors.manager || []).join("-")
                            }}</span>
                        </div>

                        <div class="form-group col-md-12">
                            <label>{{ $t("mobile") }}</label>
                            <input
                                type="text"
                                class="form-control rtl"
                                :class="{ 'is-invalid': edit_errors.mobile }"
                                v-model="itemDetails.mobile"
                            />
                            <span class="error invalid-feedback">{{
                                (edit_errors.mobile || []).join("-")
                            }}</span>
                        </div>
                    </div>

                    <button
                        type="submit"
                        class="btn btn-info mt-2"
                        :disabled="edit_disabled"
                        @click.prevent="updateAssociation"
                    >
                        {{ $t("save") }}
                        <b-spinner small v-if="edit_disabled"></b-spinner>
                    </button>
                </form>
            </div>
        </b-modal>

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <b-tabs
                            pills
                            card
                            no-key-nav
                            content-class="custom-card"
                            nav-wrapper-class="custom-nav-card"
                        >
                            <b-tab no-body :title="$t('all')" active>
                                <template v-slot:title>
                                    <i class="fa fa-list ml-2 mr-2"></i>
                                    {{ $t("all") }}
                                </template>
                                <b-card-text>
                                    <b-card no-body class="card">
                                        <b-card-header>
                                            <div class="card-tools">
                                                <div
                                                    class="input-group input-group-sm"
                                                >
                                                    <i
                                                        class="fas fa-cog c_pointer customize_table"
                                                        :title="
                                                            $t(
                                                                'customize_table'
                                                            )
                                                        "
                                                        @click="customizeTable"
                                                    ></i>
                                                    <input
                                                        type="text"
                                                        class="form-control float-right"
                                                        :placeholder="
                                                            $t('search')
                                                        "
                                                        v-model="search_key"
                                                    />
                                                    <div
                                                        class="input-group-append"
                                                    >
                                                        <button
                                                            type="submit"
                                                            :disabled="
                                                                search_disabled
                                                            "
                                                            @click.prevent="
                                                                searchAll
                                                            "
                                                            class="btn btn-default"
                                                        >
                                                            <i
                                                                class="fa fa-spinner fa-spin"
                                                                v-if="
                                                                    search_disabled
                                                                "
                                                            ></i>
                                                            <i
                                                                class="fas fa-search"
                                                                v-else
                                                            ></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-card-header>
                                        <!-- /.card-header -->
                                        <b-card-body
                                            class="table-responsive p-0"
                                        >
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th
                                                            v-if="
                                                                table_selected_fields.includes(
                                                                    '#'
                                                                )
                                                            "
                                                        >
                                                            #
                                                        </th>
                                                        <th
                                                            v-if="
                                                                table_selected_fields.includes(
                                                                    'name'
                                                                )
                                                            "
                                                            class="sort"
                                                            @click="
                                                                sortTable(
                                                                    'name'
                                                                )
                                                            "
                                                        >
                                                            {{ $t("association_name") }}
                                                        </th>
                                                        <th
                                                            v-if="
                                                                table_selected_fields.includes(
                                                                    'manager'
                                                                )
                                                            "
                                                            class="sort"
                                                            @click="
                                                                sortTable(
                                                                    'manager'
                                                                )
                                                            "
                                                        >
                                                            {{ $t("manager") }}
                                                        </th>

                                                        <th
                                                            v-if="
                                                                table_selected_fields.includes(
                                                                    'mobile'
                                                                )
                                                            "
                                                            class="sort"
                                                            @click="
                                                                sortTable(
                                                                    'mobile'
                                                                )
                                                            "
                                                        >
                                                            {{ $t("mobile") }}
                                                        </th>

                                                        <th
                                                            v-if="
                                                                table_selected_fields.includes(
                                                                    'status'
                                                                )
                                                            "
                                                            class="sort"
                                                            @click="
                                                                sortTable(
                                                                    'status'
                                                                )
                                                            "
                                                        >
                                                            {{ $t("status") }}
                                                        </th>

                                                        <th
                                                            v-if="
                                                                table_selected_fields.includes(
                                                                    'actions'
                                                                )
                                                            "
                                                        >
                                                            {{ $t("actions") }}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td
                                                            :colspan="
                                                                table_selected_fields.length
                                                            "
                                                            v-if="!load"
                                                        >
                                                            <i
                                                                class="fa fa-sync  fa-spin load-con"
                                                            ></i>
                                                        </td>
                                                        <td
                                                            :colspan="
                                                                table_selected_fields.length
                                                            "
                                                            class="no_data"
                                                            v-if="
                                                                load &&
                                                                    associations.length ==
                                                                        0
                                                            "
                                                        >
                                                            {{ $t("no_data") }}
                                                        </td>
                                                    </tr>
                                                    <tr
                                                        v-for="(item,
                                                        i) in associations"
                                                        :key="i"
                                                        v-show="load"
                                                    >
                                                        <td
                                                            v-if="
                                                                table_selected_fields.includes(
                                                                    '#'
                                                                )
                                                            "
                                                        >
                                                            {{ i + 1 }}
                                                        </td>
                                                        <td
                                                            v-if="
                                                                table_selected_fields.includes(
                                                                    'name'
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                item["name"] ||
                                                                    "-"
                                                            }}
                                                        </td>

                                                        <td
                                                            v-if="
                                                                table_selected_fields.includes(
                                                                    'manager'
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                item[
                                                                    "manager"
                                                                ] || "-"
                                                            }}
                                                        </td>

                                                        <td
                                                            v-if="
                                                                table_selected_fields.includes(
                                                                    'mobile'
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                item[
                                                                    "mobile"
                                                                ] || "-"
                                                            }}
                                                        </td>

                                                        <td
                                                            v-if="
                                                                table_selected_fields.includes(
                                                                    'status'
                                                                )
                                                            "
                                                        >
                                                            <span
                                                                :class="
                                                                    'badge badge-' +
                                                                        (item.status ==
                                                                        'on'
                                                                            ? 'info'
                                                                            : 'danger')
                                                                "
                                                                >{{
                                                                    $t(
                                                                        item.status
                                                                    )
                                                                }}</span
                                                            >
                                                        </td>

                                                        <td
                                                            class="actions-group"
                                                            v-if="
                                                                table_selected_fields.includes(
                                                                    'actions'
                                                                )
                                                            "
                                                        >
                                                            <button
                                                                v-if="
                                                                    $can(
                                                                        'edit associations'
                                                                    )
                                                                "
                                                                class="btn btn-primary btn-sm"
                                                                v-tooltip="
                                                                    $t(
                                                                        'change_status'
                                                                    )
                                                                "
                                                                @click="
                                                                    changeStatus(
                                                                        item.id
                                                                    )
                                                                "
                                                            >
                                                                <b-spinner
                                                                    small
                                                                    v-if="
                                                                        disabled_s[
                                                                            i
                                                                        ]
                                                                    "
                                                                >
                                                                </b-spinner>
                                                                <i
                                                                    class="fas fa-exchange-alt"
                                                                    v-else
                                                                ></i>
                                                            </button>

                                                            <button
                                                                v-if="
                                                                    $can(
                                                                        'edit associations'
                                                                    )
                                                                "
                                                                class="btn btn-info btn-sm"
                                                                v-tooltip="
                                                                    $t('edit')
                                                                "
                                                                @click="
                                                                    showEditItem(
                                                                        item.id
                                                                    )
                                                                "
                                                            >
                                                                <i
                                                                    class="fas fa-pencil-alt"
                                                                >
                                                                </i>
                                                            </button>

                                                            <button
                                                                v-if="
                                                                    $can(
                                                                        'delete associations'
                                                                    )
                                                                "
                                                                @click="
                                                                    deleteItem(
                                                                        item.id
                                                                    )
                                                                "
                                                                class="btn btn-danger btn-sm"
                                                                v-tooltip="
                                                                    $t('delete')
                                                                "
                                                            >
                                                                <i
                                                                    class="fas fa-trash"
                                                                >
                                                                </i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </b-card-body>
                                        <!-- /.card-body -->
                                        <b-card-footer
                                            class="card-footer clearfix"
                                        >
                                            <b-pagination
                                                class="m-2"
                                                v-model="currentPage"
                                                :total-rows="rows"
                                                :per-page="perPage"
                                                :prev-text="$t('previous')"
                                                :next-text="$t('next')"
                                                align="right"
                                                :first-number="true"
                                                :last-number="true"
                                            ></b-pagination>
                                        </b-card-footer>
                                    </b-card> </b-card-text
                            ></b-tab>
                            <b-tab
                                no-body
                                :title="$t('add')"
                                v-if="$can('add associations')"
                            >
                                <template v-slot:title>
                                    <i class="fa fa-plus ml-2 mr-2"></i>
                                    {{ $t("add") }}
                                </template>
                                <b-card-text>
                                    <b-card no-body>
                                        <b-card-body>
                                            <form class="col-md-8">
                                                <b-alert
                                                    :show="
                                                        add_error || add_success
                                                            ? true
                                                            : false
                                                    "
                                                    :variant="
                                                        add_error
                                                            ? 'danger'
                                                            : 'success'
                                                    "
                                                    dismissible
                                                    @dismissed="
                                                        add_error = add_success =
                                                            ''
                                                    "
                                                >
                                                    {{
                                                        add_error || add_success
                                                    }}
                                                </b-alert>

                                                <div>
                                                    <b-tabs
                                                        content-class="mt-3"
                                                        no-key-nav
                                                    >
                                                        <b-tab
                                                            :title="$t('info')"
                                                            active
                                                        >
                                                            <div
                                                                class="form-row "
                                                            >
                                                                <div
                                                                    class="form-group col-md-12"
                                                                >
                                                                    <label>{{
                                                                        $t(
                                                                            "association_name"
                                                                        )
                                                                    }}</label>
                                                                    <input
                                                                        type="text"
                                                                        class="form-control"
                                                                        v-model="
                                                                            add_data.name
                                                                        "
                                                                    />
                                                                </div>
                                                                <div
                                                                    class="form-group col-md-6"
                                                                >
                                                                    <label>{{
                                                                        $t(
                                                                            "manager"
                                                                        )
                                                                    }}</label>
                                                                    <input
                                                                        type="text"
                                                                        class="form-control ltr"
                                                                        v-model="
                                                                            add_data.manager
                                                                        "
                                                                    />
                                                                </div>

                                                                <div
                                                                    class="form-group col-md-6"
                                                                >
                                                                    <label>{{
                                                                        $t(
                                                                            "contact_mobile"
                                                                        )
                                                                    }}</label>
                                                                    <input
                                                                        type="number"
                                                                        min="0"
                                                                        class="form-control ltr"
                                                                        v-model="
                                                                            add_data.mobile
                                                                        "
                                                                    />
                                                                </div>
                                                            </div>
                                                        </b-tab>
                                                    </b-tabs>
                                                </div>

                                                <button
                                                    type="submit"
                                                    class="btn btn-info mt-2"
                                                    :disabled="add_disabled"
                                                    @click="addItem"
                                                >
                                                    {{ $t("add") }}
                                                    <b-spinner
                                                        small
                                                        v-if="add_disabled"
                                                    ></b-spinner>
                                                </button>
                                            </form>
                                        </b-card-body>
                                        <!-- /.card-body -->
                                    </b-card>
                                </b-card-text></b-tab
                            >
                        </b-tabs>

                        <!-- /.card -->
                    </div>
                </div>
                <!-- /.row -->
            </div>
            <!-- /.container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>

<script>
export default {
    name: "Associations",
    data() {
        return {
            load: false,
            associations: [],
            search_key: "",
            old_search_key: "",
            disabled_s: [],
            currentPage: 1,
            perPage: 1,
            rows: 1,
            add_disabled: false,
            add_success: "",
            add_error: "",
            add_progress: 0,
            add_data: {
                city_id: "",
                lat: 1,
                lng: 1
            },
            search_disabled: false,
            disabled: false,
            sort_key: "",
            sort_type: "asc",
            table_fields: [
                "#",
                "name",
                "manager",
                "mobile",
                "status",
                "created_at",
                "actions"
            ],
            table_selected_fields: [],
            top_disabled: false,
            page_type: "",
            show_data: {},
            itemDetails: {},
            edit_disabled: false,
            edit_success: "",
            edit_error: "",
            edit_errors: ""
        };
    },
    created() {
        this.table_selected_fields = this.$store.state.users_selected_fields;
        this.getAssociations();
    },
    watch: {
        currentPage() {
            this.getAssociations();
        },
        table_selected_fields() {
            this.$store.commit(
                "setUsersSelectedFields",
                this.table_selected_fields
            );
        }
    },
    methods: {
        showEditItem(id) {
            this.show_success = this.edit_error = "";
            this.show_data = { ...this.associations.find(e => e.id == id) };
            this.itemDetails = this.show_data;
            this.$refs.edit_item_modal.show();
        },
        updateAssociation() {
            this.edit_disabled = true;
            this.edit_error = this.edit_success = "";

            this.axios
                .post("associations/update", this.show_data)
                .then(response => {
                    let res = response.data;
                    this.edit_errors = {};
                    if (res.error_flag == 0) {
                        this.edit_success = res.message;
                        this.show_data = { ...res.result.item };
                        let i = this.associations.findIndex(
                            e => e.id == this.show_data.id
                        );
                        this.$set(this.associations, i, res.result.item);
                    } else if (res.error_flag == 1) {
                        if (typeof res.message === "object") {
                            this.edit_errors = res.message;
                        } else {
                            this.edit_error = res.message;
                        }
                    } else {
                        this.handleError("updateAssociation", res.message);
                    }
                })
                .catch(error => {
                    this.handleError("updateAssociation", error);
                })
                .finally(() => {
                    this.edit_disabled = false;
                });
        },
        getAssociations() {
            return this.axios
                .get("associations", {
                    params: {
                        page: this.currentPage,
                        search_key: this.search_key,
                        page_type: this.page_type,
                        sort_type: this.sort_type,
                        sort_key: this.sort_key
                    }
                })
                .then(response => {
                    let res = response.data;
                    if (res.error_flag == 0) {
                        this.associations = res.result.associations.data;
                        this.rows = res.result.associations.pagination.total;
                        this.perPage =
                            res.result.associations.pagination.per_page;
                    }
                })
                .catch(error => {
                    this.handleError("getAssociations", error);
                })
                .finally(() => {
                    this.load = true;
                });
        },
        sortTable(key) {
            if (this.sort_key != key) {
                this.sort_type = "asc";
            } else {
                this.sort_type = this.sort_type == "asc" ? "desc" : "asc";
            }
            this.sort_key = key;
            this.getAssociations();
        },
        searchAll() {
            if (this.search_key != this.old_search_key) {
                this.old_search_key = this.search_key;
                this.search_disabled = true;
                this.getAssociations().then(() => {
                    this.search_disabled = false;
                });
            }
        },
        addItem() {
            this.add_disabled = true;
            let fd = new FormData();

            Object.keys(this.add_data).forEach(key => {
                if (this.add_data[key]) {
                    fd.append(key, this.add_data[key]);
                }
            });
            this.axios
                .post("associations/add", fd)
                .then(response => {
                    let res = response.data;
                    this.add_error = this.add_success = "";
                    if (res.error_flag == 0) {
                        this.add_success = res.message;
                        this.getAssociations();
                        this.add_data = {
                            city_id: "",
                            lat: 1,
                            lng: 1
                        };
                    } else if (res.error_flag == 1) {
                        this.add_error = res.message;
                    } else {
                        this.handleError("addItem", res.message);
                    }
                })
                .catch(error => {
                    this.handleError("addItem", error);
                })
                .finally(() => {
                    this.add_disabled = false;
                    this.add_progress = 0;
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: "smooth"
                    });
                });
        },
        changeStatus(id) {
            let index = this.findIndex(this.associations, "id", id);
            let i = this.findIndex(this.associations, "id", id);
            this.$set(this.disabled_s, i, true);
            this.axios
                .post("associations/update_status/" + id)
                .then(response => {
                    let res = response.data;
                    if (res.error_flag == 0) {
                        this.associations[index].status = res.result.status;
                    } else if (res.error_flag == 1) {
                        this.alert(res.message, this.$t("error"));
                    } else {
                        this.handleError("changeStatus", res.message);
                    }
                })
                .catch(error => {
                    this.handleError("chnageStatus", error);
                })
                .finally(() => {
                    this.$set(this.disabled_s, i, false);
                });
        },
        deleteItem(id) {
            let index = this.findIndex(this.associations, "id", id);

            this.$bvModal
                .msgBoxConfirm(this.$t("are_you_sure"), {
                    okVariant: "danger",
                    okTitle: this.$t("yes"),
                    cancelTitle: this.$t("no"),
                    centered: true,
                    footerClass: "custom_modal_footer",
                    contentClass: "custom_modal_content"
                })
                .then(value => {
                    if (value) {
                        this.axios
                            .post("associations/delete/" + id)
                            .then(response => {
                                let res = response.data;
                                if (res.error_flag == 0) {
                                    this.associations.splice(index, 1);
                                    this.$noty.success(res.message);
                                } else if (res.error_flag == 1) {
                                    this.alert(res.message, this.$t("error"));
                                } else {
                                    this.handleError("deleteItem", res.message);
                                }
                            })
                            .catch(error => {
                                this.handleError("deleteItem", error);
                            });
                    }
                });
        }
    }
};
</script>

<style scoped>
.img_div {
    margin: 15px;
    display: inline-block;
    position: relative;
}

.img_div img {
    height: 120px;
    width: 120px;
}

.img_div div {
    background: #ff0018;
    position: absolute;
    top: -10px;
    color: white;
    left: -10px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
}
</style>
