<template>
  <div>
    <b-modal
      ref="edit_option_modal"
      :title="$t('edit') + ' ' + $t('option_2')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <form class="col-md-12">
          <b-alert
            :show="edit_error || edit_success ? true : false"
            :variant="edit_error ? 'danger' : 'success'"
            dismissible
            @dismissed="edit_error = edit_success = ''"
          >
            {{ edit_error || edit_success }}
          </b-alert>
          <div class="form-row ">
            <div class="form-group col-md-12">
              <label>{{ $t("categories") }}</label>

              <multiselect
                selectLabel=""
                selectedLabel=""
                deselectLabel=""
                v-model="edit_data.categories_ids"
                :placeholder="$t('select') + ' ' + $t('categories')"
                :multiple="true"
                :searchable="true"
                :internal-search="true"
                :options-limit="300"
                :show-no-results="false"
                :hide-selected="true"
                :options="categories"
                track-by="id"
                :label="'name_' + $i18n.locale"
                :custom-label="
                  option => {
                    return option['name_' + $i18n.locale];
                  }
                "
              >
              </multiselect>
              <span class="error invalid-feedback">{{
                (edit_errors.category_id || []).join("-")
              }}</span>
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("name") }} | {{ $t("ar") }}</label>
              <input
                type="text"
                class="form-control rtl"
                v-model="edit_data.name_ar"
                :class="{ 'is-invalid': edit_errors.name_ar }"
              />
              <span class="error invalid-feedback d-block">{{
                (edit_errors.name_ar || []).join("-")
              }}</span>
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("name") }} | {{ $t("en") }}</label>
              <input
                type="text"
                class="form-control ltr"
                v-model="edit_data.name_en"
                :class="{ 'is-invalid': edit_errors.name_en }"
              />
              <span class="error invalid-feedback d-block">{{
                (edit_errors.name_ar || []).join("-")
              }}</span>
            </div>

            <div class="form-row col-md-12">
              <div class="form-group col-md-12 mb-0">
                <label>{{ $t("values") }}</label>
                <button
                  class="btn btn-primary btn-sm mr-2 ml-2"
                  @click="addEditSubOption"
                  type="button"
                  v-tooltip="$t('add_new')"
                >
                  <i class="fa fa-plus"></i>
                </button>
              </div>
              <div
                class="col-md-12"
                v-for="(option, m) in edit_data.values"
                :key="m"
              >
                <div class="input-group mb-3">
                  <input
                    type="text"
                    v-model="edit_data.values[m].name_ar"
                    class="form-control rtl"
                    :class="{
                      'is-invalid': edit_errors['values.' + m + '.name_ar']
                    }"
                    :placeholder="$t('name') + ' | ' + $t('ar')"
                  />
                  <input
                    class="form-control ltr"
                    :class="{
                      'is-invalid': edit_errors['values.' + m + '.name_en']
                    }"
                    v-model="edit_data.values[m].name_en"
                    :placeholder="$t('name') + ' | ' + $t('en')"
                  />
                  <div class="input-group-append">
                    <button
                      v-if="m > 0"
                      class="btn btn-danger"
                      @click="deleteEditSubOption(m)"
                      type="button"
                      v-tooltip="$t('delete')"
                    >
                      <i class="fa fa-minus"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-info mt-2"
            :disabled="edit_disabled"
            @click.prevent="updateOption"
          >
            {{ $t("save") }}
            <b-spinner small v-if="edit_disabled"></b-spinner>
          </button>
        </form>
      </div>
    </b-modal>

    <b-modal
      ref="customize_table_modal"
      centered
      :title="$t('customize_table')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <div class="row clearfix">
          <div class="col-md-12">
            <div class="form-row" style="padding: 0 20px">
              <div
                class="form-group col-md-6"
                v-for="(field, i) in table_fields"
                :key="i"
              >
                <input
                  type="checkbox"
                  class="form-check-input"
                  aria-label="Checkbox for following text input"
                  :value="field"
                  :id="'f_' + field"
                  v-model="table_selected_fields"
                  style="vertical-align: middle;"
                /><label
                  class="form-check-label"
                  :for="'f_' + field"
                  style="color: blue;"
                >
                  {{ $t(field.toLowerCase()) }}</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <b-tabs
              pills
              card
              no-key-nav
              content-class="custom-card"
              nav-wrapper-class="custom-nav-card"
            >
              <b-tab no-body :title="$t('all')" active>
                <template v-slot:title>
                  <i class="fa fa-list ml-2 mr-2"></i> {{ $t("all") }}
                </template>
                <b-card-text>
                  <b-card no-body class="card">
                    <b-card-header>
                      <div class="card-tools">
                        <div class="input-group input-group-sm">
                          <i
                            class="fas fa-cog c_pointer customize_table"
                            :title="$t('customize_table')"
                            @click="customizeTable"
                          ></i>
                          <input
                            type="text"
                            class="form-control float-right"
                            :placeholder="$t('search')"
                            v-model="search_key"
                          />
                          <div class="input-group-append">
                            <button
                              type="submit"
                              :disabled="search_disabled"
                              @click.prevent="searchAll"
                              class="btn btn-default"
                            >
                              <i
                                class="fa fa-spinner fa-spin"
                                v-if="search_disabled"
                              ></i>
                              <i class="fas fa-search" v-else></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-card-header>
                    <!-- /.card-header -->
                    <b-card-body class="table-responsive p-0">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th v-if="table_selected_fields.includes('#')">
                              #
                            </th>
                            <th
                              v-if="table_selected_fields.includes('name')"
                              class="sort"
                              @click="sortTable('name_' + $i18n.locale)"
                            >
                              {{ $t("name") }}
                            </th>
                            <th
                              v-if="table_selected_fields.includes('category')"
                              class="sort"
                              @click="sortTable('category_id')"
                            >
                              {{ $t("category") }}
                            </th>
                            <th
                              v-if="
                                table_selected_fields.includes('created_at')
                              "
                              class="sort"
                              @click="sortTable('created_at')"
                            >
                              {{ $t("created_at") }}
                            </th>
                            <th
                              v-if="table_selected_fields.includes('status')"
                              class="sort"
                              @click="sortTable('status')"
                            >
                              {{ $t("status") }}
                            </th>
                            <th
                              v-if="table_selected_fields.includes('actions')"
                            >
                              {{ $t("actions") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              :colspan="table_selected_fields.length"
                              v-if="!load"
                            >
                              <i class="fa fa-sync  fa-spin load-con"></i>
                            </td>
                            <td
                              :colspan="table_selected_fields.length"
                              class="no_data"
                              v-if="load && options.length == 0"
                            >
                              {{ $t("no_data") }}
                            </td>
                          </tr>
                          <tr
                            v-for="(item, i) in options"
                            :key="i"
                            v-show="load"
                          >
                            <td v-if="table_selected_fields.includes('#')">
                              {{ i + 1 }}
                            </td>

                            <td v-if="table_selected_fields.includes('name')">
                              {{ item.name_ar + " - " + item.name_en }}
                            </td>
                            <td
                              v-if="table_selected_fields.includes('category')"
                            >
                              {{
                                (item.category || {})["name_" + $i18n.locale]
                              }}
                            </td>
                            <td
                              v-if="
                                table_selected_fields.includes('created_at')
                              "
                              v-html="dateTimeFormat(item.created_at)"
                            ></td>
                            <td v-if="table_selected_fields.includes('status')">
                              <span
                                :class="
                                  'badge badge-' +
                                    (item.status == 'on' ? 'info' : 'danger')
                                "
                                >{{ $t(item.status) }}</span
                              >
                            </td>
                            <td
                              class="actions-group"
                              v-if="table_selected_fields.includes('actions')"
                            >
                              <button
                                v-if="$can('edit options')"
                                class="btn btn-primary btn-sm"
                                v-tooltip="$t('change_status')"
                                @click="changeStatus(item.id)"
                              >
                                <b-spinner small v-if="disabled_s[i]">
                                </b-spinner>
                                <i class="fas fa-exchange-alt" v-else></i>
                              </button>
                              <button
                                v-if="$can('edit options')"
                                class="btn btn-info btn-sm"
                                v-tooltip="$t('edit')"
                                @click="showEditOption(item.id)"
                              >
                                <i class="fas fa-pencil-alt"> </i>
                              </button>
                              <button
                                v-if="$can('delete options')"
                                @click="deleteOption(item.id)"
                                class="btn btn-danger btn-sm"
                                v-tooltip="$t('delete')"
                              >
                                <i class="fas fa-trash"> </i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-card-body>
                    <!-- /.card-body -->
                    <b-card-footer class="card-footer clearfix">
                      <b-pagination
                        class="m-2"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        :prev-text="$t('previous')"
                        :next-text="$t('next')"
                        align="right"
                        :first-number="true"
                        :last-number="true"
                      ></b-pagination>
                    </b-card-footer>
                  </b-card> </b-card-text
              ></b-tab>
              <b-tab no-body :title="$t('add')" v-if="$can('add options')">
                <template v-slot:title>
                  <i class="fa fa-plus ml-2 mr-2"></i> {{ $t("add") }}
                </template>
                <b-card-text>
                  <b-card no-body>
                    <b-card-body>
                      <form class="col-md-8">
                        <b-alert
                          :show="add_error || add_success ? true : false"
                          :variant="add_error ? 'danger' : 'success'"
                          dismissible
                          @dismissed="add_error = add_success = ''"
                        >
                          {{ add_error || add_success }}
                        </b-alert>
                        <div class="form-row ">
                          <div class="form-group col-md-12">
                            <label>{{ $t("categories") }}</label>

                            <multiselect
                              selectLabel=""
                              selectedLabel=""
                              deselectLabel=""
                              v-model="add_data.categories_ids"
                              :placeholder="
                                $t('select') + ' ' + $t('categories')
                              "
                              :multiple="true"
                              :searchable="true"
                              :internal-search="true"
                              :options-limit="300"
                              :show-no-results="false"
                              :hide-selected="true"
                              :options="categories"
                              track-by="id"
                              :label="'name_' + $i18n.locale"
                              :custom-label="
                                option => {
                                  return option['name_' + $i18n.locale];
                                }
                              "
                            >
                            </multiselect>
                            <span class="error invalid-feedback">{{
                              (add_errors.category_id || []).join("-")
                            }}</span>
                          </div>
                          <div class="form-group col-md-6">
                            <label>{{ $t("name") }} | {{ $t("ar") }}</label>
                            <input
                              type="text"
                              class="form-control rtl"
                              :class="{ 'is-invalid': add_errors.name_ar }"
                              v-model="add_data.name_ar"
                            />
                            <span class="error invalid-feedback d-block">{{
                              (add_errors.name_ar || []).join("-")
                            }}</span>
                          </div>
                          <div class="form-group col-md-6">
                            <label>{{ $t("name") }} | {{ $t("en") }}</label>
                            <input
                              type="text"
                              class="form-control ltr"
                              :class="{ 'is-invalid': add_errors.name_en }"
                              v-model="add_data.name_en"
                            />
                            <span class="error invalid-feedback d-block">{{
                              (add_errors.name_en || []).join("-")
                            }}</span>
                          </div>

                          <div class="form-row col-md-12">
                            <div class="form-group col-md-12 mb-0">
                              <label>{{ $t("values") }}</label>
                              <button
                                class="btn btn-primary btn-sm mr-2 ml-2"
                                @click="addSubOption"
                                type="button"
                                v-tooltip="$t('add_new')"
                              >
                                <i class="fa fa-plus"></i>
                              </button>
                            </div>
                            <div
                              class="col-md-12"
                              v-for="(option, m) in add_data.values"
                              :key="m"
                            >
                              <div class="input-group mb-3">
                                <input
                                  type="text"
                                  v-model="add_data.values[m].name_ar"
                                  class="form-control rtl"
                                  :class="{
                                    'is-invalid':
                                      add_errors['values.' + m + '.name_ar']
                                  }"
                                  :placeholder="$t('name') + ' | ' + $t('ar')"
                                />
                                <input
                                  class="form-control ltr"
                                  :class="{
                                    'is-invalid':
                                      add_errors['values.' + m + '.name_en']
                                  }"
                                  v-model="add_data.values[m].name_en"
                                  :placeholder="$t('name') + ' | ' + $t('en')"
                                />
                                <div class="input-group-append">
                                  <button
                                    v-if="m > 0"
                                    class="btn btn-danger"
                                    @click="deleteSubOption(m)"
                                    type="button"
                                    v-tooltip="$t('delete')"
                                  >
                                    <i class="fa fa-minus"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-info mt-2"
                          :disabled="add_disabled"
                          @click="addOption"
                        >
                          {{ $t("add") }}
                          <b-spinner small v-if="add_disabled"></b-spinner>
                        </button>
                      </form>
                    </b-card-body>
                    <!-- /.card-body -->
                  </b-card>
                </b-card-text></b-tab
              >
            </b-tabs>

            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  name: "Options",
  components: {
    Multiselect
  },
  data() {
    return {
      load: false,
      options: [],
      categories: [],
      option_sets: [],
      search_key: "",
      disabled_s: [],
      currentPage: 1,
      perPage: 1,
      rows: 1,
      add_data: {
        categories_ids: [],
        values: [
          {
            name_ar: "",
            name_en: ""
          }
        ]
      },
      add_disabled: false,
      add_success: "",
      add_error: "",
      edit_data: {},
      edit_disabled: false,
      edit_success: "",
      edit_error: "",
      edit_errors: {},
      attr_types: ["text", "textarea", "number", "boolean", "options"],
      old_search_key: "",
      search_disabled: false,
      options_2: [],
      categories_values: [],
      add_errors: {},
      table_fields: [
        "#",
        "name",
        "category",
        "status",
        "created_at",
        "actions"
      ],
      table_selected_fields: [],
      sort_type: "",
      sort_key: ""
    };
  },
  created() {
    this.table_selected_fields = this.$store.state.options_selected_fields;
    this.getOptions();
    this.getCategories();
  },
  watch: {
    currentPage() {
      this.getOptions();
    },
    "add_data.name_ar"(newVal, oldVal) {
      if (!this.isArabicChars(newVal) && newVal !== oldVal) {
        this.$set(this.add_data, "name_ar", "");
      }
    },
    "edit_data.name_ar"(newVal, oldVal) {
      if (!this.isArabicChars(newVal) && newVal !== oldVal) {
        this.$set(this.edit_data, "name_ar", "");
      }
    },
    "add_data.categories_ids"() {
      let category = this.add_data.categories_ids.find(e => e.id === "all");
      if (category) {
        let allCategories = JSON.parse(
          JSON.stringify(this.add_data.categories_ids)
        );
        let i = allCategories.findIndex(e => e.id === category.id);
        allCategories.splice(i, 1);
        allCategories = this.categories;
        this.add_data.categories_ids = allCategories.filter(
          e => e.id !== category.id
        );
      }
    },
    "edit_data.categories_ids"() {
      let category = this.edit_data.categories_ids.find(e => e.id === "all");
      if (category) {
        let allCategories = JSON.parse(
          JSON.stringify(this.edit_data.categories_ids)
        );
        let i = allCategories.findIndex(e => e.id === category.id);
        allCategories.splice(i, 1);
        allCategories = this.categories;
        this.edit_data.categories_ids = allCategories.filter(
          e => e.id !== category.id
        );
      }
    }
  },
  methods: {
    getOptions() {
      return this.axios
        .get("options", {
          params: {
            page: this.currentPage,
            search_key: this.search_key,
            sort_key: this.sort_key,
            sort_type: this.sort_type
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            let options = res.result.options.data;
            this.options = options;
            options.forEach((e, i) => {
              this.$set(this.options[i], "categories_ids", e.categories);
            });
            this.option_sets = res.result.option_sets;
            this.rows = res.result.options.total;
            this.perPage = res.result.options.per_page;
          }
        })
        .catch(error => {
          this.handleError("getOptions", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    searchAll() {
      if (this.search_key != this.old_search_key) {
        this.old_search_key = this.search_key;
        this.search_disabled = true;
        this.getOptions().then(() => {
          this.search_disabled = false;
        });
      }
    },
    sortTable(key) {
      if (this.sort_key != key) {
        this.sort_type = "asc";
      } else {
        this.sort_type = this.sort_type == "asc" ? "desc" : "asc";
      }
      this.sort_key = key;
      this.getSubCategories();
    },
    getCategories() {
      return this.axios
        .get("categories")
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            let categories = res.result.categories;
            this.categories = categories;
            this.categories.unshift({
              name_ar: "تحديد الكل",
              name_en: "Select all",
              id: "all"
            });
          }
        })
        .catch(error => {
          this.handleError("getCategories", error);
        });
    },
    addOption() {
      this.add_disabled = true;
      this.add_error = this.add_success = "";
      this.add_errors = {};
      let fd = new FormData();

      Object.keys(this.add_data).forEach(key => {
        if (key === "categories_ids") {
          this.add_data[key].forEach((item, idx) => {
            fd.append(`${key}[${idx}]`, item.id);
          });
        } else if (key === "values") {
          this.add_data[key].forEach((item, idx) => {
            fd.append(`${key}[${idx}][name_ar]`, item.name_ar);
            fd.append(`${key}[${idx}][name_en]`, item.name_en);
          });
        } else if (this.add_data[key]) {
          fd.append(key, this.add_data[key]);
        }
      });
      this.axios
        .post("options/add", fd)
        .then(response => {
          let res = response.data;

          if (res.error_flag == 0) {
            this.add_success = res.message;
            this.getOptions();
            this.add_data = {
              categories_ids: [],
              values: [
                {
                  name_ar: "",
                  name_en: ""
                }
              ]
            };
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.add_errors = res.message;
            } else {
              this.add_error = res.message;
            }
          } else {
            this.handleError("addOption", res.message);
          }
        })
        .catch(error => {
          this.handleError("addOption", error);
        })
        .finally(() => {
          this.add_disabled = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    updateOption() {
      this.edit_disabled = true;
      this.edit_error = this.edit_success = "";
      let fd = new FormData();

      Object.keys(this.edit_data).forEach(key => {
        if (key === "categories_ids") {
          this.edit_data[key].forEach((item, idx) => {
            fd.append(`${key}[${idx}]`, item.id);
          });
        } else if (key === "values") {
          this.edit_data[key].forEach((item, idx) => {
            if (item.id) {
              fd.append(`${key}[${idx}][id]`, item.id);
            }
            fd.append(`${key}[${idx}][name_ar]`, item.name_ar);
            fd.append(`${key}[${idx}][name_en]`, item.name_en);
          });
        } else if (this.edit_data[key]) {
          fd.append(key, this.edit_data[key]);
        }
      });
      this.axios
        .post("options/update", fd)
        .then(response => {
          let res = response.data;
          this.edit_errors = {};
          if (res.error_flag == 0) {
            this.edit_success = res.message;
            this.getOptions();
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.edit_errors = res.message;
            } else {
              this.edit_error = res.message;
            }
          } else {
            this.handleError("updateOption", res.message);
          }
        })
        .catch(error => {
          this.handleError("updateOption", error);
        })
        .finally(() => {
          this.edit_disabled = false;
        });
    },
    changeStatus(id) {
      let index = this.findIndex(this.options, "id", id);
      let i = this.findIndex(this.options, "id", id);
      this.$set(this.disabled_s, i, true);
      this.axios
        .post("options/update_status/" + id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.$noty.success(res.message);
            this.options[index].status = res.result.status;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("changeStatus", res.message);
          }
        })
        .catch(error => {
          this.handleError("chnageStatus", error);
        })
        .finally(() => {
          this.$set(this.disabled_s, i, false);
        });
    },
    deleteOption(id) {
      let index = this.findIndex(this.options, "id", id);

      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("options/delete/" + id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.options.splice(index, 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deleteOption", res.message);
                }
              })
              .catch(error => {
                this.handleError("deleteOption", error);
              });
          }
        });
    },
    addSubOption() {
      if (this.add_data.values[this.add_data.values.length - 1].name_ar) {
        this.add_data.values.push({
          name_ar: "",
          name_en: ""
        });
      }
    },
    addEditSubOption() {
      if (this.edit_data.values[this.edit_data.values.length - 1].name_ar) {
        this.edit_data.values.push({
          name_ar: "",
          name_en: ""
        });
      }
    },
    deleteSubOption(i) {
      this.add_data.values.splice(i, 1);
    },
    deleteEditSubOption(i) {
      this.edit_data.values.splice(i, 1);
    },
    showEditOption(id) {
      this.edit_success = this.edit_error = "";
      this.edit_errors = {};
      this.edit_data = JSON.parse(
        JSON.stringify({ ...this.options.find(e => e.id == id) })
      );
      this.$refs.edit_option_modal.show();
    }
  }
};
</script>
<style>
.multiselect__tags {
  font-size: 16px;
  font-weight: 100 !important;
}
</style>
