<template>
  <div>
    <b-modal
      ref="customize_table_modal"
      centered
      :title="$t('customize_table')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <div class="row clearfix">
          <div class="col-md-12">
            <div class="form-row" style="padding: 0 20px">
              <div
                class="form-group col-md-6"
                v-for="(field, i) in table_fields"
                :key="i"
              >
                <input
                  type="checkbox"
                  class="form-check-input"
                  aria-label="Checkbox for following text input"
                  :value="field"
                  :id="'f_' + field"
                  v-model="table_selected_fields"
                  style="vertical-align: middle;"
                /><label
                  class="form-check-label"
                  :for="'f_' + field"
                  style="color: blue;"
                >
                  {{ $t(field.toLowerCase()) }}</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <b-tabs
              pills
              card
              no-key-nav
              content-class="custom-card"
              nav-wrapper-class="custom-nav-card"
            >
              <b-tab no-body :title="$t('all')" active>
                <template v-slot:title>
                  <i class="fa fa-list ml-2 mr-2"></i> {{ $t("all") }}
                </template>
                <b-card-text>
                  <b-card no-body class="card">
                    <b-card-header>
                      <div class="card-tools">
                        <div class="input-group input-group-sm">
                          <i
                            class="fas fa-cog c_pointer customize_table"
                            :title="$t('customize_table')"
                            @click="customizeTable"
                          ></i>
                          <input
                            type="text"
                            class="form-control float-right"
                            :placeholder="$t('search')"
                            v-model="search_key"
                          />
                          <div class="input-group-append">
                            <button
                              type="submit"
                              :disabled="search_disabled"
                              @click.prevent="searchAll"
                              class="btn btn-default"
                            >
                              <i
                                class="fa fa-spinner fa-spin"
                                v-if="search_disabled"
                              ></i>
                              <i class="fas fa-search" v-else></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-card-header>
                    <!-- /.card-header -->
                    <b-card-body class="table-responsive p-0">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th v-if="table_selected_fields.includes('#')">
                              #
                            </th>
                            <th
                              class="sort"
                              @click="sortTable('notification_action')"
                              v-if="
                                table_selected_fields.includes(
                                  'notification_action'
                                )
                              "
                            >
                              {{ $t("notification_action") }}
                            </th>
                            <th
                              class="sort"
                              @click="sortTable('notification_user_type')"
                              v-if="
                                table_selected_fields.includes(
                                  'notification_user_type'
                                )
                              "
                            >
                              {{ $t("notification_user_type") }}
                            </th>
                            <th
                              class="sort"
                              @click="sortTable('created_at')"
                              v-if="
                                table_selected_fields.includes('created_at')
                              "
                            >
                              {{ $t("created_at") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              :colspan="table_selected_fields.length"
                              v-if="!load"
                            >
                              <i class="fa fa-sync  fa-spin load-con"></i>
                            </td>
                            <td
                              :colspan="table_selected_fields.length"
                              class="no_data"
                              v-if="load && notifications.length == 0"
                            >
                              {{ $t("no_data") }}
                            </td>
                          </tr>
                          <tr
                            v-for="(item, i) in notifications"
                            :key="i"
                            v-show="load"
                          >
                            <td v-if="table_selected_fields.includes('#')">
                              {{ i + 1 }}
                            </td>
                            <td
                              v-if="
                                table_selected_fields.includes(
                                  'notification_action'
                                )
                              "
                            >
                              {{
                                $safeValue(
                                  () => item.data.notification.title,
                                  "-"
                                )
                              }}<br />
                              {{
                                $safeValue(
                                  () => item.data.notification.body,
                                  "-"
                                )
                              }}<br />
                              [ {{ $safeValue(() => item.data.data.id, "-") }} ]
                            </td>
                            <td
                              v-if="
                                table_selected_fields.includes(
                                  'notification_user_type'
                                )
                              "
                            >
                              {{ $t(item.user_type + "_2") }}
                            </td>
                            <td
                              v-if="
                                table_selected_fields.includes('created_at')
                              "
                              v-html="dateTimeFormat(item.created_at)"
                            ></td>
                          </tr>
                        </tbody>
                      </table>
                    </b-card-body>
                    <!-- /.card-body -->
                    <b-card-footer class="card-footer clearfix">
                      <b-pagination
                        class="m-2"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        :prev-text="$t('previous')"
                        :next-text="$t('next')"
                        align="right"
                        :first-number="true"
                        :last-number="true"
                      ></b-pagination>
                    </b-card-footer>
                  </b-card> </b-card-text
              ></b-tab>
            </b-tabs>

            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: "Coupons",
  data() {
    return {
      load: false,
      notifications: [],
      rows: 0,
      search_key: "",
      old_search_key: "",
      search_disabled: false,
      disabled_s: [],
      currentPage: 1,
      perPage: 1,
      add_progress: 0,
      sort_key: "",
      sort_type: "asc",
      table_fields: [
        "#",
        "notification_user_type",
        "notification_type",
        "notification_action",
        "created_at",
        "status",
        "actions"
      ],
      table_selected_fields: [],
      filters: {}
    };
  },
  created() {
    this.table_selected_fields = this.$store.state.notification_selected_fields;
    this.getNotifications();
  },
  watch: {
    currentPage() {
      this.filters["page"] = this.currentPage;
      this.load = false;
      this.getNotifications();
    },
    table_selected_fields() {
      this.$store.commit(
        "setNotificationsSelectedFields",
        this.table_selected_fields
      );
    }
  },
  methods: {
    getNotifications() {
      return this.axios
        .post("notifications/get", this.filters)
        .then(response => {
          let res = response.data;
          console.log(res);
          if (res.error_flag == 0) {
            this.notifications = res.result.notifications.data;
            this.rows = res.result.notifications.pagination.total;
            this.perPage = res.result.notifications.pagination.per_page;
          }
        })
        .catch(error => {
          this.handleError("getNotifications", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    searchAll() {
      if (this.search_key != this.old_search_key) {
        this.old_search_key = this.search_key;
        this.search_disabled = true;
        this.load = false;
        this.filters["search_key"] = this.search_key;
        this.getRegions().then(() => {
          this.search_disabled = false;
        });
      }
    }
  }
};
</script>

<style scoped>
.subscribers_table tr:first-child th {
  border: none;
}
</style>
