<template>
  <div>
    <b-modal
      ref="customize_table_modal"
      centered
      :title="$t('customize_table')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <div class="row clearfix">
          <div class="col-md-12">
            <div class="form-row" style="padding: 0 20px">
              <div
                class="form-group col-md-6"
                v-for="(field, i) in table_fields"
                :key="i"
              >
                <input
                  type="checkbox"
                  class="form-check-input"
                  aria-label="Checkbox for following text input"
                  :value="field"
                  :id="'f_' + field"
                  v-model="table_selected_fields"
                  style="vertical-align: middle;"
                /><label
                  class="form-check-label"
                  :for="'f_' + field"
                  style="color: blue;"
                >
                  {{ $t(field.toLowerCase()) }}</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="income_modal"
      :title="$t('income_2') + ' #' + income.id"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <div class="col-md-12 income_det">
          <div class="form-row" v-if="income.id">
            <div class="form-group col-md-12">
              <label>{{ $t("name") }}</label>

              {{ income.name }}
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("mobile") }}</label>
              {{ income.mobile }}
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("email") }}</label>
              {{ income.email }}
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("subject") }}</label>
              {{ income.subject }}
            </div>
            <div class="form-group col-md-6">
              <label>{{ $t("source") }}</label>
              {{ income.msg_source }}
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("msg") }}</label>
              <pre
                class="p-0 m-0"
              ><p style="font-family:cairo">{{ income.msg.trim()}}</p></pre>
            </div>
            <div class="form-group col-md-12">
              <label>{{ $t("date") }}</label>
              <span v-html="dateTimeFormat(income.created_at)"></span>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <br />

            <b-card no-body class="card">
              <b-card-header>
                {{ $t("balance") }}: {{ balance }} {{ $t("sar") }}
                <div class="card-tools">
                  <div class="input-group input-group-sm">
                    <i
                      class="fas fa-cog c_pointer customize_table"
                      :title="$t('customize_table')"
                      @click="customizeTable"
                    ></i>
                    <input
                      type="text"
                      class="form-control float-right"
                      :placeholder="$t('search')"
                      v-model="search_key"
                    />
                    <div class="input-group-append">
                      <button
                        type="submit"
                        :disabled="search_disabled"
                        @click.prevent="searchAll"
                        class="btn btn-default"
                      >
                        <i
                          class="fa fa-spinner fa-spin"
                          v-if="search_disabled"
                        ></i>
                        <i class="fas fa-search" v-else></i>
                      </button>
                    </div>
                  </div>
                </div>
              </b-card-header>
              <!-- /.card-header -->
              <b-card-body class="table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th v-if="table_selected_fields.includes('#')">
                        #
                      </th>
                      <th
                        class="sort"
                        @click="sortTable('order_id')"
                        v-if="table_selected_fields.includes('order_id')"
                      >
                        {{ $t("order_id") }}
                      </th>
                      <th
                        class="sort"
                        @click="sortTable('payment_method')"
                        v-if="table_selected_fields.includes('payment_method')"
                      >
                        {{ $t("payment_method") }}
                      </th>
                      <th
                        class="sort"
                        @click="sortTable('products_price')"
                        v-if="table_selected_fields.includes('products_price')"
                      >
                        {{ $t("products_price") }}
                      </th>
                      <th
                        class="sort"
                        @click="sortTable('delivery_fees')"
                        v-if="table_selected_fields.includes('delivery_fees')"
                      >
                        {{ $t("delivery_fees") }}
                      </th>
                      <th
                        class="sort"
                        @click="sortTable('order_amount')"
                        v-if="table_selected_fields.includes('total')"
                      >
                        {{ $t("total") }}
                      </th>
                      <th
                        class="sort"
                        @click="sortTable('admin_credit')"
                        v-if="table_selected_fields.includes('admin_credit')"
                      >
                        {{ $t("admin_credit") }}
                      </th>
                      <th
                        class="sort"
                        @click="sortTable('admin_debit')"
                        v-if="table_selected_fields.includes('admin_debit')"
                      >
                        {{ $t("admin_debit") }}
                      </th>

                      <th
                        class="sort"
                        @click="sortTable('vendor_credit')"
                        v-if="table_selected_fields.includes('vendor_credit')"
                      >
                        {{ $t("vendor_credit") }}
                      </th>
                      <th
                        class="sort"
                        @click="sortTable('vendor_debit')"
                        v-if="table_selected_fields.includes('vendor_debit')"
                      >
                        {{ $t("vendor_debit") }}
                      </th>

                      <th
                        class="sort"
                        @click="sortTable('delivery_credit')"
                        v-if="table_selected_fields.includes('delivery_credit')"
                      >
                        {{ $t("delivery_credit") }}
                      </th>
                      <th
                        class="sort"
                        @click="sortTable('delivery_debit')"
                        v-if="table_selected_fields.includes('delivery_debit')"
                      >
                        {{ $t("delivery_debit") }}
                      </th>

                      <th
                        v-if="table_selected_fields.includes('created_at')"
                        class="sort"
                        @click="sortTable('created_at')"
                      >
                        {{ $t("created_at") }}
                      </th>
                      <th v-if="table_selected_fields.includes('actions')">
                        {{ $t("actions") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td :colspan="table_selected_fields.length" v-if="!load">
                        <i class="fa fa-sync  fa-spin load-con"></i>
                      </td>
                      <td
                        :colspan="table_selected_fields.length"
                        class="no_data"
                        v-if="load && incomes.length == 0"
                      >
                        {{ $t("no_data") }}
                      </td>
                    </tr>
                    <tr v-for="(item, i) in incomes" :key="i" v-show="load">
                      <td v-if="table_selected_fields.includes('#')">
                        {{ i + 1 }}
                      </td>
                      <td v-if="table_selected_fields.includes('order_id')">
                        {{ item.order_id }}
                      </td>
                      <td
                        v-if="table_selected_fields.includes('payment_method')"
                      >
                        {{ $t(item.payment_method) }}
                      </td>
                      <td
                        v-if="table_selected_fields.includes('products_price')"
                      >
                        {{ item.products_price }}
                      </td>
                      <td
                        v-if="table_selected_fields.includes('delivery_fees')"
                      >
                        {{ item.delivery_fees }}
                      </td>
                      <td v-if="table_selected_fields.includes('total')">
                        {{ item.order_amount }}
                      </td>
                      <td v-if="table_selected_fields.includes('admin_credit')">
                        {{ item.admin_credit }}
                      </td>
                      <td v-if="table_selected_fields.includes('admin_debit')">
                        {{ item.admin_debit }}
                      </td>

                      <td
                        v-if="table_selected_fields.includes('vendor_credit')"
                      >
                        {{ item.vendor_credit }}
                      </td>
                      <td v-if="table_selected_fields.includes('vendor_debit')">
                        {{ item.vendor_debit }}
                      </td>

                      <td
                        v-if="table_selected_fields.includes('delivery_credit')"
                      >
                        {{ item.delivery_credit }}
                      </td>
                      <td
                        v-if="table_selected_fields.includes('delivery_debit')"
                      >
                        {{ item.delivery_debit }}
                      </td>

                      <td
                        v-if="table_selected_fields.includes('created_at')"
                        v-html="dateTimeFormat(item.created_at)"
                      ></td>
                      <td
                        class="actions-group"
                        v-if="table_selected_fields.includes('actions')"
                      >
                        <button
                          v-if="$can('show incomes')"
                          class="btn btn-info btn-sm"
                          v-tooltip="$t('show')"
                          @click="showIncome(item.id)"
                        >
                          <i class="fas fa-eye"> </i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-card-body>
              <!-- /.card-body -->
              <b-card-footer class="card-footer clearfix">
                <b-pagination
                  class="m-2"
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  :prev-text="$t('previous')"
                  :next-text="$t('next')"
                  align="right"
                  :first-number="true"
                  :last-number="true"
                ></b-pagination>
              </b-card-footer>
            </b-card>

            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: "Incomes",
  data() {
    return {
      load: false,
      incomes: [],
      search_key: "",
      old_search_key: "",
      search_disabled: false,
      disabled_s: [],
      currentPage: 1,
      perPage: 1,
      rows: 0,
      add_data: {},
      add_disabled: false,
      add_success: "",
      add_error: "",
      add_errors: {},
      add_progress: 0,
      income: {},
      edit_disabled: false,
      edit_success: "",
      edit_error: "",
      edit_errors: {},
      sort_key: "",
      sort_type: "asc",
      table_fields: [
        "#",
        "order_id",
        "payment_method",
        "products_price",
        "delivery_fees",
        "total",
        "admin_credit",
        "admin_debit",
        "vendor_credit",
        "vendor_debit",
        "delivery_credit",
        "delivery_debit",
        "created_at"
        //"actions"
      ],
      table_selected_fields: [],
      balance: 0
    };
  },
  created() {
    this.table_selected_fields = this.$store.state.incomes_selected_fields;
    this.getIncomes();
  },
  watch: {
    currentPage() {
      this.getIncomes();
    },
    table_selected_fields() {
      this.$store.commit(
        "setIncomesSelectedFields",
        this.table_selected_fields
      );
    }
  },
  methods: {
    getIncomes() {
      return this.axios
        .get("incomes", {
          params: {
            page: this.currentPage,
            search_key: this.search_key,
            sort_key: this.sort_key,
            sort_type: this.sort_type
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.incomes = res.result.incomes.data;
            this.rows = res.result.incomes.total;
            this.perPage = res.result.incomes.per_page;
            this.balance = res.result.balance;
          }
        })
        .catch(error => {
          this.handleError("getIncomes", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    sortTable(key) {
      if (this.sort_key != key) {
        this.sort_type = "asc";
      } else {
        this.sort_type = this.sort_type == "asc" ? "desc" : "asc";
      }
      this.sort_key = key;
      this.getIncomes();
    },
    searchAll() {
      if (this.search_key != this.old_search_key) {
        this.old_search_key = this.search_key;
        this.search_disabled = true;
        this.getIncomes().then(() => {
          this.search_disabled = false;
        });
      }
    },
    deleteIncome(id) {
      let index = this.findIndex(this.incomes, "id", id);

      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("incomes/delete/" + id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.incomes.splice(index, 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deleteIncome", res.message);
                }
              })
              .catch(error => {
                this.handleError("deleteIncome", error);
              });
          }
        });
    },
    changeStatus(id) {
      let index = this.findIndex(this.incomes, "id", id);
      this.axios
        .post("incomes/update_status/" + id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.incomes[index].is_read = 1;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("changeStatus", res.message);
          }
        })
        .catch(error => {
          this.handleError("chnageStatus", error);
        })
        .finally(() => {});
    },
    showIncome(id) {
      this.income = { ...this.incomes.find(e => e.id == id) };
      if (!this.income.is_read) {
        this.changeStatus(id);
      }
      this.$refs.income_modal.show();
    }
  }
};
</script>
