<template>
  <div>
    <b-modal
      ref="edit_collection_modal"
      :title="$t('edit') + ' ' + $t('collection_2')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <form class="col-md-12">
          <b-alert
            :show="edit_error || edit_success ? true : false"
            :variant="edit_error ? 'danger' : 'success'"
            dismissible
            @dismissed="edit_error = edit_success = ''"
          >
            {{ edit_error || edit_success }}
          </b-alert>
          <div class="form-row ">
            <div class="form-group col-md-12">
              <label>{{ $t("image") }}</label>
              <div class="image_div" v-if="edit_data_image || edit_data.image">
                <img
                  :src="edit_data_image || image_url + '/' + edit_data.image"
                  @error="$event.target.src = '/images/placeholder.jpg'"
                />
                <i
                  v-if="edit_data_image"
                  class="fa fa-times"
                  @click.prevent="edit_data_image = edit_data_icon = null"
                ></i>
              </div>
              <b-form-file
                v-if="!edit_data_image"
                accept="image/*"
                @change.prevent="onImageChange($event, 'edit_data')"
                ref="edit_data_icon"
              ></b-form-file>
              <span class="error invalid-feedback d-block">{{
                (add_errors.image_input || []).join("-")
              }}</span>
            </div>

            <div class="form-group col-md-12">
              <label>{{ $t("title") }}</label>
              <input
                type="text"
                class="form-control"
                :class="{
                  'is-invalid': edit_errors.title
                }"
                v-model="edit_data.title"
              />
              <span class="error invalid-feedback">{{
                (edit_errors.title || []).join("-")
              }}</span>
            </div>

            <div class="form-group col-md-12">
              <label>{{ $t("products") }}</label>
              <multiselect
                selectLabel=""
                selectedLabel=""
                deselectLabel=""
                v-model="edit_data.products"
                :placeholder="$t('search_select') + ' ' + $t('products')"
                :multiple="true"
                :searchable="true"
                :loading="load_results"
                :internal-search="false"
                :options-limit="300"
                :show-no-results="false"
                :hide-selected="true"
                @search-change="getProducts2"
                :options="edit_results"
                track-by="id"
                :label="'name_' + $i18n.locale"
                :custom-label="
                  option => {
                    return option['name_' + $i18n.locale];
                  }
                "
              >
              </multiselect>
              <span class="error invalid-feedback d-block">{{
                (edit_errors.products_ids || []).join("-")
              }}</span>
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-info mt-2"
            :disabled="edit_disabled"
            @click.prevent="updateCollection"
          >
            {{ $t("save") }}
            <b-spinner small v-if="edit_disabled"></b-spinner>
          </button>
        </form>
      </div>
    </b-modal>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <b-tabs
              v-model="c_tab"
              pills
              card
              no-key-nav
              content-class="custom-card"
              nav-wrapper-class="custom-nav-card"
            >
              <b-tab no-body :title="$t('all')" active>
                <template v-slot:title>
                  <i class="fa fa-list ml-2 mr-2"></i> {{ $t("all") }}
                </template>
                <b-card-text>
                  <b-card no-body class="card">
                    <b-card-header>
                      <div class="card-tools">
                        <div class="input-group input-group-sm">
                          <input
                            type="text"
                            class="form-control float-right"
                            :placeholder="$t('search')"
                            v-model="search_key"
                          />
                          <div class="input-group-append">
                            <button
                              type="submit"
                              :disabled="search_disabled"
                              @click.prevent="searchAll"
                              class="btn btn-default"
                            >
                              <i
                                class="fa fa-spinner fa-spin"
                                v-if="search_disabled"
                              ></i>
                              <i class="fas fa-search" v-else></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-card-header>
                    <!-- /.card-header -->
                    <b-card-body class="table-responsive p-0">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th>
                              #
                            </th>
                            <th>{{ $t("image") }}</th>
                            <th class="sort" @click="sortTable('title')">
                              {{ $t("title") }}
                            </th>
                            <th class="sort" @click="sortTable('created_at')">
                              {{ $t("created_at") }}
                            </th>
                            <th class="sort" @click="sortTable('status')">
                              {{ $t("status") }}
                            </th>
                            <th>{{ $t("actions") }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="6" v-if="!load">
                              <i class="fa fa-sync  fa-spin load-con"></i>
                            </td>
                            <td
                              colspan="6"
                              class="no_data"
                              v-if="load && collections.length == 0"
                            >
                              {{ $t("no_data") }}
                            </td>
                          </tr>
                          <tr
                            v-for="(item, i) in collections"
                            :key="i"
                            v-show="load"
                          >
                            <td>{{ i + 1 }}</td>
                            <td>
                              <img
                                :src="image_url + '/' + item.image"
                                style="height:auto"
                                @error="
                                  $event.target.src = '/images/placeholder.jpg'
                                "
                              />
                            </td>
                            <td>{{ item["title"] }}</td>

                            <td v-html="dateTimeFormat(item.created_at)"></td>
                            <td>
                              <span
                                :class="
                                  'badge badge-' +
                                    (item.status == 'on' ? 'info' : 'danger')
                                "
                                >{{ $t(item.status) }}</span
                              >
                            </td>
                            <td class="actions-group">
                              <button
                                v-if="$can('edit collections')"
                                class="btn btn-primary btn-sm"
                                v-tooltip="$t('change_status')"
                                @click="changeStatus(item.id)"
                              >
                                <b-spinner small v-if="disabled_s[i]">
                                </b-spinner>
                                <i class="fas fa-exchange-alt" v-else></i>
                              </button>
                              <button
                                v-if="$can('edit collections')"
                                class="btn btn-info btn-sm"
                                v-tooltip="$t('edit')"
                                @click="showEditCollection(item.id)"
                              >
                                <i class="fas fa-pencil-alt"> </i>
                              </button>
                              <button
                                v-if="$can('delete collections')"
                                @click="deleteCollection(item.id)"
                                class="btn btn-danger btn-sm"
                                v-tooltip="$t('delete')"
                              >
                                <i class="fas fa-trash"> </i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-card-body>
                    <!-- /.card-body -->
                    <b-card-footer class="card-footer clearfix">
                      <b-pagination
                        class="m-2"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        :prev-text="$t('previous')"
                        :next-text="$t('next')"
                        align="right"
                        :first-number="true"
                        :last-number="true"
                      ></b-pagination>
                    </b-card-footer>
                  </b-card> </b-card-text
              ></b-tab>
              <b-tab no-body :title="$t('add')" v-if="$can('add collections')">
                <template v-slot:title>
                  <i class="fa fa-plus ml-2 mr-2"></i> {{ $t("add") }}
                </template>
                <b-card-text>
                  <b-card no-body>
                    <b-card-body>
                      <form class="col-md-8">
                        <b-alert
                          :show="add_error || add_success ? true : false"
                          :variant="add_error ? 'danger' : 'success'"
                          dismissible
                          @dismissed="add_error = add_success = ''"
                        >
                          {{ add_error || add_success }}
                        </b-alert>
                        <div class="form-row ">
                          <div class="form-group col-md-12">
                            <label>{{ $t("image") }}</label>

                            <div class="image_div" v-if="add_data_image">
                              <img :src="add_data_image" />
                              <i
                                class="fa fa-times"
                                @click.prevent="
                                  add_data_image = add_data_icon = null
                                "
                              ></i>
                            </div>
                            <b-form-file
                              v-else
                              :placeholder="$t('choose_file')"
                              accept="image/*"
                              multiple
                              @change.prevent="
                                onImageChange($event, 'add_data')
                              "
                              ref="add_data_icon"
                            ></b-form-file>
                            <span class="error invalid-feedback d-block">{{
                              (add_errors.image_input || []).join("-")
                            }}</span>
                          </div>
                          <div class="form-group col-md-12">
                            <label>{{ $t("title") }}</label>
                            <input
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': add_errors.title
                              }"
                              v-model="add_data.title"
                            />
                            <span class="error invalid-feedback">{{
                              (add_errors.title || []).join("-")
                            }}</span>
                          </div>

                          <span class="error invalid-feedback">{{
                            (add_errors.title_en || []).join("-")
                          }}</span>

                          <div class="form-group col-md-12">
                            <label>{{ $t("products") }}</label>
                            <multiselect
                              selectLabel=""
                              selectedLabel=""
                              deselectLabel=""
                              v-model="add_data.products_ids"
                              :placeholder="
                                $t('search_select') + ' ' + $t('products')
                              "
                              :multiple="true"
                              :searchable="true"
                              :loading="load_results"
                              :internal-search="false"
                              :options-limit="300"
                              :show-no-results="false"
                              :hide-selected="true"
                              @search-change="getProducts"
                              :options="add_results"
                              track-by="id"
                              :label="'name_' + $i18n.locale"
                              :custom-label="
                                option => {
                                  return option['name_' + $i18n.locale];
                                }
                              "
                            >
                            </multiselect>
                            <span class="error invalid-feedback d-block">{{
                              (add_errors.products_ids || []).join("-")
                            }}</span>
                          </div>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-info mt-2"
                          :disabled="add_disabled"
                          @click="addCollection"
                        >
                          {{ $t("add") }}
                          <b-spinner small v-if="add_disabled"></b-spinner>
                        </button>
                        <div class="icheck-primary  add_with_new">
                          <input
                            type="checkbox"
                            id="add_with_new"
                            value="1"
                            v-model="add_with_new"
                          />
                          <label for="add_with_new">
                            {{ $t("add_with_new") }}
                          </label>
                        </div>
                      </form>
                    </b-card-body>
                    <!-- /.card-body -->
                  </b-card>
                </b-card-text></b-tab
              >
            </b-tabs>

            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  title: "Collections",
  components: {
    Multiselect
  },
  data() {
    return {
      load: false,
      collections: [],
      search_key: "",
      old_search_key: "",
      search_disabled: false,
      disabled_s: [],
      currentPage: 1,
      perPage: 1,
      rows: 1,
      add_data: {
        products_ids: []
      },
      add_disabled: false,
      add_success: "",
      add_data_icon: null,
      add_data_image: null,
      edit_data_icon: null,
      edit_data_image: null,
      image_url: "",
      add_error: "",
      add_errors: {},
      add_progress: 0,
      edit_data: {},
      edit_disabled: false,
      edit_success: "",
      edit_error: "",
      edit_errors: {},
      load_results: false,
      add_results: [],
      edit_results: [],
      sort_key: "",
      sort_type: "asc",
      add_with_new: 0,
      c_tab: 0
    };
  },
  created() {
    this.getCollections();
  },
  watch: {
    currentPage() {
      this.getCollections();
    }
  },
  methods: {
    getCollections() {
      return this.axios
        .get("collections", {
          params: {
            page: this.currentPage,
            search_key: this.search_key,
            sort_key: this.sort_key,
            sort_type: this.sort_type
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.collections = res.result.collections.data;
            this.rows = res.result.collections.total;
            this.perPage = res.result.collections.per_page;
            this.image_url = res.result.image_url;
          }
        })
        .catch(error => {
          this.handleError("getCollections", error);
        })
        .finally(() => {
          this.load = true;
        });
    },
    sortTable(key) {
      if (this.sort_key != key) {
        this.sort_type = "asc";
      } else {
        this.sort_type = this.sort_type == "asc" ? "desc" : "asc";
      }
      this.sort_key = key;
      this.getCollections();
    },
    getProducts(query) {
      if (!query) {
        return true;
      }
      this.load_results = true;
      return this.axios
        .get("collections/products", {
          params: {
            search_key: query
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.add_results = res.result.products;
          }
        })
        .catch(error => {
          this.handleError("getProducts", error);
        })
        .finally(() => {
          this.load_results = false;
        });
    },
    getProducts2(query) {
      if (!query) {
        return true;
      }
      this.load_results = true;
      return this.axios
        .get("collections/products", {
          params: {
            search_key: query
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.edit_results = res.result.products;
          }
        })
        .catch(error => {
          this.handleError("getProducts", error);
        })
        .finally(() => {
          this.load_results = false;
        });
    },
    searchAll() {
      if (this.search_key != this.old_search_key) {
        this.old_search_key = this.search_key;
        this.search_disabled = true;
        this.getCollections().then(() => {
          this.search_disabled = false;
        });
      }
    },
    addCollection() {
      this.add_disabled = true;
      let fd = new FormData();
      if (this.add_data_icon) {
        fd.append("image_input", this.add_data_icon, this.add_data_icon.title);
      }

      Object.keys(this.add_data).forEach(key => {
        if (this.add_data[key]) {
          if (key == "products_ids") {
            let products_ids = this.add_data.products_ids.map(e => e.id);
            products_ids.forEach(e => {
              fd.append("products_ids[]", e);
            });
            return true;
          }
          fd.append(key, this.add_data[key]);
        }
      });
      this.add_error = this.add_success = "";
      this.axios
        .post("collections/add", fd)
        .then(response => {
          let res = response.data;
          this.add_errors = {};
          if (res.error_flag == 0) {
            this.add_success = res.message;
            this.getCollections().then(() => {
              if (!this.add_with_new) {
                this.c_tab = 0;
              }
            });
            this.add_data = {
              products_ids: []
            };
            this.add_data_image = null;
            this.add_data_icon = null;
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.add_errors = res.message;
            } else {
              this.add_error = res.message;
            }
          } else {
            this.handleError("addCollection", res.message);
          }
        })
        .catch(error => {
          this.handleError("addCollection", error);
        })
        .finally(() => {
          this.add_disabled = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    },
    updateCollection() {
      let i = this.collections.findIndex(e => e.id == this.edit_data.id);
      this.edit_disabled = true;
      let fd = new FormData();

      if (this.edit_data_icon) {
        fd.append(
          "image_input",
          this.edit_data_icon,
          this.edit_data_icon.title
        );
      }

      Object.keys(this.edit_data).forEach(key => {
        if (this.edit_data[key]) {
          if (key == "products") {
            let products_ids = this.edit_data.products.map(e => e.id);
            products_ids.forEach(e => {
              fd.append("products_ids[]", e);
            });
            return true;
          }
          fd.append(key, this.edit_data[key]);
        }
      });
      this.edit_error = this.edit_success = "";
      this.axios
        .post("collections/update", fd)
        .then(response => {
          let res = response.data;
          this.edit_errors = {};
          if (res.error_flag == 0) {
            this.edit_success = res.message;
            this.$set(this.collections, i, res.result.collection);
            this.edit_data = res.result.collection;
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.edit_errors = res.message;
            } else {
              this.edit_error = res.message;
            }
          } else {
            this.handleError("updateCollection", res.message);
          }
        })
        .catch(error => {
          this.handleError("updateCollection", error);
        })
        .finally(() => {
          this.edit_disabled = false;
        });
    },
    changeStatus(id) {
      let index = this.findIndex(this.collections, "id", id);
      let i = this.findIndex(this.collections, "id", id);
      this.$set(this.disabled_s, i, true);
      this.axios
        .post("collections/update_status/" + id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.$noty.success(res.message);
            this.collections[index].status = res.result.status;
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("changeStatus", res.message);
          }
        })
        .catch(error => {
          this.handleError("chnageStatus", error);
        })
        .finally(() => {
          this.$set(this.disabled_s, i, false);
        });
    },
    deleteCollection(id) {
      let index = this.findIndex(this.collections, "id", id);

      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("collections/delete/" + id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.collections.splice(index, 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deleteCollection", res.message);
                }
              })
              .catch(error => {
                this.handleError("deleteCollection", error);
              });
          }
        });
    },
    showEditCollection(id) {
      this.edit_success = this.edit_error = "";
      this.edit_errors = {};
      this.edit_data = { ...this.collections.find(e => e.id == id) };
      this.$refs.edit_collection_modal.show();
    }
  }
};
</script>
