<template>
  <div>
    <b-modal
      ref="wallet_customize_table_modal"
      centered
      :title="$t('customize_table')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <div class="row clearfix">
          <div class="col-md-12">
            <div class="form-row" style="padding: 0 20px">
              <div
                class="form-group col-md-6"
                v-for="(field, i) in wallet_table_fields"
                :key="i"
              >
                <input
                  type="checkbox"
                  class="form-check-input"
                  aria-label="Checkbox for following text input"
                  :value="field"
                  :id="'f_' + field"
                  v-model="wallet_table_selected_fields"
                  style="vertical-align: middle;"
                /><label
                  class="form-check-label"
                  :for="'f_' + field"
                  style="color: blue;"
                >
                  {{ $t(field.toLowerCase()) }}</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <br />

            <b-card no-body class="card">
              <b-card-header>
                {{ $t("balance") }}: {{ balance }} {{ $t("sar") }}
                <div class="card-tools">
                  <div class="input-group input-group-sm">
                    <i
                      class="fas fa-cog c_pointer customize_table"
                      :title="$t('customize_table')"
                      @click="wallet_customizeTable"
                    ></i>
                    <input
                      type="text"
                      class="form-control float-right"
                      :placeholder="$t('search')"
                      v-model="wallet_search_key"
                    />
                    <div class="input-group-append">
                      <button
                        type="submit"
                        :disabled="wallet_search_disabled"
                        @click.prevent="wallet_searchAll"
                        class="btn btn-default"
                      >
                        <i
                          class="fa fa-spinner fa-spin"
                          v-if="wallet_search_disabled"
                        ></i>
                        <i class="fas fa-search" v-else></i>
                      </button>
                    </div>
                  </div>
                </div>
              </b-card-header>
              <!-- /.card-header -->
              <b-card-body class="table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th v-if="wallet_table_selected_fields.includes('#')">
                        #
                      </th>
                      <th
                        v-if="
                          wallet_table_selected_fields.includes(
                            'transaction_id'
                          )
                        "
                      >
                        {{ $t("transaction_id") }}
                      </th>
                      <th
                        v-if="wallet_table_selected_fields.includes('order_id')"
                        class="sort"
                        @click="wallet_sortTable('order_id')"
                      >
                        {{ $t("order_id") }}
                      </th>
                      <th
                        v-if="
                          wallet_table_selected_fields.includes(
                            'transaction_type'
                          )
                        "
                        class="sort"
                        @click="wallet_sortTable('transaction_type')"
                      >
                        {{ $t("transaction_type") }}
                      </th>
                      <th
                        v-if="
                          wallet_table_selected_fields.includes(
                            'transaction_alias'
                          )
                        "
                        class="sort"
                        @click="wallet_sortTable('transaction_alias')"
                      >
                        {{ $t("transaction_alias") }}
                      </th>
                      <th
                        v-if="wallet_table_selected_fields.includes('amount')"
                        class="sort"
                        @click="wallet_sortTable('amount')"
                      >
                        {{ $t("amount") }}
                      </th>
                      <th
                        v-if="wallet_table_selected_fields.includes('balance')"
                        class="sort"
                        @click="wallet_sortTable('close_balance')"
                      >
                        {{ $t("balance") }}
                      </th>

                      <th
                        v-if="wallet_table_selected_fields.includes('is_due')"
                        class="sort"
                        @click="sortTable('is_due')"
                      >
                        {{ $t("is_due") }}
                      </th>
                      <th
                        v-if="wallet_table_selected_fields.includes('due_date')"
                        class="sort"
                        @click="sortTable('due_date')"
                      >
                        {{ $t("due_date") }}
                      </th>
                      <th
                        v-if="
                          wallet_table_selected_fields.includes('created_at')
                        "
                        class="sort"
                        @click="sortTable('created_at')"
                      >
                        {{ $t("created_at") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        :colspan="wallet_table_selected_fields.length"
                        v-if="!wallet_load"
                      >
                        <i class="fa fa-sync  fa-spin load-con"></i>
                      </td>
                      <td
                        :colspan="wallet_table_selected_fields.length"
                        class="no_data"
                        v-if="wallet_load && wallet.length == 0"
                      >
                        {{ $t("no_data") }}
                      </td>
                    </tr>
                    <tr
                      v-for="(item, i) in wallet"
                      :key="i"
                      v-show="wallet_load"
                    >
                      <td v-if="wallet_table_selected_fields.includes('#')">
                        {{ i + 1 }}
                      </td>
                      <td
                        v-if="
                          wallet_table_selected_fields.includes(
                            'transaction_id'
                          )
                        "
                      >
                        {{ item.transaction_id }}
                      </td>
                      <td
                        v-if="wallet_table_selected_fields.includes('order_id')"
                      >
                        {{ item.order_id || "-" }}
                      </td>
                      <td
                        v-if="
                          wallet_table_selected_fields.includes(
                            'transaction_type'
                          )
                        "
                      >
                        {{ item.transaction_type }}
                      </td>
                      <td
                        v-if="
                          wallet_table_selected_fields.includes(
                            'transaction_alias'
                          )
                        "
                      >
                        {{ item.transaction_alias }}
                      </td>
                      <td
                        v-if="wallet_table_selected_fields.includes('amount')"
                      >
                        {{ item.amount }}
                      </td>
                      <td
                        v-if="wallet_table_selected_fields.includes('balance')"
                      >
                        {{ item.close_balance }}
                      </td>
                      <td
                        v-if="wallet_table_selected_fields.includes('is_due')"
                      >
                        {{ item.is_due ? $t("is_due") : $t("not_due") }}
                      </td>
                      <td
                        v-if="wallet_table_selected_fields.includes('due_date')"
                        v-html="dateTimeFormat(item.due_date)"
                      ></td>
                      <td
                        v-if="
                          wallet_table_selected_fields.includes('created_at')
                        "
                        v-html="dateTimeFormat(item.created_at)"
                      ></td>
                    </tr>
                  </tbody>
                </table>
              </b-card-body>
              <!-- /.card-body -->
              <b-card-footer class="card-footer clearfix">
                <b-pagination
                  class="m-2"
                  v-model="wallet_currentPage"
                  :total-rows="wallet_rows"
                  :per-page="wallet_perPage"
                  :prev-text="$t('previous')"
                  :next-text="$t('next')"
                  align="right"
                  :first-number="true"
                  :last-number="true"
                ></b-pagination>
              </b-card-footer>
            </b-card>

            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: "Wallet",
  data() {
    return {
      wallet_load: false,
      wallet_table_fields: [
        "#",
        "transaction_id",
        "order_id",
        "transaction_alias",
        "transaction_type",
        "amount",
        "balance",
        "is_due",
        "due_date",
        "created_at"
      ],
      wallet_table_selected_fields: [],
      wallet: [],
      balance: 0,
      wallet_search_key: "",
      wallet_old_search_key: "",
      wallet_currentPage: 1,
      wallet_perPage: 1,
      wallet_rows: 1,
      wallet_search_disabled: false,
      wallet_sort_key: "",
      wallet_sort_type: "asc"
    };
  },
  created() {
    this.wallet_table_selected_fields = this.$store.state.wallet_selected_fields;
    this.getWallet();
  },
  watch: {
    wallet_currentPage() {
      this.getWallet();
    },
    table_selected_fields() {
      this.$store.commit("setWalletSelectedFields", this.table_selected_fields);
    }
  },
  methods: {
    getWallet() {
      return this.axios
        .get("wallet", {
          params: {
            page: this.wallet_currentPage,
            search_key: this.wallet_search_key,
            sort_key: this.wallet_sort_key,
            sort_type: this.wallet_sort_type
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.wallet = res.result.wallets.data;
            this.wallet_rows = res.result.wallets.total;
            this.wallet_perPage = res.result.wallets.per_page;
            this.balance = res.result.balance;
          }
        })
        .catch(error => {
          this.handleError("getWallet", error);
        })
        .finally(() => {
          this.wallet_load = true;
        });
    },
    wallet_sortTable(key) {
      if (this.wallet_sort_key != key) {
        this.wallet_sort_type = "asc";
      } else {
        this.wallet_sort_type = this.wallet_sort_type == "asc" ? "desc" : "asc";
      }
      this.wallet_sort_key = key;
      this.getWallet();
    },
    wallet_searchAll() {
      if (this.wallet_search_key != this.wallet_old_search_key) {
        this.wallet_old_search_key = this.wallet_search_key;
        this.wallet_search_disabled = true;
        this.getWallet().then(() => {
          this.wallet_search_disabled = false;
        });
      }
    },
    wallet_customizeTable() {
      this.$refs.wallet_customize_table_modal.show();
    }
  }
};
</script>
